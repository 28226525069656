import React from 'react';
import { AiOutlineClockCircle } from "react-icons/ai";

const WeekCard = ({ data, title, isCurrentWeek, children }) => {
	return (
		<div className='mod_lab_out'>
			{/* <p className={`${isCurrentWeek ? 'text_highlht' : 'header_box_text'}`}>  */}
			<p className={`header_box_text`}> 
				{title}
			</p>
			<div
				className={
					'header_box' + ' ' + `${title == 'Sourcing Week'|| title == 'Order Week' ? 'no_highlight' : 'highlht'}`
				}
			>
				{data ? data : '---'}
				{isCurrentWeek ? 
					<AiOutlineClockCircle className={'clock_icon'} color='#002776'/> 
				: null}
			</div>

			{children}
		</div>
	);
};

export default WeekCard;
