import React from 'react';
import { formatCurrency, markNumberPosition } from '../../../util';
import TotalCard from '../../Cards/TotalCard';

/**
 * @author
 * @function MatrixTotals
 **/

const MatrixTotals = ({
	orderTotalBox,
	dispalyOrderTotalBox,
	orderTotalWeight,
	displayOrderTotalWeight,
	orderTotalUSD,
	priceInfo={},
	displayOrderTotalUSD,
}) => {
	return (
		<>
			{dispalyOrderTotalBox && (
				<TotalCard
					title='Order Total Box'
					data={`${orderTotalBox || '--'}${
						orderTotalBox && orderTotalBox == 1 ? ' Box' : ' Boxes'
					}`}
					hasTitleData={<p></p>}
				/>
			)}
			{displayOrderTotalWeight && (
				<TotalCard
					title='Order Total Weight'
					data={`${
						orderTotalWeight
							? markNumberPosition(orderTotalWeight.toFixed(2))
							: '--'
					} kg`}
					hasTitleData={<p></p>}
				/>
			)}
			{displayOrderTotalUSD && (
				<TotalCard
					title={`Order Total ${(priceInfo.currencyType)?priceInfo.currencyType:'USD'}`}
					data={
						orderTotalUSD === 0
							? '----'
							: `${formatCurrency(parseFloat(orderTotalUSD),(priceInfo.currencyType)?priceInfo.currencyType:'USD')}`
					}
					hasTitleData={<p></p>}
				/>
			)}
		</>
	);
};

export default MatrixTotals;
