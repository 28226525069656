import React, { useState, useEffect } from "react";
import axios from "axios";
import Sidebar from "../Sidebar/Sidebar";
import { Link } from "react-router-dom";
import { MdNavigateNext } from "react-icons/md";
import "./Sell.css";
import SelectorCard from "../Cards/SelectorCard";
import CommonServices from "../../api/CommonServices";

const Sell = () => {
  const [selectedSeaFoodDestination, setSelectedSeaFoodDestination] = useState(
    []
  );
  const [unselectedSeaFoodDestination, setUnselectedSeaFoodDestination] =
    useState([]);
  const [selectedPreProspects, setSelectedPreProspects] = useState([]);
  const [unselectedPreProspects, setUnselectedPreProspects] = useState([]);
  const [selectedProspects, setSelectedProspects] = useState([]);
  const [unselectedProspects, setUnselectedProspects] = useState([]);
  const [selectedCustomers, setSelectedCustomers] = useState([]);
  const [unSelectedCustomers, setUnselectedCustomers] = useState([]);

  const [selectedseaFoodDisable, setselectedSeaFoodDisable] = useState(true);
  const [unselectedseaFoodDisable, setunselectedSeaFoodDisable] =
    useState(true);

  const [selectedpreprospectDisable, setselectedpreprospectDisable] =
    useState(true);
  const [unselectedpreprospectDisable, setunselectedpreprospectDisable] =
    useState(true);

  const [selectedprospectDisable, setselectedprospectDisable] = useState(true);
  const [unselectedprospectDisable, setunselectedprospectDisable] =
    useState(true);

  const [selectedcustomerDisable, setselectedcustomerDisable] = useState(true);
  const [unselectedcustomerDisable, setunselectedcustomerDisable] =
    useState(true);

  useEffect(() => {
    getSelectedSeafoodDestination();
    getUnselectedSeafoodDestination();
    getSelectedPreProspects();
    getUnselectedPreProspects();
    getSelectedProspects();
    getUnselectedProspects();
    getSelectedCustomers();
    getUnselectedCustomers();
  }, []);

  const getSelectedCustomers = async () => {
    try {
      const SelectCustomer = await CommonServices.SelectedCustomerLocation();

      if (SelectCustomer.data) {
        const { data } = SelectCustomer;
        setSelectedCustomers(data.results);
        if (data.results.length === 0) {
          setselectedcustomerDisable(true);
        } else {
          setselectedcustomerDisable(false);
        }
      }
    } catch (err) {
      console.log("ERROR Selected Customers", err);
    }
  };

  const getUnselectedCustomers = async () => {
    try {
      const SelectCustomer = await CommonServices.UnSelectedCustomerLocation();

      if (SelectCustomer.data) {
        const { data } = SelectCustomer;
        setUnselectedCustomers(data.results);
        if (data.results.length === 0) {
          setunselectedcustomerDisable(true);
        } else {
          setunselectedcustomerDisable(false);
        }
      }
    } catch (err) {
      console.log("ERROR Unselected Customers", err);
    }
  };

  const getSelectedProspects = async () => {
    try {
      const SelectProspect = await CommonServices.SelectedProspects();

      if (SelectProspect.data) {
        const { data } = SelectProspect;
        setSelectedProspects(data.results);
        if (data.results.length === 0) {
          setselectedprospectDisable(true);
        } else {
          setselectedprospectDisable(false);
        }
      }
    } catch (err) {
      console.log("ERROR Selected Prospect", err);
    }
  };

  const getUnselectedProspects = async () => {
    try {
      const UnselectProspect = await CommonServices.UnselectedProspects();

      if (UnselectProspect.data) {
        const { data } = UnselectProspect;
        setUnselectedProspects(data.results);
        if (data.results.length === 0) {
          setunselectedprospectDisable(true);
        } else {
          setunselectedprospectDisable(false);
        }
      }
    } catch (err) {
      console.log("ERROR Unselected Prospect", err);
    }
  };

  const getSelectedPreProspects = async () => {
    try {
      const SelectPreprospects = await CommonServices.SelectPreprospects();

      if (SelectPreprospects.data) {
        const { data } = SelectPreprospects;
        setSelectedPreProspects(data.results);
        if (data.results.length === 0) {
          setselectedpreprospectDisable(true);
        } else {
          setselectedpreprospectDisable(false);
        }
      }
    } catch (err) {
      console.log("ERROR Selected Preprospect", err);
    }
  };

  const getUnselectedPreProspects = async () => {
    try {
      const UnselectPreprospect = await CommonServices.UnSelectPreprospects();

      if (UnselectPreprospect.data) {
        const { data } = UnselectPreprospect;
        setUnselectedPreProspects(data.results);
        if (data.results.length === 0) {
          setunselectedpreprospectDisable(true);
        } else {
          setunselectedpreprospectDisable(false);
        }
      }
    } catch (err) {
      console.log("ERROR Unselect Preprospect");
    }
  };

  const getUnselectedSeafoodDestination = async () => {
    try {
      const UnselectDestination =
        await CommonServices.UnSelectSeafoodDestination();

      if (UnselectDestination.data) {
        const { data } = UnselectDestination;
        setUnselectedSeaFoodDestination(data.results);
        if (data.results.length === 0) {
          setunselectedSeaFoodDisable(true);
        } else {
          setunselectedSeaFoodDisable(false);
        }
      }
    } catch (err) {
      console.log("ERROR Unselect Seafood Destination");
    }
  };

  const getSelectedSeafoodDestination = async () => {
    try {
      const SelectDestination = await CommonServices.SelectSeafoodDestination();

      if (SelectDestination.data) {
        const { data } = SelectDestination;
        setSelectedSeaFoodDestination(data.results);
        if (data.results.length === 0) {
          setselectedSeaFoodDisable(true);
        } else {
          setselectedSeaFoodDisable(false);
        }
      }
    } catch (err) {
      console.log("ERROR Unselect Seafood Destination");
    }
  };

  const customerSelection = async (id, val) => {
    let data = {};
    if (val === "addAll") {
      const customerShipmentLocations = unSelectedCustomers
        .filter((x) => x.is_selected === false)
        .map((x) => x.id);
      data["selected"] = true;
      data["customer_shipment_location"] = customerShipmentLocations;
    } else if (val === "removeAll") {
      const customerShipmentLocations = selectedCustomers
        .filter((x) => x.is_selected === true)
        .map((x) => x.id);
      data["selected"] = false;
      data["customer_shipment_location"] = customerShipmentLocations;
    } else if (val === "select") {
      data["selected"] = true;
      data["customer_shipment_location"] = [id];
    } else if (val === "unselect") {
      data["selected"] = false;
      data["customer_shipment_location"] = [id];
    }

    try {
      const CustomerShipment = await CommonServices.setCustomerShipment(data);
      if (CustomerShipment.data) {
        getSelectedCustomers();
        getUnselectedCustomers();
      }
    } catch (err) {
      console.log("Customer Shipment SET ERROR", err);
    }
  };

  const prospectSelection = async (id, val) => {
    let data = {};
    if (val === "addAll") {
      const prospectArray = unselectedProspects
        .filter((x) => x.is_selected === false)
        .map((x) => x.id);

      data["selected"] = true;
      data["prospect"] = prospectArray;
    } else if (val === "removeAll") {
      const prospectArray = selectedProspects
        .filter((x) => x.is_selected === true)
        .map((x) => x.id);

      data["selected"] = false;
      data["prospect"] = prospectArray;
    } else if (val === "select") {
      data["selected"] = true;
      data["prospect"] = [id];
    } else if (val === "unselect") {
      data["selected"] = false;
      data["prospect"] = [id];
    }

    try {
      const SelectProspect = await CommonServices.PostProspects(data);
      if (SelectProspect.data) {
        getSelectedProspects();
        getUnselectedProspects();
      }
    } catch (err) {
      console.log("Customer Shipment SET ERROR", err);
    }
  };

  const preProspectSelection = async (id, val) => {
    let data = {};
    if (val === "addAll") {
      const prospectArray = unselectedPreProspects
        .filter((x) => x.is_selected === false)
        .map((x) => x.id);

      data["selected"] = true;
      data["pre_prospect"] = prospectArray;
    } else if (val === "removeAll") {
      const prospectArray = selectedPreProspects
        .filter((x) => x.is_selected === true)
        .map((x) => x.id);

      data["selected"] = false;
      data["pre_prospect"] = prospectArray;
    } else if (val === "select") {
      data["selected"] = true;
      data["pre_prospect"] = [id];
    } else if (val === "unselect") {
      data["selected"] = false;
      data["pre_prospect"] = [id];
    }

    try {
      const SelectPreprospect = await CommonServices.PostPreProspect(data);
      if (SelectPreprospect.data) {
        getUnselectedPreProspects();
        getSelectedPreProspects();
      }
    } catch (err) {
      console.log("Preprospect SET ERROR", err);
    }
  };

  const destinationSelection = async (id, val) => {
    let data = {};
    if (val === "addAll") {
      const prospectArray = unselectedSeaFoodDestination
        .filter((x) => x.is_selected === false)
        .map((x) => x.id);

      data["selected"] = true;
      data["seafood_destination"] = prospectArray;
    } else if (val === "removeAll") {
      const prospectArray = selectedSeaFoodDestination
        .filter((x) => x.is_selected === true)
        .map((x) => x.id);

      data["selected"] = false;
      data["seafood_destination"] = prospectArray;
    } else if (val === "select") {
      data["selected"] = true;
      data["seafood_destination"] = [id];
    } else if (val === "unselect") {
      data["selected"] = false;
      data["seafood_destination"] = [id];
    }

    try {
      const SelectDestination = await CommonServices.PostDestination(data);
      if (SelectDestination.data) {
        getSelectedSeafoodDestination();
        getUnselectedSeafoodDestination();
      }
    } catch (err) {
      console.log("Preprospect SET ERROR", err);
    }
  };

  return (
    <>
      <section className={"sell_body_wrap packing_wrapper"}>
        {/* <Sidebar /> */}

        <div style={{ paddingBottom: "30px" }}>
          <div className="scrl_out" style={{ paddingBottom: "30px" }}>
            <section className={"cmn_head"}>
              <div className={"cmn_head_align"}>
                <p>Thursday</p>
                <Link to={"/sell/friday"}>
                  <div className={"btn next_button"}>
                    <p className={"next_link"}>Friday</p>
                    <div className={"next_icon next_icon_align"}>
                      <MdNavigateNext
                        size={20}
                        style={{
                          color: "white",
                        }}
                      />
                    </div>
                  </div>
                </Link>
              </div>

              {/* <label>Create New Orders & Modify base orders here</label> */}
            </section>
            <p>Selected</p>
            <div className={"card_con"}>
              <SelectorCard
                title="Seafood Destination"
                state={selectedSeaFoodDestination}
                setState={destinationSelection}
                disable={selectedseaFoodDisable}
              />
              <SelectorCard
                title="Pre Prospects"
                state={selectedPreProspects}
                setState={preProspectSelection}
                disable={selectedpreprospectDisable}
              />

              <SelectorCard
                title="Prospects"
                state={selectedProspects}
                setState={prospectSelection}
                disable={selectedprospectDisable}
              />
              <SelectorCard
                title="Customers"
                state={selectedCustomers}
                setState={customerSelection}
                disable={selectedcustomerDisable}
              />
            </div>
            <p>Not Selected</p>
            <div className={"card_con"}>
              <SelectorCard
                title="Seafood Destination"
                state={unselectedSeaFoodDestination}
                setState={destinationSelection}
                is_selected={false}
                disable={unselectedseaFoodDisable}
              />
              <SelectorCard
                title="Pre Prospects"
                state={unselectedPreProspects}
                setState={preProspectSelection}
                is_selected={false}
                disable={unselectedpreprospectDisable}
              />
              <SelectorCard
                title="Prospects"
                state={unselectedProspects}
                setState={prospectSelection}
                is_selected={false}
                disable={unselectedprospectDisable}
              />
              <SelectorCard
                title="Customers"
                state={unSelectedCustomers}
                setState={customerSelection}
                is_selected={false}
                disable={unselectedcustomerDisable}
              />
            </div>
          </div>
        </div>
      </section>
    </>
  );
};

export default Sell;
