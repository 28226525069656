import axios from 'axios';
import BrowserStorage from '../utils/BrowserStorage';
import { resolve } from './resolve';
class API {
	constructor() {
		// axios.defaults.baseURL = "https://localhost:3000";
		axios.interceptors.request.use(
			(config) => {
				const token = BrowserStorage.getValue('token');
				console.log(token, 'tokentoken');
				if (token) {
					const item = { ...config };
					item.headers.Authorization = `Bearer ${token}`;
					return item;
				}
				return config;
			},
			(error) => {
				return Promise.reject(error);
			}
		);
		axios.interceptors.response.use(
			(responseInter) => {
				return responseInter;
			},
			(err) => {
				return new Promise(function (resolve, reject) {
					if (err.response && err.response.status === 203) {
						setTimeout(() => {
							window.location.replace('/access-denied');
						});
						console.log(err)
						return false;
						//}
					}
					console.log(err)
					throw err;
				});
			}
		);
	}

	async get(url, config = {}) {
		return await resolve(axios.get(url, config).then((res) => res.data));
	}

	async post(url, data = {}) {
		return await resolve(axios.post(url, data).then((res) => res.data));
	}

	async put(url, data = {}) {
		return await resolve(axios.put(url, data).then((res) => res.data));
	}

	async delete(url) {
		return await resolve(axios.delete(url).then((res) => res.data));
	}
}

export default new API();
