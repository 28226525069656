import React, { Fragment } from 'react';
import '../../styles/PackingManager.css';

function PackingMatrix({
	toggle = true,
	purchase_matrix = {},
	remaining_purchase_data = {},
	shipment_matrix = {},
	selected_shipment = '',
	selected_station = '',
	handlePackingDataChange = () => {},
}) {
	const [weightClass, setWeightClass] = React.useState([]);

	React.useEffect(() => {
		// let weight_classes;
		if (Object.keys(purchase_matrix).length > 0) {
			setWeightClass(Object.keys(purchase_matrix));
		}
		return () => {
			setWeightClass([]);
		};
	}, []);

	return (
		<div className='fc_scroll'>
			<div className='packing-details'>
				<p>
					<span>Shipment:</span> {selected_shipment}
				</p>
				<p>
					<span>Fishery:</span> {selected_station}
				</p>
			</div>
			<table className='table-new table-packing'>
				<WeekHeader />
				{toggle && (
					<tbody
						style={{ overflowX: 'auto', width: '100%', position: 'relative' }}
						className='fc_scroll'
					>
						{weightClass.map((weight, i) => {
							return (
								<Fragment key={i}>
									<WeightRow
										weight={weight}
										purchaser_data={purchase_matrix[weight]}
										remaining_purchase_data={remaining_purchase_data[weight]}
										packing_data={shipment_matrix[weight]}
										handlePackingDataChange={handlePackingDataChange}
									/>
								</Fragment>
							);
						})}
					</tbody>
				)}
			</table>
		</div>
	);
}

const WeekHeader = () => {
	return (
		<thead>
			<tr>
				<th></th>
				<th>Purchase</th>
				<th>Remaining Purchase</th>
				<th>Packing Batch</th>
				<th>Residual</th>
			</tr>
		</thead>
	);
};

const WeightReducer = (state, { type, payload }) => {
	switch (type) {
		case 'SET_RESIDUAL':
			return {
				...state,
				residualValue: payload.residualValue,
			};

		default:
			return {
				...state,
			};
	}
};

const WeightRow = ({
	weight = '',
	purchaser_data = {},
	remaining_purchase_data = {},
	packing_data = {},
	handlePackingDataChange = () => {},
}) => {
	const [state, dispatch] = React.useReducer(WeightReducer, {
		residualValue: remaining_purchase_data[0].volume - packing_data[0].volume,
	});

	return (
		<tr>
			<td>HOG {weight} kg</td>
			<td>{purchaser_data[0].volume === 0 ? '-' : purchaser_data[0].volume}</td>
			<td>
				{remaining_purchase_data[0].volume === 0
					? '-'
					: remaining_purchase_data[0].volume}
			</td>
			<td>
				<input
					className='input-wrap'
					type='number'
					value={packing_data[0].volume}
					onChange={(e) => {
						e.preventDefault();
						if (e.target.value >= 0) {
							handlePackingDataChange(e.target.value, weight);
							dispatch({
								type: 'SET_RESIDUAL',
								payload: {
									residualValue: remaining_purchase_data[0].volume - e.target.value,
								},
							});
						}
					}}
				/>
			</td>
			<td>{state.residualValue === 0 ? '-' : state.residualValue}</td>
		</tr>
	);
};

export default PackingMatrix;
