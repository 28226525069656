import React from 'react'
import { Link } from 'react-router-dom';
import Sidebar from '../components/Sidebar/Sidebar';
import SeafoodCalendar from '../components/SeafoodCalendar/SeafoodCalendar';

const SeafoodCalendarPage = () => {
    return (
        <section className={'body_wrap'}>
        {/* <Sidebar /> */}
        <div className={'cont_wp'}>
            <Link to={'/home'}>
                <p className={'Back_link'}>Back</p>
             </Link>
             <section className={'cmn_head'}>
                 <p>Seafood Calendar</p>
            </section>
            <SeafoodCalendar />
        </div>
    </section>
    )
}

export default SeafoodCalendarPage
