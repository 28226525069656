import React from 'react'
import '../styles/createPreprospect.css'
import {useHistory, useParams} from 'react-router-dom'
import CommonServices from '../api/CommonServices'
import { ToastsStore } from 'react-toasts'
import UserServices from '../api/UserServices'
import { IoMdAddCircle } from 'react-icons/io'
import { AiFillMinusCircle } from 'react-icons/ai'

function EditCustomerPage() {

    const [entity, setEntity] = React.useState(1)

    const [customerAbbr, setCustomerAbbr] = React.useState('')
    const [prospectShort, setProspectShort] = React.useState('')
    const [prospect, setProspect] = React.useState('')
    const [address, setAddress] = React.useState('')
    const [invoiceCSLAddress, setInvoiceCSLAddress] = React.useState('')
    const [inoviceCSLAddressTwo, setInoviceCSLAddressTwo] = React.useState('')
    const [tempinvoiceCSLAddressTwo, setTempinvoiceCSLAddressTwo] = React.useState('')
    const [invoiceCSLCity, setInvoiceCSLCity] = React.useState('')
    const [invoiceCSLZip, setInvoiceCSLZip] = React.useState('')
    const [email, setEmail] = React.useState('')

    const history = useHistory();
    const URLParams = useParams();

    const [seafoodCountry, setSeafoodCountry] = React.useState([])
    const [selectedSC, setSelectedSC] = React.useState('')
    const [seafoodAirport, setSeafoodAirport] = React.useState([])
    const [selectedAirport, setSelectedAirport] = React.useState('')
    const [targetProduct, setTargetProduct] = React.useState([])
    const [selectedProduct, setSelectedProduct] = React.useState('')
    const [currency, setCurrency] = React.useState([])
    const [selectedCurrency, setSelectedCurrency] = React.useState('')
    const [isMultiInvoice, setIsMultiInvoice] = React.useState(false)

    React.useEffect(() => {
       switch (URLParams.access_type) {
            case 'customer_shipment_location':
              setEntity(3)
              break;
            case 'pre-prospect':
              setEntity(1)
              break;
            case 'prospect':
              setEntity(2)
              break;
           default:
              setEntity(1)
              break;
       } 
    }, [])

    const fetchSeafoodCountry = async() => {
      try {
        const getSC = await CommonServices.getSeafoodCountries();
        if(getSC.data){
          const {success} = getSC.data;
          success && setSeafoodCountry(getSC?.data?.results)
        }
      } catch (err) {
        setSeafoodCountry([])
      }
    }

    const fetchAirport = async (value) =>{
      try {
        const getAirport = await CommonServices.getAirport(value)
        if(getAirport.data){
          setSeafoodAirport(getAirport?.data)
        }
      } catch (err) {
        setSeafoodAirport([])
      }
    }

    const fetchTargetProduct = async() =>{
      try {
        const getProduct = await CommonServices.getTargetProduct();
        if(getProduct.data){
          const {success} = getProduct.data
          success && setTargetProduct(getProduct?.data?.result)
        }
      } catch (err) {
        setTargetProduct([])
      }
    }

    const fetchCurrency = async() =>{
      try {
        const getCurrency = await CommonServices.getCurrency();
        if(getCurrency.data){
          const {success} = getCurrency.data
          success && setCurrency(getCurrency.data?.data)
        }
      } catch (err) {
        setCurrency([])
      }
    }

    const fetchIDDetails = async() =>{
        try {
            let access_type = URLParams.access_type.replace(/_/g, "-")
            const getData = await UserServices.userDetail({customerId:URLParams.id,access_type})
            if(getData.data){
                const {success} = getData.data;
                console.log('data',getData.data)
                if(success){
                fetchAirport(getData.data.result.seafood_country?.id)
                setCustomerAbbr(getData.data.result.cabbrv)
                setProspectShort(getData.data.result.prospect_short)
                setProspect(getData.data.result.prospect)
                setAddress(getData.data.result.invoice_address)
                setEmail(getData.data.result.email)
                setSelectedSC(getData.data.result.seafood_country?.id)
                setSelectedAirport(getData.data.result.seafood_airport?.airport?.iata_code)
                setSelectedProduct(getData.data.result.target_product)
                setSelectedCurrency(getData.data.result.currency)
                if(access_type==='customer-shipment-location'){
                  setInvoiceCSLAddress(getData.data.result.invoice_address_1)
                  setInoviceCSLAddressTwo(getData.data.result.invoice_address_2)
                  getData.data.result.invoice_address_2 !== ''?setIsMultiInvoice(true):setIsMultiInvoice(false)
                  setInvoiceCSLCity(getData.data.result.invoice_address_city)
                  setInvoiceCSLZip(getData.data.result.invoice_address_zip)
                }
                }
            }
        } catch (error) {
            console.log('error',error)
            ToastsStore.error("An Error Occurred on Retrieving Data")

        }
    }

    React.useEffect(() => {
      fetchSeafoodCountry()
      fetchTargetProduct()
      fetchCurrency()
      fetchIDDetails()
    }, [])

    const handleChangeSC = (e) =>{
      selectedAirport!=='' && setSelectedAirport('')
      fetchAirport(e.target.value)
      setSelectedSC(e.target.value)
    }

    const handleChangeAirport = (e) =>{
      setSelectedAirport(e.target.value)
    }

    const handleChangeProduct = (e) =>{
      setSelectedProduct(e.target.value)
    }

    const handleChangeCurrency = (e) =>{
      setSelectedCurrency(e.target.value)
    }

    const validateEmail = (email) => {
      return String(email)
        .toLowerCase()
        .match(
          /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/
        );
    };

    // const specialCharCheck =(str) =>{
    //   var a = /^[a-zA-Z]+$/
    //   return a.test(str)
    // }

    const updatingCustomer = async() =>{
      try {
        if(customerAbbr==='' || prospect===''|| prospectShort==='' || selectedSC==='' || selectedAirport===''||selectedCurrency==='' || email==='' || selectedProduct==='' || customerAbbr===null || prospect===null|| prospectShort===null || selectedSC===null || selectedAirport===null||selectedCurrency===null || email===null || selectedProduct===null){
          ToastsStore.warning('Fill out every details.')
          return;
        }

        if(entity===3){
          if(invoiceCSLAddress==='' || invoiceCSLCity==='' || invoiceCSLZip===''){
            ToastsStore.warning('Fill out every details.')
            return
          }
        }
        else{
          if(address===undefined || address===''){
            ToastsStore.warning('Fill out every details.')
            return
          }
        }
        
        if(customerAbbr.length>3){
          ToastsStore.warning('Customer Abbreviation cannot be more than 3 Characters');
          return;
        }

        if(!validateEmail(email)){
          ToastsStore.warning('give a valid email.')
          return
        }

        if(prospect.length>50 || prospectShort.length>50){
          ToastsStore.warning('Name cannot be more than 50 characters.')
          return
        }

        if(email.length>35){
          ToastsStore.warning('Email is too long. give another email')
          return
        }

        // if(!specialCharCheck(prospect) || !specialCharCheck(prospectShort)){
        //   ToastsStore.warning('Name cannot contain any special characters or numbers.')
        //   return
        // }
        


        let bodyData = {
          "cabbrv" : customerAbbr,
          "prospect":prospect, 
          "prospect_short":prospectShort, 
          "seafood_country":selectedSC, 
          "seafood_airport":selectedAirport,
          "currency":selectedCurrency,
          "invoice_address_1":invoiceCSLAddress,
          "invoice_address_2":inoviceCSLAddressTwo,
          "invoice_address_zip":invoiceCSLZip,
          "invoice_address_city":invoiceCSLCity,
          "invoice_address":address,
          "email":email,
          "target_product":selectedProduct
          }
        let postData;
        if(entity===1){
          postData = await CommonServices.updateCustomerByPreprospect(URLParams.id,bodyData);
        }
        if(entity===2){
          postData = await CommonServices.updateCustomerByProspect(URLParams.id,bodyData)
        }
        if(entity===3){
          postData = await CommonServices.updateCustomerByCSL(URLParams.id,bodyData);
        }
        if(postData.data){
          
          ToastsStore.success('Successfully Updated.')
          setTimeout(() => {
            history.replace('/business')
          }, 1000);
        }
        if(postData.error){
          const {response} = postData.error
          if(response.status === 412){
            Object.values(response.data.errors).forEach(item=>{
              ToastsStore.error(item)
            })
          }
          else{
            ToastsStore.error('An Error Occurred, Try Again.')
          }
        }
      } catch (err) {
        ToastsStore.error('An Error Occurred, Try Again.')
      }
    }

    const handleDeleteMultiInvoice = (e,prevAddressTwo) =>{
      e.preventDefault()
      setTempinvoiceCSLAddressTwo(prevAddressTwo)
      setInoviceCSLAddressTwo('')
      setIsMultiInvoice(false)
    }

    const handleAddMultiInvoice = (e) =>{
      e.preventDefault()
      setInoviceCSLAddressTwo(tempinvoiceCSLAddressTwo)
      setIsMultiInvoice(true)
    }
    return (
        <section className="body_wrap">
          <div className={'cont_wp'}>
            <div className={'scrl_out'}>
              <section>
              <p className={'Back_link'} onClick={()=>history.goBack()}>
							Back
              </p>
              <p className='cp_header'>Edit {entity===1?'Pre Prospect':entity===2?'Prospect':'Customer'}</p>
              </section>
              <form>
                  <div className='edit-wrapper'>
                    <div className='basic_address_wrapper'>
                      <div className='cp_input_wrap' style={{display:'grid',gridTemplateColumns:'1fr 3fr',gap:20}}>
                        <div>
                          <label className='cp_input_header'>Cabbrv</label>
                          <input type='text' className='cp_input_field' value={customerAbbr} onChange={(e)=>setCustomerAbbr(e.target.value)}/>
                          <label className='cp_short_label'>Customer abbreviation</label>
                        </div>
                        <div>
                          <label className='cp_input_header'>Prospect Short</label>
                          <input type='text' className='cp_input_field' value={prospectShort} onChange={(e)=>setProspectShort(e.target.value)}/>
                          <label className='cp_short_label'>The commonly used (shortened) name or acronym of the prospect</label>
                        </div>
                      </div>
                      <div className='cp_input_wrap'>
                        <label className='cp_input_header'>Prospect</label>
                        <input type='text' className='cp_input_field' value={prospect} onChange={(e)=>setProspect(e.target.value)}/>
                        <label className='cp_short_label'>The full official name of the prospect , including legal entity type</label>
                      </div>
                      {entity!==3?<div className='cp_input_wrap'>
                        <label className='cp_input_header'>Address</label>
                        <textarea rows={10} type='text' className='cp_input_field cp_text_area' value={address} onChange={(e)=>setAddress(e.target.value)} />
                      </div>:<><div className='cp_input_wrap'>
                      <label className='cp_input_header'>Invoice Address 1</label>
                        <input type='text' className='cp_input_field' value={invoiceCSLAddress} onChange={(e)=>setInvoiceCSLAddress(e.target.value)}/>
                        </div>
                      
                      {isMultiInvoice || inoviceCSLAddressTwo !=='' ?<><div className='cp_input_wrap'>
                      <label className='cp_input_header'>Invoice Address 2</label>
                        <span className='cp_input_invoce_wrap'><input type='text' className='cp_input_field' value={inoviceCSLAddressTwo} onChange={(e)=>setInoviceCSLAddressTwo(e.target.value)}/><AiFillMinusCircle  color='#002776' size={24} onClick={(e)=>handleDeleteMultiInvoice(e,inoviceCSLAddressTwo)}/></span>
                        </div>
                        </>:<span className='cp_add_new_wrap'><span /><IoMdAddCircle color='#002776' size={24} onClick={handleAddMultiInvoice}/></span>}
                        <div className='cp_input_wrap' style={{display:'grid',gridTemplateColumns:'3fr 1fr',gap:20}}>
                        <div>
                          <label className='cp_input_header'>City</label>
                          <input type='text' className='cp_input_field' value={invoiceCSLCity} onChange={(e)=>setInvoiceCSLCity(e.target.value)}/>
                          <label className='cp_short_label'>Invoice Address City</label>
                        </div>
                        <div>
                          <label className='cp_input_header'>Zipcode</label>
                          <input type='text' className='cp_input_field' value={invoiceCSLZip} onChange={(e)=>setInvoiceCSLZip(e.target.value)}/>
                          <label className='cp_short_label'>Inovice Address Zip</label>
                        </div>
                      </div>
                        </>}
                      <div>
                      <div className='cp_input_wrap'>
                        <label className='cp_input_header'>Email ID</label>
                        <input type='email' className='cp_input_field' value={email} onChange={(e)=>setEmail(e.target.value)}/>
                      </div>
                      </div>

                    </div>
                    <div className='dynamic_address_wrapper'>
                      <div className='cp_input_wrap cp_select_block'>
                        <label className='cp_input_header'>Seafood Country</label>
                        <select value={selectedSC} onChange={handleChangeSC}>
                          <option value='' disabled>Select Country</option>
                          {seafoodCountry.map((item,index)=><option key={index} value={item.id}>{item?.country?.country}</option>)}
                        </select>
                      </div>
                      <div className='cp_input_wrap cp_select_block'>
                        <label className='cp_input_header'>Seafood Airport</label>
                        <select value={selectedAirport} onChange={handleChangeAirport}>
                          <option value='' disabled>Select Airport</option>
                          {seafoodAirport.map((item,index)=><option key={index} value={item.iata_code}>{item.airport}({item.iata_code})</option>)}
                        </select>
                      </div>
                      <div className='cp_input_wrap cp_select_block'>
                        <label className='cp_input_header'>Target Product</label>
                        <select value={selectedProduct} onChange={handleChangeProduct}>
                          <option value='' disabled>Select Product</option>
                          {targetProduct.map((item,index)=><option key={index} value={item.id}>{item.product_string}</option>)}
                        </select>
                      </div>
                      <div className='cp_input_wrap cp_select_block'>
                        <label className='cp_input_header'>Currency</label>
                        <select value={selectedCurrency} onChange={handleChangeCurrency}>
                          <option value='' disabled>Select Currency</option>
                          {currency.map((item,index)=><option key={index} value={item.currency_code}>{item.currency_code}</option>)}
                        </select>
                      </div>
                    </div>
                  </div>
              </form>
              <section>
                <div className='cp_button_container'>
                  <button className='cp_primary_btn' onClick={(e)=>{
                    e.preventDefault()
                    updatingCustomer()
                    }}>Update</button>
                  <button className='cp_cancel_btn' onClick={(e)=>{
                    e.preventDefault()
                    history.goBack()
                  }}>Cancel</button>
                </div>
              </section>
            </div>
          </div>
        </section>
    )
}

export default EditCustomerPage
