import React, { useEffect, useReducer, useState } from 'react';
import { IoAddCircleOutline, IoEye } from 'react-icons/io5';
import { MdModeEdit } from 'react-icons/md';
import { Link } from 'react-router-dom';
import PurchaseServices from '../../api/PurchaseServices';
import RFQPurchaseCheckout from '../Purchase/RFQPurchaseCheckout';
import ToggleSwitch from '../Toggle/ToggleSwitch';
import './RFQMatrix.css';

export const ACTIONS = {
	GET_PURCHASE_MATRIX: 'GetRFQMatrix',
	SELECT_PURCHASE: 'SELECT_PURCHASE',
};

const reducer = (state, { type, payload }) => {
	switch (type) {
		case ACTIONS.GET_PURCHASE_MATRIX:
			return {
				...state,
				purchaseMatrixResults: payload.purchaseMatrixResults,
			};
		case ACTIONS.SELECT_PURCHASE:
			return {
				...state,
				selectedPurchase: payload,
			};
		default:
			return {
				...state,
			};
	}
};

const PurchaseMatrix = ({
	toggle = false,
	edit_visibility = true,
	multi_select = true,
	single_producer_allowed = '',
	handlePurchaseProducer = () => {},
	...props
}) => {
	const [state, dispatch] = useReducer(reducer, {
		purchaseMatrixResults: [],
	});
	const [isRFQModalOpened, setIsRFQModalOpened] = useState(false);

	useEffect(() => {
		fetchPurchaseData();
	}, []);

	//fetching the RFQ matrix
	const fetchPurchaseData = async () => {
		// const matrixData = await PurchaseServices.getPuchaseList();
		const matrixData = await PurchaseServices.getPurchaseMatrixData({
			producers: [],
			exporters: [],
		});
		if (matrixData.data) {
			const { data } = matrixData;
			console.log(data, 'vxfkjbvfdhjbvfhjbvfhj');
			dispatch({
				type: ACTIONS.GET_PURCHASE_MATRIX,
				payload: {
					purchaseMatrixResults: data.results,
				},
			});
		}
	};
	const manageRFQModal = (modalOpen = false, optionalRfq = '') => {
		setIsRFQModalOpened(modalOpen);
		dispatch({
			type: ACTIONS.SELECT_RFQ_ID,
			payload: optionalRfq,
		});
	};

	return (
		<>
			{state.selectedPurchase && (
				<RFQPurchaseCheckout
					isModalOpened={isRFQModalOpened}
					manageRFQModal={manageRFQModal}
					selectedPurchaseInfo={state.selectedPurchase}
					{...props}
				/>
			)}
			<table style={{ width: '100%', borderSpacing: 0 }}>
				<thead>
					<tr className='table-header'>
						<th style={{ textAlign: 'left' }}>
							<span className={'inData table-content'}>Producer</span>
						</th>
						<th></th>
					</tr>
				</thead>
				{toggle && (
					<tbody>
						{state.purchaseMatrixResults.length > 0 ? (
							state.purchaseMatrixResults.map((user, index) => {
								return (
									<tr key={index}>
										<td className='producer-cell' style={{ textAlign: 'left' }}>
											{user.name}
											{user.has_purchase && edit_visibility && (
												<Link
													to={`/buy/confirm/${user.purchase_id}/${user.access_id}/${user.type}`}
												>
													<MdModeEdit size={20} />
												</Link>
											)}
											{user.rfq_id && (
												<IoEye
													size={20}
													onClick={() => {
														manageRFQModal(true);
														dispatch({
															type: ACTIONS.SELECT_PURCHASE,
															payload: user,
														});
													}}
												/>
											)}
										</td>
										<td>
											<ToggleSwitch
												status={user.purchase_id === single_producer_allowed ? false : true}
												fromMatrix={true}
												// disabled={
												// 	single_producer_allowed === user.purchase_id ||
												// 	!single_producer_allowed
												// 		? !user.has_purchase
												// 		: !multi_select || !user.has_purchase
												// }
												disabled={!user.has_purchase}
												onToggleChange={(toggle) => handlePurchaseProducer(user, toggle)}
											/>
										</td>
									</tr>
								);
							})
						) : (
							<tr>
								<td colspan='3'>No data available </td>
							</tr>
						)}
					</tbody>
				)}
			</table>
		</>
	);
};

export default PurchaseMatrix;
