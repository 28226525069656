import React from 'react'
import { AiFillCheckCircle } from 'react-icons/ai'
import { FiFilter } from 'react-icons/fi'

function FilterButton({onClick=()=>{},isActive=false}) {
    return (
      <span className='filter_button_container' onClick={(e)=>{
        e.preventDefault()
        onClick()}}>
        <FiFilter color='#888'/>
        Filter
        {isActive && <AiFillCheckCircle size={21} color='#002376'/>}
      </span>
    )
}

export default FilterButton
