import React, {useState, useEffect} from 'react'

const SeafoodCountrySelector = ({ handleChange }) => {

    const [countries, setSeafoodCountries] = useState([])
    
    useEffect(() => {    
        async function fetchSeafoodCountries() {
            const url = 'https:///market/api/seafood-country/?is_overseas=1'
            const headers = {
                'Authorization': '558ef807-ad2b-4aba-8629-c8d227745493'
            } 
            const seafoodCountriesResponse = await fetch(url, {headers:headers}).then(res => res.json())
            const seafood_countries = seafoodCountriesResponse.results
            setSeafoodCountries(seafood_countries)
            console.log(seafood_countries)
        }
        fetchSeafoodCountries()
    }, [])

    return (
        <div>
            <select onChange={handleChange}>
                {countries.map((country) => (
                    <option value={country.seafood_country.iso_country}>{country.seafood_country.country} ({country.seafood_country.iso_country})</option>
                ))}        
            </select>
        </div>
    )
}

export default SeafoodCountrySelector
