import React, { Component, Fragment } from 'react';
import { Link } from 'react-router-dom';
import  '../../../styles/CreateNote.css'
import { ToastsStore } from 'react-toasts';
import CustomerNoteServices from '../../../api/CustomerNoteServices';
import BrowserStorage from '../../../utils/BrowserStorage';
import { FiChevronRight } from 'react-icons/fi';

class CreateNote extends Component {
	constructor() {
		super();
		this.state = {
			elements: [],
			sel: null,
			quoteModel: {},
			noteData: {},
      shipment_week:'',
      noteTotalOrder:0,
		};
    this.retrievedMatrix = {};
		this.showHeaderDate = true;
		this.disabBtn = 1;
		this.defWwight = [
			'1–2',
			'2–3',
			'3–4',
			'4–5',
			'5–6',
			'6–7',
			'7–8',
			'8–9',
			'9+',
		];
		this.dayVal = [
			{
				dayIndex: 1,
				value: 0,
			},
			{
				dayIndex: 2,
				value: 0,
			},
			{
				dayIndex: 3,
				value: 0,
			},
			{
				dayIndex: 4,
				value: 0,
			},
			{
				dayIndex: 5,
				value: 0,
			},
			{
				dayIndex: 6,
				value: 0,
			},
			{
				dayIndex: 7,
				value: 0,
			},
		];
	}
	componentDidMount() {
		this.baseOrderMatrix(this.defWwight);
		this.getNotes();
	}
	baseOrderMatrix(weigh) {
		const weighClass = weigh;
		const items = {};
		let model = {};
		weighClass.map((data, i) => {
			items[data] = JSON.parse(JSON.stringify(this.dayVal));
			model[data] = {
				hasVolume: 0,
				isOpen: false,
			};
		});
    this.setState({
      elements:weighClass,
      sel:items,
      quoteModel:model
    })
		// this.state.elements = weighClass;
		// this.state.sel = items;
		// this.state.quoteModel = model;
		this.forceUpdate();
		console.log(this.state.sel);
	}
	// onBlur = (event, a, inx, days) => {
	// 	if (event.target.value === '') {
	// 		event.target.value = 0;
	// 	}
	// 	days.volume = parseInt(event.target.value);
	// 	this.state.sel[a][inx].value = parseInt(event.target.value);
	// 	this.disabBtn = 0;
	// 	// this.state.quoteModel[a].isOpen = false;
	// 	this.showHeaderDate = true;
	// 	// for (let item in this.state.quoteModel) {
	// 	// 	if (this.state.quoteModel[item].isOpen) this.showHeaderDate = false;
	// 	// }
	// 	if (parseInt(event.target.value)) {
	// 		this.state.quoteModel[a].hasVolume = true;
	// 	}
	// 	this.forceUpdate();
	// };
	// toggleAccordion = (event, key) => {
	// 	this.state.quoteModel[key].isOpen = !this.state.quoteModel[key].isOpen;
	// 	// this.showHeaderDate = !this.state.quoteModel[key].isOpen;
	// 	this.forceUpdate();
	// };
	async createNote(reset = false) {
		if (this.disabBtn == 0 || reset) {
			let matrix = {};
			for (let item of this.state.noteMatrix) {
				matrix[item.key] = item.list;
				if (reset) {
					matrix[item.key] = item.list.map((item) => {
						item.volume = 0;
						return item;
					});
				}
			}

			const userDetailsFromLocal = BrowserStorage.getValue('userInfo');

			let data = {
				data: matrix,
				access_id: userDetailsFromLocal.accessId,
				access_type: userDetailsFromLocal.access_type,
			};
			const createNote = await CustomerNoteServices.updateCustomerNoteMatrix(
				0,
				data
			);
			if (createNote.data) {
				ToastsStore.success('Note updated');
				this.disabBtn = 1;
				this.getNotes();
				window.location.href = '/home/create-note';
				// this.forceUpdate();
			}
		} else {
			return;
		}
	}

	async getNotes() {
		const getNotes = await CustomerNoteServices.getNotes();
		if (getNotes.data) {
			const { data } = getNotes;
			let order = [];
			let model = {};
			for (let item in data.full_matrix_combined) {
				let temp = {};
				temp['key'] = item;
				temp['list'] = data.full_matrix_combined[item].sort((a, b) =>
					a.weight_class > b.weight_class
						? 1
						: b.weight_class > a.weight_class
						? -1
						: 0
				);
				order.push(temp);

				let sum = 0;
				data.full_matrix_combined[item].forEach((element) => {
					sum += element.volume;
				});
				model[item] = {
					hasVolume: sum != 0,
					isOpen: false,
				};
			}
      this.retrievedMatrix = JSON.parse(JSON.stringify(data.full_matrix_combined));
			this.setState({
				elements: data.full_matrix_combined,
				noteMatrix: order,
				noteTotalOrder: data.total_weight,
				quoteModel: model,
				noteData: data.customer_data,
        shipment_week:Object.values(data.full_matrix_combined)[0][7].date
			});
			this.forceUpdate();
			console.log(this.state.noteMatrix);
		}
	}

	async updateAnnounce() {
		this.state.noteData.is_announced = !this.state.noteData.is_announced;
		let data = {
			is_announce: this.state.noteData.is_announced,
		};
		const announceNote = await CustomerNoteServices.announceNote(
			data,
			this.state.noteData.id
		);
		if (announceNote.data) {
			this.getNotes();
			this.forceUpdate();
		}
	}

  handleRowVolumeChange = (e,weightClass,index) =>{
    console.log(
			`Main Event : target Value ${e.target.value} with weightClass ${weightClass} in Index:${index}`
		);

    if (e.target.value === '') {
			e.target.value = 0;
		}

    e.target.value = parseInt(e.target.value) || 0;
    e.target.value = e.target.value <= 0 ? 0 : e.target.value >= 270 ? 270 : e.target.value;
    
    let tempMatrix = this.state.elements

    tempMatrix[weightClass][index].volume = parseInt(e.target.value)

    this.setState({
      elements:tempMatrix
    })

    //MATRIX VOLUME TOTAL
    let total = 0;

    total = Object.values(tempMatrix).reduce((a,b)=>{
      return b.reduce((c,d)=>{
        return c+d.volume
      },a)   
    },0)

    this.setState({
      noteTotalOrder:total
    })
    
    //CHECK MATRIX EQUALITY
    let checkMatrixEquals = Object.entries(this.retrievedMatrix).every((item)=>item[1].every((val,index)=>tempMatrix[item[0]][index].volume ===val.volume))
    
    checkMatrixEquals?this.disabBtn=1:this.disabBtn=0

    console.log('tempMatrix',tempMatrix)

  }

	render() {
		var daysOrder = ['', 'Mon', 'Tue', 'Wed', 'Thu', 'Fri', 'Sat', 'Sun'];
		return (
			<>
				<section className={'body_wrap'}>
					{/* <Sidebar /> */}
					<div className={'cont_wp'}>
						<Link to={'/home'}>
							<p className={'Back_link'}>Back</p>
						</Link>
						{/* <section className={'cmn_head'}>
                            <p>Create Base Order</p>
                        </section> */}

						<div class='scrl_out'>
							<section className={'home_top_wrap'}>
								<div className={'home_top_block createNoteTitleWrap'}>
									<p>
										Manage <br /> Notes
									</p>
									{/* <label>ma Here</label> */}
									<div className={'Base_total_block'}>
										<span>
											{this.state.noteTotalOrder}{' '}
											{this.state.noteTotalOrder == 1 ? 'Box' : 'Boxes'}
										</span>{' '}
										<br />
										<label>Total Quantity</label>
									</div>
								</div>
								<div className={'home_top_block'}>
									<div className={'ReqBtnWrp'}>
										{this.state.noteData && (
											<div className={'announce_wrap note-announce'}>
												<p>Announce</p>
												<div className={'toggle_wrap'}>
													<input
														type='checkbox'
														id='announce'
														checked={this.state.noteData.is_announced}
													/>
													<label
														for='announce'
														onClick={() => this.updateAnnounce()}
													></label>
												</div>
											</div>
										)}
										<div
											className={'conBt' + ' ' + 'sp_btn'}
											onClick={() => this.createNote(true)}
										>
											{this.state.noteTotalOrder == 0 && (
												<div className={'disabOvl'}></div>
											)}
											Reset
										</div>
										{
											<div
												className={'conBt' + ' ' + 'sp_btn'}
												onClick={() => this.createNote()}
											>
												{this.disabBtn === 1 && <div className={'disabOvl'}></div>}
												Update
											</div>
										}
									</div>
								</div>
							</section>

							<section className={'base_order_wrapper'}>
                <table
										className='rfq-manager-table'
										style={{ width: '100%', borderSpacing: 0, marginTop: 20,boxShadow:"2px 2px 4px lightgray" }}
									>
                    <thead>
											<tr className='table-header'>
												<th style={{ width: 100 }}></th>
												{this.dayVal.map((val, i) => {
													return <th style={{ color: '#fff' }}>{daysOrder[i + 1]}</th>;
												})}
												<th style={{ color: '#fff' }}>{this.state.shipment_week}</th>
                        <th></th>
											</tr>
										</thead>
                    <tbody>
								{this.state.noteMatrix &&
									this.state.noteMatrix.map((item, i) => {
										return (
                      <Fragment key={i}>
                      <WeightRow data={item} handleRowVolumeChange={this.handleRowVolumeChange} />
                      </Fragment>
										);
									})}
                  </tbody>
                </table>
							</section>
						</div>
					</div>
				</section>
			</>
		);
	}
}

const WeightRow = ({data,
  handleRowVolumeChange = (e, weightClass, index) => {
  e.preventDefault();
  console.log(
    `target Value ${e.target.value} with weightClass ${weightClass} in Index:${index}`
  );
}
}) =>{

  const [toggleEditRow, setToggleEditRow] = React.useState(false)



  return (
    <tr className='base-order-downstream-td'>
      <td
        className='producer-cell'
        style={{ textAlign: 'left', width: 100 }}
      >
        HOG {data.key} kg
      </td>
      {data.list && data.list.map((days, index) => {
        return <td><input className={` ${
          toggleEditRow ? 'volume-input' : 'volume-input-disabled'
        }`} type='number' value={days.volume!==0 ? days.volume : ''}onChange={(e) => {
          e.preventDefault();
          if (e.target.value >= 0) handleRowVolumeChange(e, data.key, index);
        }}
         /></td>;
      })}
      <td>
        <FiChevronRight
        className={` ${toggleEditRow ? 'toggle-on' : ''}`}
        onClick={(e) => {
          e.preventDefault();
          setToggleEditRow(!toggleEditRow);
        }} 
        style={{ height: 20, width: 20, cursor: 'pointer' }}
        />
      </td>
    </tr>
  )
}

export default CreateNote;
