import React from 'react'
import { Link } from 'react-router-dom';
import Sidebar from '../components/Sidebar/Sidebar';
import InvoiceLog from '../components/Logs/InvoiceLog';

const MiscellaneousPage = () => {
    return (
        <section className={'body_wrap'}>
        {/* <Sidebar /> */}
        <div className={'cont_wp'}>
            <Link to={'/home'}>
                <p className={'Back_link'}>Back</p>
             </Link>
             <section className={'cmn_head'}>
                 <p>Miscellaneous</p>
            </section>
            <a href="https://workspace.google.com" target="_blank">Google Workspace</a>

        </div>
    </section>
    )
}

export default MiscellaneousPage
