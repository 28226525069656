import React, { useState } from 'react';

const eventBinder = (WrappedComponent) => {
	return (props) => {
		const [
			hover,
			setHover
		] = useState(false);
		const toggleHover = () => {
			setHover(!hover);
		};
		return (
			<WrappedComponent
				onMouseEnter={toggleHover}
				onMouseLeave={toggleHover}
				onClick={
					!props.disabled ? props.onClick : () => { }
				}
				{...props}
				hover={hover}
				onChange={props.onChange}
			/>
		);
	};
};
export default eventBinder;
