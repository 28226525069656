import React, { Component, useState, useEffect, useReducer } from 'react';
import axios from 'axios';
import Sidebar from '../Sidebar/Sidebar';
import moment from 'moment';
import { ToastsStore } from 'react-toasts';
import ToggleSwitch from '../Toggle/ToggleSwitch';
import { AiOutlineCheckCircle } from 'react-icons/ai';
import { IoAddCircleOutline } from 'react-icons/io5';
import Modal from 'react-modal';
import { GrClose } from 'react-icons/gr';
import Moment from 'react-moment';
import { IoCalculatorOutline } from 'react-icons/io5';
import {
	MdModeEdit,
	MdHome,
	MdExpandLess,
	MdExpandMore,
	MdRefresh,
} from 'react-icons/md';
import { IoIosPricetag } from 'react-icons/io';
import { Link } from 'react-router-dom';
import { MdNavigateBefore } from 'react-icons/md';

import QuotePrice from '../CostTarget/QuotePrice';
import OrderVolumeMatrix from '../Matrix/OrderVolumeMatrix';
import MatrixHeader from '../Matrix/MatrixHeader';
import CommonServices from '../../api/CommonServices';
import CalendarServices from '../../api/CalendarServices';
import QuoteServices from '../../api/QuoteServices';
import ForecastOrderServices from '../../api/ForecastOrderServices';
import matrixWithToggleSwitch from '../../hoc/matrixWithToggleSwitch';
import userIcon from '../../assets/images/user.png';
import customerIcon from '../../assets/images/customer.png';
import { BiArrowToLeft, BiArrowToRight } from 'react-icons/bi';
import ReactTooltip from 'react-tooltip';

const OutlookVolumeMatrix = matrixWithToggleSwitch(OrderVolumeMatrix, {
	title: 'Outlook Matrix',
});
//modal styles
const customStyles = {
	content: {
		background: 'white',
		width: '50rem',
		maxWidth: 'calc(100vw - 2rem)',
		maxHeight: 'calc(100vh - 2rem)',
		boxshadow: '0 0 30px 0 rgba(0, 0, 0, 0.25)',
		overflowY: 'auto',
		position: 'relative',
	},
	overlay: {
		position: 'fixed',
		zIndex: 999999,
		top: 0,
		left: 0,
		width: '100vw',
		height: '100vh',
		background: 'rgba(0, 0, 0, 0.5)',
		display: 'flex',
		alignItems: 'center',
		justifyContent: 'center',
	},
};

//constants
export const ACTIONS = {
	SET_FIELDS: 'SetFields',
	SET_MATRIX_VOLUMES: 'SetMatrixVolumes',
	UPDATE_MATRIX_VOLUME: 'UpdateMatrixVolume',
	SET_MATRIX_HEADER: 'SetMatrixHeader',
	SET_OUTLOOK_MATRIX: 'SetOutlookMatrix',
};

//reducers
const reducer = (state, action) => {
	switch (action.type) {
		case ACTIONS.SET_FIELDS:
			return {
				...state,
				[action.payload.field]: action.payload.value,
			};
		case ACTIONS.SET_MATRIX_VOLUMES:
			return {
				...state,
				matrix_volumes_9_1: action.payload.matrix_volumes_9_1,
				matrix_volumes_9_7: action.payload.matrix_volumes_9_7,
				matrix_volumes_9_8: action.payload.matrix_volumes_9_8,
				inital_matrix_volumes: action.payload.inital_matrix_volumes,
				matrix_id: action.payload.matrix_id,
				matrix_dimension_status: action.payload.matrix_dimension_status,
				matrix_row_total: action.payload.matrix_row_total,
				disable_9_7: action.payload.disable_9_7,
				initial_state: action.payload.matrix_dimension_status,
				weight_classes: Object.keys(action.payload.matrix_row_total),
				sourcing_week: action.payload.sourcing_week,
				shipment_week: action.payload.shipment_week,
				shipment_date: action.payload.shipment_date,
				sourcing_date: action.payload.sourcing_date,
			};
		case ACTIONS.UPDATE_MATRIX_VOLUME:
			if (action.payload.matrix_dimension_status === 1)
				return {
					...state,
					matrix_volumes_9_1: action.payload.matrix,
				};
			if (action.payload.matrix_dimension_status === 2)
				return {
					...state,
					matrix_volumes_9_7: action.payload.matrix,
				};
			return {
				...state,
				matrix_volumes_9_8: action.payload.matrix,
			};
		case ACTIONS.SET_MATRIX_HEADER:
			return {
				...state,
				total_matrix_volume: action.payload.total_volume,
				total_matrix_weight: action.payload.total_weight,
			};
		case ACTIONS.SET_OUTLOOK_MATRIX:
			return {
				...state,
				outlook_matrix: action.payload.outlook_matrix,
			};
		default:
			return {
				...state,
			};
	}
};

const Friday = () => {
	//states
	const [accumulated, setAccumulated] = useState([]);
	const [accumulatedSel, setAccumulatedSel] = useState(null);
	const [accumulatedModel, setAccumulatedModel] = useState({});
	const [accmulatedStat, setAccmulatedStat] = useState([]);
	const [results, setResults] = useState([]);
	const [orderStatus, setOrderStatus] = useState([]);
	const [shipmentWeek, setShipmentWeek] = useState('');
	const [modal, setModal] = useState(false);
	const [modalMatrix, setModalMatrix] = useState(null);
	// const [modalMatrixData, setModalMatrixData] = useState([]);
	// const [modalMatrixModel, setModalMatrixModel] = useState([]);
	const [calculatorModal, setCalculatorModal] = useState(false);
	const [headings, setHeadings] = useState(null);
	const [rows, setRows] = useState(null);
	const [calculatorResults, setCalculatorResults] = useState([]);
	const [addModal, setAddModal] = useState(false);
	const [quoteId, setQuoteId] = useState('');
	const [expand, setExpand] = useState(true);
	const [type, setType] = useState('');
	const [apiData, setApiData] = useState({});
	const [buttonDisable, setButtonDisable] = useState(true);
	// const [customerShipmentLocation, setCustomerShipmentLocation] = useState('');
	const [forecastOrderId, setForecastOrderId] = useState(null);
	const [matrixShow, setMatrixShow] = useState(true);
	const [headerData, setHeaderData] = useState(null);
	const [userResults, setUserResults] = useState({});
	const [eightDay, setEightDay] = useState(false);
	const [isQuoteeClicked, setIsQuoteeClicked] = useState(false);
	const [weekDet, setWeekDet] = useState([]);

	const [secondCol, setSecondCol] = useState(1);
	const [thirdCol, setThirdCol] = useState(1);
	const [fourthCol, setFourthCol] = useState(1);
	const [forecastColGroup, setForecastColGroup] = useState(1);

	const [secondColClick, setSecondColClick] = useState(false);
	const [thirdColClick, setThirdColClick] = useState(false);
	const [fourthColClick, setFourthColClick] = useState(false);

	const [col2, setCol2] = useState(false);
	const [col3, setCol3] = useState(false);
	const [col4, setCol4] = useState(false);
	const [viewType, setViewType] = useState('');

	//Force Update
	const [, updateState] = React.useState();

	const [state, dispatch] = useReducer(reducer, {
		outlook_matrix: {},
		matrix_dimension_status: 2, // 1-> 9+1, 2-> 9*7, 3-> 9*8
		disable_9_1: false,
		disable_9_7: false,
		disable_9_8: false,
		disable_matrix_selector: false,
		total_matrix_volume: 0,
		total_matrix_weight: 0,
		button_enabled: true,
		drops_are_disabled: false, // true after volumes are entered
		matrix_volumes_9_1: {},
		matrix_volumes_9_7: {},
		matrix_volumes_9_8: {},
		inital_matrix_volumes: {},
		initial_dimension_status: 2,
		week_data: [],
		weight_classes: [],
		selected_sourcing_week: '',
		initial_sourcing_week: '',
		sourcing_week: '', // default -> current week,
		shipment_week: '',
	});

	useEffect(() => {
		callForecastApi();
		getDate();
		getWeekDates(state.sourcing_week);
	}, []);

	useEffect(() => {
		if (isQuoteeClicked) {
			setModalMatrix(userResults.quotee_matrix);
		} else {
			if (eightDay) {
				setModalMatrix(userResults.forecast_order_matrix_8th_day);
			} else {
				setModalMatrix(userResults.forecast_order_matrix);
			}
		}
	}, [eightDay]);

	const callForecastApi = async (params) => {
		const dataa = {};
		if (params) {
			dataa['filter_param'] = params;
		}

		try {
			const updateOutlook = await CommonServices.updateOutlook(dataa);
			if (updateOutlook.data) {
				const { data } = updateOutlook;
				let model = {};

				Object.entries(data.accumulated_matrix).map((item, i) => {
					return (model[item[0]] = {
						hasVolume: item[1].some((val) => val.volume > 0),
					});
				});

				// console.log('modelling', modelling);

				console.log('model', model);
				setAccumulatedModel(model);
				setAccumulatedSel(data.accumulated_matrix);
				let orderResults = JSON.parse(JSON.stringify(data.results));

				const filteredStatus = orderResults.map((item) => {
					if (item.has_order_placed) {
						item.negotiation_status = 'Done';
						return item;
					}
					if (item.type === 'pre_prospect') {
						item.negotiation_status = 'Admin Pending';
						return item;
					}
					if (item.type === 'seafood_destination') {
						item.negotiation_status = '';
						return item;
					}
					if (item.status !== '') {
						const isAllFalse = item?.status.every(
							(value) =>
								value.admin_offer === false && value.customer_counteroffer === false
						);
						const AdmintoRespond = item?.status.some(
							(value) =>
								value.admin_offer > value.customer_counteroffer &&
								value.next_move === 'admin'
						);
						if (isAllFalse || AdmintoRespond) {
							item.negotiation_status = 'Admin Pending';
							return item;
						}

						//CUSTOMER PENDING CASES
						const CustomerPriceSended = item?.status.some(
							(value) => value.admin_offer && !value.customer_counteroffer
						);
						const hasVolume = item?.status.some(
							(value) =>
								value.admin_offer === false &&
								value.customer_counteroffer === false &&
								value.has_volume
						);

						if (CustomerPriceSended || hasVolume) {
							item.negotiation_status = 'Customer Pending';
							return item;
						}
						return item;
					} else {
						item.negotiation_status = '';
						return item;
					}
				});

				console.log('filtered', filteredStatus);

				setResults(filteredStatus);

				setShipmentWeek(data.week);
				setButtonDisable(true);
				setAccumulated(Object.keys(data.accumulated_matrix));

				accumulated.map((key, i) => {
					accumulatedSel[key].sort(function (x, y) {
						return x['dayIndex'] - y['dayIndex'];
					});
				});
				dispatch({
					type: ACTIONS.SET_OUTLOOK_MATRIX,
					payload: {
						outlook_matrix: data.accumulated_matrix,
					},
				});
				// this.getDate("val");
				sumAccord();
			}
		} catch (error) {
			console.log(error);
		}
	};
	useEffect(() => {
		if (state.matrix_dimension_status !== state.initial_dimension_status) {
			dispatch({
				type: ACTIONS.SET_FIELDS,
				payload: {
					field: 'button_enabled',
					value: false,
				},
			});
		} else {
			dispatch({
				type: ACTIONS.SET_FIELDS,
				payload: {
					field: 'button_enabled',
					value: true,
				},
			});
		}
	}, [state.matrix_dimension_status]);

	// method to get shipment week dates from api
	const getWeekDates = async (sourcing_week) => {
		const getWeekDates = await CalendarServices.getWeekDates(sourcing_week);
		if (getWeekDates.data) {
			const { data } = getWeekDates;
			dispatch({
				type: ACTIONS.SET_FIELDS,
				payload: {
					field: 'week_data',
					value: data.shipment_dates,
				},
			});
			dispatch({
				type: ACTIONS.SET_FIELDS,
				payload: {
					field: 'sourcing_week',
					value: data.sourcing_week,
				},
			});
			dispatch({
				type: ACTIONS.SET_FIELDS,
				payload: {
					field: 'shipment_week',
					value: data.shipment_week,
				},
			});
		}
	};

	useEffect(() => {
		console.log('------------ORDER VOLUME MATRIX CHANGED------------');
		if (state.matrix_dimension_status === 1) {
			dispatchMatrixHeaders(state.matrix_volumes_9_1);
		}
		if (state.matrix_dimension_status === 2) {
			dispatchMatrixHeaders(state.matrix_volumes_9_7);
		}
		if (state.matrix_dimension_status === 3) {
			dispatchMatrixHeaders(state.matrix_volumes_9_8);
		}
	}, [
		state.matrix_volumes_9_1,
		state.matrix_volumes_9_7,
		state.matrix_volumes_9_8,
		state.matrix_dimension_status,
	]);

	const getVolumes = (matrix) => {
		let sum = 0;
		Object.entries(matrix).map((item) =>
			item[1].map((val) => (sum += val.volume))
		);
		return sum;
	};

	const dispatchMatrixHeaders = (matrix) => {
		dispatch({
			type: ACTIONS.SET_MATRIX_HEADER,
			payload: {
				total_volume: getVolumes(matrix),
				total_weight: getVolumes(matrix) * 22,
			},
		});
	};

	const sumAccord = () => {
		accumulated.map((key, i) => {
			var arrEl = accumulatedSel[key].map(function (o, i) {
				return o.value;
			});
			var sum = arrEl.reduce(function (a, b) {
				return a + b;
			}, 0);
			var accrStat = sum === 0 ? true : false;
			//MUTATING STATE
			accmulatedStat[i] = accrStat;
		});
	};

	const forceUpdate = React.useCallback(() => updateState({}), []);

	const getDate = (val, index = false) => {
		var nextMonday = new Date();
		nextMonday.setDate(
			nextMonday.getDate() + ((1 + 7 - nextMonday.getDay()) % 7)
		);
		var nextWeekArrays = [nextMonday];
		for (let i = 1; i < 7; i++) {
			nextWeekArrays.push(
				new Date(new Date(nextMonday).setDate(nextMonday.getDate() + i))
			);
		}
		if (nextWeekArrays.indexOf('undistributed') !== -1) {
			console.log('already exists');
		} else {
			nextWeekArrays.push('undistributed');
		}
		setWeekDet(nextWeekArrays);
		forceUpdate();
	};

	const getMatrix = async (user, modelName, type, forecast_order_id) => {
		setType(type);
		try {
			let data = {
				access_type: user.type,
				model_name: modelName,
				access_id: user.access_id,
				sourcing_week: state.sourcing_week,
			};
			const getMatrixData = await CommonServices.getMatrixData(data);
			if (getMatrixData.data) {
				const { data } = getMatrixData;
				dispatch({
					type: ACTIONS.SET_MATRIX_VOLUMES,
					payload: {
						// response key should be changed once api is ready.
						matrix_volumes_9_1: data.matrix_row_total,
						matrix_volumes_9_7: data.full_active_matrix,
						matrix_volumes_9_8: data.full_matrix_combined,
						// matrix_volumes_9_8: dummyData.full_9_8_matrix,
						inital_matrix_volumes: data,
						matrix_id: data.matrix_id,
						matrix_dimension_status: data.matrix_dimension_status,
						matrix_row_total: data.matrix_row_total,
						disable_9_7: data.matrix_dimension_status === 3 ? true : false,
						shipment_week: data.shipment_week,
					},
				});
				dispatch({
					type: ACTIONS.SET_FIELDS,
					payload: {
						field: 'initial_dimension_status',
						value: data.matrix_dimension_status,
					},
				});

				dispatch({
					type: ACTIONS.SET_FIELDS,
					payload: {
						field: 'initial_sourcing_week',
						value: state.selected_sourcing_week,
					},
				});

				dispatch({
					type: ACTIONS.SET_FIELDS,
					payload: {
						field: 'button_enabled',
						value: true,
					},
				});
				openModal(user);
				const results = data;
				results['customerName'] = user.name;
				setHeaderData(results);
				setForecastOrderId(forecast_order_id);
			}
		} catch (error) {
			console.log(error);
		}
	};

	const renderModelType = (dimensionStatus) => {
		console.log(dimensionStatus, 'status');
		switch (dimensionStatus) {
			case 1:
				return state.matrix_volumes_9_1;
			case 2:
				return state.matrix_volumes_9_7;
			case 3:
				return state.matrix_volumes_9_8;
			default:
				return null;
		}
	};
	const renderInitialModelType = (dimensionStatus) => {
		switch (dimensionStatus) {
			case 1:
				return state.inital_matrix_volumes.full_8th_day_matrix;
			case 2:
				return state.inital_matrix_volumes.full_active_matrix;
			case 3:
				return state.inital_matrix_volumes.full_matrix_combined;
			default:
				return null;
		}
	};

	const openModal = (user) => {
		setUserResults(user);
		setModal(true);
	};

	const closeModal = () => {
		setModal(false);
		setCalculatorModal(false);
		setEightDay(false);
		setIsQuoteeClicked(false);
		// setUserResults({});
	};

	const openCalculatorModal = async (type, id) => {
		const getQuoteCalculator = await QuoteServices.getQuoteCalculator(id, type);
		if (getQuoteCalculator.data) {
			const { data } = getQuoteCalculator;
			setHeadings(data.heading);
			setRows(data.rows);
			setCalculatorResults(data);
			setCalculatorModal(true);
		}
	};

	const addPriceStatus = (a) => {
		if (a == 2) {
			setAddModal(false);
			callForecastApi();
			// this.props.history.push(`/home/request/${this.state.quoteId}`);
			ToastsStore.success('Price Added');
		} else {
			setAddModal(false);
		}
	};

	const updateVolumeFieldChanged = (volumeObj) => {
		// Matrix Updation code
		console.log('-------changed--------', volumeObj);

		let tempMatrix;

		tempMatrix = JSON.parse(
			JSON.stringify({ ...renderModelType(state.matrix_dimension_status) })
		);

		if (state.matrix_dimension_status === 1) {
			tempMatrix[volumeObj.weight_class][0].volume = parseInt(volumeObj.volume);
		} else {
			tempMatrix[volumeObj.weight_class][volumeObj.dayIndex - 1].volume = parseInt(
				volumeObj.volume
			);
		}
		let checkNotNull = !Object.values(tempMatrix).every((o) => o === null);

		const inputChange =
			JSON.stringify(renderInitialModelType(state.matrix_dimension_status)) ===
			JSON.stringify(tempMatrix);

		dispatch({
			type: ACTIONS.SET_FIELDS,
			payload: {
				field: 'button_enabled',
				value: !checkNotNull || inputChange,
			},
		});

		// SetOrderVolumeMatrix({ ...tempMatrix });
		dispatch({
			type: ACTIONS.UPDATE_MATRIX_VOLUME,
			payload: {
				matrix: { ...tempMatrix },
				matrix_dimension_status: state.matrix_dimension_status,
			},
		});

		console.log('temporary Matrix', tempMatrix);
	};

	const createVolume = async () => {
		if (!state.button_enabled) {
			apiData['sourcing_week'] = headerData.sourcing_week;
			apiData['access_type'] = userResults.type;
			apiData['access_id'] = userResults.access_id;
			apiData['data'] = renderModelType(state.matrix_dimension_status);

			const UpdateForcast = await ForecastOrderServices.updateForeCastOrderMatrix(
				forecastOrderId ? forecastOrderId : 0,
				apiData
			);

			if (UpdateForcast.data) {
				setModal(false);
				setEightDay(false);
				callForecastApi();
				ToastsStore.success(
					`Volume ${forecastOrderId ? 'updated' : 'created'} successfully`
				);
			}
			if (UpdateForcast.error) {
				ToastsStore.error('Error on Creating Volume');
			}
		}
	};

	const hideMatrix = () => {
		setMatrixShow(!matrixShow);
	};

	const handleEightDay = () => {
		setEightDay(!eightDay);
	};

	const forecastColClick = () => {
		setSecondCol(1);
		setThirdCol(0.45);
		setFourthCol(0.45);
		setForecastColGroup(1);
		setSecondColClick(true);
		setThirdColClick(false);
		setFourthColClick(false);
		setCol2(!col2);
		setCol3(false);
		setCol4(false);

		if (col2) {
			setSecondCol(1);
			setThirdCol(1);
			setFourthCol(1);
			setForecastColGroup(1);
			setSecondColClick(true);
			setThirdColClick(true);
			setFourthColClick(true);
			// api call
			callForecastApi();
		} else {
			callForecastApi('forecast');
		}
	};
	const quoteColClick = () => {
		setSecondCol(0.45);
		setThirdCol(1);
		setFourthCol(0.45);
		setForecastColGroup(0.45);
		setSecondColClick(false);
		setThirdColClick(true);
		setFourthColClick(false);
		setCol2(false);
		setCol3(!col3);
		setCol4(false);

		if (col3) {
			setSecondCol(1);
			setThirdCol(1);
			setFourthCol(1);
			setForecastColGroup(1);
			setSecondColClick(true);
			setThirdColClick(true);
			setFourthColClick(true);
			//api call
			callForecastApi();
		} else {
			callForecastApi('quote');
		}
	};
	const orderColClick = () => {
		setSecondCol(0.45);
		setThirdCol(0.45);
		setFourthCol(1);
		setForecastColGroup(0.45);
		setSecondColClick(false);
		setThirdColClick(false);
		setFourthColClick(true);
		setCol2(false);
		setCol3(false);
		setCol4(!col4);

		if (col4) {
			setSecondCol(1);
			setThirdCol(1);
			setFourthCol(1);
			setForecastColGroup(1);
			setSecondColClick(true);
			setThirdColClick(true);
			setFourthColClick(true);
			//api call
			callForecastApi();
		} else {
			callForecastApi('order');
		}
	};

	const reset = () => {
		setSecondCol(1);
		setThirdCol(1);
		setFourthCol(1);
		setForecastColGroup(1);
		setSecondColClick(true);
		setThirdColClick(true);
		setFourthColClick(true);
		//api call
		callForecastApi();
	};

	const handleDimensionStatus = (e) => {
		console.log(e);
		dispatch({
			type: ACTIONS.SET_FIELDS,
			payload: {
				field: 'matrix_dimension_status',
				value: parseInt(e.target.value),
			},
		});
	};

	var daysOrder = [
		'',
		'Mon',
		'Tue',
		'Wed',
		'Thu',
		'Fri',
		'Sat',
		'Sun',
		state.shipment_week,
	];

	const renderStatus = (status = '') => {
		switch (status) {
			case 'Customer Pending':
				return (
					<span data-tip='Customer pending' className='table-icon'>
						<BiArrowToRight size='18' />
						<img style={{ width: 18 }} src={customerIcon} alt='customer' />
						<ReactTooltip />
					</span>
				);
			case 'Admin Pending':
				return (
					<span data-tip='Admin pending'>
						<img style={{ width: 18 }} src={userIcon} alt='admin' />
						<BiArrowToLeft size='18' />
						<ReactTooltip />
					</span>
				);
			case 'Done':
				return (
					<span data-tip='Order Placed'>
						<AiOutlineCheckCircle size={18} color={'green'} />
						<ReactTooltip />
					</span>
				);
			case '':
				return <span>--</span>;
			default:
				return <span>--</span>;
		}
	};

	return (
		<>
			<Modal
				isOpen={modal}
				onRequestClose={() => closeModal()}
				onAfterOpen={() => (document.body.style.overflow = 'hidden')}
				onAfterClose={() => (document.body.style.overflow = 'unset')}
				style={{
					overlay: {
						position: 'fixed',
						zIndex: 999999,
						top: 0,
						left: 0,
						width: '100vw',
						height: '100vh',
						background: 'rgba(0, 0, 0, 0.5)',
						display: 'flex',
						alignItems: 'center',
						justifyContent: 'center',
					},
					content: {
						background: 'white',
						// width: type == 'view' ? '50rem' : '',
						maxWidth: 'calc(100vw - 2rem)',
						maxHeight: 'calc(100vh - 2rem)',
						boxshadow: '0 0 30px 0 rgba(0, 0, 0, 0.25)',
						overflowY: 'auto',
						position: 'relative',
					},
				}}
			>
				<div class='scrl_out' style={{ paddingBottom: '30px' }}>
					<div className={'modal_heading'}>
						<p>{viewType}</p>
						<div className={'close-icon'} onClick={() => closeModal()}>
							<GrClose size={25} />
						</div>
					</div>
					<section className={'Modal_Base_wrap'}>
						<div className={'Modify_top'}>
							<MatrixHeader
								headerData={headerData}
								eighthDay={eightDay}
								setEighthDay={handleEightDay}
								type={type}
								state={state}
								viewType={viewType}
								handleDimensionStatus={handleDimensionStatus}
								buttonDisable={buttonDisable}
								forecastOrderId={forecastOrderId}
								createVolume={createVolume}
							/>
						</div>
						<>
							<div
								className={'Base_block'}
								style={{ display: 'grid', gridAutoFlow: 'column' }}
							>
								<div style={{ display: 'grid', gridAutoFlow: 'column' }}>
									<div style={{ overflowX: 'auto' }}>
										<OrderVolumeMatrix
											// orderVolumeMatrix={modalMatrix}
											orderVolumeMatrix={renderModelType(state.matrix_dimension_status)}
											onUpdate={updateVolumeFieldChanged}
											enableUpdate={type == 'view' ? false : true}
											createVolume={createVolume}
											buttonDisable={buttonDisable}
											forecastOrderId={forecastOrderId}
											state={state}
											daysOrder={daysOrder}
											type={type}
											viewType={viewType}
										/>
									</div>
								</div>
							</div>
						</>
					</section>
				</div>
			</Modal>
			<Modal
				isOpen={calculatorModal}
				onRequestClose={() => closeModal()}
				onAfterOpen={() => (document.body.style.overflow = 'hidden')}
				onAfterClose={() => (document.body.style.overflow = 'unset')}
				style={customStyles}
			>
				<div class='scrl_out' style={{ paddingBottom: '30px' }}>
					<div className={'modal_heading'}>
						<p>Calculator</p>
						<div className={'close-icon'} onClick={() => closeModal()}>
							<GrClose size={25} />
						</div>
					</div>

					{calculatorResults ? (
						<div>
							<p className={'modal_text'}>
								Shipment Week: {calculatorResults.shipment_week}
							</p>
							<p className={'modal_text'}>
								Purchasing Week: {calculatorResults.purchasing_week}
							</p>
						</div>
					) : null}
					<section className={'OrderTableWrap' + ' ' + 'scrl_out'}>
						<table className={'calcultor_table'}>
							<tr className={'table_head'}>
								{headings ? headings.map((val, i) => <th>{val}</th>) : null}
							</tr>

							<tbody>
								{rows ? (
									rows.map((value, i) => (
										<tr key={i}>
											{value.map((val, j) => (
												<td key={j}>{val == 0 || val == null ? '-' : val}</td>
											))}
										</tr>
									))
								) : (
									<tr className={'no_data'}>
										<td colspan='5'>No data available </td>
									</tr>
								)}
							</tbody>
						</table>
					</section>
				</div>
			</Modal>
			<section className={'body_wrap packing_wrapper'}>
				{/* <Sidebar /> */}

				<div style={{ paddingBottom: '30px' }}>
					<div class='scrl_out' style={{ paddingBottom: '30px' }}>
						<section className={'home_top_wrap'}>
							<div className={'home_top_block'}>
								<p>Friday Matrix</p>
								<label>Shipment Week : {shipmentWeek ? shipmentWeek : ' --- '}</label>
							</div>
							<div
								style={{
									display: 'flex',
									justifyContent: 'flex-end',
									alignItems: 'flex-start',
								}}
							>
								<Link to={'/sell/thursday'}>
									<div className={'btn next_button'}>
										<div className={'next_icon'}>
											<MdNavigateBefore
												size={20}
												style={{
													color: 'white',
													marginTop: '-2px',
												}}
											/>
											<p className={'next_link'}>Thursday</p>
										</div>
									</div>
								</Link>
							</div>
						</section>
						<section>
							<OutlookVolumeMatrix
								orderVolumeMatrix={state.outlook_matrix}
								type='view'
								state={{
									...state,
									week_data: [...state.week_data, state.shipment_week],
								}}
								daysOrder={[...state.week_data, state.shipment_week]}
								toggleSwitchDisabled={false}
								// toggleStatus={state.toggle_outlook_matrix}
								toggleStatus={true}
								handleToggleStatus={
									(status) => {}
									// handleToggleStatus(status, 'toggle_outlook_matrix')
								}
							/>
						</section>
					</div>

					<section className={'OrderTableWrap' + ' ' + 'scrl_out'}>
						<table className={'friday_table'}>
							<tr className={'table_head'}>
								<th>
									<div className='icon-aligner'>
										<MdRefresh
											size={20}
											onClick={() => reset()}
											style={{ cursor: 'pointer' }}
										/>
										<span style={{ paddingLeft: '15px' }}>Customer</span>
									</div>
								</th>
								<th
									scope='colgroup'
									colSpan={expand ? '4' : '0'}
									style={{
										cursor: 'pointer',
										opacity: secondCol,
										textAlign: !expand ? 'left' : 'center',
									}}
								>
									<span onClick={() => forecastColClick()}>Estimate</span>
									{expand ? (
										<MdExpandLess
											size={20}
											className={'expand_icon'}
											onClick={() => setExpand(false)}
										/>
									) : (
										<MdExpandMore
											size={20}
											className={'expand_icon'}
											onClick={() => setExpand(true)}
										/>
									)}
								</th>
								<th
									onClick={() => quoteColClick()}
									style={{
										cursor: 'pointer',
										opacity: thirdCol,
									}}
								>
									Quote
								</th>
								<th
									onClick={() => orderColClick()}
									style={{
										cursor: 'pointer',
										opacity: fourthCol,
									}}
								>
									Order
								</th>
								<th>Calculator</th>
								<th>Status</th>
							</tr>
							<tr
								style={{
									color: '#58637A',
									background: '#efffff',
									display: expand ? '' : 'none',
								}}
							>
								<th></th>
								<th
									scope='col'
									style={{ opacity: forecastColGroup, textAlign: 'left' }}
								>
									Previous
								</th>
								<th
									scope='col'
									style={{ opacity: forecastColGroup, textAlign: 'left' }}
								>
									Base
								</th>
								<th
									scope='col'
									style={{ opacity: forecastColGroup, textAlign: 'left' }}
								>
									Forecast{' '}
								</th>
								<th
									scope='col'
									style={{ opacity: forecastColGroup, textAlign: 'left' }}
								>
									Notes
								</th>
								<th></th>
								<th></th>
								<th></th>
								<th></th>
							</tr>

							<tbody>
								{results.length > 0 ? (
									results.map((user, index) => {
										return (
											<tr>
												<td>
													<Link to='/home'>
														<MdHome size={20} color={'gray'} className={'home_icon'} />
													</Link>
													{user.name}
												</td>
												{expand ? (
													<>
														<td
															className={`${
																secondColClick && secondCol == 1
																	? 'col1'
																	: !secondColClick && secondCol !== 1
																	? 'col'
																	: 'col1'
															}`}
														>
															{user.active_matrix == 'prev_order' ? (
																<AiOutlineCheckCircle
																	size={18}
																	color={'green'}
																	onClick={() => {
																		getMatrix(user, 'OrderPlaced', 'view');
																		setViewType('Previous Order');
																	}}
																	style={{ cursor: 'pointer', textAlign: 'left' }}
																/>
															) : expand && user.has_previous_order ? (
																<AiOutlineCheckCircle
																	color={'grey'}
																	size={18}
																	onClick={() => {
																		getMatrix(user, 'OrderPlaced', 'view');
																		setViewType('Previous Order');
																	}}
																	style={{ cursor: 'pointer' }}
																/>
															) : null}{' '}
														</td>
														<td
															className={`${
																secondColClick && secondCol == 1
																	? 'col1'
																	: !secondColClick && secondCol !== 1
																	? 'col'
																	: 'col1'
															}`}
														>
															{user.active_matrix == 'base_order' ? (
																<AiOutlineCheckCircle
																	size={18}
																	color={'green'}
																	onClick={() => {
																		getMatrix(user, 'BaseOrder', 'view');
																		setViewType('Base Order');
																	}}
																	style={{ cursor: 'pointer' }}
																/>
															) : expand && user.has_base_order ? (
																<AiOutlineCheckCircle
																	color={'grey'}
																	size={18}
																	onClick={() => {
																		getMatrix(user, 'BaseOrder', 'view');
																		setViewType('Base Order');
																	}}
																	style={{ cursor: 'pointer' }}
																/>
															) : null}
														</td>
														<td
															className={`${
																secondColClick && secondCol == 1
																	? 'col1'
																	: !secondColClick && secondCol !== 1
																	? 'col'
																	: 'col1'
															}`}
														>
															{!user.has_forecast_order ? (
																!(user.has_order_placed || user.has_quotee) &&
																expand && (
																	<IoAddCircleOutline
																		onClick={() => {
																			getMatrix(user, 'ForecastOrder', 'edit');
																			setViewType('Forecast Order');
																		}}
																		size={20}
																		color={'grey'}
																		style={{ cursor: 'pointer' }}
																	/>
																)
															) : user.has_forecast_order ? (
																<>
																	{user.active_matrix == 'forecast_order' ? (
																		<AiOutlineCheckCircle
																			size={20}
																			color={'green'}
																			onClick={() => {
																				getMatrix(user, 'ForecastOrder', 'view');
																				setViewType('Forecast Order');
																			}}
																			style={{
																				cursor: 'pointer',
																			}}
																		/>
																	) : expand && user.has_forecast_order ? (
																		<AiOutlineCheckCircle
																			color={'grey'}
																			size={18}
																			onClick={() => {
																				getMatrix(user, 'ForecastOrder', 'view');
																				setViewType('Forecast Order');
																			}}
																			style={{ cursor: 'pointer' }}
																		/>
																	) : null}
																	{expand && (!user.has_order_placed || !user.has_quotee) ? (
																		<MdModeEdit
																			onClick={() => {
																				getMatrix(
																					user,
																					'ForecastOrder',
																					'edit',
																					user.forecast_order_id
																				);
																				setViewType('Forecast Order');
																			}}
																			size={20}
																			color={'grey'}
																			style={{
																				cursor: 'pointer',
																				marginLeft: '10px',
																			}}
																		/>
																	) : null}
																</>
															) : null}
														</td>
														<td
															className={`${
																secondColClick && secondCol == 1
																	? 'col1'
																	: !secondColClick && secondCol !== 1
																	? 'col'
																	: 'col1'
															}`}
														>
															{user.active_matrix == 'note' ? (
																<AiOutlineCheckCircle
																	size={18}
																	color={'green'}
																	onClick={() => {
																		getMatrix(user, 'CustomerNote', 'view');
																		setViewType('Customer Note');
																	}}
																	style={{ cursor: 'pointer' }}
																/>
															) : expand && user.has_customer_note ? (
																<AiOutlineCheckCircle
																	color={'grey'}
																	size={18}
																	onClick={() => {
																		getMatrix(user, 'CustomerNote', 'view');
																		setViewType('Customer Note');
																	}}
																	style={{ cursor: 'pointer' }}
																/>
															) : null}
														</td>
													</>
												) : (
													<td>
														{user.active_matrix == 'note' ||
														user.active_matrix == 'forecast_order' ||
														user.active_matrix == 'base_order' ||
														user.active_matrix == 'prev_order' ? (
															<AiOutlineCheckCircle
																color={'green'}
																size={18}
																onClick={() => {
																	getMatrix(
																		user,
																		user.active_matrix == 'note'
																			? 'CustomerNote'
																			: user.active_matrix == 'forecast_order'
																			? 'ForecastOrder'
																			: user.active_matrix == 'base_order'
																			? 'BaseOrder'
																			: user.active_matrix == 'prev_order'
																			? 'PreviousOrder'
																			: null,
																		'view'
																	);
																	setViewType(
																		user.active_matrix == 'forecast_order'
																			? 'Forecast Order'
																			: user.active_matrix == 'note'
																			? 'Customer Note'
																			: user.active_matrix == 'base_order'
																			? 'Base Order'
																			: user.active_matrix == 'prev_order'
																			? 'Previous Order'
																			: null
																	);
																}}
																style={{ cursor: 'pointer' }}
															/>
														) : null}
													</td>
												)}
												<td
													className={`${
														thirdColClick && thirdCol == 1
															? 'col1'
															: !thirdColClick && thirdCol !== 1
															? 'col'
															: 'col1'
													}`}
												>
													{user.has_quotee && user.manage_quotee ? (
														<>
															<AiOutlineCheckCircle
																size={20}
																color={
																	user.manage_quotee ||
																	(!user.has_order_placed && user.has_quotee)
																		? 'green'
																		: 'gray'
																}
																onClick={() => {
																	getMatrix(user, 'Quotee', 'view');
																	setViewType('Quote');
																}}
																style={{ cursor: 'pointer' }}
															/>

															{/* <Link
																to={`/home/request/${user.quotee_id}/${user.customer_shipment_location_id}/${user.type}`}
															> */}
															<MdModeEdit
																size={20}
																color={'grey'}
																style={{
																	cursor: 'pointer',
																	marginLeft: '10px',
																}}
																onClick={() => {
																	localStorage.setItem('requestQuoteId', user.quotee_id);
                                  if(user.type==='pre_prospect'){
                                    window.location.href =`/sell/friday/request/${user.quotee_id}/${user.access_id}/${user.type}?model_name=LightQuote`

                                  }
                                  else{

                                    window.location.href = `/sell/friday/request/${user.quotee_id}/${user.access_id}/${user.type}`;
                                  }
																}}
															/>
															{/* </Link> */}

															<IoIosPricetag
																size={20}
																color={'gray'}
																onClick={() => {
																	setAddModal(true);
																	setQuoteId(user.quotee_id);
																}}
																style={{
																	cursor: 'pointer',
																	marginLeft: '10px',
																}}
															/>
														</>
													) : !user.has_quotee && user.manage_quotee ? (
														// <Link
														// 	to={`/home/request/0/${user.customer_shipment_location_id}/${user.type}`}
														// >
														<IoAddCircleOutline
															size={20}
															color={'grey'}
															style={{ cursor: 'pointer' }}
															onClick={() => {
																localStorage.setItem('requestQuoteId', '0');
																window.location.href = `/sell/friday/request/0/${user.access_id}/${user.type}`;
															}}
														/>
													) : (
														// </Link>
														<AiOutlineCheckCircle
															size={20}
															color={
																user.manage_quotee ||
																(!user.has_order_placed && user.has_quotee)
																	? 'green'
																	: 'gray'
															}
															onClick={() => {
																getMatrix(user, 'Quotee', 'view');
																setViewType('Quote');
															}}
															style={{ cursor: 'pointer' }}
														/>
													)}
												</td>

												<td
													className={`${
														fourthColClick && fourthCol == 1
															? 'col1'
															: !fourthColClick && fourthCol !== 1
															? 'col'
															: 'col1'
													}`}
												>
													{user.active_matrix == 'order' ? (
														<AiOutlineCheckCircle
															size={18}
															color={'green'}
															onClick={() => {
																getMatrix(user, 'OrderPlaced', 'view');
																setViewType('OrderPlaced');
															}}
															style={{ cursor: 'pointer' }}
														/>
													) : user.has_order_placed ? (
														<AiOutlineCheckCircle
															size={18}
															color={'grey'}
															onClick={() => {
																getMatrix(user, 'OrderPlaced', 'view');
																setViewType('OrderPlaced');
															}}
															style={{ cursor: 'pointer' }}
														/>
													) : null}
												</td>
												<td>
													{' '}
													{user.has_quotee ? (
														<IoCalculatorOutline
															onClick={() => {
																openCalculatorModal('quote', user.quotee_id);
															}}
															size={18}
															color={'green'}
															style={{ cursor: 'pointer' }}
														/>
													) : (
														<IoCalculatorOutline
															onClick={() => {
																openCalculatorModal(user.type, user.access_id);
															}}
															size={18}
															color={'gray'}
															style={{ cursor: 'pointer' }}
														/>
													)}
												</td>
												<td>{renderStatus(user.negotiation_status)}</td>
											</tr>
										);
									})
								) : (
									<tr className={'no_data'}>
										<td colspan='10'>No data available </td>
									</tr>
								)}
							</tbody>
						</table>
					</section>
				</div>
			</section>
			{addModal && (
				<QuotePrice id={quoteId} addPriceStatus={addPriceStatus}></QuotePrice>
			)}
		</>
	);
};

export default Friday;
