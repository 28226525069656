import React from 'react';
import HeadCaption from './Modules/HeadCaption';
import NineStarSelector from './Modules/NineStarSelector';
import SplitSelector from './Modules/SplitSelector';
import MatrixTotals from './Modules/MatrixTotals';
import ButtonComponent from './Modules/ButtonComponent';
import WeekComponets from './Modules/WeekComponents';
import './Style.css';
import MatrixSelector from './Modules/MatrixSelector';
import BubbleSelector from './Modules/BubbleSelector';
import WeekSelectorWrapper from './Modules/WeekSelectorWrapper';
/**
 * @author
 * @function GlobalMatrixCard
 **/

const GlobalMatrixCard = ({
	buttonStyleType='grid',
	enabledComponents,

	nineStarDisplay = true,
	nineStarSelectedDimension,
	nineStarHandleChangeDimension,
	nineStarDisabledOtpions,

	headerCaption,
	headerSubCaption,

	splitDisplay = true,
	splitSelectorChecked,
	splitSelectorHandleChange,
	splitSelectorDisabled,

	orderTotalBox,
	dispalyOrderTotalBox,
	orderTotalWeight,
	displayOrderTotalWeight,
	orderTotalUSD,
	displayOrderTotalUSD,
	priceInfo = {},

	updateButtonDisplay,
	updateButtonDisable,
	updateButtonHandle,

	viewOrderButtonDisplay,
	viewOrderButtonDisable,
	viewOrderButtonHandle,

	viewPurchaseButtonDisplay,
	viewPurchaseButtonDisable,
	viewPurchaseButtonHandle,

	confirmPurchaseButtonDisplay,
	confirmPurchaseButtonDisable,
	confirmPurchaseButtonHandle,

	refreshButtonDisplay,
	refreshButtonHandle,
	rankingButtonDisplay,
	rankingButtonHandle,

	thumbsDownProducerButtonDisplay,
	thumbsDownProducerButtonHandle,
	thumbsDownProducerButtonColor,
	thumbsDownSf7ButtonHandle,
	thumbsDownSf7ButtonColor,
	thumbsDownSf7ButtonDisplay,

	emailButtonDisplay,
	emailButtonHandle,

	documentButtonDisplay,
	documentButtonHandle,

	sourcingWeekDisplay,
	sourcingWeek,
	sourcingDates,
	shipmentWeekDisplay,
	shipmentWeek,
	shipmentDates,
	currentWeek,

	onMatrixModelChange,
	onAccessTypeChange,
	onAccessIdChange,
	onSourcingWeekChange,
	onGetMatrixButtonClick,
	disableMatrixModel,
	disableAccessType,
	disableAccessId,
	disableSourcingWeek,
	disableGetMatrixButton,
	selectedMatrixModel,
	selectedAccessType,
	selectedAccessId,

	bubbleSelectorTitle,
	bubbleSelectorBubbleArray,
	bubbleSelectorSelectedBubbles,
	bubbleSelectorHandleAddBubble,
	bubbleSelectorHandleDeleteBubble,
	bubbleSelectorHandleBubbleSelect,
	bubbleSelectorHandleSigma,
	bubbleSelectorMatrixChangedStatus,
	bubbleSelectorAddBtnClicked,
	bubbleSelectorisAddVisibility = true,

	//week selector
	access_type,
	access_id,
	modelName = '',
	selectedSourcingWeek,
	is_disabled,
	MatixIconDisplay,
	showActiveMatrixIconDisplay,
	onWeekClick = () => {},
	updateActiveWeekDataStatus = () => {},
}) => {
	return (
		<div className='fc_main_header_wrapper'>
			<div className='wrap_btn_drop'>
				<div className='matrixModelSelectorMain'>
					{enabledComponents.includes('HeadCaption') && (
						<HeadCaption
							caption={headerCaption}
							subCaption={headerSubCaption}
							MatixIconDisplay={MatixIconDisplay}
							showActiveMatrixIconDisplay={showActiveMatrixIconDisplay}
						/>
					)}
				</div>
				<div className={buttonStyleType==='grid'?'header_button_main':'header_button_main_flex'}>
					{enabledComponents.includes('ButtonComponent') && (
						<ButtonComponent
							buttonStyleType={buttonStyleType}
							updateButtonDisplay={updateButtonDisplay}
							updateButtonDisable={updateButtonDisable}
							updateButtonHandle={updateButtonHandle}
							viewOrderButtonDisplay={viewOrderButtonDisplay}
							viewOrderButtonDisable={viewOrderButtonDisable}
							viewOrderButtonHandle={viewOrderButtonHandle}
							viewPurchaseButtonDisplay={viewPurchaseButtonDisplay}
							viewPurchaseButtonDisable={viewPurchaseButtonDisable}
							viewPurchaseButtonHandle={viewPurchaseButtonHandle}
							confirmPurchaseButtonDisplay={confirmPurchaseButtonDisplay}
							confirmPurchaseButtonDisable={confirmPurchaseButtonDisable}
							confirmPurchaseButtonHandle={confirmPurchaseButtonHandle}
							refreshButtonDisplay={refreshButtonDisplay}
							refreshButtonHandle={refreshButtonHandle}
							rankingButtonDisplay={rankingButtonDisplay}
							rankingButtonHandle={rankingButtonHandle}
							thumbsDownProducerButtonDisplay={thumbsDownProducerButtonDisplay}
							thumbsDownProducerButtonHandle={thumbsDownProducerButtonHandle}
							thumbsDownProducerButtonColor={thumbsDownProducerButtonColor}
							thumbsDownSf7ButtonHandle={thumbsDownSf7ButtonHandle}
							thumbsDownSf7ButtonColor={thumbsDownSf7ButtonColor}
							thumbsDownSf7ButtonDisplay={thumbsDownSf7ButtonDisplay}
							emailButtonDisplay={emailButtonDisplay}
							emailButtonHandle={emailButtonHandle}
							documentButtonDisplay={documentButtonDisplay}
							documentButtonHandle={documentButtonHandle}
						/>
					)}
				</div>
			</div>
			{enabledComponents.includes('MatrixSelector') && (
				<div className='matrixModelSelectorMain'>
					<MatrixSelector
						onMatrixModelChange={onMatrixModelChange}
						onAccessTypeChange={onAccessTypeChange}
						onAccessIdChange={onAccessIdChange}
						onSourcingWeekChange={onSourcingWeekChange}
						onGetMatrixButtonClick={onGetMatrixButtonClick}
						disableMatrixModel={disableMatrixModel}
						disableAccessType={disableAccessType}
						disableAccessId={disableAccessId}
						disableSourcingWeek={disableSourcingWeek}
						disableGetMatrixButton={disableGetMatrixButton}
						selectedMatrixModel={selectedMatrixModel}
						selectedAccessType={selectedAccessType}
						selectedAccessId={selectedAccessId}
						selectedSourcingWeek={selectedSourcingWeek}
						access_type={access_type}
						access_id={access_id}
						modelName={modelName}
						updateActiveWeekDataStatus={updateActiveWeekDataStatus}
					/>
				</div>
			)}
			{enabledComponents.includes('WeekSelector') && (
				<WeekSelectorWrapper
					access_type={access_type}
					access_id={access_id}
					modelName={modelName}
					selectedSourcingWeek={selectedSourcingWeek}
					is_disabled={is_disabled}
					onWeekClick={onWeekClick}
					updateActiveWeekDataStatus={updateActiveWeekDataStatus}
				/>
			)}
			{enabledComponents.includes('BubbleSelector') &&
				selectedSourcingWeek !== '' && (
					<div>
						<BubbleSelector
							title={bubbleSelectorTitle}
							bubbleArray={bubbleSelectorBubbleArray}
							selectedBubbles={bubbleSelectorSelectedBubbles}
							handleAddBubble={bubbleSelectorHandleAddBubble}
							handleDeleteBubble={bubbleSelectorHandleDeleteBubble}
							handleBubbleSelect={bubbleSelectorHandleBubbleSelect}
							handleSigma={bubbleSelectorHandleSigma}
							isMatrixChanged={bubbleSelectorMatrixChangedStatus}
							isAddBtnClicked={bubbleSelectorAddBtnClicked}
							isAddVisibility={bubbleSelectorisAddVisibility}
						/>
					</div>
				)}

			<div className='bottom_statics_box'>
				<div className='display_week'>
					{enabledComponents.includes('MatrixTotals') &&
						selectedSourcingWeek !== '' && (
							<MatrixTotals
								orderTotalBox={orderTotalBox}
								dispalyOrderTotalBox={dispalyOrderTotalBox}
								orderTotalWeight={orderTotalWeight}
								displayOrderTotalWeight={displayOrderTotalWeight}
								orderTotalUSD={orderTotalUSD}
								displayOrderTotalUSD={displayOrderTotalUSD}
								priceInfo={priceInfo}
							/>
						)}
					{enabledComponents.includes('WeekComponets') &&
						selectedSourcingWeek !== '' && (
							<WeekComponets
								sourcingWeekDisplay={sourcingWeekDisplay}
								sourcingWeek={sourcingWeek}
								sourcingDates={sourcingDates}
								shipmentWeekDisplay={shipmentWeekDisplay}
								shipmentWeek={shipmentWeek}
								shipmentDates={shipmentDates}
								currentWeek={currentWeek}
							/>
						)}
				</div>
				<div className='splitter_display'>
					{enabledComponents.includes('NineStarSelector') && nineStarDisplay && (
						<NineStarSelector
							selectedDimension={nineStarSelectedDimension}
							handleChange={nineStarHandleChangeDimension}
							disabledOptions={nineStarDisabledOtpions}
						/>
					)}
					{enabledComponents.includes('SplitSelector') && splitDisplay && (
						<SplitSelector
							checked={splitSelectorChecked}
							handleChange={splitSelectorHandleChange}
							disabled={splitSelectorDisabled}
						/>
					)}
				</div>
			</div>
		</div>
	);
};

export default GlobalMatrixCard;
