import React, { useState, useEffect } from 'react'
import PackingServices from '../../api/PackingServices'

const FreightRates = () => {
    const [seafood_destinations, setSeafoodDestinations] = useState([])

    useEffect(() => {    
        async function fetchFreightRates() {
            const getDestinationList = await PackingServices.getDestinationList('all');
            if(getDestinationList.data){
                const {data} = getDestinationList;
                setSeafoodDestinations(data.results);
            }
        }
        fetchFreightRates()
    }, [])


    return (
        <div>
            <ul>
            {seafood_destinations.map((destination) => (
                <li id={destination.id}>{destination.airport} ({destination.iata_code}): {destination.freight_rate ? destination.freight_rate : '—'}</li>
            ))}  
            </ul>
        </div>
    )
}

export default FreightRates

