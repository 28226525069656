import React, { useEffect, useReducer } from 'react';
import { IoEye } from 'react-icons/io5';
import ToggleSwitch from '../Toggle/ToggleSwitch';

export const ACTIONS = {
	GET_PACKING_LIST: 'GET_PACKING_LIST',
};

const reducer = (state, { type, payload }) => {
	switch (type) {
		case ACTIONS.GET_PACKING_LIST:
			return {
				...state,
				packingList: payload.packingList,
			};

		default:
			return state;
	}
};

function PackingBatchList({
	data,
	toggle = false,
	handlePackingList = () => {},
}) {
	const [state, dispatch] = useReducer(reducer, {
		packingList: [],
	});

	useEffect(() => {
		dispatch({
			type: ACTIONS.GET_PACKING_LIST,
			payload: {
				packingList: data,
			},
		});
	}, [data]);

	return (
		<>
			<table
				className='table-packing-list'
				style={{ width: '100%', borderSpacing: 0 }}
			>
				<thead>
					<tr className='table-header'>
						<th style={{ textAlign: 'left' }}>
							<span className={'inData table-content'}>Batch</span>
						</th>
						{/* <th></th> */}
					</tr>
				</thead>
				{toggle && (
					<tbody>
						{state.packingList.length > 0 ? (
							state.packingList.map((user, index) => {
								return (
									<tr key={index}>
										<td className='producer-cell' style={{ textAlign: 'left' }}>
											{user.name}

											{/* <IoEye
												size={20}
												onClick={() => {
													// manageRFQModal(true);
													// dispatch({
													// 	type: ACTIONS.SELECT_PURCHASE,
													// 	payload: user,
													// });
												}}
											/> */}
										</td>
										{/* <td>
										<ToggleSwitch
											fromMatrix={true}
											// disabled={!user.has_purchase}
											onToggleChange={(toggle) => handlePackingList(user, toggle)}
										/>
									</td> */}
									</tr>
								);
							})
						) : (
							<tr>
								<td colspan='3'>No data available </td>
							</tr>
						)}
					</tbody>
				)}
			</table>
		</>
	);
}

export default PackingBatchList;
