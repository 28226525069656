import React from 'react'
import eventBinder from '../../hoc/eventBinder'

/**
* @author
* @function CustomButton
**/

const CustomButton = ({ children, ...props }) => {
  return (
    <div
      {...props}
    >
      {children || 'Button'}
    </div>
  )

}

export default eventBinder(CustomButton)