import React, { useState } from "react";
import { Link } from "react-router-dom";
import GetOtp from "../GetOtp/GetOtp";
import ResetPassword from "../ResetPassword/ResetPassword";

const ForgotPassword = () => {
  const [showOtp, setShowOtp] = useState(true);
  const [isAdmin, setIsAdmin] = useState(false);

  const handlePassword = (status) => {
    setShowOtp(status);
  };
  return (
    <div className={"loginWrap"}>
      <div className={"bub_1"}>
        <img
          src={require("../../../assets/images/login_bubbles.png")}
          alt="log_bub"
        />
      </div>
      <div className={"bub_2"}>
        <img
          src={require("../../../assets/images/login_bubbles.png")}
          alt="log_bub"
        />
      </div>
      <div className={"bub_3"}>
        <img
          src={require("../../../assets/images/login_bubbles.png")}
          alt="log_bub"
        />
      </div>
      <div className={"l_top_im"}>
        <img src={require("../../../assets/images/t_l_im.png")} alt="log_bub" />
      </div>
      <div className={"b_rt_im"}>
        <img src={require("../../../assets/images/b_r_im.png")} alt="log_bub" />
      </div>
      <div className={"loginOuter"}>
        <section>
          <div className={"logo_landing"}></div>
          <p>Salmon across the seven seas</p>
          <label>Overseas salmon in 1-2-3</label>
          <div className={"downloadWrap"}>
            <p>Download Our App</p>
            <div className={"down_btn_out"}>
              <div className={"btn_app"}>
                <div className={"app_ico"}></div>
                Download from
                <br /> Android App Store
              </div>
              <div className={"btn_app"}>
                <div className={"app_ico"}></div>
                Download from
                <br /> App Store
              </div>
            </div>
          </div>
          <div className="fc_stream_wrapper">
            <div
              onClick={() => setIsAdmin(!isAdmin)}
              className={
                isAdmin === true
                  ? "wrg-toggle--checked  wrg-toggle pos-relative"
                  : "wrg-toggle pos-relative"
              }
            >
              <div
                className="wrg-toggle-container"
                style={{
                  backgroundColor: isAdmin ? "green" : "#4d4d4d",
                }}
              >
                <div className="wrg-toggle-check">
                  <span></span>
                </div>
                <div className="wrg-toggle-uncheck">
                  <span></span>
                </div>
              </div>
              <div className="wrg-toggle-circle"></div>
              <input
                type="checkbox"
                aria-label="Toggle Button"
                className="wrg-toggle-input"
              />
            </div>
          </div>
        </section>
        <section>
          {showOtp ? (
            <GetOtp submitOtp={handlePassword} isAdmin={isAdmin} />
          ) : (
            <ResetPassword />
          )}

          <Link to={"/"}>
            <p className={"fP"}>Back To Login</p>
          </Link>
        </section>
      </div>
    </div>
  );
};

export default ForgotPassword;
