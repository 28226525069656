import React, { useState, useEffect } from 'react';
import './ActiveQuotes.css';

function ActiveShipments({ activeShipments }) {
	return (
		<div className='active-quote-container'>
			<h3>Active Shipments</h3>
			<p className='subtitle'>View your active shipments here</p>
			<ShipmentTable data={activeShipments} />
		</div>
	);
}

const ShipmentTable = ({ data }) => {
	const [currentPage, setCurrentPage] = useState(1);
	const [dataPerPage] = useState(5);

	const handlePage = (e) => {
		setCurrentPage(e.target.id);
	};

	const indexOfLastTodo = currentPage * dataPerPage;
	const indexOfFirstTodo = indexOfLastTodo - dataPerPage;
	const currentItems = data.slice(indexOfFirstTodo, indexOfLastTodo);

	const pageNumbers = [];
	for (let i = 1; i <= Math.ceil(data.length / dataPerPage); i++) {
		pageNumbers.push(i);
	}

	const renderPageNumbers = pageNumbers.map((number) => {
		return (
			<div
				key={number}
				id={number}
				onClick={handlePage}
				className={`pagination-numbers ${
					parseInt(currentPage) === number ? 'pagination-active' : ''
				}`}
			>
				{number}
			</div>
		);
	});

	return (
		<table className='shipment-table-new'>
			<thead>
				<tr>
					<th>Customer</th>
					<th>Destination Airport</th>
					<th>Arrival Date</th>
				</tr>
			</thead>
			<tbody
				style={{ overflowX: 'auto', width: '100%', position: 'relative' }}
				className='fc_scroll'
			>
				{currentItems.length ? (
					currentItems.map((item, i) => {
						return (
							<tr key={i}>
								<td>{item.name}</td>
								<td>{item.destination_airport_name}</td>
								<td>{item.arrival_target}</td>
							</tr>
						);
					})
				) : (
					<tr>
						<td style={{ textAlign: 'center', padding: 20 }} colSpan={3}>
							No data available
						</td>
					</tr>
				)}
			</tbody>
			{renderPageNumbers.length > 1 && (
				<tfoot>
					<tr>
						<td colSpan={4} className='pagination-number-container'>
							{renderPageNumbers}
						</td>
					</tr>
				</tfoot>
			)}
		</table>
	);
};

export default ActiveShipments;
