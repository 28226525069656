import React, { Fragment, useEffect, useReducer } from 'react';
import { GrClose } from 'react-icons/gr';
import GlobalMatrixCard from '../GlobalMatrixCard/GlobalMatrixCard';
import Modal from 'react-modal';
import CalendarServices from '../../api/CalendarServices';
import RFQServices from '../../api/RFQServices';
import './RFQView.css';
import { formatCurrency } from '../../util';

/**
 * @author
 * @function RFQView
 **/

const initalState = {
	headerInfo: {
		caption: 'RFQ View',
		subCaption: '',
	},
	enabledComponents: ['HeadCaption', 'MatrixTotals', 'WeekComponets'],
	total_matrix_volume: '',
	total_matrix_weight: '',
	total_matrix_usd: '',
	sourcing_week: '',
	sourcing_date: '',
	shipment_week: '',
	shipment_date: '',
	matrix_data_9_8: '',
	weight_classes: [],
	matrix_data_9_7: '',
	matrix_data_9_1: '',
	matrix_dimension_status: 1,
	each_row_volume_total: 0,
	each_row_price_total: 0,
	rejection_status: 0,
};
export const ACTIONS = {
	SET_FIELDS: 'SET_FIELDS',
	GET_RFQ_MATRIX: 'GET_RFQ_MATRIX',
	SET_DATES: 'SET_DATES',
	SET_VOLUME_AND_WEIGHT: 'SET_VOLUME_AND_WEIGHT',
	SET_HEADER: 'SET_HEADER',
};
const reducer = (state, { type, payload }) => {
	const renderDisplayMatrix = (matrix_dimension_status) => {
		switch (matrix_dimension_status) {
			case 1:
				return payload.matrix_data_9_1 || state.matrix_data_9_1;

			case 2:
				return payload.matrix_data_9_7 || state.matrix_data_9_7;

			case 3:
				return payload.matrix_data_9_8 || state.matrix_data_9_8;
			default:
				return payload.matrix_data_9_7 || state.matrix_data_9_7;
		}
	};
	switch (type) {
		case ACTIONS.SET_FIELDS:
			return {
				...state,
				[payload.field]: payload.value,
			};
		case ACTIONS.SET_DATES:
			return {
				...state,
				sourcing_week: payload.sourcing_week,
				sourcing_date: payload.sourcing_date,
				shipment_week: payload.shipment_week,
				shipment_date: payload.shipment_date,
				table_header_weeks: payload.table_header_weeks,
			};
		case ACTIONS.GET_RFQ_MATRIX:
			return {
				...state,
				matrix_data_9_8: payload.matrix_data_9_8,
				weight_classes: Object.keys(payload.matrix_data_9_8),
				matrix_data_9_7: payload.matrix_data_9_7,
				matrix_data_9_1: payload.matrix_data_9_1,
				display_matrix: renderDisplayMatrix(payload.matrix_dimension_status),
				matrix_dimension_status: payload.matrix_dimension_status,
				each_row_volume_total: payload.each_row_volume_total,
				each_row_price_total: payload.each_row_price_total,
				rejection_status: payload.rejection_status,
			};
		case ACTIONS.SET_VOLUME_AND_WEIGHT:
			return {
				...state,
				total_matrix_volume: payload.total_matrix_volume,
				total_matrix_weight: payload.total_matrix_volume * 22,
			};
		case ACTIONS.SET_HEADER:
			return {
				...state,
				headerInfo: {
					...state.headerInfo,
					...payload,
				},
			};
		default:
			return state;
	}
};
const RFQView = ({
	isModalOpened,
	manageRFQModal,
	rfqId,
	selected_producer,
}) => {
	const [state, dispatch] = useReducer(reducer, initalState);
	const [loadingStatus, setLoadingStatus] = React.useState('idle');
	//fetch RFQ Matrix
	const fetchRFQMatrix = async (rfqId) => {
		const matrixData = await RFQServices.getMatrixDataByRFQId(rfqId);
		setLoadingStatus('loading');
		if (matrixData.data) {
			const { data } = matrixData;
			dispatch({
				type: ACTIONS.GET_RFQ_MATRIX,
				payload: {
					matrix_data_9_8: data.full_matrix_combined,
					matrix_data_9_7: data.full_active_matrix,
					matrix_data_9_1: data.matrix_row_total,
					matrix_dimension_status: data.matrix_dimension_status,
					each_row_price_total: data.active_admin_offer_full,
					each_row_volume_total: data.weight_class_volumes,
					rejection_status: data.rfq.status,
				},
			});
			setLoadingStatus('loaded');
		} else {
			setLoadingStatus('error');
			console.error(matrixData.error);
		}
	};
	const fetchWeekDetails = async () => {
		const weekDetails = await CalendarServices.getWeekDates();
		if (weekDetails.data) {
			const { data } = weekDetails;
			dispatch({
				type: ACTIONS.SET_DATES,
				payload: {
					sourcing_week: data.sourcing_week,
					sourcing_date: data.sourcing_date,
					shipment_week: data.shipment_week,
					table_header_weeks: [...data.shipment_dates, data.shipment_week],
					shipment_date: data.shipment_date_9_1,
				},
			});
		}
	};
	useEffect(() => {
		fetchWeekDetails();
		fetchRFQMatrix(rfqId);
		dispatch({
			type: ACTIONS.SET_HEADER,
			payload: {
				caption: 'RFQ View',
				subCaption: selected_producer,
			},
		});
		return () => {
			setLoadingStatus('idle');
		};
	}, [rfqId]);
	useEffect(() => {
		if (state.display_matrix) {
			let sum_volumes = 0;
			Object.keys(state.display_matrix).length > 0 &&
				Object.entries(state.display_matrix).forEach((item, index) => {
					item[1].map((val) => {
						sum_volumes += val.volume;
					});
				});
			let total_usd = 0;

			Object.entries(state.display_matrix).forEach((item) => {
				if (state.each_row_price_total[item[0]] !== null) {
					let row_volumes = 0;
					item[1].map((val) => {
						row_volumes += val.volume;
					});
					total_usd += row_volumes * 22 * state.each_row_price_total[item[0]];
				}
			});
			dispatch({
				type: ACTIONS.SET_VOLUME_AND_WEIGHT,
				payload: {
					total_matrix_volume: sum_volumes,
				},
			});
			dispatch({
				type: ACTIONS.SET_FIELDS,
				payload: {
					field: 'total_matrix_usd',
					value: total_usd,
				},
			});
		}
	}, [state.display_matrix]);

	return (
		<Modal
			className='rfq-view-modal'
			ariaHideApp={false}
			isOpen={isModalOpened || false}
			onRequestClose={() => manageRFQModal(false)}
			style={{
				overlay: {
					position: 'fixed',
					zIndex: 999999,
					top: 0,
					left: 0,
					width: '100vw',
					height: '100vh',
					background: 'rgba(0, 0, 0, 0.5)',
					display: 'flex',
					alignItems: 'center',
					justifyContent: 'center',
				},
				content: {
					background: 'white',
					// width: type == 'view' ? '50rem' : '',
					maxWidth: 'calc(100vw - 2rem)',
					maxHeight: 'calc(100vh - 2rem)',
					boxshadow: '0 0 30px 0 rgba(0, 0, 0, 0.25)',
					overflowY: 'auto',
					position: 'relative',
				},
			}}
		>
			{loadingStatus !== 'loaded' ? (
				<p>Loading...</p>
			) : (
				<>
					<div
						className={'close-icon'}
						onClick={() => {
							manageRFQModal(false);
						}}
					>
						<GrClose size={25} />
					</div>
					<GlobalMatrixCard
						//header information
						headerCaption={state.headerInfo.caption}
						headerSubCaption={state.headerInfo.subCaption}
						enabledComponents={state.enabledComponents}
						//matrix totals
						orderTotalBox={state.total_matrix_volume}
						dispalyOrderTotalBox={true}
						orderTotalWeight={state.total_matrix_weight}
						displayOrderTotalWeight={true}
						orderTotalUSD={state.total_matrix_usd}
						displayOrderTotalUSD={true}
						priceInfo={{currencyType:'NOK'}}
						//Week component
						sourcingWeek={state.sourcing_week}
						sourcingDates={state.sourcing_date}
						shipmentWeek={state.shipment_week}
						shipmentDates={state.shipment_date}
						sourcingWeekDisplay={true}
						shipmentWeekDisplay={true}
						currentWeek={state.sourcing_week}
					/>
					<div className='fc_scroll'>
						<table className='table-new'>
							<WeekHeader
								matrix_dimension_status={state.matrix_dimension_status}
								table_header_weeks={state.table_header_weeks}
								shipment_date={state.shipment_date}
							/>
							<tbody
								style={{ overflowX: 'auto', width: '100%', position: 'relative' }}
								className='fc_scroll'
							>
								{state.weight_classes.map((key, i) => {
									return (
										<Fragment key={i}>
											<WeightRow
												prices={state.each_row_price_total[key]}
												weightClass={key}
												volumes={state.each_row_volume_total[key]}
												rowData={state.display_matrix[key]}
												matrix_row_total={state.each_row_volume_total}
											/>
										</Fragment>
									);
								})}
							</tbody>
						</table>
					</div>
				</>
			)}
		</Modal>
	);
};
const WeekHeader = ({
	matrix_dimension_status = '',
	table_header_weeks = [],
	shipment_date = '',
}) => {
	return (
		<thead>
			<tr>
				<th></th>
				{matrix_dimension_status !== 1 ? (
					table_header_weeks.map((val, i) => {
						if (
							matrix_dimension_status !== 3 &&
							table_header_weeks.length - 1 === i
						) {
							return <Fragment key={i}></Fragment>;
						}
						return <th key={i}>{val}</th>;
					})
				) : (
					<th>{shipment_date}</th>
				)}
				<th>Price/kg</th>
			</tr>
		</thead>
	);
};

const WeightRow = ({
	prices = Number(),
	volumes = Number(),
	weightClass = '',
	rowData = [],
}) => {
	return (
		<tr style={{ background: `${volumes ? '#00ffff66' : '#00ffff26'}` }}>
			<td className='td-hog'>
				<span style={volumes ? {} : { opacity: 0.3 }}>
					<span>HOG {weightClass} kg</span>
					{volumes !== 0 ? (
						<span style={{ fontSize: 14 }}>
							&nbsp;(Total {volumes} {volumes === 1 ? 'Box' : 'Boxes'})
						</span>
					) : null}
				</span>
			</td>
			{rowData.map((row, i) => (
				<td key={i}>
					<input
						value={row.volume === 0 ? '' : row.volume}
						disabled={true}
						type='number'
						className='volume-input-disabled'
					/>
				</td>
			))}
			<td className='td-kg'>{prices !== null ? <span>{formatCurrency(prices,'NOK')}/kg</span> : ''}</td>
		</tr>
	);
};

export default RFQView;
