import React, { useState, useEffect } from 'react'

const LanguageSelector = ({handleChange, filter}) => {
    const [languages, setLanguages] = useState([])

    useEffect(() => {
        console.log({filter})
        async function fetchAPI() {
            const url = 'https://dev.exocoetidae.no/administrator/api/language/'
            const headers = {
                'Authorization': '558ef807-ad2b-4aba-8629-c8d227745493'
            } 
            const apiResponse = await fetch(url, {headers:headers}).then(res => res.json())
            const languages = apiResponse.result
            setLanguages(languages)
            console.log(languages)
        }
        fetchAPI()
    }, [])

    return (
        <div>
            <select onChange={handleChange}>
            <option disabled selected value>
				--Select a language--
			</option>
                {languages.map((language) => (
                    <option value={language.seafood_language.iso_language_code}>{language.seafood_language.language_english} ({language.seafood_language.iso_language_code})</option>
                ))}        
            </select>
        </div>
    )
}

export default LanguageSelector