import React, { useEffect, useReducer } from 'react';
import { Fragment } from 'react';
import { Link, useParams } from 'react-router-dom';
import { useHistory } from 'react-router-dom';
import Sidebar from '../Sidebar/Sidebar';
import './RFQPurchaseCheckout.css';
import RFQServices from '../../api/RFQServices';
import GlobalMatrixCard from '../GlobalMatrixCard/GlobalMatrixCard';
import CalendarServices from '../../api/CalendarServices';
import Modal from 'react-modal';
import { GrClose } from 'react-icons/gr';
import { formatCurrency } from '../../util';
const initialState = {
	active_weight_classes: [],
	checkout_matrix: {},
	active_prices: {}, // active_admin_offer_limited from BE
	retrieved_rfq_id: '',

	//HEADER PARTS
	total_matrix_weight: '',
	total_matrix_volume: '',
	total_matrix_usd: '',
	sourcing_week: '',
	sourcing_date: '',
	shipment_week: '',
	shipment_date: '',
	active_weekdays: [],

	headerInfo: {
		caption: 'View Purchase',
		subCaption: '',
	},
	enabledComponents: [
		'HeadCaption',
		'ButtonComponent',
		'MatrixTotals',
		'WeekComponets',
		// 'NineStarSelector',
		// 'SplitSelector',
	],
	weight_class_volumes: {},
	loadingStatus: 'IDLE'
};
export const ACTIONS = {
	SET_HEADER_INFO: 'SET_HEADER_INFO',
	SET_CONFIG: 'SET_CONFIG',
	SET_FIELDS: 'SetFields',
	GET_CHECKOUT_MATRIX: 'GetCheckoutMatrix',
	SET_LOADING_STATUS: 'SET_LOADING_STATUS',
};
const reducer = (state, { type, payload }) => {
	switch (type) {
		case ACTIONS.SET_LOADING_STATUS:
			return {
				...state,
				loadingStatus: payload,
			};
		case ACTIONS.SET_HEADER_INFO:
			return {
				...state,
				headerInfo: { ...state.headerInfo, ...payload },
			};
		case ACTIONS.SET_CONFIG:
			return {
				...state,
				config: { ...state.config, ...payload },
			};
		case ACTIONS.SET_FIELDS:
			return {
				...state,
				[payload.field]: payload.value,
			};
		case ACTIONS.GET_CHECKOUT_MATRIX:
			return {
				...state,
				active_weight_classes: payload.active_weight_classes,
				checkout_matrix: payload.checkout_matrix,
				active_prices: payload.active_prices,
				retrieved_rfq_id: payload.retrieved_rfq_id,
				total_matrix_weight: payload.total_matrix_weight,
				total_matrix_volume: payload.total_matrix_volume,
				total_matrix_usd: payload.total_matrix_usd,
				sourcing_week: payload.sourcing_week,
				shipment_week: payload.shipment_week,
				active_weekdays: payload.active_weekdays,
				sourcing_date: payload.sourcing_date,
				shipment_date: payload.shipment_date,
				weight_class_volumes: payload.weight_class_volumes,
			};
		default:
			return {
				...state,
			};
	}
};
function RFQPurchaseCheckout({
	isModalOpened,
	manageRFQModal,
	selectedPurchaseInfo,
	...props }) {
	// const { store, dispatch } = props;
	const [state, dispatch] = useReducer(reducer, initialState);
	useEffect(() => {
		dispatch({
			type: ACTIONS.SET_HEADER_INFO,
			payload: {
				subCaption: `${selectedPurchaseInfo.type} - ${selectedPurchaseInfo.name}`
			}
		})
		const fetchWeekDetails = async () => await CalendarServices.getWeekDates();
		fetchWeekDetails()
			.then((res) => {
				const { data } = res;
				fetchViewPurchase(data);
			})
			.catch((err) => console.log('err week', err));
		return (() => {
			dispatch({
				type: ACTIONS.SET_LOADING_STATUS,
				payload: 'IDLE'
			});
		});
	}, [selectedPurchaseInfo]);

	//fetch View Purchase Matrix
	const fetchViewPurchase = async (weekData) => {
		const matrixData = await RFQServices.viewPurchasebyId(selectedPurchaseInfo.rfq_id);

		if (matrixData.data) {
			const { data } = matrixData;

			console.log('Week Data', weekData);

			console.log('Response View Purchase data', data.weight_class_volumes);
			dispatch({
				type: ACTIONS.GET_CHECKOUT_MATRIX,
				payload: {
					active_weight_classes: data.matched_weight_class,
					checkout_matrix: data.check_out_shipment_matrix,
					active_prices: data.active_admin_offer_limited,
					retrieved_rfq_id: data.rfq_id,
					total_matrix_weight: data.total_weight,
					total_matrix_volume: data.total_volume,
					total_matrix_usd: data.total_price,
					sourcing_week: data.sourcing_week,
					shipment_week: data.shipment_week,
					active_weekdays: Object.keys(data.check_out_shipment_matrix),
					sourcing_date: weekData.sourcing_date,
					shipment_date: weekData.shipment_date_9_1,
					weight_class_volumes: data.weight_class_volumes,
				},
			});
			console.log('fdjbvdfhibdf')
			dispatch({
				type: ACTIONS.SET_LOADING_STATUS,
				payload: 'LOADED'
			})
		} else {
			console.error(matrixData.error);
		}
	};
	console.log(selectedPurchaseInfo, "dsfhbvfhjvhjfbhj")
	return (
		<Modal
			className='rfq-view-modal'
			ariaHideApp={false}
			isOpen={isModalOpened || false}
			onRequestClose={() => manageRFQModal(false)}
			style={{
				overlay: {
					position: 'fixed',
					zIndex: 999999,
					top: 0,
					left: 0,
					width: '100vw',
					height: '100vh',
					background: 'rgba(0, 0, 0, 0.5)',
					display: 'flex',
					alignItems: 'center',
					justifyContent: 'center',
				},
				content: {
					background: 'white',
					// width: type == 'view' ? '50rem' : '',
					maxWidth: 'calc(100vw - 2rem)',
					maxHeight: 'calc(100vh - 2rem)',
					boxshadow: '0 0 30px 0 rgba(0, 0, 0, 0.25)',
					overflowY: 'auto',
					position: 'relative',
				},
			}}
		>
			<div
				className={'close-icon'}
				onClick={() => {
					manageRFQModal(false);
				}}
			>
				<GrClose size={25} />
			</div>
			{state.loadingStatus !== 'LOADED' ? (
				<p>Loading...</p>
			) : <div
				style={{ paddingBottom: 30 }}
				className='view_purchase_fc_issue_re_init'
			>
				<GlobalMatrixCard
					//header information
					headerCaption={state.headerInfo.caption}
					headerSubCaption={state.headerInfo.subCaption}
					enabledComponents={state.enabledComponents}
					//matrix totals
					orderTotalBox={state.total_matrix_volume}
					dispalyOrderTotalBox={true}
					orderTotalWeight={state.total_matrix_weight}
					displayOrderTotalWeight={true}
					orderTotalUSD={state.total_matrix_usd}
					displayOrderTotalUSD={true}
					priceInfo={{currencyType:'NOK'}}
					//Week component
					sourcingWeek={state.sourcing_week}
					sourcingDates={state.sourcing_date}
					shipmentWeek={state.shipment_week}
					shipmentDates={state.shipment_date}
					sourcingWeekDisplay={true}
					shipmentWeekDisplay={true}
					currentWeek={state.sourcing_week}
				// button component
				// confirmPurchaseButtonDisplay={true}
				// confirmPurchaseButtonDisable={false}
				// confirmPurchaseButtonHandle={() => {
				// 	handleConfirmPurchase(rfq_id);
				// }}
				/>
				<QuoteTable {...props} rfq_id={selectedPurchaseInfo.rfq_id || ''} state={state} dispatch={dispatch} />
			</div>}
		</Modal>
	);
}

const QuoteTable = ({ rfq_id, ...props }) => {
	const { state, dispatch } = props;

	return (
		<div className='fc_scroll'>
			<table className='table-new view-purchase-table'>
				<WeekHeader weight_class={state.active_weight_classes} />
				<tbody
					style={{ overflowX: 'auto', width: '100%', position: 'relative' }}
					className='fc_scroll'
				>
					{/* {state.active_weight_classes.map((weight, i) => {
						let Data = state.checkout_matrix[state.active_weekdays[i]];
						return Data ? (
							<Fragment key={i}>
								<WeightRow rowData={Data} price={state.active_prices[weight]} />
							</Fragment>
						) : (
							<></>
						);
					})} */}
					{state.active_weekdays.map((day, i) => {
						let Data = state.checkout_matrix[day];
						return Data ? (
							<Fragment key={i}>
								<WeightRow rowData={Data} />
							</Fragment>
						) : (
							<></>
						);
					})}

					<tr className='tr-box'>
						<td>Total Boxes</td>
						{state.active_weight_classes.map((weights) => {
							return (
								<td key={weights}>{state.weight_class_volumes[weights] || ''}</td>
							);
						})}
						<td>--</td>
					</tr>
					{/* <tr className='tr-box'>
						<td>Total Box</td>
						<td>{state.total_matrix_volume}</td>
					</tr> */}
				</tbody>
				<WeekFooter
					weight_class={state.active_weight_classes}
					activePrices={state.active_prices}
				/>
			</table>
		</div>
	);
};
const WeekFooter = ({ weight_class = [], activePrices = {} }) => {
	return (
		<tfoot className='view-purchase-footer'>
			<tr>
				<th>Price/kg</th>
				{weight_class.map((weights) => (
					<th key={weights}>{formatCurrency(activePrices[weights],'NOK')}</th>
				))}
				<th>--</th>
			</tr>
		</tfoot>
	);
};

const WeekHeader = ({ weight_class = [] }) => {
	return (
		<thead>
			<tr>
				<th></th>
				{weight_class.map((weights) => (
					<th key={weights}>HOG {weights} kg</th>
				))}
				<th>Total Box</th>
			</tr>
		</thead>
	);
};

const WeightRow = ({ rowData = [] }) => {
	console.log('Row Data', rowData);

	const [totalRow, setTotalRow] = React.useState(0);

	useEffect(() => {
		let total = 0;
		rowData.forEach((item) => {
			total += item.volume;
		});
		setTotalRow(total);
	}, []);

	return (
		<tr>
			<td>{rowData[0].date}</td>
			{rowData.map((item, i) => (
				<td key={i}>{item.volume}</td>
			))}
			<td style={{ fontWeight: 600 }}>{totalRow}</td>
		</tr>
	);
};
const formatNumber = (price) =>
	new Intl.NumberFormat('en-US', { style: 'currency', currency: 'USD' }).format(
		price
	);

export default RFQPurchaseCheckout;
