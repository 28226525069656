import React, { Component } from 'react';
import { Link } from 'react-router-dom';
import axios from 'axios';
import Sidebar from '../../Sidebar/Sidebar';
import { ToastsStore } from 'react-toasts';
import '../BaseOrder/BaseOrderIssueWrapper.css';
import BrowserStorage from '../../../utils/BrowserStorage';

class CreateBaseOrder extends Component {
	constructor() {
		super();
		this.state = {
			elements: [],
			sel: null,
			quoteModel: {},
		};
		this.showHeaderDate = true;
		this.disabBtn = 1;
		this.defWwight = [
			'1–2',
			'2–3',
			'3–4',
			'4–5',
			'5–6',
			'6–7',
			'7–8',
			'8–9',
			'9+',
		];
		this.dayVal = [
			{
				dayIndex: 1,
				volume: 0,
			},
			{
				dayIndex: 2,
				volume: 0,
			},
			{
				dayIndex: 3,
				volume: 0,
			},
			{
				dayIndex: 4,
				volume: 0,
			},
			{
				dayIndex: 5,
				volume: 0,
			},
			{
				dayIndex: 6,
				volume: 0,
			},
			{
				dayIndex: 7,
				volume: 0,
			},
		];
	}
	componentDidMount() {
		this.baseOrderMatrix(this.defWwight);
	}
	baseOrderMatrix(weigh) {
		const weighClass = weigh;
		const items = {};
		let model = {};
		weighClass.map((data, i) => {
			items[data] = JSON.parse(JSON.stringify(this.dayVal));
			model[data] = {
				hasVolume: 0,
				isOpen: false,
			};
		});
		this.state.elements = weighClass;
		this.state.sel = items;
		this.state.quoteModel = model;
		this.forceUpdate();
		console.log(this.state.sel);
	}
	onBlur = (event, a, inx) => {
		if (event.target.value === '') {
			event.target.value = 0;
		}
		this.state.sel[a][inx].volume = parseInt(event.target.value);
		this.disabBtn = 0;
		// this.state.quoteModel[a].isOpen = false;
		this.showHeaderDate = true;
		// for (let item in this.state.quoteModel) {
		// 	if (this.state.quoteModel[item].isOpen) this.showHeaderDate = false;
		// }
		if (parseInt(event.target.value)) {
			this.state.quoteModel[a].hasVolume = true;
		}
		this.forceUpdate();
	};

	toggleAccordion = (event, key) => {
		this.state.quoteModel[key].isOpen = !this.state.quoteModel[key].isOpen;
		// this.showHeaderDate = !this.state.quoteModel[key].isOpen;
		this.forceUpdate();
	};
	createBaseOrder() {
		const userInfo = BrowserStorage.getValue('userInfo');

		if (this.disabBtn == 0) {
			const headers = {
				'Content-Type': 'application/json',
				Authorization: `Bearer ${localStorage.token}`,
			};
			// let webApiUrl = '/accumulator/api/base-order/?product_id=1'
			let webApiUrl = `/accumulator/api/base-order/${0}/`;
			var data = {
				data: this.state.sel,
				access_type: userInfo.access_type,
				access_id: userInfo.accessId,
			};
			console.log(data);
			axios
				.put(webApiUrl, data, {
					headers: headers,
				})
				.then((response) => {
					ToastsStore.success('Base Order Created');
					window.location.href = '/home';
				})
				.catch((error) => {
					console.log(error);
				});
		} else {
			return;
		}
	}

	render() {
		var daysOrder = ['', 'Mon', 'Tue', 'Wed', 'Thu', 'Fri', 'Sat', 'Sun'];
		return (
			<>
				<section className={'body_wrap'}>
					<Sidebar />
					<div className={'cont_wp'}>
						<Link to={'/home'}>
							<p className={'Back_link'}>Back</p>
						</Link>
						{/* <section className={'cmn_head'}>
                            <p>Create Base Order</p>
                        </section> */}

						<div class='scrl_out'>
							<section className={'home_top_wrap1'}>
								<div className={'home_top_block'}>
									<p>
										Create <br /> Base Order
									</p>
									<label>Make New Base Order Here</label>
								</div>
								<div className={'home_top_block'}>
									<div className={'ReqBtnWrp'}>
										{
											<div
												className={'conBt' + ' ' + 'sp_btn'}
												onClick={() => this.createBaseOrder()}
											>
												{this.disabBtn === 1 && <div className={'disabOvl'}></div>}
												Create
											</div>
										}
									</div>
								</div>
							</section>

							<section className={'base_order_wrapper'}>
								<div className={'Acd_hd accordion_header primary_bg'}>
									{this.dayVal.map((val, i) => {
										return (
											<div className={'Base_days'}>
												<span className={'inData'}>{daysOrder[i + 1]}</span>
											</div>
										);
									})}
								</div>
								{this.state.elements.map((key, i) => {
									return (
										<div className={'Base_ord_wrap' + ' ' + 'qte_bg'}>
											<div
												className={'Base_ord_head' + ' ' + 'Acd_hd'}
												style={{ backgroundColor: 'rgba(0,255,255,.2)' }}
											>
												<section>
													<span>HOG {key} kg</span>
												</section>
												<section
													className={
														this.state.quoteModel[key].isOpen ||
														!this.state.quoteModel[key].hasVolume
															? 'hide'
															: ''
													}
												>
													{this.state.sel[key].map((days, index) => {
														return (
															<div className={'accordion_vol'}>
																{days.volume ? days.volume : ''}
															</div>
														);
													})}
												</section>
												<section
													className={this.state.quoteModel[key].isOpen ? 'active' : null}
												>
													<div
														className={'Accord-tog-btn'}
														onClick={(event) => this.toggleAccordion(event, key)}
													></div>
												</section>
											</div>
											{this.state.quoteModel[key].isOpen && (
												<section className={'base_order_body'}>
													{this.state.sel[key].map((days, index) => {
														return (
															<div className={'Base_days ' + 'accHdLn '}>
																<div className={'spn1'}>{daysOrder[index + 1]}</div>

																<input
																	type='text'
																	onChange={(event) => this.onBlur(event, key, index)}
																	Value={days.volume != '0' ? days.volume : ''}
																	placeholder='0'
																/>
															</div>
														);
													})}
												</section>
											)}
										</div>
									);
								})}
							</section>
						</div>
					</div>
				</section>
			</>
		);
	}
}

export default CreateBaseOrder;
