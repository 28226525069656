import Api from "./Api";

class AuthServices {
  login = async (data) => await Api.post(`/api/login/`, data);

  logout = async () => await Api.post(`/api/logout/`);

  loginExtended = async (access_id, data) =>
    await Api.post(`/api/login-extended/?access_id=${access_id}`, data);
}
export default new AuthServices();
