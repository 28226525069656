import React, {useState, useEffect} from 'react'
import axios from 'axios'
import ReactHtmlParser from "react-html-parser";
import QuoteServices from '../../api/QuoteServices';

const MiniInvoice = () => {

    const [email, setEmail] = useState([])

    const getMiniInvoice = async () => {
        const getMiniInvoice = await QuoteServices.getMiniInvoice();
        if(getMiniInvoice.data){
            const {data} = getMiniInvoice;
            setEmail(data);
        }
    }
    useEffect(() => {   
        getMiniInvoice() 

    }, [])
    console.log(email)
    return (
        <div>{ReactHtmlParser(email)}</div>

    )
}

export default MiniInvoice

