import React, { Fragment, useEffect, useReducer } from "react";
import { GrClose } from "react-icons/gr";
import GlobalMatrixCard from "../GlobalMatrixCard/GlobalMatrixCard";
import Modal from "react-modal";
import RFQServices from "../../api/RFQServices";
import "./RFQRanking.css";
import { formatCurrency } from "../../util";
import { IoEye } from "react-icons/io5";
import { BiChevronLeft } from "react-icons/bi";

/**
 * @author
 * @function RFQRanking
 **/

const initalState = {
  headerInfo: {
    caption: "RFQ Ranking",
    subCaption: "",
  },
  enabledComponents: ["HeadCaption"],
  matrix_data: [],
  provider_data: [],
  rfq_data: [],
  view_data: [],
  weight_index: "",
};
export const ACTIONS = {
  SET_FIELDS: "SET_FIELDS",
  GET_RANKING_MATRIX: "GET_RANKING_MATRIX",
  SET_WEIGHT_CLASS: "SET_WEIGHT_CLASS",
};
const reducer = (state, { type, payload }) => {
  switch (type) {
    case ACTIONS.SET_FIELDS:
      return {
        ...state,
        [payload.field]: payload.value,
      };
    case ACTIONS.GET_RANKING_MATRIX:
      return {
        ...state,
        ...payload,
      };
    case ACTIONS.SET_WEIGHT_CLASS:
      return {
        ...state,
        weight_index: payload.weight_index,
      };
    default:
      return state;
  }
};
const RFQRanking = ({ isModalOpened, manageRFQModal }) => {
  const [state, dispatch] = useReducer(reducer, initalState);
  const [loadingStatus, setLoadingStatus] = React.useState("idle");
  const [viewStatus, setViewStatus] = React.useState(false);
  useEffect(() => {
    fetchRFQRankingList();
    setLoadingStatus("loaded");
    return () => {
      setLoadingStatus("idle");
    };
  }, []);

  const fetchRFQRankingList = async () => {
    const rfqRankingList = await RFQServices.getRFQRankingList();
    if (rfqRankingList.data) {
      const { data } = rfqRankingList;
      dispatch({
        type: ACTIONS.GET_RANKING_MATRIX,
        payload: {
          matrix_data: data.total_price_ranking,
          provider_data: data.rfq_provider_ranking,
          rfq_data: data.rfq_ranking,
          view_data: data?.weight_class_price,
        },
      });
    }
  };

  console.log("state?.view_data", state?.view_data);

  const handlePriceView = (weight_index) => {
    setViewStatus(true);
    dispatch({
      type: ACTIONS.SET_WEIGHT_CLASS,
      payload: {
        weight_index,
      },
    });
  };

  return (
    <Modal
      className="rfq-ranking-modal"
      ariaHideApp={false}
      isOpen={isModalOpened || false}
      onRequestClose={() => manageRFQModal(false)}
      style={{
        overlay: {
          position: "fixed",
          zIndex: 999999,
          top: 0,
          left: 0,
          width: "100vw",
          height: "100vh",
          background: "rgba(0, 0, 0, 0.5)",
          display: "flex",
          alignItems: "center",
          justifyContent: "center",
        },
        content: {
          background: "white",
          width: "50rem",
          maxWidth: "calc(100vw - 2rem)",
          maxHeight: "calc(100vh - 2rem)",
          boxshadow: "0 0 30px 0 rgba(0, 0, 0, 0.25)",
          overflowY: "auto",
          position: "relative",
        },
      }}
    >
      {loadingStatus !== "loaded" ? (
        <p>Loading...</p>
      ) : (
        <>
          <div
            className={"close-icon"}
            onClick={() => {
              manageRFQModal(false);
            }}
          >
            <GrClose size={25} />
          </div>
          <GlobalMatrixCard
            headerCaption={state.headerInfo.caption}
            headerSubCaption={state.headerInfo.subCaption}
            enabledComponents={state.enabledComponents}
          />
          <div className="fc_scroll">
            <h4>Price Ranking</h4>
            {!viewStatus ? (
              <table className="table-new rfq-ranking-table">
                <thead>
                  <tr>
                    <th>Weight Class</th>
                    <th>Winner</th>
                    <th>Runner Up</th>
                  </tr>
                </thead>
                <tbody
                  style={{
                    overflowX: "auto",
                    width: "100%",
                    position: "relative",
                  }}
                  className="fc_scroll"
                >
                  {state.matrix_data.map((key, i) => {
                    return (
                      <Fragment key={i}>
                        <WeightRow
                          rowData={key}
                          index={i}
                          handleView={handlePriceView}
                        />
                      </Fragment>
                    );
                  })}
                </tbody>
              </table>
            ) : (
              <div>
                <button
                  className="rfq-ranking-back-btn"
                  onClick={(e) => {
                    e.preventDefault();
                    setViewStatus(false);
                  }}
                >
                  <BiChevronLeft size={18} />
                  &nbsp;Back
                </button>
                {state.view_data[state.weight_index] &&
                Object.keys(state.view_data[state.weight_index]).length > 0 ? (
                  <div>
                    <h4>
                      {state.view_data[state.weight_index].weight_class} kg
                    </h4>
                    <table className="table-new table-view-rfq-ranking">
                      <thead>
                        <tr>
                          <th>Provider</th>
                          <th>Prices</th>
                        </tr>
                      </thead>
                      <tbody>
                        {state.view_data[state.weight_index].provider.map(
                          (item, i) => {
                            return (
                              <tr>
                                <td>{item}</td>
                                <td>
                                  {formatCurrency(
                                    state.view_data[state.weight_index].prices[
                                      i
                                    ],
                                    "NOK"
                                  )}
                                </td>
                              </tr>
                            );
                          }
                        )}
                      </tbody>
                    </table>
                  </div>
                ) : (
                  <p style={{ marginTop: 10 }}>No data available</p>
                )}
              </div>
            )}
            <h3 style={{ textAlign: "center" }}>Best Provider</h3>
            <h4>Provider with same volume as outlook</h4>
            {state.rfq_data.length > 0 ? (
              <table className="table-new rfq-ranking-table">
                <thead>
                  <tr>
                    <th>Provider Name</th>
                    <th>Price</th>
                  </tr>
                </thead>
                <tbody
                  style={{
                    overflowX: "auto",
                    width: "100%",
                    position: "relative",
                  }}
                  className="fc_scroll"
                >
                  {state.rfq_data.map((key, i) => {
                    return (
                      <Fragment key={i}>
                        <ProviderRow rowData={key} />
                      </Fragment>
                    );
                  })}
                </tbody>
              </table>
            ) : (
              <p>No data available</p>
            )}
            <h4>Provider with different volume</h4>
            {state.provider_data.length > 0 ? (
              <table className="table-new rfq-ranking-table">
                <thead>
                  <tr>
                    <th>Provider Name</th>
                    <th>Price</th>
                  </tr>
                </thead>
                <tbody
                  style={{
                    overflowX: "auto",
                    width: "100%",
                    position: "relative",
                  }}
                  className="fc_scroll"
                >
                  {state.provider_data.map((key, i) => {
                    return (
                      <Fragment key={i}>
                        <ProviderRow rowData={key} />
                      </Fragment>
                    );
                  })}
                </tbody>
              </table>
            ) : (
              <p>No data available</p>
            )}
          </div>
        </>
      )}
    </Modal>
  );
};

const ProviderRow = ({ rowData = {} }) => {
  return (
    <tr style={{ background: rowData.has_rank ? "#fff" : "#bbb" }}>
      <td>{rowData.provider_name}</td>
      <td>
        {rowData.price === 0 ? "--" : formatCurrency(rowData.price, "NOK")}
      </td>
    </tr>
  );
};

const WeightRow = ({ rowData = {}, index = null, handleView = () => {} }) => {
  return Object.keys(rowData).length > 0 ? (
    <tr>
      <td>
        <div style={{ display: "flex", alignItems: "center" }}>
          {rowData.weight_class} kg &nbsp;
          <IoEye
            size={18}
            style={{ cursor: "pointer" }}
            onClick={(e) => {
              e.preventDefault();
              handleView(index);
            }}
          />
        </div>
      </td>
      {[0, 1].map((item) => {
        console.log(isNaN(rowData.prices[item]));
        return (
          <td>
            {isNaN(rowData.prices[item]) ? (
              <></>
            ) : (
              formatCurrency(rowData.prices[item], "NOK")
            )}{" "}
            {rowData.provider[item] ? `(${rowData.provider[item]})` : <></>}
          </td>
        );
      })}
    </tr>
  ) : (
    <tr>
      <td colSpan={4}>No data available.</td>
    </tr>
  );
};

export default RFQRanking;
