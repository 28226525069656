import Api from './Api';

class TenderServices {
	getTenderList = async (sourcing_week) =>
		await Api.get(`/purchaser/api/tender/tender-list/${sourcing_week ?'?sourcing_week='+sourcing_week:''}`);

	deleteTender = async (selectedTender) =>
		await Api.delete(`/purchaser/api/tender/${selectedTender}/`);

	updateMatrixData = async (tenderId, data) =>
		await Api.put(`/purchaser/api/tender/${tenderId}/`, data);
}
export default new TenderServices();
