import React, { useState, useEffect } from 'react';

const WeightClassWeekdayVolume = ({
	volumeObj,
	weightClass,
	onUpdate,
	enableUpdate,
	// setButtonDisable,
	// noBackground
}) => {
	const [volume, setVolume] = useState(volumeObj.volume);

	let initialVolume = volumeObj.volume;
	let dayIndex = volumeObj.dayIndex;

	useEffect(() => {
		setVolume(volumeObj.volume);
	}, [volumeObj.volume]);

	const updateVolume = (e) => {
		console.log(e.target.value);
		// setButtonDisable && setButtonDisable(false);
		if (
			e.target.value <= 270 &&
			e.target.value >= 0 &&
			e.target.value % 1 === 0
		) {
			setVolume(e.target.value);
			let obj = {
				weight_class: weightClass,
				dayIndex: dayIndex,
				volume: e.target.value,
				initial_volume: initialVolume, // used in subtractor
			};
			onUpdate(obj);
		}
		if (e.target.value === '' || e.target.value === 0) {
			setVolume(0);
			let obj = {
				weight_class: weightClass,
				dayIndex: dayIndex,
				volume: 0,
				initial_volume: initialVolume,
			};
			onUpdate(obj);
		}
	};

	return (
		// <div className={`${noBackground ? "no_backgrnd": ''}`}>
		<div className='no_backgrnd' style={!enableUpdate ? { width: '100%' } : {}}>
			<div
				className={`priceListBlock ${!enableUpdate ? 'price_items_align' : ''}`}
				style={{ display: 'grid', justifyContent: 'center' }}
			>
				<div>
					{!enableUpdate && <span>{volume ? volume : '-'}</span>}
					{enableUpdate && (
						<input
							type='number'
							className={'input price_input'}
							value={volume ? volume : ''}
							onChange={updateVolume}
						/>
					)}
				</div>
			</div>
		</div>
	);
};

export default WeightClassWeekdayVolume;
