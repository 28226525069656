import Api from './Api';

class PackingServices {
	currentWeekPurchases = async () =>
		await Api.get(`purchaser/api/purchase/current_week-purchases/`);
	getShipment = async (data) =>
		await Api.post(`transmitter/api/customer-shipment/packing-shipments/`, data);
	getFisheryStation = async () =>
		await Api.get(`chain/api/xfishery-establishment/`);
	initiateWeekdayMatrix = async (data) =>
		await Api.post(`/purchaser/api/packing-batch/initiate-weekday-matrix/`, data);
	createBatch = async (data, id) =>
		await Api.put(`/purchaser/api/packing-batch/${id}/`, data);
	listPacking = async (data) =>
		await Api.get(`/purchaser/api/packing-batch/?purchase_id=${data}`);

	getPackingMatrix = async () =>
		await Api.get(`/purchaser/api/purchase-batch/full-packing-matrix/`);

	getPackingDayMatrix = async (dayIndex) =>
		await Api.get(`/purchaser/api/purchase-batch/week-day-packing-matrix/${dayIndex}`);

	getDestinationList = async (request_type) =>
		await Api.get(`chain/api/seafood-destination/?request_type=${request_type}`);
}
export default new PackingServices();
