const Crypto = require('crypto-js');

export const toPascalCase = (str = '') => {
	return str.replace(/(\w)(\w*)/g, function (g0, g1, g2) {
		return g1.toUpperCase() + g2.toLowerCase();
	});
};

export const camelCaseToDisplayString = (str = '') =>
	str.replace(/([A-Z]{1,})/g, ' $1');

export const markNumberPosition = (number, seperator = ',') =>
	parseFloat(number)
		.toString()
		.replace(/(\d)(?=(\d\d\d)+(?!\d))/g, '$1' + seperator);

export const formatCurrency = (price, currencyType = 'USD') =>
	new Intl.NumberFormat(currencyType === 'USD' ? 'en-US' : 'no-NO', {
		style: 'currency',
		currency: currencyType,
	}).format(price);

export const encryptValue = (value, key) => {
	var encryption = Crypto.AES.encrypt(JSON.stringify(value), key); // Encryption part
	return encryption;
};

//receives: Encrypted Token Data,JWT
export const decryptValue = (data, key) => {
	var decryption = Crypto.AES.decrypt(data, key);
	decryption = JSON.parse(decryption.toString(Crypto.enc.Utf8));
	return decryption;
};

//RETURNS: Next Week Dates
export const getNextWeekDates = () => {
	var nextMonday = new Date();
	nextMonday.setDate(nextMonday.getDate() + ((1 + 7 - nextMonday.getDay()) % 7));
	var nextWeekArrays = [nextMonday];
	for (let i = 1; i < 7; i++) {
		nextWeekArrays.push(
			new Date(new Date(nextMonday).setDate(nextMonday.getDate() + i))
		);
	}
	return nextWeekArrays;
};

export const findRemainingNasdaqTimer = (availableTime) => {
	const endTime = new Date(availableTime);
	const currentTime = new Date(Date.now());

	let timeRemaining = endTime.getTime() - currentTime.getTime();

	const days = Math.floor(timeRemaining / (1000 * 60 * 60 * 24));
	const hours = Math.floor(
		(timeRemaining % (1000 * 60 * 60 * 24)) / (1000 * 60 * 60)
	);
	const minutes = Math.floor((timeRemaining % (1000 * 60 * 60)) / (1000 * 60));
	const seconds = Math.floor((timeRemaining % (1000 * 60)) / 1000);

	let updatedTime = days + ' days ' + hours + ' hours ' + minutes + ' min ';

	return updatedTime;
};
