import React from 'react';
import { camelCaseToDisplayString } from '../../util';

const ModelDropdownSelector = ({
	data,
	handleChange,
	accessType,
	...props
	// selectedsecondItem,
	// setSelectedsecondItem,
}) => {
	

	// useEffect(() => {
	// 	setSelectedsecondItem(dropdownData[0]);
	// }, [dropdownData]);
	return (
		<div>
			{/* <select onChange={handleChange} value={selectedsecondItem}> */}
			<select
				// disabled={disabled}
				// onChange={handleChange}
				// value={accessType}
				style={{ marginLeft: 0 }}
				{...props}
			>
				<option selected value=''>
					--Select an option--
				</option>
				
				{Object.keys(data).map((item) => (
					<option value={item} key={item}>
						{camelCaseToDisplayString(data[item])}
					</option>
				))}
			</select>
		</div>
	);
};

export default ModelDropdownSelector;
