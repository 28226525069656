import React, { useState, useEffect } from "react";
import { Link, useHistory } from "react-router-dom";
import AuthServices from "../../api/AuthServices";
import UserServices from "../../api/UserServices";
import BrowserStorage from "../../utils/BrowserStorage";

export default function UserSelector() {
  const [userList, setUserList] = useState([]);
  const userInfo = BrowserStorage.getValue("userInfo");
  const history = useHistory();

  const getUserList = async () => {
    const getUserList = await UserServices.getUserList(userInfo?.accessId);
    if (getUserList.data) {
      const { data } = getUserList;
      setUserList(data.results || []);
      BrowserStorage.clear("token");
    }
  };

  const getUserListForAgent = async () => {
    const getUserList = await UserServices.getUserListForAgent(
      userInfo?.accessId
    );
    if (getUserList.data) {
      const { data } = getUserList;
      setUserList(data.results || []);
      BrowserStorage.clear("token");
    }
  };

  useEffect(() => {
    const userInfo = BrowserStorage.getValue("userInfo");

    if (userInfo.currentRole === "agent") {
      getUserListForAgent();
    } else {
      getUserList();
    }
  }, []);
  const handleChange = async (e) => {
    let newData = JSON.parse(e.target.value);

    if (newData && Object.keys(newData).length > 0 && newData.id !== "") {
      BrowserStorage.setValue("token", userInfo?.token);
      const extendedLogin = await AuthServices.loginExtended(newData.id, {
        access_type: newData.access_type,
      });
      if (extendedLogin.data) {
        const { data } = extendedLogin;
        let userInfo = {
          ...BrowserStorage.getValue("userInfo"),
          token: data.data.token,
          accessId: data.data.active_access_id,
          access_type: data.data.access_type,
          currentRole: data.data.current_role,
        };
        BrowserStorage.setValue("userInfo", userInfo);
        BrowserStorage.setValue("token", data.data.token);
        history.push("/home");
      } else {
        BrowserStorage.clear();
      }
    }
  };
  return (
    <div className={"loginWrap"}>
      <div className={"loginOuter"}>
        <section>
          <div className={"logo_landing"}></div>
          <p>Salmon across the seven seas</p>
          <label>Overseas salmon in 1-2-3</label>
        </section>
        <section>
          <p className={"loginFormHead"}>
            Welcome ,<br />
            {userInfo.userName || ""}
          </p>
          <p className={"loginFormSubHead"}>Please select a user to continue</p>

          <select
            className={"selectUserByAdmin"}
            name="selectedOption"
            onChange={handleChange}
          >
            <option value="">Select Location</option>
            {userList.map((i) => {
              return (
                <option
                  value={JSON.stringify({
                    id: i.id,
                    access_type: i.access_type,
                  })}
                >
                  {i.name}
                </option>
              );
            })}
          </select>
          <Link to={"/"}>
            <p className={"back_link"}>Back To Login</p>
          </Link>
        </section>
      </div>
    </div>
  );
}
