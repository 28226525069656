import React from "react";
import eventBinder from "../../hoc/eventBinder";

/**
 * @author
 * @function CustomInput
 **/

const CustomInput = ({ label, type,  id, ...props }) => {
  return (
    <div className="cus_input">
      <label htmlFor={id}>{label || ""}</label>
      <input type={type || "text"} {...props} />
    </div>
  );
};

export default eventBinder(CustomInput);
