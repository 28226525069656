import React, { useReducer, useEffect, useState } from 'react';
import { BiPlus } from 'react-icons/bi';
import { AiOutlineDelete } from 'react-icons/ai';
import { ImSigma } from 'react-icons/im';
import { confirmAlert } from 'react-confirm-alert'; // Import
import 'react-confirm-alert/src/react-confirm-alert.css'; // Import css

export const ACTIONS = {
	SELECT_BUBBLE: 'SELECT_BUBBLE',
	UNSELECT_BUBBLE: 'UNSELECT_BUBBLE',
	SET_FIELDS: 'SET_FIELDS',
	SELECT_SIGMA_BTN: 'SELECT_SIGMA_BTN',
};
const reducer = (state, action) => {
	switch (action.type) {
		case ACTIONS.SET_FIELDS:
			return {
				...state,
				[action.payload.field]: action.payload.value,
			};
		case ACTIONS.SELECT_SIGMA_BTN:
			return {
				...state,
				selectSigmaBtn: action.payload,
			};
		case ACTIONS.SELECT_BUBBLE:
			if (action.multiSelect) state.selectedBubbles.push(action.id);
			else state.selectedBubbles = [action.id];
			return {
				...state,
				selectedBubbles: state.selectedBubbles,
			};
		case ACTIONS.UNSELECT_BUBBLE:
			let { selectedBubbles } = state;
			selectedBubbles.splice(selectedBubbles.indexOf(action.id), 1);
			return {
				...state,
				selectedBubbles: selectedBubbles,
			};
		default:
			throw new Error();
	}
};

function BubbleSelector({
	title = '',
	bubbleArray = [],
	selectedBubbles = [],
	handleAddBubble = () => {},
	handleDeleteBubble = () => {},
	handleBubbleSelect = () => {},
	handleSigma = () => {},
	isMatrixChanged = false,
	isAddBtnClicked = false,
	isAddVisibility = true,
}) {
	const [state, dispatch] = useReducer(reducer, {
		selectedBubbles,
		selectSigmaBtn: false,
	});
	//to handle bubble clicks
	const bubbleClickHandler = async (val) => {
		console.log('val', val);
		if (state.selectedBubbles.includes(val)) {
			await dispatch({ type: ACTIONS.UNSELECT_BUBBLE, id: val });
			handleBubbleSelect(state.selectedBubbles);
		} else {
			await dispatch({
				type: ACTIONS.SELECT_BUBBLE,
				id: val,
				multiSelect: state.selectSigmaBtn,
			});
			handleBubbleSelect(state.selectedBubbles);
		}
	};

	useEffect(() => {
		dispatch({
			type: ACTIONS.SET_FIELDS,
			payload: {
				field: 'selectedBubbles',
				value: selectedBubbles,
			},
		});
	}, [selectedBubbles]);

	useEffect(() => {
		if (!state.selectSigmaBtn && state.selectedBubbles.length >= 1) {
			dispatch({
				type: ACTIONS.SET_FIELDS,
				payload: {
					field: 'selectedBubbles',
					value: [],
				},
			});
			handleBubbleSelect([]);
		}
	}, [state.selectSigmaBtn]);
	const bubbleDeleteHandler = (e) => {
		e.preventDefault();
		confirmAlert({
			message: `Are you sure you want to delete Tender: ${state.selectedBubbles[0]}`,
			buttons: [
				{
					label: 'Yes',
					onClick: () => handleDeleteBubble(),
				},
				{
					label: 'No',
					onClick: () => false,
				},
			],
		});
	};
	const clickHandlerSigma = (e) => {
		e.preventDefault();
		handleSigma(!state.selectSigmaBtn);
		dispatch({
			type: ACTIONS.SELECT_SIGMA_BTN,
			payload: !state.selectSigmaBtn,
		});
	};
	console.log(state.selectSigmaBtn, 'sddfjhbvhjb');
	////sum handler
	return (
		<>
			<div className='bubble-container'>
				<div className={'bubble-main left'}>
					<p>{title}</p>
					<div className='bubble-main-wrap'>
						{bubbleArray.length > 0 ? (
							bubbleArray.map((val, i) => {
								return (
									<div
										key={i}
										onClick={(e) => {
											if (!isMatrixChanged) {
												e.preventDefault();
												bubbleClickHandler(val);
											} else {
												console.log('button disabled');
											}
										}}
										className={`bubbles ${
											state.selectedBubbles.includes(val) ? 'active' : 'inactive'
										} ${isMatrixChanged ? 'disabled' : ''}`}
									>
										{'#'}{i+1}
									</div>
								);
							})
						) : (
							<div>No Tender is created.</div>
						)}
						{isAddBtnClicked && !state.selectSigmaBtn && (
							<div className='bubbles temp-bubble'>T</div>
						)}
					</div>
				</div>
				<div className='bubble-main right'>
					{/* <div class="save_button"><p>Sum</p></div> */}
					{isAddVisibility &&
						!isAddBtnClicked &&
						state.selectedBubbles.length === 0 &&
						!state.selectSigmaBtn && (
							<div class='add_button' onClick={handleAddBubble}>
								<p>
									<BiPlus size={20} color='#FFF' />
								</p>
							</div>
						)}
					{!isAddBtnClicked && !isMatrixChanged && (
						<div
							class='sigma_button'
							style={state.selectSigmaBtn ? { opacity: 1 } : { opacity: 0.1 }}
							onClick={clickHandlerSigma}
						>
							<ImSigma size={20} color='#FFF' />
						</div>
					)}
					{state.selectedBubbles.length === 1 &&
						!state.selectSigmaBtn &&
						!isMatrixChanged && (
							<div className='delete-button' onClick={bubbleDeleteHandler}>
								<AiOutlineDelete size={20} color='#fff' />
							</div>
						)}
				</div>
			</div>
		</>
	);
}
export default BubbleSelector;
