import React, { useState, useEffect } from 'react'
import { Link } from 'react-router-dom';
import Sidebar from '../../Sidebar/Sidebar';
import axios from 'axios';
import { IoCalculatorOutline } from "react-icons/io5";
import Modal from "react-modal";
import { GrClose } from "react-icons/gr";
import Moment from "react-moment";
import './SeafoodDestination.css';
import PackingServices from '../../../api/PackingServices';
import QuoteServices from '../../../api/QuoteServices';
const SeafoodDestination = () => {

    const [DestinationList, setDestinationList] = useState([]);
    const [RequestType, setRequestType] = useState("calculator");
    const [headings, setHeadings] = useState([]);
    const [rows, setRows] = useState([]);
    const [calculatorResults, setCalculatorResults] = useState([]);
    const [model, setModel] = useState(false);
    const [calculatorModel, setCalculatorModel] = useState(false);
    const customStyles = {
        content: {
          background: "white",
          width: "50rem",
          maxWidth: "calc(100vw - 2rem)",
          maxHeight: "calc(100vh - 2rem)",
          boxshadow: "0 0 30px 0 rgba(0, 0, 0, 0.25)",
          overflowY: "auto",
          position: "relative",
        },
        overlay: {
          position: "fixed",
          zIndex: 999999,
          top: 0,
          left: 0,
          width: "100vw",
          height: "100vh",
          background: "rgba(0, 0, 0, 0.5)",
          display: "flex",
          alignItems: "center",
          justifyContent: "center",
        },
      };

    useEffect(()=>{
        getDestinationList(RequestType);
    }, [])

    const getDestinationList = async (RequestType) => {
        const getDestinationList = await PackingServices.getDestinationList(RequestType);
        if(getDestinationList.data){
            const {data} = getDestinationList;
            setDestinationList(data.results);
        }
    }

    const closeModel = () => {
        setModel(false)
        setCalculatorModel(false)
      };

    const openCalculatorModel = async (type, id) => {
        const getQuoteCalculator = await QuoteServices.getQuoteCalculator(id,type);
        if(getQuoteCalculator.data){
            const{data} = getQuoteCalculator;
            setHeadings(data.heading)
            setRows(data.rows)
            setCalculatorResults(data)
            setCalculatorModel(true)
        }
      };

    return (
        <section className={'body_wrap'}>
            {/* <Sidebar /> */}
            <div className={'cont_wp'}>
                <Link to={'/home'}>
                    <p className={'Back_link'}>Back</p>
                 </Link>
                 <section className={'cmn_head'}>
                     <p>Seafood Destinations</p>
                </section>
                <Modal
                    isOpen={calculatorModel}
                    onRequestClose={() => closeModel()}
                    onAfterOpen={() => (document.body.style.overflow = "hidden")}
                    onAfterClose={() => (document.body.style.overflow = "unset")}
                    style={customStyles}
                    >
                    <div class="scrl_out" style={{ paddingBottom: "30px" }}>
                        <div className={"modal_heading"}>
                            <p>Calculator</p>
                            <div className={"close-icon"} onClick={() => closeModel()}>
                                <GrClose size={25} />
                            </div>
                        </div>
                        {calculatorResults ? (
                            <div>
                                <p className={"modal_text"}>
                                    Shipment Week: {calculatorResults.shipment_week}
                                </p>
                                <p className={"modal_text"}>
                                    Purchasing Week:{" "}
                                    {calculatorResults.purchasing_week}
                                </p>
                                {calculatorResults.calculator_type==="warning" ? (<p> <IoCalculatorOutline
                                                    size={25}
                                                    color={"red"}
                                                />
                                                </p>) :"" }
                            </div>
                        ) : null}
                        <section className={"OrderTableWrap" + " " + "scrl_out"}>
                        <table className={"calcultor_table"}>
                            <tr className={"table_head"}>
                                {headings? headings.map((val, i) => <th>{val}</th>) : null}
                            </tr>
                            <tbody>
                            {rows ? (
                                rows.map((value, i) => (
                                <tr key={i}>
                                    {value.map((val, j) => (
                                        <td key={j}>{val == 0 || val == null ? "-" : val}</td>
                                    ))}
                                </tr>
                                ))
                            ) : (
                                <tr className={"no_data"}>
                                    <td colspan="5">No data available </td>
                                </tr>
                            )}
                            </tbody>
                        </table>
                        </section>
                    </div>
                </Modal>
                <div >
                    <section className={"OrderTableWrap" + " " + "scrl_out"}>
                        <table className={"seafood_table"}>
                            <tr className={"seafood_table_head"}>
                                <th>SeafoodDestination</th>
                                <th>Tombstone</th>
                                <th>Calculator</th>
                            </tr>
                            <tbody>
                                {DestinationList.length > 0 ? (DestinationList.map((destination, index) => (
                                        <tr>
                                            <td>{destination.airport}</td>
                                            <td className="tomb_stone">{" "}{destination.is_tombstone ? "*" : ""}</td>
                                            <td style={{textAlign:'center'}}>
                                                <IoCalculatorOutline
                                                    onClick = {destination.calculator_type != "disabled" ? (
                                                        () => openCalculatorModel( "seafood_destination",destination.id)
                                                    ) : null}
                                                    size={18}
                                                    color={destination.calculator_type == "disabled" ? "grey" : 
                                                            destination.calculator_type == "warning" ? "red" :  
                                                            destination.calculator_type == "active" ? "green" : ""}
                                                    style={{ cursor: destination.calculator_type != "disabled" ? "pointer" :"default" }}
                                                />
                                        
                                            </td>
                                        </tr>
                                ))
                                    ) : (
                                    <tr className={"no_data"}>
                                        <td colspan="5">No data available </td>
                                    </tr>
                                )}
                            </tbody>
                        </table>
                    </section>
                </div>
            </div>
        </section>
    )
}

export default SeafoodDestination
