import React,{useState,useEffect} from 'react'
import {useParams,useHistory} from 'react-router-dom'
import { ToastsStore } from 'react-toasts'
import CommonServices from '../api/CommonServices'
import '../styles/AddUser.css'

function AddUser() {


    const [firstName, setFirstName] = useState('')
    const [lastName, setLastName] = useState('')
    const [nameShort, setNameShort] = useState('')
    const [email, setEmail] = useState('')

    const [phoneCodes, setPhoneCodes] = useState([])
    const [selectedPhoneCode, setSelectedPhoneCode] = useState('')

    const [phoneNo, setPhoneNo] = useState('')

    const history = useHistory()
    const URLParams = useParams()

    const validateEmail = (email) => {
      return String(email)
        .toLowerCase()
        .match(
          /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/
        );
    };

    function telephoneCheck(str) {
      var a = /^(?:(?:\(?(?:00|\+)([1-4]\d\d|[1-9]\d?)\)?)?[\-\.\ \\\/]?)?((?:\(?\d{1,}\)?[\-\.\ \\\/]?){0,})(?:[\-\.\ \\\/]?[\-\.\ \\\/]?(\d+))?$/i.test(str);
      return a
    }

    const specialCharCheck =(str) =>{
      var a = /^[a-z A-Z]+$/
      return a.test(str)
    }


    const addingUser = async() =>{
      try {
        if(firstName==='' || lastName==='' || nameShort==='' || email==='' || phoneNo===''){
          ToastsStore.warning('Fill out every Details.')
          return;
        }
        if(!validateEmail(email)){
          ToastsStore.warning('give a valid email.')
          return
        }

        if(!telephoneCheck(phoneNo)){
          ToastsStore.warning('Enter a valid Number')
          return
        }

        if(firstName.length>25 || lastName.length>25){
          ToastsStore.warning('Name cannot be more than 25 characters.')
          return
        }
        if(firstName.length<2){
          ToastsStore.warning('First Name is too short.')
          return
        }
        if(nameShort.length>15){
          ToastsStore.warning('Name Short is too long.(limit in 15 characters)')
          return
        }
        if(nameShort.length<2){
          ToastsStore.warning('Name Short is too short on characters.')
          return
        }
        if(email.length>35){
          ToastsStore.warning('Email is too long. give another email')
          return
        }

        if(phoneNo.length<7){
          ToastsStore.warning('Phone Number is too short.')
          return
        }
        if(phoneNo.length>19){
          ToastsStore.warning('Phone Number is too long.')
          return
        }

        if(!specialCharCheck(firstName) || !specialCharCheck(lastName) || !specialCharCheck(nameShort)){
          ToastsStore.warning('Name cannot contain any special characters or numbers.')
          return
        }

        let bodyData = {
          "first_name":firstName,
          "last_name":lastName,
          "name_short":nameShort,
          "email":email,
          "pre_prospect":URLParams.id,
          "phone_number":phoneNo
        }

        const postUser = await CommonServices.createPreUser(bodyData)

        if(postUser.data){
          const {success} = postUser.data;
          ToastsStore.success('A Pre User is Created.')
          success && setTimeout(() => {
            history.replace(`/business/user-management/${URLParams.access_type}/${URLParams.id}`)
          }, 1000);
        }

        if(postUser.error){
          let values = Object.values(postUser.error.response.data.errors)
          values.join('\n')
          ToastsStore.error(values)
        }


      } catch (err) {
        ToastsStore.error('Error Occurred on Update, Try again.')
      }
    }

    return (
        <section className="body_wrap">
          <div className={'cont_wp'}>
            <div className={'scrl_out'}>
              <section>
              <p className={'Back_link'} onClick={()=>history.goBack()}>
							Back
              </p>
              <p className='cp_header'>Add Pre User</p>
              </section>
              <form>
                  <div className='ad_edit_wrapper'>
                    <div className='ad_input_wrap'>
                      <label className='ad_input_header'>First Name</label>
                      <input type='text' className='ad_input_field' value={firstName} onChange={(e)=>setFirstName(e.target.value)}/>
                    </div>
                    <div className='ad_input_wrap'>
                      <label className='ad_input_header'>Last Name</label>
                      <input type='text' className='ad_input_field' value={lastName} onChange={(e)=>setLastName(e.target.value)}/>
                    </div>
                    <div className='ad_input_wrap'>
                      <label className='ad_input_header'>Name Short</label>
                      <input type='text' className='ad_input_field' value={nameShort} onChange={(e)=>setNameShort(e.target.value)}/>
                      <label className='ad_short_label'>The commonly used (short) name of the person , if different from the  ‘first_name’ and ‘last_name’.</label>
                    </div>
                    <div className='ad_input_wrap'>
                      <label className='ad_input_header'>Email</label>
                      <input type='email' className='ad_input_field' value={email} onChange={(e)=>setEmail(e.target.value)}/>
                    </div>
                    <div className='ad_input_wrap'>
                      <label className='ad_input_header'>Phone Number</label>
                      <input type='' className='ad_input_field' value={phoneNo} onChange={(e)=>setPhoneNo(e.target.value)}/>
                    </div>
                  </div>
              </form>
              <section>
                <div className='ad_button_container'>
                  <button className='ad_primary_btn' onClick={(e)=>{
                    e.preventDefault()
                    addingUser()
                  }}>Add</button>
                  <button className='ad_cancel_btn' onClick={(e)=>{
                    e.preventDefault()
                    history.goBack()
                  }}>Cancel</button>
                </div>
              </section>
            </div>
          </div>
        </section>
    )
}

export default AddUser
