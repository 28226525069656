import React, { useReducer } from 'react';
import { Link } from 'react-router-dom';
import '../../WeekSelection/WeekSelection.css';
//COMPONENTS
import Sidebar from '../../Sidebar/Sidebar';
import PriceLine from '../../PriceLine/PriceLine';
import NasdaqWeek from './NasdaqWeek';
import NasdaqHistory from './NasdaqHistory';
import HistorySelector from './HistorySelector';

//CONSTANTS
export const ACTIONS = {
    SET_PRICE_LIST: 'set_price_list',
    SELECTED_HISTORY_WEEK : 'selected_history_week',
    MODAL_OPEN: 'modal_open'
}

const reducer = (state, action) => { 
    switch (action.type) {
        case ACTIONS.SET_PRICE_LIST:
            return {
                ...state,
                price_list: action.payload.prices
            }
        case ACTIONS.SELECTED_HISTORY_WEEK:
            return {
                ...state,
                selected_history_week: action.payload.selected_week
            }
        case ACTIONS.MODAL_OPEN:
            return {
                ...state,
                modal_open: action.payload.value
            }
        default:
            return {
                ...state
            }
    }
}

const NasdaqPrice = () => {
    //STATES
    const [state, dispatch] = useReducer(reducer, { 
        price_list: [],
        modal_open: false,
        selected_history_week: '',
        history_weeks: [5, 10]
    })
   
    const updatePrices = (prices) =>{
        dispatch({
            type: ACTIONS.SET_PRICE_LIST,
            payload: {
                prices: prices
            },
        });
    } 

    const updateSelectedWeek= (week) => {
        dispatch({
            type: ACTIONS.SELECTED_HISTORY_WEEK,
            payload: {
                selected_week: week
            },
        });

        dispatch({
            type: ACTIONS.MODAL_OPEN,
            payload: {
                value: true
            }
        })
    }

    const closeModal = () => {
        dispatch({
            type: ACTIONS.MODAL_OPEN,
            payload: {
                value: false
            }
        })
    }
    

    return (
        <section className={'body_wrap'}>
            {/* <Sidebar /> */}
            <div className={'scrl_out'}>
                <Link to={'/home'}>
                    <p className={'Back_link'}>Back</p>
                </Link>
                <section className={'cmn_head'}>
                    <p>Nasdaq Price</p>
                </section>
                <div className={"ct_top nasdaq_header"}>
                    <NasdaqWeek  updatePrices={updatePrices} />
                    <div className="nasdaq_week history">
                        <p>History</p>
                        <HistorySelector weeks={state.history_weeks} onHistoryWeekClick={updateSelectedWeek} />
                    </div>
                </div>                    
                <div class="scrl_out">
                    <PriceLine priceList={state.price_list} enableUpdate={false} showPricesCol={true} />
                </div>
            </div>
            {state.modal_open && (
                <NasdaqHistory limit={state.selected_history_week} closeParentModal={closeModal}/>
            )}
        </section>
    );
}

export default NasdaqPrice;