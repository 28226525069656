import Api from './Api';

class LightQuoteServices {

	updateLightQuoteMatrix = async (matrxId,data) =>
	await Api.put(`/accumulator/api/light-quote/${matrxId}/`, data);
	getLightQuoteCount = async () => await Api.get('/accumulator/api/light-quote/count/')
	getLightQuoteList = async () => await Api.get('/accumulator/api/light-quote/active-light-quotes/')

}
export default new LightQuoteServices();
