import Api from './Api';

class TimelineServices {
	getShipments = async () =>
		await Api.get(`/transmitter/api/customer-shipment/current-shipments/`);

	getTimeline = async (id) =>
		await Api.get(`/transmitter/api/customer-shipment/${id}/shipment-timeline/`);
	
	getShipmentList = async (access_id) =>
		await Api.get(`/administrator/api/dashboard/?customer_shipment_location=${access_id}`);

	getShipmentTracker = async (shipmentId) =>
	 await Api.get(`transmitter/api/customer-shipment/${shipmentId}/shipment-timeline/`)
}
export default new TimelineServices();
