import React, { Component } from "react";
import axios from "axios";
import Sidebar from "../Sidebar/Sidebar";
import { Link } from "react-router-dom";
import { MdNavigateNext } from "react-icons/md";

class UsersList extends Component {
  constructor() {
    super();
    this.state = {
    
    };
  }

  componentDidMount() {
 
  }
  
  render() {
    return (
      <>
        <section className={"sell_body_wrap packing_wrapper"}>
          {/* <Sidebar /> */}

         
        </section>
      </>
    );
  }
}

export default UsersList;
