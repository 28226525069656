import React from 'react'

/**
* @author
* @function NineStarSelector
**/

const NineStarSelector = ({selectedDimension,handleChange,disabledOptions}) => {
    return (
        <div className="dimension-selector">
            <span>
                <input
                    type='radio'
                    id='9_1'
                    name='matrix_dimension'
                    value={'9_1'}
                    checked={selectedDimension === '9_1' ? true : false}
                    onChange={()=>handleChange('9_1')}
                    disabled={
                        (disabledOptions.includes('9_1')) ? true : false
                    }
                />
                <label for='9_1'>9_1</label>
            </span>
            <span>
                <input
                    type='radio'
                    id='9_7'
                    name='matrix_dimension'
                    value={'9_7'}
                    checked={selectedDimension === '9_7' ? true : false}
                    onChange={()=>handleChange('9_7')}
                    disabled={(disabledOptions.includes('9_7')) ? true : false}
                />
                <label for='9_7'>9_7</label>
            </span>
            <span>
                <input
                    type='radio'
                    id='9_8'
                    name='matrix_dimension'
                    value={'9_8'}
                    checked={selectedDimension === '9_8' ? true : false}
                    onChange={()=>handleChange('9_8')}
                    disabled={(disabledOptions.includes('9_8')) ? true : false}
                />
                <label for='9_8'>9_8</label>
            </span>
        </div>
    )

}

export default NineStarSelector