import React, { useEffect, useState } from "react";
import { ToastsStore } from "react-toasts";

const WeightClassPrice = (props) => {
  let {
    selectedCurrencyCode,
    notSelectedCurrencyCode,
    onUpdate,
    enableUpdate,
    showPricesCol = true,
  } = props;

  const [price, setPrice] = useState(props.price);
  let weight_class = price.weight_class;

  useEffect(() => {
    setPrice(props.price);
  }, [props.price]);

  const updateSelectedPriceHandler = (data) => {
    if (price?.currency === "NOK") {
      price.price_nok = data;
      price.price_usd = "-";
      price.price_eur = "-";
    } else if (price?.currency === "USD") {
      price.price_usd = data;
      price.price_nok = "-";
      price.price_eur = "-";
    } else if (price?.currency === "EUR") {
      price.price_eur = data;
      price.price_usd = "-";
      price.price_nok = "-";
    }
  };

  const updatePrice = (e) => {
    if (parseInt(e.target.value) >= 1000) {
      ToastsStore.warning("Price cannot be more than 4 digits.");
      return;
    }

    const y = e.target.value.replace(/\s/g, "");
    const x = y.replace(/[^\w\s\.]/gi, "");
    var output = x.split(".");
    output = output.shift() + (output.length ? "." + output.join("") : "");
    var t = output;
    output =
      t.indexOf(".") >= 0
        ? t.substr(0, t.indexOf(".")) + t.substr(t.indexOf("."), 3)
        : t;

    updateSelectedPriceHandler(output);
    // price?.[`price_${selectedCurrencyCode}`] = output;
    //     notSelectedCurrencyCode.forEach(item => {
    //       price?.[`price_${item}`] = "-";
    //  });
    setPrice(price);

    let obj = {
      id: weight_class,
      price: output ? output : null,
      selectedCurrencyCode: selectedCurrencyCode.toLowerCase(),
    };
    onUpdate(obj);
  };

  const onBlur = () => {
    // price.price_nok = parseFloat(price.price_nok).toFixed(2);
    // updateSelectedPriceHandler(parseFloat(price.price_nok).toFixed(2));

    price.price_nok = parseFloat(price.price_nok).toFixed(2);
    // price.price_usd = '-';
    // price.price_eur = '-';

    setPrice(price);

    let obj = { id: weight_class, price: price.price_nok };
    onUpdate(obj);
  };

  const notSelectedCell = (item) => {
    return (
      <div className={"priceListeur"}>
        <span>
          {price?.[`price_${item}`.toLowerCase()]
            ? price?.[`price_${item}`.toLowerCase()]
            : "--"}
        </span>
      </div>
    );
  };

  const selectedCell = (selectedCurrencyCode) => {
    return (
      <>
        <div className="ct_nok_price_holder" style={{ position: "relative" }}>
          <span style={{ position: "absolute", left: 10 }}>kr</span>
          <input
            type="number"
            step="0.01"
            className="input price_input ct_price center"
            // onBlur={onBlur}
            value={
              price?.[`price_${selectedCurrencyCode}`]
                ? price?.[`price_${selectedCurrencyCode}`]
                : ""
            }
            onChange={updatePrice}
          />
        </div>
      </>
    );
  };

  return (
    <div className="priceListBlock">
      <div>
        {price.weight_class_prefix} {price.weight_class}{" "}
        {price.weight_class == "NQSALMON" ? "" : "kg"}
      </div>

      <div>{selectedCell(selectedCurrencyCode.toLowerCase())}</div>

      {notSelectedCurrencyCode.map((item, index) => (
        <div key={index}>{notSelectedCell(item.toLowerCase())}</div>
      ))}
    </div>
  );
};

export default WeightClassPrice;
