import React, { useState, useEffect } from "react";
import ReactMultiSelectCheckboxes from "react-multiselect-checkboxes";

// import options from "./data";

const MultiSelectAll = ({
  options,
  placeholderButtonLabel,
  selectedOptions,
  onOptionChange = () => {}
}) => {

  function getDropdownButtonLabel({ placeholderButtonLabel, value }) {
    if (value && value.some((o) => o.value === "*")) {
      return `${placeholderButtonLabel}: All`;
    } else {
      return `${placeholderButtonLabel}: ${value&&value.length} selected`;
    }
  }

  function onChange(value, event) {
    let options = [];
    if (event.action === "select-option" && event.option.value === "*") {
      options = this.options;
    } else if (
      event.action === "deselect-option" &&
      event.option.value === "*"
    ) {
      options = [];
    } else if (event.action === "deselect-option") {
      options =value.filter((o) => o.value !== "*");
    } else if (value.length === this.options.length - 1) {
      options = this.options;
    } else {
      options = value;
    }
    onOptionChange(options);
  }

  return (
    <ReactMultiSelectCheckboxes
      options={[{ label: "All", value: "*" }, ...options]}
      placeholderButtonLabel={placeholderButtonLabel || ''}
      getDropdownButtonLabel={getDropdownButtonLabel}
      value={selectedOptions}
      onChange={onChange}
      autoFocus={false}
      menuIsOpen={true}
      classNamePrefix="react-multi-select"
    />
  );
};

export default MultiSelectAll;
