import React from 'react'

import WeekSelector from '../WeekSelector/WeekSelector'

const NQSalmonCard = ({
    selector_title,
    last_selector_week,
    first_allowed_week,
    last_allowed_week,
    selected_week,
    default_selected_week,
    default_disabled_weeks,
    week_limit,
    week_data_stats,
    style,
    onWeekClick,
    onBFsClick,
    time_left,
    published_date,
    enable_selector_toggle
    }) => {

    return (
        <>
            <div className={'nasdaq_week'}>
                <div className={'nasdaq_week_wrapper'}>
                    <WeekSelector
                        selector_title={selector_title}
                        last_selector_week={last_selector_week}
                        first_allowed_week={first_allowed_week}
                        last_allowed_week={last_allowed_week}
                        selected_week={selected_week}
                        default_selected_week={default_selected_week}
                        default_disabled_weeks={default_disabled_weeks}
                        week_limit={week_limit}
                        week_data_stats={week_data_stats}
                        style={style}
                        week_selector_toogle={enable_selector_toggle}
                        onWeekClick={onWeekClick}
                        onBFsClick={onBFsClick}
                    />
                </div>
                {selected_week && published_date ? 
                    <p className='time_left_wrap' style= {{ marginTop: -5 }}>Published {published_date}</p>
                : null}
            </div>
            {last_selector_week === last_allowed_week && (
                <div className='time_left_wrap'>
                    <p>will be published in {time_left}</p>
                </div>
            )}
        </>
    )
}

export default NQSalmonCard