import React, { useEffect, useReducer, Fragment } from "react";
import Sidebar from "../components/Sidebar/Sidebar";
import { Link } from "react-router-dom";
import TenderServices from "../api/TenderServices";
import CommonServices from "../api/CommonServices";
import BaseOrderServices from "../api/BaseOrderServices";
import ForecastOrderServices from "../api/ForecastOrderServices";
import CustomerNoteServices from "../api/CustomerNoteServices";
import PerformaOrderServices from "../api/PerformaOrderServices";
import QuoteServices from "../api/QuoteServices";
import OrderPlacedServices from "../api/OrderPlacedServices";
import RFQServices from "../api/RFQServices";
import PurchaseServices from "../api/PurchaseServices";
import LightQuoteServices from "../api/LightQuoteServices";
import CalendarServices from "../api/CalendarServices";
import GlobalMatrixCard from "../components/GlobalMatrixCard/GlobalMatrixCard";
import { confirmAlert } from "react-confirm-alert"; // Import
import "react-confirm-alert/src/react-confirm-alert.css"; // Import css
import { ToastsStore } from "react-toasts";
import { camelCaseToDisplayString, formatCurrency } from "../util";
import { FiChevronRight } from "react-icons/fi";

const initialState = {
  headerInfo: {
    caption: "Universal Matrix",
    subCaption: "Base Order",
  },
  enabledComponents: ["HeadCaption", "ButtonComponent", "MatrixSelector"],
  week_data: [],
  nineStarDisabledOtpions: [],
  matrix_volumes_9_1: {},
  matrix_volumes_9_7: {},
  matrix_volumes_9_8: {},
  display_matrix: {},
  matrix_dimension_status: 2,
  initialDimensionStatus: 2,

  table_header_weeks: [],
  matrixVolumeChangeStatus: false,
  active_week_data_status: [],
};

export const ACTIONS = {
  SET_HEADER_INFO: "SET_HEADER_INFO",
  SET_FIELD: "SET_FIELD",
  SET_MATRIX_MODEL: "SET_MATRIX_MODEL",
  SET_ACCESS_TYPE: "SET_ACCESS_TYPE",
  SET_ACCESS_ID: "SET_ACCESS_ID",
  SET_INPUT_SOURCING_WEEK: "SET_INPUT_SOURCING_WEEK",
  DISABLE_GET_MATRIX_BUTTON: "DISABLE_GET_MATRIX_BUTTON",
  SET_MATRIX_DATA: "SET_MATRIX_DATA",
  DISABLE_UPDATE_BUTTON: "DISABLE_UPDATE_BUTTON",
  SET_MATRIX_WEEK_HEADER: "SET_MATRIX_WEEK_HEADER",
  ENABLE_HEADER_COMPONENT: "ENABLE_HEADER_COMPONENT",
  DISABLE_HEADER_COMPONENT: "DISABLE_HEADER_COMPONENT",
  SET_MATRIX_RETRIEVED_STATUS: "SET_MATRIX_RETRIEVED_STATUS",
  SET_MATRIX_TOTALS: "SET_MATRIX_TOTALS",
  SET_DISABLED_NINE_STAR_OPTIONS: "SET_DISABLED_NINE_STAR_OPTIONS",
  SWITCH_MATRIX_DIMENSION: "SWITCH_MATRIX_DIMENSION",
  SET_MATRIX_VOLUME_CHANGE_STATUS: "SET_MATRIX_VOLUME_CHANGE_STATUS",
  UPDATE_MATRIX_VOLUME: "UPDATE_MATRIX_VOLUME",
  SET_SPLIT_CHANGE: "SET_SPLIT_CHANGE",
  CHANGE_MATRIX_AND_DAYS: "CHANGE_MATRIX_AND_DAYS",
  CHANGE_DISPLAY_MATRIX: "CHANGE_DISPLAY_MATRIX",
  UPDATE_ACTIVE_WEEK_DATA_STATUS: "UPDATE_ACTIVE_WEEK_DATA_STATUS",
  CHANGE_TABLE_HEADER: "CHANGE_TABLE_HEADER",
};

const renderMatrixDimension = (dimension) => {
  switch (dimension) {
    case "9_1":
      return 1;
    case "9_7":
      return 2;
    case "9_8":
      return 3;

    default:
      return 2;
  }
};

const renderNineStar = (status) => {
  switch (status) {
    case 1:
      return "9_1";
    case 2:
      return "9_7";
    case 3:
      return "9_8";

    default:
      return "9_7";
  }
};
const getVolumes = (matrix) => {
  let sum = 0;
  Object.entries(matrix).map((item) =>
    item[1].map((val) => (sum += val.volume))
  );
  return sum;
};
const reducer = (state, { type, payload }) => {
  switch (type) {
    case ACTIONS.SET_HEADER_INFO:
      return {
        ...state,
        headerInfo: { ...state.headerInfo, ...payload },
      };
    case ACTIONS.ENABLE_HEADER_COMPONENT:
      if (!state.enabledComponents.includes(payload))
        state.enabledComponents.push(payload);
      return {
        ...state,
        enabledComponents: state.enabledComponents,
      };
    case ACTIONS.DISABLE_HEADER_COMPONENT:
      if (state.enabledComponents.indexOf(payload) >= 0)
        state.enabledComponents.splice(
          state.enabledComponents.indexOf(payload),
          1
        );
      return {
        ...state,
        enabledComponents: state.enabledComponents,
      };
    case ACTIONS.SET_FIELD:
      return {
        ...state,
        [payload.field]: payload.value,
      };
    case ACTIONS.SET_MATRIX_MODEL:
      return {
        ...state,
        selectedMatrixModel: payload,
      };
    case ACTIONS.SET_ACCESS_TYPE:
      return {
        ...state,
        selectedAccessType: payload,
      };
    case ACTIONS.SET_ACCESS_ID:
      return {
        ...state,
        selectedAccessId: payload,
      };
    case ACTIONS.SET_INPUT_SOURCING_WEEK:
      return {
        ...state,
        selectedSourcingWeek: payload,
      };
    case ACTIONS.DISABLE_GET_MATRIX_BUTTON:
      return {
        ...state,
        disableGetMatrixButton: payload,
      };
    case ACTIONS.SET_MATRIX_DATA:
      let data = {};
      let table_weeks = [];
      if (payload.matrix_dimension_status === 1) {
        data = JSON.parse(JSON.stringify(payload.matrix_volumes_9_1));
        table_weeks = [payload.shipment_date];
      }
      if (payload.matrix_dimension_status === 2) {
        data = JSON.parse(JSON.stringify(payload.matrix_volumes_9_7));
        table_weeks = state.week_data;
      }
      if (payload.matrix_dimension_status === 3) {
        data = JSON.parse(JSON.stringify(payload.matrix_volumes_9_8));
        table_weeks = [...state.week_data, payload.shipment_week];
      }
      return {
        ...state,
        matrix_volumes_9_1: payload.matrix_volumes_9_1,
        matrix_volumes_9_7: payload.matrix_volumes_9_7,
        matrix_volumes_9_8: payload.matrix_volumes_9_8,
        display_matrix: { ...data },
        inital_matrix_volumes: payload.inital_matrix_volumes,
        matrix_id: payload.matrix_id,
        matrix_dimension_status: payload.matrix_dimension_status,
        matrix_row_total: payload.matrix_row_total,
        sourcing_week: payload.sourcing_week,
        shipment_week: payload.shipment_week,
        shipment_date: payload.shipment_date,
        current_week: payload.current_week,
        table_header_weeks: table_weeks,
        sourcing_date: payload.sourcing_date,
        weight_classes: payload.matrix_row_total
          ? Object.keys(payload.matrix_row_total)
          : [],
        initialDimensionStatus: payload.matrix_dimension_status,
      };
    case ACTIONS.SET_MATRIX_WEEK_HEADER:
      return {
        ...state,
        week_data: payload.week_data,
      };
    case ACTIONS.SET_MATRIX_RETRIEVED_STATUS:
      return {
        ...state,
        is_matrix_retrieved: payload,
      };
    case ACTIONS.SET_MATRIX_TOTALS:
      return {
        ...state,
        total_matrix_volume: getVolumes(payload.matrixVolume),
        total_matrix_weight: getVolumes(payload.matrixVolume) * 22,
      };
    case ACTIONS.SET_DISABLED_NINE_STAR_OPTIONS:
      return {
        ...state,
        nineStarDisabledOtpions: payload || [],
      };
    case ACTIONS.SWITCH_MATRIX_DIMENSION:
      return {
        ...state,
        matrix_dimension_status: payload.selectedMatrixDimension,
      };
    case ACTIONS.SET_MATRIX_VOLUME_CHANGE_STATUS:
      return {
        ...state,
        matrixVolumeChangeStatus: payload,
      };
    case ACTIONS.SET_SPLIT_CHANGE:
      return {
        ...state,
        enable_subtractor: payload.enable_subtractor,
      };
    case ACTIONS.CHANGE_MATRIX_AND_DAYS:
      return {
        ...state,
        display_matrix: payload.display_matrix,
        table_header_weeks: payload.table_header_weeks,
      };
    case ACTIONS.CHANGE_DISPLAY_MATRIX:
      return {
        ...state,
        display_matrix: payload.display_matrix,
      };
    case ACTIONS.UPDATE_ACTIVE_WEEK_DATA_STATUS:
      return {
        ...state,
        active_week_data_status: payload.update_active_week_data_status,
      };
    case ACTIONS.CHANGE_TABLE_HEADER:
      return {
        ...state,
        table_header_weeks: payload.table_header_weeks,
      };
    default:
      return {
        ...state,
      };
  }
};

function UniversalMatrixPage() {
  const [state, dispatch] = useReducer(reducer, initialState);
  const [rowVolumeTotal, setRowVolumeTotal] = React.useState([]);
  const [updateClicked, setUpdateClicked] = React.useState(false);
  const [refreshBtnClicked, setRefreshBtnClicked] = React.useState(false);

  const getWeekDates = async (sourcing_week) => {
    const getWeekDates = await CalendarServices.getWeekDates(sourcing_week);
    if (getWeekDates.data) {
      const { data } = getWeekDates;
      dispatch({
        type: ACTIONS.SET_MATRIX_WEEK_HEADER,
        payload: {
          week_data: data.shipment_dates,
        },
      });
      return data.shipment_dates;
    }
  };

  const getMatrix = async (e) => {
    let data = {
      access_type:
        state.selectedAccessType == "producer" ||
        state.selectedAccessType == "exporter"
          ? "x" + state.selectedAccessType
          : state.selectedAccessType,
      model_name: state.selectedMatrixModel,
      access_id: state.selectedAccessId,
      sourcing_week: state.selectedSourcingWeek || "",
    };
    dispatch({
      type: ACTIONS.SET_FIELD,
      payload: {
        field: "sourcing_week",
        value: "",
      },
    });
    const dates = await getWeekDates(state.selectedSourcingWeek);
    const getMatrixData = await CommonServices.getMatrixData(data);
    if (getMatrixData.data && getMatrixData.data.matrix_dimension_status) {
      const { data } = getMatrixData;
      dispatch({
        type: ACTIONS.SET_MATRIX_DATA,
        payload: {
          matrix_volumes_9_1: JSON.parse(JSON.stringify(data.matrix_row_total)),
          matrix_volumes_9_7: JSON.parse(
            JSON.stringify(data.full_active_matrix)
          ),
          matrix_volumes_9_8: JSON.parse(
            JSON.stringify(data.full_matrix_combined)
          ),
          inital_matrix_volumes: {
            "9_1": JSON.parse(JSON.stringify(data.matrix_row_total)),
            "9_7": JSON.parse(JSON.stringify(data.full_active_matrix)),
            "9_8": JSON.parse(JSON.stringify(data.full_matrix_combined)),
          },
          matrix_id: data.matrix_id,
          matrix_dimension_status: data.matrix_dimension_status,
          matrix_row_total: data.matrix_row_total,
          sourcing_week: data.sourcing_week,
          shipment_week: data.shipment_week,
          shipment_date: data.shipment_date_9_1,
          sourcing_date: data.sourcing_date,
          current_week: data.current_week,
          total_matrix_volume: data.total_volume,
          total_matrix_weight: data.total_weight,
        },
      });

      switch (data.matrix_dimension_status) {
        case 1:
          dispatch({
            type: ACTIONS.CHANGE_TABLE_HEADER,
            payload: {
              table_header_weeks: [data.shipment_date_9_1],
            },
          });
          break;
        case 2:
          dispatch({
            type: ACTIONS.CHANGE_TABLE_HEADER,
            payload: {
              table_header_weeks: [...dates],
            },
          });
          break;
        case 3:
          dispatch({
            type: ACTIONS.CHANGE_TABLE_HEADER,
            payload: {
              table_header_weeks: [...dates, data.shipment_week],
            },
          });
          break;
        default:
          break;
      }

      let rowTotal = [];
      Object.values(data.matrix_row_total).forEach((item) =>
        rowTotal.push(item[0].volume)
      );

      setRowVolumeTotal(rowTotal);
      dispatch({
        type: ACTIONS.SET_MATRIX_RETRIEVED_STATUS,
        payload: true,
      });
    } else {
      ToastsStore.error("Invalid Source Week");
      dispatch({
        type: ACTIONS.SET_MATRIX_RETRIEVED_STATUS,
        payload: false,
      });
    }
    dispatch({
      type: ACTIONS.SET_MATRIX_VOLUME_CHANGE_STATUS,
      payload: false,
    });
  };

  const updateMatrix = async () => {
    setUpdateClicked(true);
    const data = {
      access_id: state.selectedAccessId,
      access_type: state.selectedAccessType,
      data: state.display_matrix,
      nullify_existing:
        state.initialDimensionStatus === state.matrix_dimension_status
          ? false
          : true, //a replacement for is_toggled
      manual_shipment_count: state.manual_shipment_count,
      sourcing_week: state.sourcing_week,
    };
    let updateMatrix = false;
    switch (state.selectedMatrixModel) {
      case "BaseOrder":
        updateMatrix = await BaseOrderServices.updateBaseOrderMatrix(
          state.matrix_id,
          data
        );
        break;
      case "ForecastOrder":
        updateMatrix = await ForecastOrderServices.updateForeCastOrderMatrix(
          state.matrix_id,
          data
        );
        break;
      case "CustomerNote":
        updateMatrix = await CustomerNoteServices.updateCustomerNoteMatrix(
          state.matrix_id,
          data
        );
        break;
      case "ProformaOrder":
        updateMatrix = await PerformaOrderServices.updatePerformaOrderMatrix(
          state.matrix_id,
          data
        );
        break;
      case "LightQuote":
        updateMatrix = await LightQuoteServices.updateLightQuoteMatrix(
          state.matrix_id,
          data
        );
        break;
      case "Quotee":
        updateMatrix = await QuoteServices.updateQuoteAdminMatrix(
          state.matrix_id,
          data
        );
        break;
      case "OrderPlaced":
        updateMatrix = await OrderPlacedServices.updateOrderPlacedMatrix(
          state.matrix_id,
          data
        );
        break;
      case "RFQ":
        updateMatrix = await RFQServices.updateRfqMatrix(state.matrix_id, data);
        break;
      case "Purchase":
        updateMatrix = await PurchaseServices.updatePurchaseMatrix(
          state.matrix_id,
          data
        );
        break;
      default:
        break;
    }
    if (updateMatrix && updateMatrix.data) {
      getMatrix();
      dispatch({
        type: ACTIONS.SET_SPLIT_CHANGE,
        payload: { enable_subtractor: false },
      });
      setUpdateClicked(false);
    }
  };

  const updateVolume = () => {
    if (
      state.matrix_dimension_status === 1 &&
      state.initialDimensionStatus !== 1
    ) {
      confirmAlert({
        message:
          "Are you sure you want to convert volumes specified on weekday to unspecified volumes?",
        buttons: [
          {
            label: "Yes",
            onClick: () => updateMatrix(),
          },
          {
            label: "No",
            onClick: () => false,
          },
        ],
      });
    } else if (state.matrix_dimension_status === 3 && state.enable_subtractor) {
      const fully_distributed = Object.entries(state.display_matrix).every(
        (item) => item[1][7].volume === 0
      );
      if (fully_distributed) {
        confirmAlert({
          message: "Thank you",
          buttons: [
            {
              label: "Ok",
              onClick: () => updateMatrix(),
            },
          ],
        });
      } else {
        if (
          Object.entries(state.display_matrix).some(
            (item) => item[1][7].volume > 0
          )
        ) {
          confirmAlert({
            message:
              "Are you sure you want to leave some volumes unspecified on weekday?",
            buttons: [
              {
                label: "Yes",
                onClick: () => updateMatrix(),
              },
              {
                label: "No",
                onClick: () => false,
              },
            ],
          });
        } else {
          confirmAlert({
            message:
              "Are you sure you want to increase the requested volumes (in one more weight classes)?",
            buttons: [
              {
                label: "Yes",
                onClick: () => updateMatrix(),
              },
              {
                label: "No",
                onClick: () => false,
              },
            ],
          });
        }
      }
    } else {
      updateMatrix();
    }
  };

  useEffect(() => {
    dispatch({
      type: ACTIONS.SET_HEADER_INFO,
      payload: {
        subCaption: camelCaseToDisplayString(state.selectedMatrixModel || ""),
      },
    });
  }, [state.selectedMatrixModel]);
  //////handling component displaying//////
  useEffect(() => {
    [
      "MatrixTotals",
      "WeekComponets",
      "NineStarSelector",
      "SplitSelector",
    ].forEach((d) => {
      if (state.is_matrix_retrieved) {
        console.log(
          renderModelType(state.matrix_dimension_status),
          "cdnbvdjbbfdb"
        );
        dispatch({
          type: ACTIONS.ENABLE_HEADER_COMPONENT,
          payload: d,
        });
        dispatch({
          type: ACTIONS.SET_MATRIX_TOTALS,
          payload: {
            matrixVolume: renderModelType(state.matrix_dimension_status),
          },
        });
      } else
        dispatch({
          type: ACTIONS.DISABLE_HEADER_COMPONENT,
          payload: d,
        });
    });
    if (["BaseOrder", "CustomerNote"].includes(state.selectedMatrixModel)) {
      dispatch({
        type: ACTIONS.DISABLE_HEADER_COMPONENT,
        payload: "WeekComponets",
      });
    }
  }, [state.is_matrix_retrieved]);

  useEffect(() => {
    if (
      ["BaseOrder", "CustomerNote"].includes(state.selectedMatrixModel) &&
      state.selectedAccessId.trim() !== ""
    ) {
      getMatrix();
    }
  }, [state.selectedAccessId]);

  ///disabling Nine star options//
  useEffect(() => {
    let disabledOptions = state.initialDimensionStatus === 2 ? [] : ["9_7"];
    if (state.enable_subtractor) disabledOptions = ["9_7", "9_1", "9_8"];
    dispatch({
      type: ACTIONS.SET_DISABLED_NINE_STAR_OPTIONS,
      payload: disabledOptions,
    });
  }, [state.initialDimensionStatus, state.enable_subtractor]);

  useEffect(() => {
    console.log("dimension switch UseEffect");
    switch (state.matrix_dimension_status) {
      case 1:
        dispatch({
          type: ACTIONS.CHANGE_MATRIX_AND_DAYS,
          payload: {
            display_matrix: JSON.parse(
              JSON.stringify(state.matrix_volumes_9_1)
            ),
            table_header_weeks: [state?.shipment_date],
          },
        });
        break;
      case 2:
        dispatch({
          type: ACTIONS.CHANGE_MATRIX_AND_DAYS,
          payload: {
            display_matrix: JSON.parse(
              JSON.stringify(state.matrix_volumes_9_7)
            ),
            table_header_weeks: [...state.week_data],
          },
        });
        break;
      case 3:
        dispatch({
          type: ACTIONS.CHANGE_MATRIX_AND_DAYS,
          payload: {
            display_matrix: JSON.parse(
              JSON.stringify(state.matrix_volumes_9_8)
            ),
            table_header_weeks: [...state.week_data, state?.shipment_week],
          },
        });
        break;

      default:
        dispatch({
          type: ACTIONS.CHANGE_MATRIX_AND_DAYS,
          payload: {
            display_matrix: state.matrix_volumes_9_7,
          },
        });
        break;
    }
  }, [
    state.matrixVolumeChangeStatus,
    state.matrix_dimension_status,
    state.selectedAccessId,
  ]);

  const renderModelType = (dimensionStatus) => {
    switch (dimensionStatus) {
      case 1:
        return state.matrix_volumes_9_1;
      case 2:
        return state.matrix_volumes_9_7;
      case 3:
        return state.matrix_volumes_9_8;
      default:
        return null;
    }
  };

  const onMatrixModelChange = (e) => {
    dispatch({ type: ACTIONS.SET_MATRIX_MODEL, payload: e.target.value });
    dispatch({ type: ACTIONS.SET_ACCESS_TYPE, payload: "" });
    dispatch({ type: ACTIONS.SET_ACCESS_ID, payload: "" });
    // dispatch({ type: ACTIONS.SET_INPUT_SOURCING_WEEK, payload: '' });
    dispatch({ type: ACTIONS.SET_MATRIX_RETRIEVED_STATUS, payload: false });
  };
  const onAccessTypeChange = (e) => {
    dispatch({ type: ACTIONS.SET_ACCESS_TYPE, payload: e.target.value });
    dispatch({ type: ACTIONS.SET_ACCESS_ID, payload: "" });
    // dispatch({ type: ACTIONS.SET_INPUT_SOURCING_WEEK, payload: '' });
    dispatch({ type: ACTIONS.SET_MATRIX_RETRIEVED_STATUS, payload: false });
  };
  const onAccessIdChange = (e) => {
    dispatch({ type: ACTIONS.SET_ACCESS_ID, payload: e.target.value });
    // dispatch({ type: ACTIONS.SET_INPUT_SOURCING_WEEK, payload: state.defaultSourcingWeek });
    dispatch({ type: ACTIONS.SET_MATRIX_RETRIEVED_STATUS, payload: false });
    dispatch({
      type: ACTIONS.SET_FIELD,
      payload: {
        field: "sourcing_week",
        value: "",
      },
    });
  };
  const onSourcingWeekChange = async (e) => {
    await dispatch({ type: ACTIONS.SET_INPUT_SOURCING_WEEK, payload: e });
  };
  const onGetMatrixButtonClick = () => {
    getMatrix();
  };
  const splitSelectorHandleChange = (e) => {
    dispatch({
      type: ACTIONS.SET_SPLIT_CHANGE,
      payload: { enable_subtractor: e.target.checked },
    });
    if (e.target.checked) {
      switchMatrixDimension("9_8");
    }
  };
  const switchMatrixDimension = (value) => {
    let dimension_status = renderMatrixDimension(value);
    dispatch({
      type: ACTIONS.SWITCH_MATRIX_DIMENSION,
      payload: {
        selectedMatrixDimension: dimension_status,
      },
    });
  };
  const refreshButtonHandle = () => {
    setRefreshBtnClicked(true);
    dispatch({
      type: ACTIONS.SET_SPLIT_CHANGE,
      payload: { enable_subtractor: false },
    });
    getMatrix();
  };
  const orderTotalBox = state.is_matrix_retrieved
    ? getVolumes(state.display_matrix)
    : 0;
  const orderTotalWeight = orderTotalBox * 22;

  const handleRowVolumeChange = (e, weightClass, index) => {
    if (e.target.value <= 270) {
      console.log(
        `target Value ${e.target.value} with weightClass ${weightClass} in Index:${index}`
      );

      let updatedMatrix = { ...state.display_matrix };

      if (state.enable_subtractor) {
        let comparison_difference =
          state.display_matrix[weightClass][index].volume - e.target.value;

        updatedMatrix[weightClass][7].volume += comparison_difference;
      }
      updatedMatrix[weightClass][index].volume = parseInt(e.target.value || 0);

      let total_sum_row = findRowTotal(updatedMatrix);

      setRowVolumeTotal(total_sum_row);

      dispatch({
        type: ACTIONS.CHANGE_DISPLAY_MATRIX,
        payload: {
          display_matrix: updatedMatrix,
        },
      });
    }
  };

  const findRowTotal = (updatedMatrix) =>
    Object.values(updatedMatrix).map((item) => {
      let sum = 0;
      item.forEach((val) => (sum += val.volume));
      return sum;
    });

  const updateActiveWeekDataStatus = (data) => {
    dispatch({
      type: "UPDATE_ACTIVE_WEEK_DATA_STATUS",
      payload: { update_active_week_data_status: data },
    });
  };

  return (
    <>
      <section className="sell_body_wrap">
        {/* <Sidebar /> */}
        <div
          style={{ paddingBottom: 30 }}
          className="set_universal_aligment_fx"
        >
          <div className="scrl_out" style={{ paddingBottom: "30px" }}>
            <Link to={"/home"}>
              <p className={"Back_link"}>Back</p>
            </Link>
          </div>
          {console.log(state.enabledComponents, "dfdsfsdfsdf")}
          <GlobalMatrixCard
            enabledComponents={state.enabledComponents}
            nineStarSelectedDimension={renderNineStar(
              state.matrix_dimension_status
            )}
            nineStarHandleChangeDimension={switchMatrixDimension}
            nineStarDisabledOtpions={
              JSON.stringify(state.display_matrix) !==
              JSON.stringify(renderModelType(state.matrix_dimension_status))
                ? ["9_1", "9_7", "9_8"]
                : state.nineStarDisabledOtpions
            }
            headerCaption={state.headerInfo.caption || ""}
            headerSubCaption={state.headerInfo.subCaption || ""}
            splitSelectorChecked={state.enable_subtractor}
            splitSelectorHandleChange={splitSelectorHandleChange}
            splitSelectorDisabled={
              JSON.stringify(state.display_matrix) !==
              JSON.stringify(renderModelType(state.matrix_dimension_status))
                ? true
                : state.matrix_dimension_status !== 3
                ? true
                : state.matrixVolumeChangeStatus
            }
            orderTotalBox={orderTotalBox}
            dispalyOrderTotalBox={true}
            orderTotalWeight={orderTotalWeight}
            displayOrderTotalWeight={true}
            updateButtonDisplay={true}
            updateButtonDisable={
              JSON.stringify(state.display_matrix) !==
              JSON.stringify(renderModelType(state.matrix_dimension_status))
                ? false
                : state.initialDimensionStatus !==
                    state.matrix_dimension_status &&
                  state.matrix_dimension_status === 1 &&
                  Object.values(state.matrix_volumes_9_1).some(
                    (item) => item[0].volume !== 0
                  )
                ? false
                : true
            }
            updateButtonHandle={updateVolume}
            refreshButtonDisplay={state.is_matrix_retrieved}
            refreshButtonHandle={refreshButtonHandle}
            emailButtonDisplay={true}
            emailButtonHandle={() => {}}
            sourcingWeekDisplay={true}
            sourcingWeek={state.sourcing_week}
            sourcingDates={state.sourcing_date}
            shipmentWeekDisplay={true}
            shipmentWeek={state.shipment_week}
            shipmentDates={state.shipment_date}
            currentWeek={state.current_week}
            onMatrixModelChange={onMatrixModelChange}
            onAccessTypeChange={onAccessTypeChange}
            onAccessIdChange={onAccessIdChange}
            onSourcingWeekChange={onSourcingWeekChange}
            onGetMatrixButtonClick={onGetMatrixButtonClick}
            disableMatrixModel={
              JSON.stringify(state.display_matrix) !==
                JSON.stringify(
                  renderModelType(state.matrix_dimension_status)
                ) || state.enable_subtractor
            }
            disableAccessType={
              JSON.stringify(state.display_matrix) !==
                JSON.stringify(
                  renderModelType(state.matrix_dimension_status)
                ) || state.enable_subtractor
            }
            disableAccessId={
              JSON.stringify(state.display_matrix) !==
                JSON.stringify(
                  renderModelType(state.matrix_dimension_status)
                ) || state.enable_subtractor
            }
            disableSourcingWeek={
              JSON.stringify(state.display_matrix) !==
                JSON.stringify(
                  renderModelType(state.matrix_dimension_status)
                ) || state.enable_subtractor
            }
            disableGetMatrixButton={
              ((state.selectedSourcingWeek || "").trim() !== ""
                ? (state.selectedSourcingWeek || "").toLowerCase() ===
                  (state.sourcing_week || "").toLowerCase()
                : true) ||
              state.matrixVolumeChangeStatus ||
              state.enable_subtractor
            }
            selectedMatrixModel={state.selectedMatrixModel}
            selectedAccessType={state.selectedAccessType}
            selectedAccessId={state.selectedAccessId}
            //week selector
            access_type={
              state.selectedAccessType == "producer" ||
              state.selectedAccessType == "exporter"
                ? "x" + state.selectedAccessType
                : state.selectedAccessType
            }
            access_id={state.selectedAccessId}
            modelName={state.selectedMatrixModel}
            updateActiveWeekDataStatus={updateActiveWeekDataStatus}
            selectedSourcingWeek={state.selectedSourcingWeek}
            MatixIconDisplay={true}
            showActiveMatrixIconDisplay={
              state.matrix_id == 0 && state.selectedSourcingWeek !== ""
                ? false
                : state.active_week_data_status.includes(true)
                ? true
                : false
            }
          />

          <div style={{ display: "grid", gridAutoFlow: "column" }}>
            <div style={{ overflowX: "auto" }} className="fc_scroll">
              {state.selectedSourcingWeek !== "" ? (
                renderModelType(state.matrix_dimension_status) &&
                state.access_id !== "" &&
                state.is_matrix_retrieved && (
                  <table className="table-new">
                    <WeekHeader
                      enable_subtractor={state.enable_subtractor}
                      table_header_weeks={state.table_header_weeks}
                    />
                    <tbody
                      style={{
                        overflowX: "auto",
                        width: "100%",
                        position: "relative",
                      }}
                      className="fc_scroll"
                    >
                      {state.weight_classes.map((key, i) => {
                        return (
                          <Fragment key={i}>
                            <WeightRow
                              weightClass={key}
                              volumes={rowVolumeTotal[i]}
                              rowData={state.display_matrix[key]}
                              handleRowVolumeChange={handleRowVolumeChange}
                              matrix_row_total={state.matrix_row_total}
                              splitEnabled={state.enable_subtractor}
                              matrix_dimension_status={
                                state.matrix_dimension_status
                              }
                              current_week={state.current_week}
                              selected_sourcing_week={
                                state.selectedSourcingWeek
                              }
                              updateClicked={updateClicked}
                              refreshBtnClicked={refreshBtnClicked}
                              setRefreshBtnClicked={setRefreshBtnClicked}
                            />
                          </Fragment>
                        );
                      })}
                    </tbody>
                  </table>
                )
              ) : (
                <p className="no-data">No week is selected</p>
              )}
            </div>
          </div>
        </div>
      </section>
    </>
  );
}

const WeekHeader = ({ enable_subtractor = false, table_header_weeks = [] }) => {
  return (
    <thead>
      <tr>
        <th></th>
        {table_header_weeks.map((val, i) => (
          <th key={i}>{val}</th>
        ))}
        {enable_subtractor && <th>Total</th>}
        <th></th>
      </tr>
    </thead>
  );
};

const WeightRow = ({
  volumes = Number(),
  weightClass = "",
  matrix_row_total,
  rowData = [],
  splitEnabled = false,
  current_week,
  selected_sourcing_week,
  updateClicked = false,
  refreshBtnClicked = false,
  setRefreshBtnClicked,
  handleRowVolumeChange = (e, weightClass, index) => {
    e.preventDefault();
    console.log(
      `target Value ${e.target.value} with weightClass ${weightClass} in Index:${index}`
    );
  },

  matrix_dimension_status = "",
}) => {
  const [toggleEditRow, setToggleEditRow] = React.useState(false);

  useEffect(() => {
    if (splitEnabled && volumes) setToggleEditRow(true);
    else setToggleEditRow(false);
  }, [splitEnabled]);

  useEffect(() => {
    setToggleEditRow(false);
  }, [matrix_dimension_status, selected_sourcing_week]);

  useEffect(() => {
    if (!updateClicked) {
      setToggleEditRow(false);
    }
  }, [updateClicked]);

  useEffect(() => {
    setToggleEditRow(false);
    setRefreshBtnClicked(false);
  }, [refreshBtnClicked]);

  return (
    <tr
      style={{
        background: `${volumes ? "#00ffff66" : "#00ffff26"}`,
      }}
    >
      <td className="td-hog">
        <span style={volumes ? {} : { opacity: 0.3 }}>
          <span>HOG {weightClass} kg</span>
          {volumes !== 0 ? (
            <span style={{ fontSize: 14 }}>
              &nbsp;(Total {volumes} {volumes === 1 ? "Box" : "Boxes"})
            </span>
          ) : null}
        </span>
      </td>
      {rowData.map((row, i) => (
        <td key={i}>
          {splitEnabled && i === rowData.length - 1 ? (
            <input
              value={row.volume === 0 ? "" : row.volume}
              disabled={true}
              type="number"
              className="volume-input-disabled"
            />
          ) : (
            <input
              value={row.volume === 0 ? "" : row.volume}
              disabled={!toggleEditRow}
              type="number"
              className={` ${
                toggleEditRow ? "volume-input" : "volume-input-disabled"
              }`}
              style={
                volumes
                  ? toggleEditRow
                    ? { backgroundColor: "#fff" }
                    : { backgroundColor: "rgba(173, 222, 222,0.5)" }
                  : toggleEditRow
                  ? { backgroundColor: "#fff" }
                  : { backgroundColor: "rgba(173, 222, 222,0.2)" }
              }
              onChange={(e) => {
                e.preventDefault();
                if (e.target.value >= 0)
                  handleRowVolumeChange(e, weightClass, i);
              }}
            />
          )}
        </td>
      ))}
      {splitEnabled && <td>{matrix_row_total[weightClass][0]?.volume}</td>}
      <td className="td-kg">
        {/* {current_week === selected_sourcing_week && ( */}
        <FiChevronRight
          className={` ${toggleEditRow ? "toggle-on" : ""}`}
          onClick={(e) => {
            e.preventDefault();
            setToggleEditRow(!toggleEditRow);
          }}
          style={{ height: 20, width: 20, cursor: "pointer" }}
        />
        {/* )} */}
      </td>
    </tr>
  );
};

export default UniversalMatrixPage;
