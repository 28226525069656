import React, { Component } from "react";
import axios from "axios";
import { ToastsStore } from "react-toasts";
import CommonServices from "../../api/CommonServices";
class QuotePrice extends Component {
  constructor(props) {
    super(props);

    this.state = {
      modalWeightClass: "",
      modalWeightClasses: [
        "1–2",
        "2–3",
        "3–4",
        "4–5",
        "5–6",
        "6–7",
        "7–8",
        "8–9",
        "9+",
      ],
      disabled: true
    };
    this.counterValue = 0;
  }

  addPriceValue = (evt) => {
    if (evt.target.value != "") {
      this.counterValue = evt.target.value;
      this.setState({
        disabled: false
      })
    } else {
      this.counterValue = "";
      this.setState({
        disabled: true
      })
    }
    this.forceUpdate();
  };
  addPriceStatus = (a) => {
    if (a == 1) {
      this.props.addPriceStatus(a);
    } else {
      this.addPrice();
    }
  };
  async addPrice() {
    if (this.counterValue > 0 && this.state.modalWeightClass) {
      let data = {
        quotee_id: this.props.id,
        weight_class: this.state.modalWeightClass,
        price: Number(this.counterValue),
        from_admin: true,
      };
      const addQuotePriceLine = await CommonServices.addQuotePriceLine(data)
      if(addQuotePriceLine.data){
        this.props.addPriceStatus(2);
      }
      else if(addQuotePriceLine.error){
        setTimeout(
          function () {
            this.props.addPriceStatus(1);
          }.bind(this),
          3000
        );
      }
    }
  }
  onModalSelectChange = (e) => {
    e.preventDefault();
    this.setState({
      modalWeightClass: e.target.value,
    });
  };
  render() {
    return (
      <>
        <div className={"modal_overlay"}>
          <div className={"Counter_body"}>
            <p>
              Add Quote Price
              <div
                className={"close_pop"}
                onClick={(event) => this.addPriceStatus(1)}
              ></div>
            </p>
            <div className={"quote_price_wrp"}>
              <section>
                <span>Weight Class</span>
                <div>
                  <select
                    value={this.state.modalWeightClass}
                    onChange={(event) => this.onModalSelectChange(event)}
                    className={"modal_currency"}
                  >
                    {this.state.modalWeightClasses.map((data, i) => {
                      return (
                        <>
                          <option value="" disabled selected hidden>
                            Select
                          </option>
                          <option value={data}>{data}</option>
                        </>
                      );
                    })}
                  </select>
                </div>
              </section>
              <section>
                <span>Price</span>
                <div>
                  <input
                    type="number"
                    onChange={(event) => this.addPriceValue(event)}
                  ></input>
                </div>
              </section>
              <section>
                <div
                  className={
                    "quote_price_submit_btn" +
                    " " +
                    "ask_button" +
                    " " +
                    "sp_btn" + " " + (this.state.disabled ? 'not_allow_cursor' : 'allow_cursor')
                  }
                  onClick={(event) => this.state.disabled ? null : this.addPriceStatus(2)}
                >
                  Submit
                </div>
              </section>
            </div>
          </div>
        </div>
      </>
    );
  }
}

export default QuotePrice;
