import Api from './Api';

class NasdaqServices {
	getDataStats = async (params={}) => 
		await Api.get(`/price/api/nasdaq-index/data-stats/`,{params});

	initiateNasdaqIndex = async () =>
		await Api.get('/price/api/nasdaq-index/initiate/');
}
export default new NasdaqServices();
