import React from 'react';
import Spinner from '../../components/Ui/Spinner/Spinner';
import Sidebar from '../Sidebar/Sidebar'
// import PriceIndicator from '../PriceIndicator/PriceIndicator';

const Layout = (props) => {
    return (

        <>
            <main>
                <Sidebar/>
                {props.children}
                <Spinner></Spinner>
            </main>
        </>

    )
};

export default Layout;