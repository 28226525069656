import React, { useState } from 'react';
import { BiArrowToLeft, BiArrowToRight } from 'react-icons/bi';
import Moment from 'react-moment';
import { Link } from 'react-router-dom';
import userIcon from '../../../assets/images/user.png';
import customerIcon from '../../../assets/images/customer.png';
import ReactTooltip from 'react-tooltip';
import './ActiveQuotes.css';

function ActiveQuotes({ activeQuotes }) {
	return (
		<div className='active-quote-container'>
			<h3>Active Quotes</h3>
			<p className='subtitle'>View your active quotes here</p>
			<QuoteTable data={activeQuotes} />
		</div>
	);
}

const QuoteTable = ({ data }) => {
	const [currentPage, setCurrentPage] = useState(1);
	const [dataPerPage] = useState(5);

	const handlePage = (e) => {
		setCurrentPage(e.target.id);
	};

	const indexOfLastTodo = currentPage * dataPerPage;
	const indexOfFirstTodo = indexOfLastTodo - dataPerPage;
	const currentItems = data.slice(indexOfFirstTodo, indexOfLastTodo);

	// Logic for displaying page numbers
	const pageNumbers = [];
	for (let i = 1; i <= Math.ceil(data.length / dataPerPage); i++) {
		pageNumbers.push(i);
	}

	const renderPageNumbers = pageNumbers.map((number) => {
		return (
			<div
				key={number}
				id={number}
				onClick={handlePage}
				className={`pagination-numbers ${
					parseInt(currentPage) === number ? 'pagination-active' : ''
				}`}
			>
				{number}
			</div>
		);
	});

	return (
		<table className='shipment-table-new'>
			<thead>
				<tr>
					<th>Quote Id</th>
					<th>Customer Name</th>
					<th>Status</th>
					<th>Last Activity</th>
				</tr>
			</thead>
			<tbody
				style={{ overflowX: 'auto', width: '100%', position: 'relative' }}
				className='fc_scroll'
			>
				{currentItems.length > 0 ? (
					currentItems.map((item, i) => {
						const updatedTime = new Date(item.updated);
						const currentTime = new Date(Date.now());

						let timeRemaining = currentTime.getTime() - updatedTime.getTime();

						const days = Math.floor(timeRemaining / (1000 * 60 * 60 * 24));

						const hours = Math.floor(
							(timeRemaining % (1000 * 60 * 60 * 24)) / (1000 * 60 * 60)
						);
						const minutes = Math.floor(
							days * 1440 +
								hours * 60 +
								(timeRemaining % (1000 * 60 * 60)) / (1000 * 60)
						);

						return (
							<tr key={i}>
								<td>
									<Link
										to={`/sell/friday/request/${item.quote_id}/${item.access_id}/customer_shipment_location`}
									>
										{item.quote_id}
									</Link>
								</td>
								<td>{item.name}</td>
								<td>
									{item.negotiation_status === 'Admin Pending' ? (
										<span data-tip='Admin pending'>
											<img style={{ width: 18 }} src={userIcon} alt='admin' />
											<BiArrowToLeft size='18' />
											<ReactTooltip />
										</span>
									) : item.negotiation_status === 'Customer Pending' ? (
										<span data-tip='Customer pending' className='table-icon'>
											<BiArrowToRight size='18' />
											<img style={{ width: 18 }} src={customerIcon} alt='customer' />
											<ReactTooltip />
										</span>
									) : (
										'' || ''
									)}
								</td>
								<td
									style={minutes < 1440 ? { color: '#16a085' } : { color: '#212121' }}
								>
									<div className='timestamp-table-cell'>
										<Moment date={new Date(item.updated)} format='ddd DD MMM , HH:mm A' />
										{minutes < 30 ? <p className='new-badge'>NEW</p> : <></>}
									</div>
								</td>
							</tr>
						);
					})
				) : (
					<tr>
						<td style={{ textAlign: 'center', padding: 20 }} colSpan={4}>
							No data available
						</td>
					</tr>
				)}
			</tbody>
			{renderPageNumbers.length > 1 && (
				<tfoot>
					<tr>
						<td colSpan={4} className='pagination-number-container'>
							{renderPageNumbers}
						</td>
					</tr>
				</tfoot>
			)}
		</table>
	);
};

export default ActiveQuotes;
