import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import './ToggleSwitch.css';
import axios from 'axios';
import CommonServices from '../../api/CommonServices';
import RFQServices from '../../api/RFQServices';

const ToggleSwitch = (props) => {
	const [toggle, setToggle] = useState(!!undefined);
	const { disabled, className } = props;

	useEffect(() => {
		console.log(props.status);
		if (props.status == 'Open') {
			setToggle(true);
		} else if (props.resetClick && !props.status) {
			setToggle(true);
		} else if (props.status == false) {
			setToggle(true);
		} else if (props.status == true) {
			setToggle(false);
		}
	}, [props.status, props.resetClick]);

	//print 0 to total number
	function range(start, end) {
		return Array(end - start + 1)
			.fill()
			.map((_, idx) => start + idx);
	}

	const triggerToggle = async () => {
		if (disabled) {
			return;
		}
		setToggle(!toggle);

		if (props.fromRFQ) {
			if (toggle) {
				props.onROWindexClick(props.rowIndex, true, false);
			} else {
				props.onROWindexClick(props.rowIndex, false, true);
			}

			const rfq = props.total
				.filter((x) => x.is_disabled === true)
				.map((x) => x.rfq_id);

			if (rfq.indexOf(props.location) !== -1) {
				console.log('Value exists!');
				const index = rfq.indexOf(props.location);
				if (index > -1) {
					rfq.splice(index, 1);
				}
			} else {
				rfq.push(props.location);
			}
			console.log(rfq);

			try {
				let send_data = {};
				if (props.answerColClick) {
					send_data['has_answer'] = true;
				}
				if (rfq.length > 0) {
					send_data['rfq'] = rfq;
				}
				const SendRFQ = await RFQServices.postRFQ(send_data);

				if (SendRFQ.data) {
					const { data } = SendRFQ;
					props.onTogglePress(data.accumulated_matrix, data.results);
				}
			} catch (err) {
				console.log('Triggle Toggle ERROR', err);
			}
		} else if (props.fromForecast) {
			if (toggle) {
				props.onROWindexClick(props.rowIndex, true, false);
			} else {
				props.onROWindexClick(props.rowIndex, false, true);
			}
			const customerShipmentLocations = props.total
				.filter(
					(x) => x.is_disabled === true && x.type === 'customer_shipment_location'
				)
				.map((x) => x.customer_shipment_location_id);
			if (props.type == 'customer_shipment_location') {
				if (customerShipmentLocations.indexOf(props.location) !== -1) {
					console.log('Value exists!');
					const index = customerShipmentLocations.indexOf(props.location);
					if (index > -1) {
						customerShipmentLocations.splice(index, 1);
					}
				} else {
					customerShipmentLocations.push(props.location);
				}
			}
			const prospect = props.total
				.filter((x) => x.is_disabled === true && x.type === 'prospect')
				.map((x) => x.customer_shipment_location_id);
			if (props.type == 'prospect') {
				if (prospect.indexOf(props.location) !== -1) {
					console.log('Value exists!');
					const index = prospect.indexOf(props.location);
					if (index > -1) {
						prospect.splice(index, 1);
					}
				} else {
					prospect.push(props.location);
				}
			}

			const datta = {};
			if (customerShipmentLocations.length > 0) {
				datta['customer_shipment_locations'] = customerShipmentLocations;
			}
			if (prospect.length > 0) {
				datta['prospect'] = prospect;
			}
			if (props.col1) {
				datta['filter_param'] = 'forecast';
			} else if (props.col2) {
				datta['filter_param'] = 'quote';
			} else if (props.col3) {
				datta['filter_param'] = 'order';
			} else if (props.col4) {
				datta['filter_param'] = 'percentage';
			}
			const updateOutlook = await CommonServices.updateOutlook(datta);
			if (updateOutlook.data) {
				const { data } = updateOutlook;
				props.onTogglePress(data.accumulated_matrix, data.results);
			}
		} else if (props.fromGlobal) {
			if (toggle) {
				const prospect = props.total
					.filter((x) => x.type === 'prospect')
					.map((x) => x.customer_shipment_location_id);
				const customerShipmentLocations = props.total
					.filter((x) => x.type === 'customer_shipment_location')
					.map((x) => x.customer_shipment_location_id);
				const datta = {};
				if (customerShipmentLocations.length > 0) {
					datta['customer_shipment_locations'] = customerShipmentLocations;
				}
				if (prospect.length > 0) {
					datta['prospect'] = prospect;
				}

				if (props.col1) {
					datta['filter_param'] = 'forecast';
				} else if (props.col2) {
					datta['filter_param'] = 'quote';
				} else if (props.col3) {
					datta['filter_param'] = 'order';
				} else if (props.col4) {
					datta['filter_param'] = 'percentage';
				}
				const updateOutlook = await CommonServices.updateOutlook(datta);
				if (updateOutlook.data) {
					const { data } = updateOutlook;
					props.onTogglePress(data.accumulated_matrix, data.results);
				}
				var result = range(0, props.total.length - 1);
				props.allRowSelected(result);
			} else {
				let datta = '';
				if (props.col1) {
					datta = 'forecast';
				} else if (props.col2) {
					datta = 'quote';
				} else if (props.col3) {
					datta = 'order';
				} else if (props.col4) {
					datta = 'percentage';
				} else {
				}
				props.forecastApi(datta, '', '');
				props.allRowSelected([]);
			}
		} else if (props.fromMatrix) {
			props.onToggleChange(!toggle);
		}
	};

	const toggleClasses = classNames(
		'wrg-toggle',
		{
			'wrg-toggle--checked': toggle,
			'wrg-toggle--disabled': disabled,
		},
		className
	);

	return (
		<>
			<div onClick={triggerToggle} className={toggleClasses}>
				<div
					className='wrg-toggle-container'
					style={{ backgroundColor: toggle ? 'green' : '#4d4d4d' }}
				>
					<div className='wrg-toggle-check'>
						<span></span>
					</div>
					<div className='wrg-toggle-uncheck'>
						<span></span>
					</div>
				</div>
				<div className='wrg-toggle-circle'></div>
				<input
					type='checkbox'
					aria-label='Toggle Button'
					className='wrg-toggle-input'
				/>
			</div>
		</>
	);
};

export default ToggleSwitch;
