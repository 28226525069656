import React, { useEffect, useState } from 'react';
import { Link } from 'react-router-dom';
import Sidebar from '../../Sidebar/Sidebar';
import axios from 'axios';
import className from './PriceIndication.css'
import PriceLine from '../../PriceLine/PriceLine';
import QuoteServices from '../../../api/QuoteServices';


const PriceIndication = () => {

    const [priceList, setPriceList] = useState([]);

    useEffect(()=>{
        getQuote();
    }, [])

    const getQuote = async () => {
        const getQuote = await QuoteServices.getQuote();
        if(getQuote.data){
            const {data} = getQuote;
            const price = Object.entries(data.active_admin_offer_limited).map(([key, val]) => {
                return {weight_class: key, cost_target: val}
            });
            setPriceList(price);
        }
    }

    return (
        <section className={'body_wrap'}>
            {/* <Sidebar /> */}
            <div className={'cont_wp'}>
                <Link to={'/home'}>
                    <p className={'Back_link'}>Back</p>
                </Link>

                <section className={'cmn_head'}>
                    <p>Get Price Indication</p>
                </section>
                <div class="scrl_out">
                    <PriceLine priceList={priceList} enableUpdate={false} />
                </div>
            </div>
        </section>
    );
 }

export default PriceIndication;