import React, { useEffect, useState } from "react";
import Sidebar from "../Sidebar/Sidebar";
import axios from "axios";
import PriceLine from "../PriceLine/PriceLine";
import "./CostTarget.css";
import Header from "./Header";
import CommonServices from "../../api/CommonServices";
import moment from "moment";

const CostTarget = () => {
  const [costTargetPriceList, setCostTargetPriceList] = useState([]);
  const [retrievedCostTargetPrice, setRetrievedCostTargetPrice] = useState([]);
  const [sourcing_week, setSourcingWeek] = useState("");
  const [sourcing_date, setSourcing_date] = useState("");
  const [packing_week, setPackingWeek] = useState("");
  const [packing_date, setPacking_date] = useState("");
  const [year, setYear] = useState("");
  const [week, setWeek] = useState("");
  const [buttonDisable, setButtonDisable] = useState(true);
  const [data, setData] = useState([]);
  const [currencies, setCurrencies] = useState([]);
  const [previousPriceList, setPrevoiusPriceList] = useState(null);
  const [historys, setHistorys] = useState([]);
  const [incotermsChoices, setIncotermsChoices] = useState([]);

  useEffect(() => {
    const dt = new Date();
    const currentWeekOfYear = ISO8601_week_no(dt);
    const currentYear = new Date().getFullYear();
    const SourcingWeek = `${currentYear}-W${currentWeekOfYear}`;

    setPackingWeek(`${currentYear}-W${currentWeekOfYear + 1}`);
    setYear(currentYear);
    setWeek(currentWeekOfYear);
    setSourcingWeek(SourcingWeek);
    // getCostTarget(SourcingWeek);
    getCurrency(SourcingWeek);
  }, []);

  useEffect(() => {
    let currentPriceList = {};
    let retrievedPriceList = {};

    for (let item of costTargetPriceList) {
      currentPriceList[item.weight_class] = isNaN(parseFloat(item.price_nok))
        ? 0
        : parseFloat(item.price_nok);
    }
    for (let item of retrievedCostTargetPrice) {
      retrievedPriceList[item.weight_class] = isNaN(parseFloat(item.price_nok))
        ? 0
        : parseFloat(item.price_nok);
    }

    let checkNotNull = Object.values(currentPriceList).every((o) => o === null);

    let isEqual =
      JSON.stringify(currentPriceList) === JSON.stringify(retrievedPriceList);

    setButtonDisable(isEqual || checkNotNull);
  }, [costTargetPriceList]);

  useEffect(() => {
    if (sourcing_week !== "") {
      getHistory();
    }
  }, [sourcing_week]);

  const getCostTarget = async (week, packing_week) => {
    setSourcingWeek(week);
    // getHistory();

    const getCostTargetPriceLine = await CommonServices.getCostTargetPriceLine(
      week,
      "purchaser"
    );

    if (getCostTargetPriceLine.data) {
      const { data } = getCostTargetPriceLine;
      setCostTargetPriceList(data.data);
      setRetrievedCostTargetPrice(JSON.parse(JSON.stringify(data.data)));
      setData(data.result);
      let priceList = {};
      // for (let item of data.data) {
      //   priceList[item.weight_class] = item.price_nok;
      // }
      setPrevoiusPriceList(priceList);
      setButtonDisable(true);
      setPackingWeek(data.result.packing_week);
      setSourcing_date(data.result.sourcing_date);
      setPacking_date(data.result.shipment_date);
      setIncotermsChoices(
        getCostTargetPriceLine?.data?.incoterms_choices
          ? getCostTargetPriceLine?.data?.incoterms_choices
          : []
      );
    }
  };

  const getCurrency = async () => {
    const getCurrencyDetail = await CommonServices.getCurrencyDetail();
    if (getCurrencyDetail.data) {
      const { data } = getCurrencyDetail;
      setCurrencies(data.data);
    }
  };
  const getHistory = async () => {
    const getCostTargetPriceHistory =
      await CommonServices.getCostTargetPriceHistory(
        sourcing_week,
        "purchaser"
      );
    if (getCostTargetPriceHistory.data) {
      const { data } = getCostTargetPriceHistory;
      setHistorys(data);
    }
  };

  const ISO8601_week_no = (dt) => {
    var tdt = new Date(dt.valueOf());
    var dayn = (dt.getDay() + 6) % 7;
    tdt.setDate(tdt.getDate() - dayn + 3);
    var firstThursday = tdt.valueOf();
    tdt.setMonth(0, 1);
    if (tdt.getDay() !== 4) {
      tdt.setMonth(0, 1 + ((4 - tdt.getDay() + 7) % 7));
    }
    return 1 + Math.ceil((firstThursday - tdt) / 604800000);
  };

  const updatePriceFieldChanged = (val) => {
    let priceList = costTargetPriceList.map((price) =>
      price.weight_class === val.id
        ? {
            ...price,
            [`price_${val.selectedCurrencyCode}`]: isNaN(val.price)
              ? null
              : val.price,
          }
        : {
            ...price,
            [`price_${val.selectedCurrencyCode}`]: isNaN(
              price?.[`price_${val.selectedCurrencyCode}`]
            )
              ? null
              : price?.[`price_${val.selectedCurrencyCode}`],
          }
    );
    setCostTargetPriceList(priceList);
  };
  const updateCostTarget = async (
    incoterms,
    currency,
    delivery,
    sourcingWeek,
    packingWeek
  ) => {
    if (!buttonDisable) {
      const defaultCurrency = costTargetPriceList?.[0]?.currency
        ? costTargetPriceList?.[0]?.currency.toLowerCase()
        : "NOK";
      let priceList = {};
      for (let item of costTargetPriceList) {
        priceList[item.weight_class] = Number(
          item?.[`price_${defaultCurrency}`.toLowerCase()]
        );
      }

      // let sourcingWeekNo = null;
      // let packingWeekNo = null;
      // if (moment().isoWeek() < 9) {
      //   sourcingWeekNo = `0${moment().isoWeek()}`;
      //   packingWeekNo = `0${moment().add("1", "weeks").isoWeek()}`;
      // } else {
      //   sourcingWeekNo = moment().isoWeek();
      //   packingWeekNo = moment().add("1", "weeks").isoWeek();
      // }

      // let sourceWeek = `${moment().isoWeekYear()}-W${sourcingWeekNo}`;
      // let packWeek = `${moment().isoWeekYear()}-W${packingWeekNo}`;

      const params = {
        incoterms: incoterms,
        currency: currency,
        delivery: delivery,
        sourcing_week: data.sourcing_week,
        packing_week: data.packing_week,
        prices: priceList,
      };
      console.log("update data", params);
      const updateCostTargetPriceLine =
        await CommonServices.updateCostTargetPriceLine(params, "purchaser");
      if (updateCostTargetPriceLine?.data) {
        setButtonDisable(true);
        getCostTarget(sourcingWeek);
        getHistory();
      }
    }
  };
  const updateButtonDisable = (val) => {
    let currentPriceList = {};
    let retrievedPriceList = {};
    for (let item of costTargetPriceList) {
      currentPriceList[item.weight_class] =
        item.price_nok === "0.00" ? null : item.price_nok;
    }
    for (let item of retrievedCostTargetPrice) {
      retrievedPriceList[item.weight_class] =
        item.price_nok === "0.00" ? null : item.price_nok;
    }
    let checkNotNull = Object.values(currentPriceList).every((o) => o === null);

    let isEqual =
      JSON.stringify(currentPriceList) === JSON.stringify(retrievedPriceList);
    setButtonDisable(isEqual || checkNotNull);
  };

  const getNotSelectedData = () => {
    if (costTargetPriceList?.[0]?.currency) {
      return [
        ...[
          ...currencies.map((currency) =>
            currency?.currency_code.toLowerCase()
          ),
        ].filter(
          (item) => costTargetPriceList?.[0]?.currency.toLowerCase() !== item
        ),
      ];
    } else {
      return ["EUR", "USD"];
    }
  };

  return (
    <>
      <section className={"sell_body_wrap packing_wrapper"}>
        {/* <Sidebar /> */}

        <div>
          <div class="scrl_out">
            <section className={"cmn_head"}>
              <p>Sourcing Cost Target</p>
            </section>
            {data && costTargetPriceList && (
              <Header
                currencies={currencies}
                sourcing_week={sourcing_week}
                packing_week={packing_week}
                week={week}
                year={year}
                data={data}
                buttonDisable={buttonDisable}
                getCostTarget={getCostTarget}
                updateCostTarget={updateCostTarget}
                updateButtonDisable={updateButtonDisable}
                previousPriceList={previousPriceList}
                dates={{
                  sourcing: sourcing_date,
                  packing: packing_date,
                }}
                incotermsChoicesData={incotermsChoices}
              />
            )}
            {sourcing_week == "" ? (
              <p style={{ width: 748, marginTop: 0 }} className="no-data">
                No week is selected
              </p>
            ) : (
              costTargetPriceList &&
              currencies && (
                <PriceLine
                  priceList={costTargetPriceList}
                  enableUpdate={true}
                  showPricesCol={true}
                  onUpdate={updatePriceFieldChanged}
                  history={historys}
                  week={week}
                  year={year}
                  selectedCurrencyCode={
                    costTargetPriceList?.[0]?.currency
                      ? costTargetPriceList?.[0]?.currency.toLowerCase()
                      : "NOK"
                  }
                  notSelectedCurrencyCode={getNotSelectedData()}
                />
              )
            )}
          </div>
        </div>
      </section>
    </>
  );
};

export default CostTarget;
