import React from 'react'

/**
* @author
* @function SplitSelector
**/

const SplitSelector = ({ checked,handleChange,disabled }) => {
    return (
        <div className="dimension-selector">
             <span>
                <input
                    type='checkbox'
                    onChange={handleChange}
                    checked={checked}
                    id='splitSelector'
                    disabled={disabled}
                />
                <label for='split'>Split</label>
            </span>
        </div>
    )
}
export default SplitSelector