import React from 'react'
import '../styles/createPreprospect.css'
import {useHistory} from 'react-router-dom'
import CommonServices from '../api/CommonServices'
import { ToastsStore } from 'react-toasts'

function CreatePreprospect() {

    const [customerAbbr, setCustomerAbbr] = React.useState('')
    const [prospectShort, setProspectShort] = React.useState('')
    const [prospect, setProspect] = React.useState('')
    const [address, setAddress] = React.useState('')
    const [email, setEmail] = React.useState('')

    const history = useHistory()

    const [seafoodCountry, setSeafoodCountry] = React.useState([])
    const [selectedSC, setSelectedSC] = React.useState('')
    const [seafoodAirport, setSeafoodAirport] = React.useState([])
    const [selectedAirport, setSelectedAirport] = React.useState('')
    const [targetProduct, setTargetProduct] = React.useState([])
    const [selectedProduct, setSelectedProduct] = React.useState('')
    const [currency, setCurrency] = React.useState([])
    const [selectedCurrency, setSelectedCurrency] = React.useState('')

    const fetchSeafoodCountry = async() => {
      try {
        const getSC = await CommonServices.getSeafoodCountries();
        if(getSC.data){
          const {success} = getSC.data;
          success && setSeafoodCountry(getSC?.data?.results)
        }
      } catch (err) {
        setSeafoodCountry([])
      }
    }

    const fetchAirport = async (value) =>{
      try {
        const getAirport = await CommonServices.getAirport(value)
        if(getAirport.data){
          setSeafoodAirport(getAirport?.data)
        }
      } catch (err) {
        setSeafoodAirport([])
      }
    }

    const fetchTargetProduct = async() =>{
      try {
        const getProduct = await CommonServices.getTargetProduct();
        if(getProduct.data){
          const {success} = getProduct.data
          success && setTargetProduct(getProduct?.data?.result)
        }
      } catch (err) {
        setTargetProduct([])
      }
    }

    const fetchCurrency = async() =>{
      try {
        const getCurrency = await CommonServices.getCurrency();
        if(getCurrency.data){
          const {success} = getCurrency.data
          success && setCurrency(getCurrency.data?.data)
        }
      } catch (err) {
        setCurrency([])
      }
    }

    React.useEffect(() => {
      fetchSeafoodCountry()
      fetchTargetProduct()
      fetchCurrency()
    }, [])

    const handleChangeSC = (e) =>{
      selectedAirport!=='' && setSelectedAirport('')
      fetchAirport(e.target.value)
      setSelectedSC(e.target.value)
    }

    const handleChangeAirport = (e) =>{
      setSelectedAirport(e.target.value)
    }

    const handleChangeProduct = (e) =>{
      setSelectedProduct(e.target.value)
    }

    const handleChangeCurrency = (e) =>{
      setSelectedCurrency(e.target.value)
    }

    const validateEmail = (email) => {
      return String(email)
        .toLowerCase()
        .match(
          /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/
        );
    };

    // const specialCharCheck =(str) =>{
    //   var a = /^[a-zA-Z]+$/
    //   return a.test(str)
    // }

    const createPreprospect = async() =>{
      try {
        if(customerAbbr==='' || prospect===''|| prospectShort==='' || selectedSC==='' || selectedAirport===''||selectedCurrency===''||address==='' || email==='' || selectedProduct==='' || customerAbbr===null || prospect===null|| prospectShort===null || selectedSC===null || selectedAirport===null||selectedCurrency===null || email===null || selectedProduct===null){
          ToastsStore.warning('Fill out every details.')
          return;
        }
        if(customerAbbr.length>3){
          ToastsStore.warning('Customer Abbreviation cannot be more than 3 Characters');
          return;
        }

        if(!validateEmail(email)){
          ToastsStore.warning('give a valid email.')
          return
        }

        if(prospect.length>50 || prospectShort.length>50){
          ToastsStore.warning('Name cannot be more than 50 characters.')
          return
        }

        if(email.length>35){
          ToastsStore.warning('Email is too long. give another email')
          return
        }

        // if(!specialCharCheck(prospect) || !specialCharCheck(prospectShort)){
        //   ToastsStore.warning('Name cannot contain any special characters or numbers.')
        //   return
        // }


        let bodyData = {
          "cabbrv" : customerAbbr,
          "prospect":prospect, 
          "prospect_short":prospectShort, 
          "seafood_country":selectedSC, 
          "seafood_airport":selectedAirport,
          "currency":selectedCurrency,
          "invoice_address":address,
          "email":email,
          "target_product":selectedProduct
          }
        const postData = await CommonServices.postPreProspect(bodyData);
        if(postData.data){
          ToastsStore.success('A Pre Prospect is Created.')
          setTimeout(() => {
            history.replace('/business')
          }, 1000);
        }
        if(postData.error){
          const {response} = postData.error
          if(response.status === 412){
            Object.values(response.data.errors).forEach(item=>{
              ToastsStore.error(item)
            })
          }
          else{
            ToastsStore.error('An Error Occurred, Try Again.')
          }
        }
      } catch (err) {
        ToastsStore.error('An Error Occurred, Try Again.')
      }
    }

    return (
        <section className="body_wrap">
          <div className={'cont_wp'}>
            <div className={'scrl_out'}>
              <section>
              <p className={'Back_link'} onClick={()=>history.goBack()}>
							Back
              </p>
              <p className='cp_header'>Add Pre prospect</p>
              </section>
              <form>
                  <div className='edit-wrapper'>
                    <div className='basic_address_wrapper'>
                      <div className='cp_input_wrap' style={{display:'grid',gridTemplateColumns:'1fr 3fr',gap:20}}>
                        <div>
                          <label className='cp_input_header'>Cabbrv</label>
                          <input type='text' className='cp_input_field' value={customerAbbr} onChange={(e)=>setCustomerAbbr(e.target.value)}/>
                          <label className='cp_short_label'>Customer abbreviation</label>
                        </div>
                        <div>
                          <label className='cp_input_header'>Prospect Short</label>
                          <input type='text' className='cp_input_field' value={prospectShort} onChange={(e)=>setProspectShort(e.target.value)}/>
                          <label className='cp_short_label'>The commonly used (shortened) name or acronym of the prospect</label>
                        </div>
                      </div>
                      <div className='cp_input_wrap'>
                        <label className='cp_input_header'>Prospect</label>
                        <input type='text' className='cp_input_field' value={prospect} onChange={(e)=>setProspect(e.target.value)}/>
                        <label className='cp_short_label'>The full official name of the prospect , including legal entity type</label>
                      </div>
                      <div className='cp_input_wrap'>
                        <label className='cp_input_header'>Address</label>
                        <textarea rows={10} type='text' className='cp_input_field cp_text_area' value={address} onChange={(e)=>setAddress(e.target.value)} />
                      </div>
                      <div>
                      <div className='cp_input_wrap'>
                        <label className='cp_input_header'>Email ID</label>
                        <input type='email' className='cp_input_field' value={email} onChange={(e)=>setEmail(e.target.value)}/>
                      </div>
                      </div>

                    </div>
                    <div className='dynamic_address_wrapper'>
                      <div className='cp_input_wrap cp_select_block'>
                        <label className='cp_input_header'>Seafood Country</label>
                        
                        <select value={selectedSC} onChange={handleChangeSC}>
                          <option value='' disabled>Select Country</option>
                          {seafoodCountry.map((item,index)=><option key={index} value={item.id}>{item?.country?.country}</option>)}
                        </select>
                      </div>
                      {selectedSC && <div className='cp_input_wrap cp_select_block'>
                        <label className='cp_input_header'>Seafood Airport</label>
                        <select value={selectedAirport} onChange={handleChangeAirport}>
                          <option value='' disabled>Select Airport</option>
                          {seafoodAirport.map((item,index)=><option key={index} value={item.iata_code}>{item.airport}({item.iata_code})</option>)}
                        </select>
                      </div>}
                      <div className='cp_input_wrap cp_select_block'>
                        <label className='cp_input_header'>Target Product</label>
                        <select value={selectedProduct} onChange={handleChangeProduct}>
                          <option value='' disabled>Select Product</option>
                          {targetProduct.map((item,index)=><option key={index} value={item.id}>{item.product_string}</option>)}
                        </select>
                      </div>
                      <div className='cp_input_wrap cp_select_block'>
                        <label className='cp_input_header'>Currency</label>
                        <select value={selectedCurrency} onChange={handleChangeCurrency}>
                          <option value='' disabled>Select Currency</option>
                          {currency.map((item,index)=><option key={index} value={item.currency_code}>{item.currency_code}</option>)}
                        </select>
                      </div>
                    </div>
                  </div>
              </form>
              <section>
                <div className='cp_button_container'>
                  <button className='cp_primary_btn' onClick={(e)=>{
                    e.preventDefault()
                    createPreprospect()
                  }}>Create</button>
                  <button className='cp_cancel_btn' onClick={(e)=>{
                    e.preventDefault()
                    history.goBack()
                  }}>Cancel</button>
                </div>
              </section>
            </div>
          </div>
        </section>
    )
}

export default CreatePreprospect
