import React from "react";
import { Route, Switch, Redirect } from "react-router-dom";
import Auth from "./containers/Auth/Auth";
import ForgotPassword from "./containers/Auth/ForgotPassword/ForgotPassword";
import { PrivateRoute } from "./components/Route/PrivateRoute";
import RoleManagement from "./settings/roleManagement";
import NotAuthorized from "./pages/NotAuthorized";
import Layout from "./components/Layouts/Layout";
const getAllRoutes = (routeArray = [], outputRoutes = []) => {
  routeArray.forEach((d) => {
    const newData = { ...d };
    if (newData.children && newData.children.length) {
      getAllRoutes(newData.children, outputRoutes);
    }
    delete newData.children;
    if (
      newData.path &&
      newData.component &&
      !outputRoutes.find((fe) => fe.path === newData.path)
    )
      outputRoutes.push(newData);
  });
  return outputRoutes;
};
const allPrivateRoutes = getAllRoutes(RoleManagement);
function App() {
  return (
    <>
      <Switch>
        {allPrivateRoutes.map((each) => {
          return (
            <PrivateRoute
              path={each.path}
              exact
              component={each.component}
              withOutLayout={each.withOutLayout || false}
              roles={each.roles || []}
            />
          );
        })}
        <Route
          path="/login"
          exact
          render={({ location }) => {
            let auth = localStorage.getItem("token");
            return auth ? <Redirect to="/home" /> : <Auth />;
          }}
        />
        <Route path="/forgot-password" component={ForgotPassword} />
        <Route path="/access-denied" exact>
          <Layout>
            <NotAuthorized />
          </Layout>{" "}
        </Route>
        /access-denied
        <Redirect from="*" to="/login" />
      </Switch>
    </>
  );
}
export default App;
