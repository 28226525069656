import React,{useEffect} from "react";
import { Link, useParams } from "react-router-dom";
import "./ShipmentTracker.css";
import {ReactComponent as CheckSVG} from "../../../assets/images/check-green.svg";
import {ReactComponent as SubMilestoneSVG} from '../../../assets/images/submilestone.svg'
import {ReactComponent as SubMilestoneDisabledSVG} from '../../../assets/images/submilestone-grey.svg'
import PDFSVG from "../../../assets/images/pdf.svg";
import DownloadSVG from "../../../assets/images/download.svg";
import TicketSVG from "../../../assets/images/ticket.svg";
import PackingSVG from "../../../assets/images/packing.svg";
import TruckSVG from "../../../assets/images/truck.svg";
import AirportSVG from "../../../assets/images/airport.svg";
import FlightSVG from "../../../assets/images/flight.svg";
import {ReactComponent as FlightLandSVG} from '../../../assets/images/flight-landing.svg'
import {ReactComponent as FlightTakeOffSVG} from '../../../assets/images/flight-takeoff.svg'
import TriangleSVG from '../../../assets/images/red-triangle.svg';
import TimelineServices from "../../../api/TimelineServices";


const ShipmentTracker = () => {

  const { id } = useParams();
  const [trianglePoint,setTrianglePoint] = React.useState(1)
  const [error, setError] = React.useState(false)
  const [timelineData, setTimelineData] = React.useState({
    timeline:[],
    flight_ticket_upload:false,
  })

  useEffect(() => {
    console.log('id', id)
    fetchTracker()
  }, [])

  const fetchTracker = async () => {
    try {
      const results = await TimelineServices.getShipmentTracker(id);
      if(results.error){
        console.log(results.error)
        setError(true)
        return;
      }
      if (results.data) {
        const { data } = results;
        console.log('shipment response',data)
        setError(false)
        setTrianglePoint(data?.result?.triangle_item)
        setTimelineData(data?.result)
      }

    } catch (error) {
      setError(true)
      console.log(error)
    }
  }
  

  // const DummyData = [
  //   {
  //     counter: 1,
  //     time: "18:30",
  //     date: "Wed 25 May",
  //     description: "Order Confirmed",
  //     status: "past",
  //     has_pdf: false,
  //     milestone_level: 1,
  //     plate_present: false,
  //   },
  //   {
  //     counter: 2,
  //     time: "19:30",
  //     date: "Wed 25 May",
  //     description: "Flight Ticket Confirmed",
  //     status: "past",
  //     has_pdf: true,
  //     milestone_level: 1,
  //     plate_present: false,
  //   },
  //   {
  //     counter: 3,
  //     time: "20:30",
  //     date: "Wed 25 May",
  //     description: "Packing Started",
  //     status: "future",
  //     has_pdf: false,
  //     milestone_level: 2,
  //     plate_present: true,
  //   },
  //   {
  //     counter: 4,
  //     time: "21:30",
  //     date: "Wed 25 May",
  //     description: "Packing list & Invoice(Packing Completed)",
  //     status: "past",
  //     has_pdf: true,
  //     milestone_level: 1,
  //     plate_present: false,
  //   },
  //   {
  //     counter: 5,
  //     time: "22:30",
  //     date: "Wed 25 May",
  //     description: "Truck departure from packing station",
  //     status: "past",
  //     has_pdf: false,
  //     milestone_level: 2,
  //     plate_present: true,
  //   },
  //   {
  //     counter: 6,
  //     time: "23:30",
  //     date: "Wed 25 May",
  //     description: "Shipment documents",
  //     status: "past",
  //     has_pdf: true,
  //     milestone_level: 1,
  //     plate_present: false,
  //   },
  //   {
  //     counter: 7,
  //     time: "2:30",
  //     date: "Wed 26 May",
  //     description: "Truck Arrival at the airport cargo terminal",
  //     status: "past",
  //     has_pdf: false,
  //     milestone_level: 1,
  //     plate_present: true,
  //   },
  //   {
  //     counter: 8,
  //     time: "4:30",
  //     date: "Wed 26 May",
  //     description: "Flight Takeoff",
  //     status: "future",
  //     has_pdf: false,
  //     milestone_level: 1,
  //     plate_present: true,
  //   },
  //   {
  //     counter: 9,
  //     time: "6:30",
  //     date: "Wed 26 May",
  //     description: "Connecting Arrival",
  //     status: "future",
  //     has_pdf: false,
  //     milestone_level: 2,
  //     plate_present: false,
  //   },
  //   {
  //     counter: 10,
  //     time: "8:30",
  //     date: "Wed 26 May",
  //     description: "Connecting Departure",
  //     status: "future",
  //     has_pdf: false,
  //     milestone_level: 2,
  //     plate_present: false,
  //   },
  //   {
  //     counter: 11,
  //     time: "10:30",
  //     date: "Wed 26 May",
  //     description: "Flight Landed",
  //     status: "future",
  //     has_pdf: false,
  //     milestone_level: 1,
  //     plate_present: true,
  //   },
  //   {
  //     counter: 12,
  //     time: "12:30",
  //     date: "Wed 26 May",
  //     description: "Ready for Clearance",
  //     status: "future",
  //     has_pdf: false,
  //     milestone_level: 1,
  //     plate_present: true,
  //   },
  // ];

  return (
    <section className="sell_body_wrap">
      <div style={{ paddingBottom: 30 }} className="purchase_details_fc">
        <div className="scrl_out" style={{ paddingBottom: "30px" }}>
          <Link to={"/home/shipment-list"}>
            <p className={"Back_link"}>Back</p>
          </Link>
        </div>
      </div>
      <div className="shipment_container">
        {error ? <div className="error_msg">Something went wrong, Try Later.</div>:
        timelineData.timeline.length>0 ?timelineData?.timeline.map((item, index, arr) => {
        // DummyData.map((item, index, arr) => { // USE THIS LINE FOR DUMMY DATA
          let dateElement = <div className="timeline_date">{item.date}</div>;

          var overallStyles={
            descriptionStyle:{
              margin:0,
              color:'#000',
              fontWeight:400,
              fontSize:16,
            },
            image:<CheckSVG fill='#007381' stroke="#007381"/>,
          }

          var icontoUse = null
          var iconClasstoUse = ''

          var fligntIMGtoUse = item.description.toLowerCase().search('flight')>-1?'':null

          switch (item.milestone_level) {
            case 1:
              if(item.status==='future'){
                overallStyles = {
                  ...overallStyles,
                  descriptionStyle:{
                    ...overallStyles.descriptionStyle,
                    color:'#979797',
                    fontWeight:600,
                  },
                  image:<CheckSVG fill='#979797'/>,
                }
                break;
              }
              overallStyles = {
                ...overallStyles,
                descriptionStyle:{
                  ...overallStyles.descriptionStyle,
                  color:'#007381',
                  fontWeight:600,
                },
                image:<CheckSVG fill='#007381' />,
              }
              break;
            case 2:
              if(item.status==='future'){
                overallStyles = {
                  ...overallStyles,
                  descriptionStyle:{
                    ...overallStyles.descriptionStyle,
                    color:'#979797',
                    fontSize:14,
                  },
                  image:<SubMilestoneDisabledSVG/>,
                }
                break;
              }
              overallStyles = {
                ...overallStyles,
                descriptionStyle:{
                  ...overallStyles.descriptionStyle,
                  color:'#979797',
                  fontSize:14,
                },
                image:<SubMilestoneSVG />,
              }
              break;
            default:
              break;
          }
          if(!timelineData.flight_ticket_upload){
            switch (index) {
              case 1:
                icontoUse = TicketSVG
                break;
              case 3:
                icontoUse = PackingSVG
                break;
              case 5:
                icontoUse = TruckSVG
                break;
              case 6:
                icontoUse = AirportSVG
                break;
              case 7:
                icontoUse = FlightSVG
                break;
              case 8:
                icontoUse = AirportSVG;
                break;
              default:
                icontoUse = FlightSVG;
                break;
            }
            iconClasstoUse=index
          }
          else{
            switch (index) {
              case 2:
                icontoUse = TicketSVG
                break;
              case 4:
                icontoUse = PackingSVG
                break;
              case 6:
                icontoUse = TruckSVG
                break;
              case 7:
                icontoUse = AirportSVG
                break;
              case 10:
                icontoUse = FlightSVG
                break;
              case 11:
                icontoUse = AirportSVG;
                break;
              default:
                icontoUse = FlightSVG;
                break;
            }
            iconClasstoUse=index+String(index)
          }

          switch (item?.type) {
            case 'Flight arrival':
              fligntIMGtoUse = <FlightLandSVG />
              break;
            case "Flight Takeoff":
              fligntIMGtoUse = <FlightTakeOffSVG />
              break;
            default:
              break;
          }

          let TriangleElement = trianglePoint=== item.counter && <img  src={TriangleSVG} alt='triangle' className="timeline_triangle"/>

          return (
            <div className="timeline_wrap" key={index}>
              {index === 0 ? (
                dateElement
              ) : arr[index]?.date !== arr[index - 1]?.date ? (
                <div className="timeline_date_wrap"><div className="timeline_date">{item.date}</div>
                
                {TriangleElement}
                </div>
              ) : (
                <div className="timeline_date_wrap"><div className="timeline_date" />
                {TriangleElement}</div>
              )}
              <div className="timeline_section_wrap">
                <div className="timeline_milestone" aria-label={item.milestone_level}>
                  <span>{item.time}</span>
                  {/* <img src={CheckSVG} alt="check" /> */}
                  {overallStyles.image}
                </div>
                <div
                  className={`timeline_description_wrap ${
                    item.plate_present ? "timeline_plate" : ""
                  }`}
                >
                  {icontoUse!==null && item.plate_present && (
                    <span
                      className={`timeline_section_icon_wrap icon-${iconClasstoUse}`}
                    >
                      <img
                        src={icontoUse}
                        alt="icon-timeline"
                        className="timeline_section_icon"
                      />
                    </span>
                  )}
                  <p style={overallStyles.descriptionStyle}>{item.description} &nbsp;{fligntIMGtoUse}</p>
                  {item.has_pdf && (
                    <a href={`https://dev.exocoetidae.no/${item.pdf}`} target="_blank" rel="noopener noreferrer"><span className="timeline_pdf">
                      <img
                        src={PDFSVG}
                        alt="pdf"
                        className="timeline_pdf_icon"
                      />
                      DummyPDF
                      <img
                        src={DownloadSVG}
                        alt="download"
                        className="timeline_pdf_icon"
                      />
                    </span></a>
                  )}
                </div>
              </div>
            </div>
          );
        }):<p>No Timeline to Show.</p>}
      </div>
    </section>
  );
};

export default ShipmentTracker;
