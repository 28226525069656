import React, { useState, useEffect, useReducer } from 'react';
import axios from 'axios';
import { Link } from 'react-router-dom';
import NQSalmonCard from '../components/Nasdaq/NQSalmonCard';
import NQSalmonTable from '../components/Nasdaq/NQSalmonTable';
import Sidebar from '../components/Sidebar/Sidebar';
import HistorySelector from '../components/Home/Nasdaq/HistorySelector';
import NasdaqHistory from '../components/Home/Nasdaq/NasdaqHistory';
import NasdaqServices from '../api/NasdaqServices';
import { findRemainingNasdaqTimer } from '../util';

const reducer = (state, action) => {
	switch (action.type) {
		case 'initiateNQSPage':
			return {
				...state,
				selector_title: action.payload.selector_title,
				first_allowed_week: action.payload.first_allowed_week,
				last_allowed_week: action.payload.last_allowed_week,
				last_selector_week: action.payload.last_selector_week,
				week_limit: action.payload.limit,
				default_selected_week: action.payload.default_selected_week,
				default_disabled_weeks: action.payload.default_disabled_weeks,
				style: action.payload.style,
				selected_week: action.payload.default_selected_week,
				published_date: action.payload.last_publication_date,
			};
		case 'setWeek':
			return {
				...state,
				selected_week: action.payload.selected_week,
			};
		case 'setLastWeek':
			return {
				...state,
				last_selector_week: action.payload.last_selector_week,
				selected_week: '',
			};
		case 'setPublishedDate':
			return {
				...state,
				published_date: action.payload.published_date,
			};
		case 'setSelectedHistoryWeek':
			return {
				...state,
				selected_history_week: action.payload.selected_history_week,
			};
		case 'openHistoryModal':
			return {
				...state,
				open_history_modal: action.payload.open_history_modal,
			};
		case 'timeLeft':
			return {
				...state,
				time_left: action.payload,
			};
		default:
			throw new Error("Don't understand action.");
	}
};

const NQSalmonPage = () => {
	const [week_data_stats, setWeekDataStats] = useState([]);

	const [state, dispatch] = useReducer(reducer, {
		selector_title: '',
		last_selector_week: '',
		default_disabled_weeks: [],
		first_allowed_week: '',
		last_nasdaq_week: '',
		last_allowed_week: '',
		week_limit: '',
		selected_week: '',
		style: '',
		open_history_modal: false,
		selected_history_week: '',
		time_left: '',
		published_date: '',
		enable_selector_toggle: false,
	});

	useEffect(() => {
		initiateNQSPage({});
	}, []);
	useEffect(() => {
		if(state.last_selector_week && state.week_limit)
		initiateDataStats({
			last_week: state.last_selector_week,
			limit: state.week_limit,
		});
	}, [state.last_selector_week,state.week_limit]);

	const initiateDataStats = async (params) => {
		try {
			const getNasdaqDataStats = await NasdaqServices.getDataStats(params);

			if (getNasdaqDataStats.data) {
				const { data } = getNasdaqDataStats;

				setWeekDataStats(data.result);
			}
		} catch (err) {
			console.log('GET Nasdaq Data Stats ERROR', err);
		}
	};

	const initiateNQSPage = async (params) => {
		try {
			const getNasdaqIndex = await NasdaqServices.initiateNasdaqIndex();

			if (getNasdaqIndex.data) {
				const { data } = getNasdaqIndex;

				dispatch({
					type: 'initiateNQSPage',
					payload: data.result,
				});
				setInterval(() => enableTimer(data.result.next_available_date), 1000);
			}
		} catch (err) {
			console.log('Initiate Nasdaq Index ERROR', err);
		}
	};
	// time left
	const enableTimer = (next_available_date) => {
		let getAvailableTime = findRemainingNasdaqTimer(next_available_date);

		dispatch({
			type: 'timeLeft',
			payload: getAvailableTime,
		});
	};

	const setWeekDataStatsList = (stat_list) => {
		setWeekDataStats(stat_list);
	};

	const handleWeekClick = (e) => {
		dispatch({
			type: 'setWeek',
			payload: { selected_week: e.target.value },
		});
	};

	const onBFsClick = (last_selector_week) => {
		dispatch({
			type: 'setLastWeek',
			payload: {
				last_selector_week: last_selector_week,
			},
		});
		console.log(state.default_disabled_weeks);
	};
	// updating published date
	const setPublishedDate = (published_date) => {
		dispatch({
			type: 'setPublishedDate',
			payload: {
				published_date: published_date,
			},
		});
	};

	const handleHistoryWeekClick = (selected_history_week) => {
		dispatch({
			type: 'setSelectedHistoryWeek',
			payload: {
				selected_history_week: selected_history_week,
			},
		});
		dispatch({
			type: 'openHistoryModal',
			payload: {
				open_history_modal: true,
			},
		});
	};
	const closeHistoryModal = () => {
		dispatch({
			type: 'openHistoryModal',
			payload: {
				open_history_modal: false,
			},
		});
	};

	console.log( week_data_stats, 'week_data_status')
	return (
		<section className={'body_wrap'}>
			{/* <Sidebar /> */}
			<Link to={'/home'}>
				<p className={'Back_link'}>Back</p>
			</Link>
			<section className={'cmn_head'}>
				<p>Nasdaq Price</p>
			</section>
			<div className={'cont_wp'}>
				{(week_data_stats&& week_data_stats.length) > 0 ? (
					<>
						<div className={'ct_top nasdaq_header'}>
							<NQSalmonCard
								// WeekSelector
								selector_title={state.selector_title}
								last_selector_week={state.last_selector_week}
								first_allowed_week={state.first_allowed_week}
								last_allowed_week={state.last_allowed_week}
								selected_week={state.selected_week}
								default_selected_week={state.default_selected_week}
								default_disabled_weeks={state.default_disabled_weeks}
								week_limit={state.week_limit}
								week_data_stats={week_data_stats}
								style={state.style}
								onWeekClick={handleWeekClick}
								onBFsClick={onBFsClick}
								time_left={state.time_left}
								published_date={state.published_date}
								enable_selector_toggle={state.enable_selector_toggle}
							/>
							<div className='nasdaq_week history'>
								<p>History</p>
								<HistorySelector
									weeks={[5, 10]}
									onHistoryWeekClick={handleHistoryWeekClick}
								/>
							</div>
						</div>
						<NQSalmonTable
							// API data - fetching more than one week!
							last_selector_week={state.last_selector_week}
							week_limit={state.week_limit}
							selected_week={state.selected_week}
							setWeekDataStats={setWeekDataStatsList}
							setPublishedDate={setPublishedDate}
						/>
					</>
				) : (
					<div></div>
				)}
			</div>
			{state.open_history_modal && (
				<NasdaqHistory
					limit={state.selected_history_week}
					closeParentModal={closeHistoryModal}
				/>
			)}
		</section>
	);
};

export default NQSalmonPage;
