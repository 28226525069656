import React from "react";
import "./Dropdown.css";

function DropdownButton({
  Customstyles = {},
  placeholder = "Select",
  optionData = [],
  selectedOption = "",
  onChangeOption = () => {},
}) {
  return (
    <div>
      <select
        className="dropdown_container"
        style={{ ...Customstyles }}
        value={selectedOption}
        onChange={(e) => onChangeOption(e)}
      >
        <option value="" disabled>
          {placeholder}
        </option>
        {optionData.length > 0 ? (
          optionData.map((item, index) => {
            return (
              <option key={index} value={item.id}>
                {item.value}
              </option>
            );
          })
        ) : (
          <></>
        )}
      </select>
      {selectedOption !== "" && (
        <span
          style={{
            display: "flex",
            fontSize: 12,
            color: "#002376",
            justifyContent: "flex-end",
            cursor: "pointer",
          }}
          onClick={(e) => {
            e.preventDefault();
            onChangeOption(null);
          }}
        >
          clear filter
        </span>
      )}
    </div>
  );
}

export default DropdownButton;
