import React, { useState, useEffect } from 'react';
import axios from 'axios';
import { FaFilePdf } from 'react-icons/fa';
import CommonServices from '../../api/CommonServices';

const SeafoodSpecs = () => {
	const [seafood_specs, setSeafoodSpecs] = useState([]);

	useEffect(() => {
		getSpecsList({});
	}, []);

	const getSpecsList = async (params) => {
		try {
			const ProductSpec = await CommonServices.getProductSpecification(params);

			if (ProductSpec.data) {
				const { data } = ProductSpec;
				setSeafoodSpecs(data.result);
			}
		} catch (err) {
			console.log('ERROR Product Specification', err);
		}
	};

	return (
		<div>
			<ul>
				{seafood_specs.map((spec) => (
					<li id={spec.id}>
						{spec.product.product_string} — {spec.trim.trim}
						{spec.seafood_sheet ? (
							<a href={spec.seafood_sheet} target='_blank'>
								<FaFilePdf
									size={16}
									style={{
										marginLeft: '10px',
									}}
									color={'grey'}
								/>
							</a>
						) : (
							''
						)}
					</li>
				))}
			</ul>
		</div>
	);
};

export default SeafoodSpecs;
