import React, { Component } from 'react';
import Sidebar from '../Sidebar/Sidebar';
import axios from 'axios';
import className from './Users.css';
import CommonServices from '../../api/CommonServices';

class Users extends Component {
	constructor() {
		super();
		this.state = {
			userList: [],
			customerLocations: [],
			showAddUser: false,
			first_name: '',
			last_name: '',
			email: '',
			short_name: '',
			// type: '',
			type: '2',
			password: '',
			customer_shipment_locations: [],
			shipmentLocation: [],
		};
		this.orderParams = {
			page_size: 8,
		};
	}
	componentDidMount() {
		this.getUsers();
		this.getShipmentLocation();
	}

	async getUsers() {
		try {
			const CustomerUsers = await CommonServices.getCustomerUsers(
				this.orderParams
			);

			if (CustomerUsers.data) {
				console.log('Customer Users', CustomerUsers.data);
				const { data } = CustomerUsers;
				this.setState({
					userList: data.results,
				});
			}
		} catch (err) {
			console.log('GET Customer Users ERROR', err);
		}

		// const headers = {
		//     'Content-Type': 'application/json',
		//     'Authorization': `Bearer ${BrowserStorage.getValue('token')}`
		// }
		// let webApiUrl = 'customer/api/customer-user/'
		// axios.get(webApiUrl, {
		//     params: this.orderParams,
		//     headers: headers
		// })
		//     .then(response => {
		//         console.log("ress",response.data)

		//     })
		//     .catch((error) => {
		//         console.log(error)
		//     })
	}

	showPopup() {
		this.setState({ showAddUser: true });
	}

	hidePopup() {
		this.setState({ showAddUser: false });
	}

	async addUser() {
		if (
			!this.state.first_name ||
			!this.state.last_name ||
			!this.state.email ||
			!this.state.password
		) {
			return;
		}
		console.log(this.state);
		let data = {
			first_name: this.state.first_name,
			last_name: this.state.last_name,
			name_short: this.state.short_name,
			// "status": "1",
			// "customer": this.state.type,
			status: this.state.type == 2 ? 2 : 1,
			customer:
				this.state.type == 2 ? Number(localStorage.getItem('customer')) : null,
			email: this.state.email,
			password: this.state.password,
			// "customer_shipment_locations": [1, 2]
			customer_shipment_locations: this.state.shipmentLocation,
		};
		// console.log(data);

		try {
			const CustomerUsers = await CommonServices.getCustomerUsers();

			if (CustomerUsers.data) {
				this.getUsers();
				this.hidePopup();
			}
		} catch (err) {
			console.log('GET Customer Users ERROR', err);
		}
	}
	async getShipmentLocation() {
		try {
			const ShipmentLocations = await CommonServices.getShipmentLocation();
			if (ShipmentLocations.data) {
				const { data } = ShipmentLocations;
				console.log('Response Shipment Location', data);
				this.setState({ customerLocations: data.results });
				console.log(this.state.customerLocations);
				this.forceUpdate();
			}
		} catch (err) {
			console.log('GET Shipment Location ERROR', err);
		}
	}

	inputChange(event, type) {
		console.log(event.target.value);
		this.state[type] = event.target.value;
	}

	inputTypeChange = (event) => {
		this.setState({
			type: event.target.value,
		});
	};
	handleLocationChange = (e) => {
		console.log(e.target.value);
		this.setState({
			shipmentLocation: [...this.state.shipmentLocation, e.target.value],
		});
	};

	render() {
		return (
			<>
				<section className={'body_wrap'}>
					{/* <Sidebar /> */}
					<div className={'cont_wp'}>
						<section className={'cmn_head titleSection'}>
							<div className={'titleWrap'}>
								<p>Users</p>
								<label>View all users in your account</label>
							</div>
							<div className={'btnWrap'}>
								<div className={'btn'} onClick={() => this.showPopup()}>
									Add User
								</div>
							</div>
						</section>
						<section className={'OrderTableWrap' + ' ' + 'scrl_out'}>
							{this.state.userList.length != 0 ? (
								<table>
									<tr className={'table_head'}>
										<th>Name</th>
										<th>Email</th>
										<th>Type</th>
										<th>Locations</th>
									</tr>

									<tbody>
										{this.state.userList.map((user, index) => {
											return (
												<tr>
													<td>
														{user.first_name} {user.last_name}
													</td>
													<td>{user.email}</td>
													<td>{user.type == 2 ? 'Admin' : 'User'}</td>
													<td>
														{user.customer_shipment_locations.map((location, i) => {
															return (
																<span className='item'>
																	{location.invoice_address_2 || location.invoice_address_1}
																</span>
															);
														})}
													</td>
												</tr>
											);
										})}
									</tbody>
								</table>
							) : (
								<p>You don't have any users to list</p>
							)}
						</section>
					</div>
				</section>
				{this.state.showAddUser ? (
					<section className={'popupContainer'} onClick={() => this.hidePopup()}>
						<div className={'popupWrap'} onClick={(event) => event.stopPropagation()}>
							<div className={'popHead'}>
								<h3>Add User</h3>
							</div>
							<div className={'popBody'}>
								<div className={'fieldWrap'}>
									<label>First Name</label>
									<input
										type='text'
										onChange={(event) => this.inputChange(event, 'first_name')}
									/>
								</div>
								<div className={'fieldWrap'}>
									<label>Last Name</label>
									<input
										type='text'
										onChange={(event) => this.inputChange(event, 'last_name')}
									/>
								</div>
								<div className={'fieldWrap'}>
									<label>Short Name</label>
									<input
										type='text'
										onChange={(event) => this.inputChange(event, 'short_name')}
									/>
								</div>
								<div className={'fieldWrap'}>
									<label>Email</label>
									<input
										type='text'
										onChange={(event) => this.inputChange(event, 'email')}
									/>
								</div>
								<div className={'fieldWrap'}>
									<label>Account Type</label>
									{/* <select onChange={(event) => this.inputChange(event, 'type')} value={this.state.type}> */}
									<select
										onChange={(event) => this.inputTypeChange(event, 'type')}
										value={this.state.type}
									>
										{' '}
										{/* <option value="1">Admin</option>
                                    <option value="2">User</option> */}
										<option value='2'>Admin</option>
										<option value='1'>User</option>
									</select>
								</div>
								<div className={'fieldWrap'}>
									<label>Password</label>
									<input
										type='password'
										onChange={(event) => this.inputChange(event, 'password')}
									/>
								</div>
								{this.state.type == 2 ? null : (
									<div className={'fieldWrap'}>
										<label>Choose shipment locations</label>
										<div className={'checkWrap'}>
											{/* {this.state.customerLocations.map(location => {
                                        return <div className={'checkbox'}>
                                            <input type="checkbox" name="locations" value={location.pk} id={location.pk} />
                                            <label for={location.pk}>{location.fields.country}</label>
                                        </div>
                                    })} */}

											{this.state.customerLocations.map((location) => {
												return (
													<div className={'checkbox'}>
														<input
															type='checkbox'
															onChange={(e) => {
																this.handleLocationChange({
																	target: {
																		value: location.id,
																	},
																});
															}}
															name='locations'
															value={this.state.shipmentLocation}
														/>
														<label for={location.id}>
															{location.invoice_address_2 || location.invoice_address_1}
														</label>
													</div>
												);
											})}
										</div>
									</div>
								)}
								<div className={'btnWrap'}>
									<div className={'btn'} onClick={() => this.addUser()}>
										Submit
									</div>
								</div>
							</div>
						</div>
					</section>
				) : null}
			</>
		);
	}
}

export default Users;
