import React from "react";
import { Link } from "react-router-dom";
import TimelineServices from "../../../api/TimelineServices";
import "./ShipmentList.css";

function ShipmentList() {
  const [shipmentListing, setShipmentListing] = React.useState([]);

  React.useEffect(() => {
    fetchShipmentList();
  }, []);

  const fetchShipmentList = async () => {
    try {
      var { accessId } = JSON.parse(localStorage.getItem("userInfo"));
      const results = await TimelineServices.getShipmentList(accessId);
      if (results.error) {
        console.log("error", results.error);
        setShipmentListing([]);
        return;
      }
      if (results.data) {
        const { data } = results;
        setShipmentListing(data?.data?.customer_shipments_details);
      }
      // const shipmentList = JSON.parse(localStorage.getItem('shipmentList'))
    } catch (err) {
      console.log("error", err);
      setShipmentListing([]);
    }
  };

  console.log(shipmentListing);

  return (
    <section className="sell_body_wrap">
      <div>
        <div className="scrl_out">
          <Link to={"/home"}>
            <p className={"Back_link"}>Back</p>
          </Link>
          <h1 className="shipment-heading">Shipment List</h1>
        </div>
      </div>
      {shipmentListing.length > 0 ? (
        <div>
          <ol className="shipment_list">
            {shipmentListing.map((item, index) => {
              return (
                <li key={index}>
                  <Link to={`/home/shipment-track/${item?.id ?? 0}`}>
                    <p>{item?.destination_airport_name}</p>
                    <p> {item?.arrival_target}</p>
                    {item?.box_count > 0 && (
                      <p>
                        <b>{item?.box_count} Boxes</b>
                      </p>
                    )}
                  </Link>
                </li>
              );
            })}
          </ol>
        </div>
      ) : (
        <>No Shipments to show.</>
      )}
    </section>
  );
}

export default ShipmentList;
