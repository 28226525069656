import React, { useState, useEffect } from 'react';
import { Link } from 'react-router-dom';
import './DashboardOverview.css';
import QuoteServices from '../../../api/QuoteServices';
import TimelineServices from '../../../api/TimelineServices';
import ActiveQuotes from './ActiveQuotes';
import ActiveShipments from './ActiveShipments';
import ReactTooltip from 'react-tooltip';
import adminIcon from '../../../assets/images/user.png';
import customerIcon from '../../../assets/images/customer.png';
import flightIcon from '../../../assets/images/flight-icon-primary.png';
import LightQuoteIcon from '../../../assets/images/light_quote.svg'
import LightQuoteServices from '../../../api/LightQuoteServices';
import ActiveLightQuote from './ActiveLightQuote';

const DashboardOverview = () => {
	const [activeQuotes, setActiveQuotes] = useState([]);
	const [activeShipments, setActiveShipments] = useState([]);
  const [countLightQuote, setCountLightQuote] = useState(0);
  const [activeLightQuote, setActiveLightQuote] = useState([])


	const fetchActiveShipments = async () => {
		const getShipments = await TimelineServices.getShipments();
		if (getShipments.data) {
			const { data } = getShipments;
			setActiveShipments(data.result || []);
		}
	};

	const fetchActiveOrders = async () => {
		const getActiveQuotes = await QuoteServices.getActiveQuotes();
		if (getActiveQuotes.data) {
			const { data } = getActiveQuotes;
			let newActiveOrders = [...data.result] || [];
			newActiveOrders = newActiveOrders.map((each) => {
				console.log('negotiation', each);

				//ADMIN PENDING CASES
				const isAllFalse = each?.negotiation.every(
					(value) =>
						value.admin_offer === false && value.customer_counteroffer === false
				);
				const AdmintoRespond = each?.negotiation.some(
					(value) =>
						value.admin_offer > value.customer_counteroffer &&
						value.next_move === 'admin'
				);
				if (isAllFalse || AdmintoRespond) {
					each.negotiation_status = 'Admin Pending';
					return each;
				}

				//CUSTOMER PENDING CASES
				const CustomerPriceSended = each?.negotiation.some(
					(value) => value.admin_offer && !value.customer_counteroffer
				);
				const hasVolume = each?.negotiation.some(
					(value) =>
						value.admin_offer === false &&
						value.customer_counteroffer === false &&
						value.has_volume
				);

				if (CustomerPriceSended || hasVolume) {
					each.negotiation_status = 'Customer Pending';
					return each;
				}
				return each;
			});
			setActiveQuotes(
				newActiveOrders.filter((d) =>
					['Admin Pending', 'Customer Pending'].includes(d.negotiation_status)
				) || []
			);
		}
	};


	const fetchLightQuote = async() =>{
    const getLightQuoteList = await LightQuoteServices.getLightQuoteList()
    if(getLightQuoteList.data){
      console.log('get Light Quote',getLightQuoteList.data)
      setActiveLightQuote(getLightQuoteList.data.result)
	  setCountLightQuote(getLightQuoteList.data.count)
    }
	}

	useEffect(() => {
		fetchActiveOrders();
		fetchActiveShipments();
		fetchLightQuote();
	}, []);

	return (
		<>
			<div className={'cont_wp'}>
				<section className={'cmn_head'}>
					<p>Midstream Dashboard</p>
					{/* <label>Create New Orders & Modify base orders here</label> */}
				</section>
				<div className={'scrl_out'}>
					<section className={'home_top_wrap'}>
						<div className={'home_top_block'}>
							<div className={'home_blk_stat_wrap'}>
								<div className={'home_blk_stat'}>
									<h3>Manage Your Seafood</h3>
									<label>Manage your supply chain here</label>

									<section className={'home_bt_out_wp'}>
										<Link to='/buy/cost-target'>
											<div className={'disabled_btn'}>Buy</div>
										</Link>
										<div className={'disabled_btn-container'}>
											<div
												className={'disabled_btn not-allowed'}
												// style={{ display: 'flex', justifyContent: 'center' }}
											>
												<div className='flight_ico'></div>
												{/* <img src={flightIcon} alt='shipment' style={{ width: 18 }} /> */}
											</div>
										</div>
										<Link to='/sell/thursday'>
											<div className={'disabled_btn'}>Sell</div>
										</Link>
									</section>
								</div>
							</div>
						</div>
						<div className='home_top_block d-flex'>
							<div className={'home_blk_stat_wrap'}>
								<div className={'home_blk_stat'}>
									<div className={'title'}>
										<h3>Active Discussions</h3>
									</div>
								</div>
							</div>
							<div className='discussion-container'>
								<div className='discussion-section quotes-card'>
									<p>Quotes</p>
									<div className='icon-container '>
										<IconCard
											tip='Admin Pending'
											img={adminIcon}
											count={
												activeQuotes.filter((p) => p.negotiation_status === 'Admin Pending')
													.length || 0
											}
											color={{
												bgColor: 'rgba(242, 168, 157,0.4)',
												textColor: 'rgba(235, 90, 80,1)',
											}}
										/>
										<IconCard
											tip='Customer Pending'
											img={customerIcon}
											count={
												activeQuotes.filter(
													(p) => p.negotiation_status === 'Customer Pending'
												).length || 0
											}
											color={{
												bgColor: 'rgba(242, 200, 157,0.4)',
												textColor: 'rgba(240, 165, 91,1)',
											}}
										/>
									</div>
								</div>
								
								
								<div className='discussion-section'>
									<p>Light Quote</p>
									<div className='icon-container'>
										<IconCard
											tip='Light Quote'
											img={LightQuoteIcon}
											count={countLightQuote || 0}
											color={{
												bgColor: 'rgba(235, 152, 52,0.4)',
												textColor: 'rgba(235, 152, 52,1)',
											}}
										/>
									</div>
								</div>
								
								<div className='discussion-section'>
									<p>Shipments</p>
									<div className='icon-container'>
										<IconCard
											tip='Shipments'
											img={flightIcon}
											count={activeShipments.length || 0}
											color={{
												bgColor: 'rgba(128, 182, 189,0.4)',
												textColor: 'rgba(0, 114, 129,1)',
											}}
										/>
									</div>
								</div>
							</div>
						</div>
					</section>
					<section className='dashboard-grid-wrap'>
						<div className='dashboard-grid-wrap-itme'>
							<ActiveQuotes activeQuotes={activeQuotes} />
						</div>
						<div className='dashboard-grid-wrap-itme'>
							<ActiveShipments activeShipments={activeShipments} />
						</div>
						<div className='dashboard-grid-wrap-itme'>
							<ActiveLightQuote activeLightQuote={activeLightQuote} />
						</div>
					</section>
				</div>
			</div>
		</>
	);
};

const IconCard = ({
	tip = '',
	img = '',
	count = '',
	color = {
		textColor: '#212121',
		bgColor: 'eee',
	},
	imgStyles = {},
}) => {
	return (
		<div
			className='icon-card'
			style={{ background: color.bgColor }}
			data-tip={tip}
		>
			<img style={{ width: 18, ...imgStyles }} src={img} alt='admin' />

			<h5 style={{ color: color.textColor }}>{count}</h5>
			<ReactTooltip />
		</div>
	);
};

export default DashboardOverview;
