import Api from './Api';

class ProducerServices {
	SelectedProducers = async () =>
		await Api.get('/producer/api/xproducer/?is_selected=1');
	UnselectedProducers = async () =>
		await Api.get('/producer/api/xproducer/?is_selected=0');
	SelectedExporters = async () =>
		await Api.get('/chain/api/xexporter/?is_selected=1');
	UnselectedExporters = async () =>
		await Api.get('/chain/api/xexporter/?is_selected=0');
	SelectAProducer = async (data) =>
		await Api.post('/producer/api/xproducer/', data);
	SelectAExporter = async (data) =>
		await Api.post('/chain/api/xexporter/', data);
}
export default new ProducerServices();
