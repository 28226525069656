import React, { useState, useEffect } from 'react';
import axios from 'axios';
import CalendarServices from '../../api/CalendarServices';

const SeafoodCalendar = () => {
	const [events, setSeafoodCalendar] = useState([]);

	const getSeafoodCalendar = async () => {
		const Calendar = await CalendarServices.upcomingCalendar();

		if (Calendar.data) {
			const { data } = Calendar;
			setSeafoodCalendar(data.result);
		}
		if (Calendar.error) {
			console.log('Seafood Calendar Upcoming on ERROR', Calendar.error);
		}
	};
	useEffect(() => {
		getSeafoodCalendar();
	}, []);

	return (
		<div>
			<ul>
				{events.map((event) => (
					<li key={event.id}>
						{event.event} | {event.location} ({event.date_start} - {event.date_end})
					</li>
				))}
			</ul>
		</div>
	);
};

export default SeafoodCalendar;
