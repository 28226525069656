import React from 'react';
import './MatrixHeader.css';
import TotalCard from '../Cards/TotalCard';

const MatrixHeader = ({
	headerData = [],
	eighthDay = false,
	setEighthDay,
	type = '',
	state = {},
	handleDimensionStatus,
	createVolume,
	forecastOrderId,
}) => {
	return (
		<div>
			<div style={{ display: 'flex', justifyContent: 'space-between' }}>
				<p>Name: {headerData && headerData.customerName}</p>
				{type == 'edit' && (
					<div
						className={`${
							state.button_enabled ? 'not_allow_cursor' : 'allow_cursor'
						}`}
					>
						<div
							className={'save_button'}
							onClick={() =>
								state.button_enabled ? console.log('button disabled') : createVolume()
							}
						>
							<p>{forecastOrderId ? 'Update' : 'Create'}</p>
						</div>
					</div>
				)}
			</div>
			<div className={'matrix_header_wrap'}>
				<div className={'ct_la_wrap'}>
					{state && (
						<TotalCard
							data={`${state.total_matrix_volume ? state.total_matrix_volume : '---'}${
								state.total_matrix_volume === 1 ? ' Box' : ' Boxes'
							}`}
							title={'Order Total Box'}
						/>
					)}
				</div>

				<div className={'ct_la_wrap'}>
					{state && (
						<TotalCard
							data={`${
								state.total_matrix_weight
									? state.total_matrix_weight
											.toFixed(2)
											.replace(/(\d)(?=(\d{3})+(?!\d))/g, '$1 ')
									: '---'
							}  kg`}
							title={'Order Total Weight'}
						/>
					)}
				</div>

				<div className={'ct_la_wrap'}>
					{headerData && (
						<TotalCard
							data={`$${headerData.total_price ? headerData.total_price : 0}`}
							title={'Order Total USD'}
						/>
					)}
				</div>

				{headerData && (
					<TotalCard data={headerData.sourcing_week} title={'Order Week'} />
				)}

				{headerData && (
					<TotalCard data={headerData.shipment_week} title={'Shipment Week'} />
				)}
				<div className='ct_la_wrap'>
					<div className='dimension-selector'>
						<span>
							<input
								type='radio'
								id='9_1'
								name='matrix_dimension'
								value={1}
								checked={state.matrix_dimension_status === 1 ? true : false}
								onChange={handleDimensionStatus}
								disabled={type == 'view' ? true : false}
							/>
							<label for='9_1'>9_1</label>
						</span>
						<span>
							<input
								type='radio'
								id='9_7'
								name='matrix_dimension'
								value={2}
								checked={state.matrix_dimension_status === 2 ? true : false}
								onChange={handleDimensionStatus}
								disabled={
									type == 'view' || state.initial_dimension_status === 3 ? true : false
								}
							/>
							<label for='9_7'>9_7</label>
						</span>
						<span>
							<input
								type='radio'
								id='9_8'
								name='matrix_dimension'
								value={3}
								checked={state.matrix_dimension_status === 3 ? true : false}
								onChange={handleDimensionStatus}
								disabled={type == 'view' ? true : false}
							/>
							<label for='9_8'>9_8</label>
						</span>
					</div>
				</div>
			</div>
		</div>
	);
};

export default MatrixHeader;
