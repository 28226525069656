import React from 'react';
import classes from './Spinner.css'

const spinner =() =>(
    <div className={'digiLoader'}>
        <div className={'spinner'}>
            <div className={'double-bounce1'}></div>
            <div className={'double-bounce2'}></div>
        </div>
    </div>
);

export default spinner;