import React from "react";
import WeekCard from "../../Cards/WeekCard";

/**
 * @author
 * @function WeekComponets
 **/

const WeekComponets = ({
  sourcingWeekDisplay,
  sourcingWeek,
  sourcingDates,
  shipmentWeekDisplay,
  shipmentWeek,
  shipmentDates,
  currentWeek
}) => {
  return (
    <>
      {sourcingWeekDisplay && (
        <WeekCard
          data={sourcingWeek}
          title="Sourcing Week"
          isCurrentWeek={(currentWeek === sourcingWeek)?true:false}
        >
          <p className={(currentWeek === sourcingWeek)?'text_highlht':''}>{sourcingDates}</p>
        </WeekCard>
      )}
      {shipmentWeekDisplay && (
        <WeekCard title="Shipment Week" data={shipmentWeek}  isCurrentWeek={(currentWeek === shipmentWeek)?true:false}>
          <p className={(currentWeek === shipmentWeek)?'text_highlht':''}>{shipmentDates}</p>
        </WeekCard>
      )}
    </>
  );
};

export default WeekComponets;
