import React, { useEffect, useReducer, useState } from 'react';
import { IoAddCircleOutline, IoEye } from 'react-icons/io5';
import { MdModeEdit } from 'react-icons/md';
import { Link } from 'react-router-dom';
import Modal from 'react-modal';
import TenderServices from '../../api/TenderServices';
import ToggleSwitch from '../Toggle/ToggleSwitch';
import './RFQMatrix.css';
import RFQServices from '../../api/RFQServices';
import RFQView from '../RFQ/RFQView';

export const ACTIONS = {
	GET_RFQ_MATRIX: 'GetRFQMatrix',
	SET_FIELDS: 'SetFields',
};

const reducer = (state, { type, payload }) => {
	switch (type) {
		case ACTIONS.GET_RFQ_MATRIX:
			return {
				...state,
				rfqMatrixResults: payload.rfqMatrixResults,
			};
		case ACTIONS.SET_FIELDS:
			return {
				...state,
				[payload.field]: payload.value,
			};
		default:
			return {
				...state,
			};
	}
};

const RFQMatrix = ({
	toggle = false,
	handleRFQProducer = () => {},
	...props
}) => {
	const [state, dispatch] = useReducer(reducer, {
		rfqMatrixResults: [],
		tender_data: [],
		selected_user: {},
		selected_bubble: null,
	});

	const [isModalOpened, setIsModalOpened] = useState(false);
	const [isRFQModalOpened, setIsRFQModalOpened] = useState(false);
	const [selectedRFQ, setSelectedRFQ] = useState('');

	useEffect(() => {
		fetchRFQMatrix();
	}, []);

	//fetching the RFQ matrix
	const fetchRFQMatrix = async () => {
		const matrixData = await RFQServices.getRFQMatrixData({
			producers: [],
			exporters: [],
		});
		if (matrixData.data) {
			const { data } = matrixData;
			dispatch({
				type: ACTIONS.GET_RFQ_MATRIX,
				payload: {
					rfqMatrixResults: data.results,
				},
			});
		}
	};
	const handleRFQModal = async (rfqid = '', name) => {
		dispatch({
			type: ACTIONS.SET_FIELDS,
			payload: {
				field: 'selected_producer',
				value: name,
			},
		});
		setSelectedRFQ(rfqid);
		manageRFQModal(true);
	};
	const manageRFQModal = (modalOpen = false) => {
		setIsRFQModalOpened(modalOpen);
	};
	const handleTenderModal = async (user) => {
		dispatch({
			type: ACTIONS.SET_FIELDS,
			payload: {
				field: 'selected_user',
				value: user,
			},
		});
		const tenderList = await TenderServices.getTenderList();
		if (tenderList.data) {
			console.log('Tender list Data:', tenderList.data.results);
			dispatch({
				type: ACTIONS.SET_FIELDS,
				payload: {
					field: 'tender_data',
					value: tenderList.data.results,
				},
			});
			setIsModalOpened(true);
		} else {
			console.error(tenderList.error);
		}
	};

	const closeModal = () => {
		setIsModalOpened(false);
		dispatch({
			type: ACTIONS.SET_FIELDS,
			payload: {
				field: 'selected_bubble',
				value: null,
			},
		});
	};

	const bubbleClickHandler = (val) => {
		dispatch({
			type: ACTIONS.SET_FIELDS,
			payload: {
				field: 'selected_bubble',
				value: val,
			},
		});
	};

	const renderUserStatus = (status) => {
		if (
			status === 'Turned Down' ||
			status === 'Rejected' ||
			status === 'Hold' ||
			status === 'Accepted'
		) {
			return 'view';
		} else if (status === 'No RFQ') {
			return 'add';
		} else {
			return 'edit';
		}
	};

	return (
		<>
			<TenderListModal
				isModalOpened={isModalOpened}
				data={state.tender_data}
				selected_bubble={state.selected_bubble}
				selected_user={state.selected_user}
				closeModal={closeModal}
				bubbleClickHandler={bubbleClickHandler}
			/>
			<RFQView
				isModalOpened={isRFQModalOpened}
				manageRFQModal={manageRFQModal}
				rfqId={selectedRFQ}
				selected_producer={state.selected_producer}
			/>
			<table
				className='rfq-manager-table'
				style={{ width: '100%', borderSpacing: 0 }}
			>
				<thead>
					<tr className='table-header'>
						<th className='producer-head'>
							<span className={'inData table-content'}>Producer</span>
						</th>
						<th style={{ textAlign: 'center' }}>
							<span className={'inData table-content'}>Status</span>
						</th>
						<th></th>
					</tr>
				</thead>
				{toggle && (
					<tbody>
						{state.rfqMatrixResults.length > 0 ? (
							state.rfqMatrixResults.map((user, index) => {
								return (
									<tr key={index}>
										<td className='producer-cell' style={{ textAlign: 'left' }}>
											{user.name}
											{renderUserStatus(user.status) === 'view' ? (
												<IoEye
													size={20}
													onClick={() => handleRFQModal(user.rfq_id, user.name)}
												/>
											) : renderUserStatus(user.status) === 'add' ? (
												<IoAddCircleOutline
													size={20}
													onClick={() => handleTenderModal(user)}
												/>
											) : (
												<Link
													to={`/buy/request/${user.rfq_id}/${user.access_id}/${user.type}`}
												>
													<MdModeEdit
														size={20}
														onClick={() => {
															localStorage.setItem('rfqTenderId', user.rfq_id);
														}}
													/>
												</Link>
											)}
										</td>
										<td>{user.status}</td>
										<td>
											<ToggleSwitch
												fromMatrix={true}
												disabled={!user.has_rfq}
												onToggleChange={(toggle) => handleRFQProducer(user, toggle)}
											/>
										</td>
									</tr>
								);
							})
						) : (
							<tr>
								<td colspan='3'>No data available </td>
							</tr>
						)}
					</tbody>
				)}
			</table>
		</>
	);
};

const TenderListModal = ({
	data,
	isModalOpened,
	closeModal,
	bubbleClickHandler,
	selected_bubble,
	selected_user,
}) => {
	const customStyles = {
		content: {
			background: 'white',
			width: '20rem',
			maxWidth: 'calc(100vw - 2rem)',
			maxHeight: 'calc(100vh - 2rem)',
			boxshadow: '0 0 30px 0 rgba(0, 0, 0, 0.25)',
			overflowY: 'auto',
			position: 'relative',
			textAlign: 'center',
		},
		overlay: {
			position: 'fixed',
			zIndex: 999999,
			top: 0,
			left: 0,
			width: '100vw',
			height: '100vh',
			background: 'rgba(0, 0, 0, 0.5)',
			display: 'flex',
			alignItems: 'center',
			justifyContent: 'center',
		},
	};
	return (
		<Modal
			isOpen={isModalOpened}
			onRequestClose={closeModal}
			style={customStyles}
		>
			<div className={'bubble-main left'}>
				<p>Choose a Tender</p>
				<div className='bubble-main-wrap'>
					{data && data.length > 0 ? (
						data.map((val, i) => {
							return (
								<div
									key={i}
									onClick={(e) => {
										e.preventDefault();
										bubbleClickHandler(val);
									}}
									className={`bubbles ${
										selected_bubble === val ? 'active' : 'inactive'
									}`}
								>
									{val}
								</div>
							);
						})
					) : (
						<div>No Tender is created.</div>
					)}
				</div>
			</div>

			<>
				<div style={{ display: 'flex', justifyContent: 'center', gap: 20 }}>
					<div
						className={`${
							selected_bubble === null ? 'disabled' : ' '
						} save_button pointer`}
						onClick={() => {
							if (selected_bubble !== null) {
								console.log('submit clicked');
								localStorage.setItem('rfqTenderId', selected_bubble);
								window.location.href = `/buy/request/${selected_bubble}/${selected_user.access_id}/${selected_user.type}?tender=${selected_bubble}`;
							}
						}}
					>
						Submit
					</div>
					<div className={'cancel_button'} onClick={closeModal}>
						Cancel
					</div>
				</div>
			</>
		</Modal>
	);
};

export default RFQMatrix;
