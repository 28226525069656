import React from 'react'
import { Link } from 'react-router-dom';
import Sidebar from '../../Sidebar/Sidebar';

import { useState } from 'react';
import DayPickerInput from "react-day-picker/DayPickerInput";
import "react-day-picker/lib/style.css";

const ExpiryDateCalculator = () => {

    const [production_date, setProductionDate] = useState(new Date());
    const [shelf_life,  setShelfLife] = useState(null)
    const [production_date_log,  setProductionDateLog] = useState('')
    const [shelf_life_log,  setShelfLifeLog] = useState('')
    const [expiry_date,  setExpiryDate] = useState('')


    // Calculate Expiry date
      const onSubmit = (e) => {
        e.preventDefault()
        if(!shelf_life) {
            alert('Please add a shelf_life')
            return
        }
        const expiry_date = production_date
        expiry_date.setDate(expiry_date.getDate() + parseInt(shelf_life))
        const exp = expiry_date.getDate() + '.' + (expiry_date.getMonth() + 1) + '.' + expiry_date.getFullYear() 
        setExpiryDate(exp)
        setProductionDateLog(production_date)
        setShelfLifeLog(shelf_life)
        setProductionDate(new Date())
        setShelfLife(null)
    }

    // set date
    function onChange(date) {
        setProductionDate(date);
    }

    const action = ['test', 'hello']

    return (
        <section className={'body_wrap'}>
            {/* <Sidebar /> */}
            <div className={'cont_wp'}>
                <Link to={'/home'}>
                    <p className={'Back_link'}>Back</p>
                 </Link>
                 <p style={{color:"red"}}>TODO</p>
                    <ul style={{color:"red"}}>
                        <li><h7>Show Production and Expiry date in a GUI that resembling a label.</h7></li>
                    </ul>
                 <section className={'cmn_head'}>
                     <p>Expiry Date Calculator</p>
                </section>
                <div >
                    <form  onSubmit={onSubmit}>
                        <div >
                            <label>Production date: </label>
                            <DayPickerInput onDayChange={onChange} />
                        </div>
                        <div>
                            <label>Shelf life</label>
                            <input type='number' placeholder='shelf life' 
                            value={shelf_life} onChange={(e) => setShelfLife(e.target.value)} />
                        </div>
                        <input type='submit' value='Calculate' />
                    </form>
                    <p>Shelf life: { shelf_life_log ? shelf_life_log : ''}</p>
                    <p>Expiry date: { expiry_date ? expiry_date : 'form not submitted'}</p>
                </div>
            </div>

        </section>
    )
}

export default ExpiryDateCalculator
