import React from "react";
import { BsChevronDown } from "react-icons/bs";

import "./style.css";

const Dropdown = ({
  label,
  selectedOption,
  options,
  placeholder,
  setSelectedOptionState,
  dropdownHandler,
  disabledDropdown,
}) => {
  const { useState, useEffect } = React;

  const selectOptionHandler = (e) => {
    if (setSelectedOptionState) {
      setSelectedOptionState(e.target.value);
    }
  };

  return (
    <div style={{ display: "flex", alignItems: "center", width: "100%" }}>
      {label && (
        <label htmlFor="dropdown" id="dropdown" style={{ width: 90 }}>
          {label}
        </label>
      )}
      <div className="custom-select" style={{ width: 190 }}>
        <div style={{ position: "absolute", right: 10, top: 10 }}>
          <BsChevronDown
            style={{
              color: disabledDropdown ? "#b6b6b6" : "#000",
              cursor: "pointer",
            }}
          />
        </div>
        <select
          id="dropdown"
          className="dropdown_container"
          style={{
            color: disabledDropdown ? "#b6b6b6" : "#000",
          }}
          value={selectedOption}
          onChange={
            setSelectedOptionState ? selectOptionHandler : dropdownHandler
          }
          disabled={disabledDropdown}
        >
          <option value="" disabled>
            {placeholder}
          </option>
          {options.length > 0 &&
            options.map((item, index) => {
              return (
                <option key={index} value={item}>
                  {item}
                </option>
              );
            })}
        </select>
      </div>
    </div>
  );
};

Dropdown.defaultProps = {
  label: null,
  selectedOption: "",
  customStyle: {},
  options: [],
  placeholder: "Select",
};

export default Dropdown;
