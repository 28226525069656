import React from 'react'
import { Link } from 'react-router-dom';
import Sidebar from '../components/Sidebar/Sidebar';
import SeafoodSpecsCard from '../components/seafood/SeafoodSpecsCard';
import SeafoodSpecs from '../components/seafood/SeafoodSpecs';

const SeafoodSpecPage = () => {
    return (
        <section className={'body_wrap'}>
        {/* <Sidebar /> */}
        <div className={'cont_wp'}>
            <Link to={'/home'}>
                <p className={'Back_link'}>Back</p>
             </Link>
             <section className={'cmn_head'}>
                 <p>Seafood specifications</p>
            </section>
            <div>
                <SeafoodSpecsCard />
                <SeafoodSpecs />
            </div>
        </div>
    </section>
    )
}
export default SeafoodSpecPage
