import React, { useEffect, useState } from 'react';
import { AiOutlineLeft, AiOutlineRight } from 'react-icons/ai';
import './WeekSelection.css';
import TotalCard from '../Cards/TotalCard';

const WeekSelection = (props) => {
	let {
		onIncrementWeek,
		onDecrementWeek,
		year,
		week,
		fromNasdaq,
		data,
		fromHistoryPopup,
		history,
	} = props;

	const [sourcingWeek, setNewSourcingWeek] = useState(props.sourcingWeek);
	const [packingWeek, setNewPackingWeek] = useState(props.packingWeek);
	const [sourcingWeekDay, setSourcingWeekDay] = useState(props.sourcingWeek);
	const [packingWeekDay, setPackingWeekDay] = useState(props.packingWeek);
	const [disableRighticon, setDisableRighticon] = useState(false);
	const [disableLefticon, setDisableLefticon] = useState(true);
	const [currentWeek, setCurrentWeek] = useState('');
	const days = ['', 'M', 'T', 'W', 'T', 'F', 'S', 'S'];
	useEffect(() => {
		setSourcingWeekDay(getDateRangeOfWeek(week, year));
		setPackingWeekDay(getDateRangeOfWeek(week + 1, year));
		setCurrentWeek(`${year}-W${week}`);
	}, []);

	const incrementWeek = () => {
		const sourceWeek = week + 1;
		const packWeek = week + 2;
		const sourcing_week = `${year}-W${sourceWeek}`;
		const packing_week = `${year}-W${packWeek}`;
		console.log(sourcing_week);
		console.log(packing_week);
		setNewSourcingWeek(sourcing_week);
		setNewPackingWeek(packing_week);
		setDisableRighticon(true);
		setDisableLefticon(false);
		onIncrementWeek(sourcing_week, packing_week);

		setSourcingWeekDay(getDateRangeOfWeek(sourceWeek, year));
		setPackingWeekDay(getDateRangeOfWeek(packWeek, year));
	};

	const decrementWeek = () => {
		const sourceWeek = fromNasdaq ? week - 1 : week;
		const packWeek = week + 1;

		const sourcing_week = `${year}-W${sourceWeek}`;
		const packing_week = `${year}-W${packWeek}`;
		console.log(sourcing_week);
		console.log(packing_week);
		setNewPackingWeek(packing_week);
		setNewSourcingWeek(sourcing_week);
		setDisableRighticon(false);
		setDisableLefticon(true);
		onDecrementWeek(sourcing_week, packing_week);

		setSourcingWeekDay(getDateRangeOfWeek(sourceWeek, year));
		setPackingWeekDay(getDateRangeOfWeek(packWeek, year));
	};

	const getDateRangeOfWeek = (w, y) => {
		var simple = new Date(y, 0, 1 + (w - 1) * 7);
		var dow = simple.getDay();
		var ISOweekStart = simple;
		if (dow <= 4) ISOweekStart.setDate(simple.getDate() - simple.getDay() + 1);
		else ISOweekStart.setDate(simple.getDate() + 8 - simple.getDay());

		let ISOweekEnd = new Date(ISOweekStart);
		ISOweekEnd.setDate(ISOweekStart.getDate() + 6);
		console.log(ISOweekEnd);
		const months = [
			'Jan',
			'Feb',
			'Mar',
			'Apr',
			'May',
			'Jun',
			'Jul',
			'Aug',
			'Sep',
			'Oct',
			'Nov',
			'Dec',
		];
		const days = [
			'Sunday',
			'Monday',
			'Tuesday',
			'Wednesday',
			'Thursday',
			'Friday',
			'Saturday',
		];
		let start = ISOweekStart.getDate() + ' ' + months[ISOweekStart.getMonth()];
		let end = ISOweekEnd.getDate() + ' ' + months[ISOweekEnd.getMonth()];
		return start + ' - ' + end;
	};

	return (
		<div className='week_selection_wrap'>
			<div
				className={
					fromHistoryPopup ? 'hide_icon' : !disableRighticon ? 'no_display' : ''
				}
				onClick={() => (!disableRighticon ? null : decrementWeek())}
			>
				<AiOutlineLeft
					size={20}
					color={'#002776'}
					className={`${!disableRighticon ? '' : 'allow_cursor '}`}
				/>
				<AiOutlineLeft
					size={20}
					color={'#002776'}
					className={`arrow_icon ${!disableRighticon ? '' : 'allow_cursor '}`}
				/>
			</div>
			{!fromNasdaq && (
				<div className={`week_lab_out`}>
					<div className={'ct_la_wrap'}>
						<div>
							<TotalCard
								hasTitleData={<p className={sourcingWeek == currentWeek ? 'text_highlht' : ''}>Sourcing Week</p>}
								data={sourcingWeek}
								title={data.sourcing_date}
								isCurrentWeek={sourcingWeek == currentWeek ? true : false}
							/>
						</div>
					</div>
					{!history ? (
						sourcingWeek == currentWeek ? (
							<div className={'days_wrap'}>
								{days.map((val, i) => {
									return (
										<>
											{val ? (
												<div
													className={`days ${
														i == 4
															? 'thurs'
															: i == 5
															? 'fri'
															: i == data.current_day_index
															? 'active_day'
															: 'inactive_day'
													}`}
												>
													<p
														className={
															i == 4
																? 'days_thurs_text'
																: i == 5
																? 'days_fri_text'
																: i == data.current_day_index
																? 'days_text_active'
																: 'days_text'
														}
													>
														{val}
													</p>{' '}
													{i == data.current_day_index ? (
														<div className={'underline'}></div>
													) : null}
												</div>
											) : null}
										</>
									);
								})}
							</div>
						) : null
					) : null}
				</div>
			)}

			<div className={'week_lab_out last'}>
				<div className={'ct_la_wrap'}>
					<div>
						<TotalCard data={packingWeek} title={data.shipment_date} 	hasTitleData={<p>{fromNasdaq ? 'Nasdaq' : 'Packing'} Week</p>}/>
					</div>
				</div>
			</div>

			<div
				className={
					fromHistoryPopup ? 'hide_icon' : !disableLefticon ? 'no_display' : ''
				}
				onClick={() => (!disableLefticon ? null : incrementWeek())}
			>
				<AiOutlineRight
					size={20}
					color={'#002776'}
					className={`arrow_left_icon ${!disableLefticon ? '' : 'allow_cursor'}`}
				/>
				<AiOutlineRight
					size={20}
					color={'#002776'}
					className={`${!disableLefticon ? '' : 'allow_cursor'}`}
				/>
			</div>
		</div>
	);
};

export default WeekSelection;
