import React, { Suspense, useReducer, useState } from 'react';
import { Link, useHistory } from 'react-router-dom';
import Modal from 'react-modal';
import Sidebar from '../components/Sidebar/Sidebar';
import OrderVolumeMatrixComponent from '../components/Matrix/OrderVolumeMatrix';
import PurchaseListComponent from '../components/Matrix/PurchaseMatrix';
import matrixWithToggleSwitch from '../hoc/matrixWithToggleSwitch';
import CommonServices from '../api/CommonServices';
import PackingServices from '../api/PackingServices';
import '../styles/PackingManager.css';
import PackingMatrix from '../components/Matrix/PackingMatrix';
import { BiChevronLeft, BiRefresh } from 'react-icons/bi';
import PackingBatchList from '../components/Matrix/PackingBatchList';
import { ToastsStore } from 'react-toasts';

const PurchaseMatrix = matrixWithToggleSwitch(OrderVolumeMatrixComponent, {
	title: 'Purchase Matrix',
});
const DeductMatrix = matrixWithToggleSwitch(OrderVolumeMatrixComponent, {
	title: 'Deduct Matrix',
});

const PackingBatchMatrix = matrixWithToggleSwitch(PackingMatrix, {
	title: 'Packing Batch',
});

const PurchaseList = matrixWithToggleSwitch(PurchaseListComponent, {
	title: 'Purchase List',
});
const PackingBatch = matrixWithToggleSwitch(PackingBatchList, {
	title: 'Packing Batches',
});

export const ACTIONS = {
	SET_FIELDS: 'SET_FIELDS',
	SET_PRODUCER_MATRIX: 'SET_PRODUCER_MATRIX',
	ON_MATRICES: 'ON_MATRICES',
	OFF_MATRICES: 'OFF_MATRICES',
	SET_DAY: 'SET_DAY',
	SET_SHIPMENT: 'SET_SHIPMENT',
	SET_SHIPMENT_VALUE: 'SET_SHIPMENT_VALUE',
	SET_PACKING_STATION: 'SET_PACKING_STATION',
	SET_PACKING_MATRICES: 'SET_PACKING_MATRICES',
	GET_FISHERY: 'GET_FISHERY',
	CHANGE_PACKING_MATRIX: 'CHANGE_PACKING_MATRIX',
	RESET_MODAL: 'RESET_MODAL',
	RESET_SELECTED_IDS: 'RESET_SELECTED_IDS',
	SET_PACKING_BATCH_ID: 'SET_PACKING_BATCH_ID',
	SET_PACKING_LIST: 'SET_PACKING_LIST',
	CLEAR_PACKING_LIST: 'CLEAR_PACKING_LIST',
	FILTER_DAY_INDEX: 'FILTER_DAY_INDEX',
};

const reducer = (state, { type, payload }) => {
	switch (type) {
		case ACTIONS.SET_FIELDS:
			return {
				...state,
				[payload.field]: payload.value,
			};
		case ACTIONS.SET_PRODUCER_MATRIX:
			let getMatrixStatus = payload.matrix_dimensional_status;
			let week_dates = [];

			if (getMatrixStatus === 1) {
				week_dates = payload.shipment_week;
			} else if (getMatrixStatus === 2) {
				week_dates = payload.week_data;
			} else {
				week_dates = [...payload.week_data, payload.shipment_week];
			}

			return {
				...state,
				accumulated_matrix: payload.accumulated_matrix,
				week_data: week_dates,
				shipment_week: payload.shipment_week,
				matrix_dimensional_status: payload.matrix_dimensional_status,
				residual_matrix: payload.residual_matrix,
			};
		case ACTIONS.ON_MATRICES:
			return {
				...state,
				disable_matrices: false,
				toggle_deduct: true,
				toggle_packing_batch: true,
				toggle_producer: false,
			};
		case ACTIONS.OFF_MATRICES:
			return {
				...state,
				disable_matrices: true,
				toggle_deduct: false,
				toggle_packing_batch: false,
				toggle_producer: false,
				selected_producers: '',
			};
		case ACTIONS.SET_DAY:
			return {
				...state,
				selected_day: payload.selected_day,
			};
		case ACTIONS.SET_SHIPMENT:
			return {
				...state,
				customer_shipment: payload.customer_shipment,
				arrival_target: payload.arrival_target,
			};
		case ACTIONS.SET_SHIPMENT_VALUE:
			return {
				...state,
				selected_shipment: payload.selected_shipment,
				selected_shipment_id: parseInt(payload.selected_shipment_id),
				selected_station: '',
			};
		case ACTIONS.SET_PACKING_STATION:
			return {
				...state,
				selected_station: payload.selected_station,
				selected_station_id: parseInt(payload.selected_station_id),
				is_update_disabled: state.selected_shipment === '' ? true : false,
			};
		case ACTIONS.GET_FISHERY:
			return {
				...state,
				packing_station: payload.packing_station,
			};
		case ACTIONS.RESET_MODAL:
			return {
				...state,
				selected_day: '',
				selected_shipment: '',
				selected_station: '',
				arrival_target: '',
				customer_shipment: [],
				is_update_disabled: true,
			};
		case ACTIONS.SET_PACKING_MATRICES:
			return {
				...state,
				purchase_matrix: payload.purchase_matrix,
				remaining_matrix: payload.remaining_matrix,
				shipment_matrix: payload.shipment_matrix,
			};
		case ACTIONS.CHANGE_PACKING_MATRIX:
			let temp_matrix = state.shipment_matrix;

			temp_matrix[payload.weight_class][0].volume = parseInt(
				payload.updated_value
			);

			return {
				...state,
				shipment_matrix: temp_matrix,
			};
		case ACTIONS.RESET_SELECTED_IDS:
			return {
				...state,
				selected_shipment_id: '',
				selected_station_id: '',
				purchase_matrix: {},
				remaining_matrix: {},
				shipment_matrix: {},
			};
		case ACTIONS.SET_PACKING_BATCH_ID:
			return {
				...state,
				packing_batch_id: payload.packing_batch_id,
			};

		case ACTIONS.SET_PACKING_LIST:
			return {
				...state,
				packing_list: payload.packing_list,
			};
		case ACTIONS.CLEAR_PACKING_LIST:
			return {
				...state,
				packing_list: [],
			};
		case ACTIONS.FILTER_DAY_INDEX:
			return {
				...state,
				filter_day_index: payload.filter_day_index,
			};
		default:
			return state;
	}
};

const PackingManager = () => {
	const [state, dispatch] = useReducer(reducer, {
		accumulated_matrix: {},
		residual_matrix: {},
		week_data: [],
		table_header_weeks: [],
		selected_producers: '',
		customer_shipment: [],
		packing_station: [],
		shipment_week: '',
		matrix_dimensional_status: 3,
		toggle_producer: false,
		toggle_deduct: false,
		toggle_packing: true,
		toggle_purchase_list: true,
		toggle_packing_batch: false,
		disable_matrices: true,
		selected_day: '',
		selected_shipment: '',
		selected_shipment_id: '',
		selected_station: '',
		selected_station_id: '',
		packing_batch_id: null,
		arrival_target: '',
		is_update_disabled: true,
		filter_day_index: [],
		//MATRIXES AFTER UPDATE
		purchase_matrix: {},
		remaining_matrix: {},
		shipment_matrix: {},
		packing_list: [],
	});

	const [AddModal, setAddModal] = useState(false);
	const [status, setStatus] = useState('purchase');
	const [modalStatus, setModalStatus] = useState('idle');

	const history = useHistory();

	const renderDimensionalStatus = (accumulated_matrix) => {
		let get_matrix_dimension_length = 0;
		let matrix_status = 0;
		Object.entries(accumulated_matrix).forEach((matrix) => {
			get_matrix_dimension_length = matrix[1].length;
		});

		switch (get_matrix_dimension_length) {
			case 8:
				matrix_status = 3;
				break;
			case 7:
				matrix_status = 2;
				break;
			case 1:
				matrix_status = 1;
				break;
			default:
				matrix_status = 2;
				break;
		}

		return matrix_status;
	};

	React.useEffect(() => {
		fetchFisheryDetails();
		return () => {
			setModalStatus('idle');
		};
	}, []);

	const fetchFisheryDetails = async () => {
		try {
			const fisheryDetails = await PackingServices.getFisheryStation();
			if (fisheryDetails.data) {
				const { data } = fisheryDetails;
				console.log('Response Fishery', data);
				dispatch({
					type: ACTIONS.GET_FISHERY,
					payload: {
						packing_station: data.result,
					},
				});
			}
		} catch (error) {
			console.log(error);
		}
	};

	const handlePurchaseProducer = async (producer, toggleStatus) => {
		console.log('producer', producer);
		let filteredProducers = state.selected_producers;
		let dispatchCondition = (Newproducer) => {
			console.log('dispatched condition', Newproducer);
			dispatch({
				type: ACTIONS.SET_FIELDS,
				payload: {
					field: 'selected_producers',
					value: Newproducer,
				},
			});
		};
		console.log('Toggle Status', toggleStatus);
		if (toggleStatus) {
			filteredProducers = producer.purchase_id;
			dispatchCondition(filteredProducers);
			dispatch({
				type: ACTIONS.ON_MATRICES,
			});
		} else {
			filteredProducers = '';
			dispatchCondition(filteredProducers);
		}
		console.log('items', filteredProducers);

		if (filteredProducers.length !== 0)
			try {
				let packing_matrix = [];
				let packing_matrix_ids = [];
				//PACKING BATCH API
				const packingListData = await PackingServices.listPacking([
					filteredProducers,
				]);

				if (packingListData.data) {
					const { data } = packingListData;

					if (data.result.length > 0) {
						packing_matrix = data.result;
						packing_matrix_ids = data.packing_batch_ids;
					}

					dispatch({
						type: ACTIONS.SET_PACKING_LIST,
						payload: {
							packing_list: data.result,
						},
					});
				}
				let matrixData;
				if (packing_matrix.length > 0) {
					matrixData = await CommonServices.getMatrixDataByComparison({
						model: 'PackingBatch',
						model_ids: packing_matrix_ids,
						comparison: 'Purchase',
						comparison_ids: [filteredProducers],

						// model: 'Purchase',
						// model_ids: filteredProducers,
						// comparison: packing_matrix.length > 0 ? 'PackingBatch' : null,
						// comparison_ids: packing_matrix.length > 0 ? packing_matrix_ids : null,
					});
				} else {
					matrixData = await CommonServices.getMatrixDataByComparison({
						model: 'Purchase',
						model_ids: [filteredProducers],
						comparison: null,
						comparison_ids: null,
					});
				}

				if (matrixData.data) {
					const { data } = matrixData;
					let dimension_status = renderDimensionalStatus(data.accumulated_matrix);
					console.log('Response Comparison Data', data);

					let Matrix = JSON.parse(JSON.stringify(data.residual_matrix));

					let filterDayIndex = [];

					Object.values(Matrix).forEach((value) =>
						// item.filter((value) => value.volume > 0)
						{
							let variableX = value
								.filter((x) => {
									return x.volume && !filterDayIndex.includes(x.dayIndex);
								})
								.map((d) => d.dayIndex);

							filterDayIndex = filterDayIndex.concat(variableX);
						}
					);

					console.log('filterDay', filterDayIndex);

					dispatch({
						type: ACTIONS.SET_PRODUCER_MATRIX,
						payload: {
							accumulated_matrix:
								packing_matrix.length > 0
									? data.comparison_matrix
									: data.accumulated_matrix,
							residual_matrix: data.residual_matrix,
							week_data: data.matrix_date_list,
							shipment_week: data.shipment_week,
							matrix_dimensional_status: dimension_status,
							//deduct matrix - BE
						},
					});
					dispatch({
						type: ACTIONS.ON_MATRICES,
					});
					dispatch({
						type: ACTIONS.FILTER_DAY_INDEX,
						payload: {
							filter_day_index: filterDayIndex,
						},
					});
				}
			} catch (err) {
				console.log(err);
			}
		else {
			dispatch({
				type: ACTIONS.OFF_MATRICES,
			});
			dispatch({
				type: ACTIONS.CLEAR_PACKING_LIST,
			});
		}
	};

	const handleToggleStatus = (toggleStatus, toggleType) => {
		console.log('handleToggleStatus');
		dispatch({
			type: ACTIONS.SET_FIELDS,
			payload: {
				field: toggleType,
				value: toggleStatus,
			},
		});
	};

	const customStyles = {
		content: {
			background: 'white',
			width: '20rem',
			maxWidth: 'calc(100vw - 2rem)',
			maxHeight: 'calc(100vh - 2rem)',
			boxshadow: '0 0 30px 0 rgba(0, 0, 0, 0.25)',
			overflowY: 'auto',
			position: 'relative',
			textAlign: 'center',
		},
		overlay: {
			position: 'fixed',
			zIndex: 999999,
			top: 0,
			left: 0,
			width: '100vw',
			height: '100vh',
			background: 'rgba(0, 0, 0, 0.5)',
			display: 'flex',
			alignItems: 'center',
			justifyContent: 'center',
		},
	};

	const closeModal = () => {
		setAddModal(false);
		dispatch({
			type: ACTIONS.RESET_MODAL,
		});
	};

	const handleShipmentChange = (value, id) => {
		dispatch({
			type: ACTIONS.SET_SHIPMENT_VALUE,
			payload: {
				selected_shipment: value,
				selected_shipment_id: id,
			},
		});
	};

	const handlePackingChange = (value, id = null) => {
		dispatch({
			type: ACTIONS.SET_PACKING_STATION,
			payload: {
				selected_station: value,
				selected_station_id: id,
			},
		});
	};

	const handleAddPacking = async (value) => {
		setAddModal(true);
		console.log('value date', value);
		dispatch({
			type: ACTIONS.SET_DAY,
			payload: {
				selected_day: value,
			},
		});
		setModalStatus('loading');
		try {
			let day_index = state.week_data.indexOf(value);
			const retrievedShipment = await PackingServices.getShipment({
				week: state.shipment_week,
				day_index,
			});

			if (retrievedShipment.data) {
				const { data } = retrievedShipment;
				dispatch({
					type: ACTIONS.SET_SHIPMENT,
					payload: {
						customer_shipment: data.result,
						arrival_target: data.result[0].arrival_target,
					},
				});
				setModalStatus('loaded');
			} else {
				console.log(retrievedShipment.error);
				setModalStatus('error');
			}
		} catch (err) {
			console.log(err);
			setModalStatus('error');
		}
	};

	const handleUpdate = async () => {
		try {
			const initiateMatrix = await PackingServices.initiateWeekdayMatrix({
				arrival_target: state.arrival_target,
				purchase_id: parseInt(state.selected_producers),
				customer_shipment_id: state.selected_shipment_id,
			});
			if (initiateMatrix.data) {
				const { data } = initiateMatrix;
				console.log('Response Update', data);
				dispatch({
					type: ACTIONS.SET_PACKING_MATRICES,
					payload: {
						purchase_matrix: data.result.purchase_weekday_matrix,
						remaining_matrix: data.result.remaining_purchase_weekday_matrix,
						shipment_matrix: data.result.shipment_weekday_matrix,
					},
				});
				setStatus('packing');
				setAddModal(false);
			} else {
				setAddModal(false);
				dispatch({
					type: ACTIONS.RESET_MODAL,
				});
				ToastsStore.error('API Server Error');
			}
		} catch (error) {
			console.log(error);
			dispatch({
				type: ACTIONS.RESET_MODAL,
			});
			setAddModal(false);
		}
	};

	const handlePackingDataChange = (value, weight) => {
		console.log('value', value);
		// console.log('data', data);
		console.log('weight', weight);
		dispatch({
			type: ACTIONS.CHANGE_PACKING_MATRIX,
			payload: {
				weight_class: weight,
				updated_value: value,
			},
		});
	};

	const createPackingBatch = async () => {
		try {
			const createBatchInstance = await PackingServices.createBatch(
				{
					purchase_id: state.selected_producers,
					xfishery_establishment_id: state.selected_station_id,
					customer_shipment_id: state.selected_shipment_id,
					data: state.shipment_matrix,
				},
				state.packing_batch_id === null ? 0 : state.packing_batch_id
			);

			if (createBatchInstance.data) {
				//TODO
				const { data } = createBatchInstance;
				console.log('Response Creation Batch', createBatchInstance.data);
				dispatch({
					type: ACTIONS.SET_PACKING_BATCH_ID,
					payload: {
						packing_batch_id: data.packing_batch_id,
					},
				});
			}
		} catch (error) {
			console.log(error);
		}
	};

	const cancelPackingBatch = () => {
		// window.location = '/buy/packing';
		history.go(0);
	};

	return (
		<>
			<Modal
				isOpen={AddModal}
				// onAfterOpen={afterOpenModal}
				onRequestClose={closeModal}
				style={customStyles}
				ariaHideApp={false}
			>
				<div className='dropdown__main'>
					<div className='day-container'>
						<label>Day</label>
						<h4>{state.selected_day}</h4>
					</div>
					{state.customer_shipment.length > 0 ? (
						<>
							<div className='dropdown-container'>
								<label>Customer Shipment</label>
								<Dropdown
									data={state.customer_shipment}
									selected_value={state.selected_shipment}
									handleChange={handleShipmentChange}
									type='shipment'
								/>
							</div>
							<div className='dropdown-container'>
								<label>Packing Station</label>
								<Dropdown
									data={state.packing_station}
									selected_value={state.selected_station}
									handleChange={handlePackingChange}
									type='station'
								/>
							</div>
						</>
					) : modalStatus === 'loading' ? (
						<p>Loading...</p>
					) : (
						<p>No Shipment Available.</p>
					)}
				</div>
				<>
					<div style={{ display: 'flex', justifyContent: 'center', gap: 20 }}>
						<div className={'cancel_button'} onClick={closeModal}>
							Cancel
						</div>
						{!state.is_update_disabled && (
							<div
								className={'save_button pointer'}
								onClick={(e) => {
									e.preventDefault();
									handleUpdate();
								}}
							>
								Update
							</div>
						)}
					</div>
				</>
			</Modal>
			<section className='sell_body_wrap'>
				{/* <Sidebar /> */}
				<div style={{ paddingBottom: 30 }}>
					<div className='scrl_out' style={{ paddingBottom: '30px' }}>
						<Link to={'/home'}>
							<p className={'Back_link'}>Back</p>
						</Link>
						<section className={'cmn_head'}>
							<div className={'cmn_head_align'}>
								<p>Packing Manager</p>
							</div>
						</section>
					</div>
					<PurchaseMatrix
						orderVolumeMatrix={state.accumulated_matrix}
						state={state}
						daysOrder={state.week_data}
						type='view'
						toggleSwitchDisabled={state.disable_matrices}
						toggleStatus={state.toggle_producer}
						handleToggleStatus={(status) => {
							handleToggleStatus(status, 'toggle_producer');
						}}
					/>
					{status === 'purchase' && (
						<>
							<DeductMatrix
								orderVolumeMatrix={state.residual_matrix}
								state={{
									...state,
									table_header_add_visibility:
										state.selected_producers !== '' ? true : false,
								}}
								daysOrder={state.week_data}
								type='view'
								toggleSwitchDisabled={state.disable_matrices}
								toggleStatus={state.toggle_deduct}
								handleToggleStatus={(status) => {
									handleToggleStatus(status, 'toggle_deduct');
								}}
								handleAddPacking={handleAddPacking}
							/>
							<PackingBatch
								data={state.packing_list}
								toggleSwitchDisabled={state.disable_matrices}
								toggleStatus={state.toggle_packing_batch}
								handleToggleStatus={(status) => {
									handleToggleStatus(status, 'toggle_packing_batch');
								}}
							/>
							<PurchaseList
								toggleSwitchDisabled={false}
								toggleStatus={state.toggle_purchase_list}
								edit_visibility={false}
								multi_select={false}
								single_producer_allowed={state.selected_producers}
								handlePurchaseProducer={handlePurchaseProducer}
								handleToggleStatus={(status) => {
									handleToggleStatus(status, 'toggle_purchase_list');
									dispatch({
										type: ACTIONS.OFF_MATRICES,
									});
									dispatch({
										type: ACTIONS.CLEAR_PACKING_LIST,
									});
								}}
							/>
						</>
					)}
					{status === 'packing' && (
						<div style={{ display: 'grid' }}>
							<div className='packing-btn-container packing-align-start'>
								<button
									className='refresh-packing-btn '
									onClick={(e) => {
										e.preventDefault();
										cancelPackingBatch();
									}}
								>
									<BiChevronLeft size={22} />
									Back
								</button>
								<button
									className='update-packing-btn'
									onClick={(e) => {
										e.preventDefault();
										createPackingBatch();
										setTimeout(() => {
											history.go(0);
										}, 1000);
									}}
								>
									Update
								</button>
							</div>
							<PackingBatchMatrix
								toggleStatus={state.toggle_packing}
								selected_day={
									state.purchase_matrix[Object.keys(state.purchase_matrix)[0]][0].date
								}
								selected_shipment={state.selected_shipment}
								selected_station={state.selected_station}
								purchase_matrix={state.purchase_matrix}
								remaining_purchase_data={state.remaining_matrix}
								shipment_matrix={state.shipment_matrix}
								handleToggleStatus={(status) => {
									handleToggleStatus(status, 'toggle_packing');
								}}
								handlePackingDataChange={handlePackingDataChange}
							/>
						</div>
					)}
				</div>
			</section>
		</>
	);
};

const Dropdown = ({
	handleChange = (e) => {
		console.log('value', e.target.value);
	},
	data = [],
	selected_value = '',
	type = '',
}) => {
	const [selectedValue, setSelectedValue] = useState(selected_value);

	React.useEffect(() => {
		console.log(selected_value, 'day');
		setSelectedValue(selected_value);
	}, [selected_value]);

	return (
		<div>
			<select
				onChange={(e) => {
					e.preventDefault();
					var index = e.target.selectedIndex;
					var optionElement = e.target.childNodes[index];
					var id = optionElement.getAttribute('dataid');
					handleChange(e.target.value, id);
				}}
				value={selectedValue}
				style={{ marginLeft: 0 }}
			>
				<option disabled selected value=''>
					--Select an option--
				</option>
				{data.length > 0 &&
					data.map((item, index) => (
						<option value={item.name} dataid={item.id} key={index}>
							{item.name}
						</option>
					))}
			</select>
		</div>
	);
};

export default PackingManager;
