import React, { useEffect, useState } from "react";
import "./CostTarget.css";
import Modal from "react-modal";
import { GrClose } from "react-icons/gr";
import { HiArrowNarrowRight } from "react-icons/hi";
import WeekSelection from "../WeekSelection/WeekSelection";
import TotalCard from "../Cards/TotalCard";

const customStyles = {
  content: {
    background: "white",
    width: "43rem",
    maxWidth: "calc(100vw - 2rem)",
    maxHeight: "calc(100vh - 2rem)",
    boxshadow: "0 0 30px 0 rgba(0, 0, 0, 0.25)",
    overflowY: "auto",
    position: "relative",
  },
  overlay: {
    position: "fixed",
    zIndex: 999999,
    top: 0,
    left: 0,
    width: "100vw",
    height: "100vh",
    background: "rgba(0, 0, 0, 0.5)",
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
  },
};


const HistoryModalComponent = (props) =>{

  const [headerArrows, setHeaderArrows] = useState([]);
  const [historyData, setHistoryData] = useState(props.historyData);

  useEffect(()=>{
    const colLength = props.historyData.data[Object.keys(props.historyData.data)[0]].length;
    const x = props.historyData.data[Object.keys(props.historyData.data)[0]].slice(Math.max(colLength - 9, 1));
    
    for(let i = 0; i < 6 - colLength; i++){
      x.unshift('-');
    }
  
    let history = Object.keys(props.historyData.data).map(item => {
      console.log(props.historyData.data[item].length);
      const len = props.historyData.data[item].length;
      for(let i = 0; i < 6 - len; i++){
        props.historyData.data[item].unshift('');
        console.log(props.historyData.data[item]);
      }
      return props.historyData.data[item];
    })
    

    setHeaderArrows(x);
    setHistoryData(props.historyData);
    console.log(history)
  }, [props.historyData])
  
    
    return (
      
        <Modal
          isOpen={props.modal}
          onRequestClose={() => props.closeModal()}
          onAfterOpen={() => (document.body.style.overflow = "hidden")}
          onAfterClose={() => (document.body.style.overflow = "unset")}
          style={customStyles}
        >
          <div class="scrl_out" style={{ paddingBottom: "30px" }}>
            <div className={"modal_heading"}>
              <p>Sourcing Cost Record</p>
              <div
                className={"close-icon"}
                onClick={() => props.closeModal()}
              >
                <GrClose size={25} />
              </div>
            </div>
            <div>
              <WeekSelection
                sourcingWeek={historyData.result.sourcing_week}
                packingWeek={historyData.result.packing_week}
                fromHistoryPopup={true}
                data={historyData.result}
                week={props.week}
                year={props.year}
                history={true}
              />
            </div>

            <section className={"OrderTableWrap" + " " + "scrl_out"}>
              <table className={"history_table"}>
                <tr className={"table_head"}>
                  <th>Weight Class</th>

                  {headerArrows.map((val) => (
                    <th className={ val == '-' && 'hide_arrow'}>
                      <HiArrowNarrowRight size={16}/> 
                    </th>
                  ))}
                  <th>Price (NOK)</th>
                </tr>

                <tbody>
                  {Object.entries(historyData.data).map(
                    ([key, val]) => (
                      <tr key={key}>
                        <td>{key} kg</td>
                        {val.slice(Math.max(val.length - 10, 0)).map((v, i) => (
                          <td>{v}</td>
                        ))}
                      </tr>
                    )
                  )}
                </tbody>
              </table>
            </section>
          </div>
        </Modal>
      
    );
  
}

export default HistoryModalComponent;
