import React, { useReducer, useEffect } from 'react';
import { Link } from 'react-router-dom';
import MultiSelectAll from '../components/MultiSelect/MultiSelectAll';
import Sidebar from '../components/Sidebar/Sidebar';
import GlobalMatrixCard from '../components/GlobalMatrixCard/GlobalMatrixCard';
import ModelDropdownSelector from '../components/ModelSelectors/ModelDropdownSelector';
import RefinerServices from '../api/RefinerServices';
const refinerTypeOptions = {
	producer: 'Producer',
	exporter: 'Exporter',
};
const initialState = {
	refinerType: 'producer',
};
const ACTIONS = {
	SET_REFINER_TYPE: 'SET_REFINER_TYPE',
	SET_REFINER_OPTIONS: 'SET_REFINER_OPTIONS',
	SET_SELECTED_REFINER_OPTIONS: 'SET_SELECTED_REFINER_OPTIONS',
};
const reducer = (state, { type, payload }) => {
	switch (type) {
		case ACTIONS.SET_REFINER_TYPE:
			return { ...state, refinerType: payload };
		case ACTIONS.SET_REFINER_OPTIONS:
			return { ...state, refinerOptions: payload };
		case ACTIONS.SET_SELECTED_REFINER_OPTIONS:
			return { ...state, selectedRefinerOptions: payload };
		default:
			return state;
	}
};
const RefinerPage = () => {
	const [state, dispatch] = useReducer(reducer, initialState);
	const onChangeRefinerType = async (e) => {
		dispatch({
			type: ACTIONS.SET_REFINER_TYPE,
			payload: e.target.value,
		});
	};
	const fetchRefinerOptions = async (refinerType) => {
		let options = [];
		dispatch({ type: ACTIONS.SET_REFINER_OPTIONS, payload: [] });
		switch (refinerType) {
			case 'producer':
				const getProducerList = await RefinerServices.getProducerList();
				if (getProducerList.data && getProducerList.data.results) {
					options =
						getProducerList.data.results.map((x) => ({
							...x,
							label: x.producer,
							value: x.id,
						})) || [];
				}
				break;
			case 'exporter':
				const getExporterList = await RefinerServices.getExporterList();
				if (getExporterList.data && getExporterList.data.results) {
					options =
						getExporterList.data.results.map((x) => ({
							...x,
							label: x.exporter,
							value: x.id,
						})) || [];
				}
				break;
			default:
				break;
		}
		if (options.length) {
			dispatch({ type: ACTIONS.SET_REFINER_OPTIONS, payload: options || [] });
			dispatch({ type: ACTIONS.SET_SELECTED_REFINER_OPTIONS, payload: [] });
		}
	};
	const updateRefinerOptions = async (refinerType) => {
		const selectedOptions = (state.selectedRefinerOptions || [])
			.filter((x) => x.value != '*')
			.map((x) => x.value);
		switch (refinerType) {
			case 'producer':
				const xProducerConvert = await RefinerServices.xProducerConvert({
					producer: selectedOptions || [],
				});
				break;
			case 'exporter':
				const xExporterrConvert = await RefinerServices.xExporterConvert({
					exporter: selectedOptions || [],
				});
				break;
			default:
				break;
		}
		fetchRefinerOptions(refinerType);
	};
	useEffect(() => {
		if (state.refinerType && state.refinerType !== '') {
			fetchRefinerOptions(state.refinerType);
		}
	}, [state.refinerType]);
	const updateRefinery = () => {
		if (state.refinerType) {
			updateRefinerOptions(state.refinerType);
		}
	};
	return (
		<section className='sell_body_wrap'>
			{/* <Sidebar /> */}
			<Link to={'/home'}>
				<p className={'Back_link'}>Back</p>
			</Link>
			<GlobalMatrixCard
				headerCaption='Refiner'
				headerSubCaption=''
				enabledComponents={['HeadCaption', 'ButtonComponent']}
				updateButtonDisplay={true}
				updateButtonDisable={
					!state.refinerType || !state.refinerOptions || !state.refinerOptions.length
				}
				updateButtonHandle={updateRefinery}
			/>
			<div className='refiner_fc' style={{ padding: '10px' }}>
				<ModelDropdownSelector
					disabled={false}
					name='refiner_type'
					value={state.refinerType}
					data={refinerTypeOptions}
					onChange={onChangeRefinerType}
				/>
				<div className='multi-select'>
					{state.refinerType &&
						state.refinerOptions &&
						state.refinerOptions.length !== 0 && (
							<MultiSelectAll
								options={state.refinerOptions || []}
								placeholderButtonLabel={
									refinerTypeOptions[state.refinerType] || state.refinerType || ''
								}
								selectedOptions={
									state.refinerOptions && state.refinerOptions.length
										? state.selectedRefinerOptions
										: []
								}
								onOptionChange={(v) => {
									dispatch({ type: ACTIONS.SET_SELECTED_REFINER_OPTIONS, payload: v });
								}}
							/>
						)}
				</div>
			</div>
		</section>
	);
};

export default RefinerPage;
