import Api from './Api';

class RFQServices {
	postRFQ = async (data) => await Api.post('/purchaser/api/rfq/', data);

	getMatrixDataByRFQId = async (data) =>
		await Api.get(`/purchaser/api/rfq/?rfq_id=${data}`);
	updateRfqMatrix = async (matrxId, data) =>
		await Api.put(`/purchaser/api/rfq/${matrxId}/`, data);
	getRFQMatrixData = async (data) =>
		await Api.post(`purchaser/api/rfq/rfq-lists/`, data);
	getRFQPriceLine = async (data) =>
		await Api.post(`/purchaser/api/rfq-priceline/`, data);
	rejectRFQ = async (rfqId, data) =>
		await Api.put(`/purchaser/api/rfq/${rfqId}/status/`, data);
	viewPurchasebyId = async (rfqId) =>
		await Api.get(`/purchaser/api/rfq/${rfqId}/view-purchase/`);
	confirmPurchaseById = async (rfqId) =>
		await Api.get(`/purchaser/api/rfq-to-purchase/${rfqId}/`);
	getRFQRankingList = async () => await Api.get(`/purchaser/api/rfq/ranking/`);
}
export default new RFQServices();
