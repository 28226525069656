import React, { useEffect, useState } from 'react';
import axios from 'axios';
import './Nasdaq.css';
import CalendarServices from '../../../api/CalendarServices';

const NasdaqWeek = ({ updatePrices }) => {
	const [weekArray, setweekArray] = useState([]);
	const [selectedWeek, setSelectedWeek] = useState();
	const [publishedDate, setPublishedDate] = useState();
	const [weekDate, setWeekDate] = useState();
	const [timeLeft, setTimeLeft] = useState('');

	let availableDateTimer;

	useEffect(() => {
		getNasdaqWeeks();
	}, []);

	const getNasdaqWeeks = async () => {
		const getNasdaqWeeks = await CalendarServices.getNasdaqWeeks();
		if (getNasdaqWeeks.data) {
			const { data } = getNasdaqWeeks;
			setweekArray(data.result.week);
			setSelectedWeek(data.result.last_publication_week);
			let week = data.result.week.filter(
				(item) => item.week == data.result.last_publication_week
			)[0];
			updatePrices(week.prices);
			setPublishedDate(week ? week.published_date : '');
			setWeekDate(week ? week.week_date : '');
			availableDateTimer = setInterval(
				() => enableTimer(data.result.next_available_date),
				1000
			);
		}
	};

	const onWeekClick = (val) => {
		setPublishedDate(val.published_date);
		updatePrices(val.prices);
		setSelectedWeek(val.week);
		setWeekDate(val.week_date);
	};

	const enableTimer = (next_available_date) => {
		const endTime = new Date(next_available_date);
		const currentTime = new Date(Date.now());

		let timeRemaining = endTime.getTime() - currentTime.getTime();

		// if (timeRemaining <= 0) {
		// 	clearInterval(availableDateTimer);
		// 	timeRemaining = 0;
		// 	getNasdaqWeeks();
		// }

		const days = Math.floor(timeRemaining / (1000 * 60 * 60 * 24));
		const hours = Math.floor(
			(timeRemaining % (1000 * 60 * 60 * 24)) / (1000 * 60 * 60)
		);
		const minutes = Math.floor((timeRemaining % (1000 * 60 * 60)) / (1000 * 60));
		const seconds = Math.floor((timeRemaining % (1000 * 60)) / 1000);

		let updatedTime = days + ' days ' + hours + ' hours ' + minutes + ' min ';
		setTimeLeft(updatedTime);
	};

	return (
		<>
			<div className={'nasdaq_week'}>
				<p>Nasdaq Week</p>
				<div className='nasdaq_week_wrap'>
					{weekArray.map((val, i) => {
						return (
							<div
								key={i}
								onClick={() =>
									i <= weekArray.length - 1 && val.prices.length
										? onWeekClick(val)
										: null
								}
								className={`bubbles ${val.is_next_published_week
										? 'greyed_out'
										: !val.prices.length
											? 'inactive_week'
											: selectedWeek == val.week
												? 'active_week'
												: 'allow_cursor'
									} ${val.is_last_published_week && 'current_week'} ${!val.prices.length && 'no_price'
									}`}
							>
								{val.week}
							</div>
						);
					})}
				</div>
				<p>{weekDate}</p>
				<p className='time_left_wrap'>Published {publishedDate}</p>
			</div>
			<div className='time_left_wrap'>
				<p>will be published in {timeLeft}</p>
			</div>
		</>
	);
};

export default NasdaqWeek;
