import React, { useReducer } from 'react'
import GlobalMatrixCard from '../GlobalMatrixCard/GlobalMatrixCard';
import Sidebar from '../Sidebar/Sidebar';


/**
* @author
* @function RFQSample
**/
const ACTIONS = {
    NINE_STAR_SELECTOR_DIMENSION_CHANGE: 'NINE_STAR_SELECTOR_DIMENSION_CHANGE',
    SPLIT_SELECTOR_CHANGE: 'SPLIT_SELECTOR_CHANGE',
    SPLIT_SELECTOR_DISABLE: 'SPLIT_SELECTOR_DISABLE',
    SPLIT_SELECTOR_ENABLE: 'SPLIT_SELECTOR_ENABLE',
    SET_MATRIX_TOTAL: 'SET_MATRIX_TOTAL'

}
const initialState = {
    headerInfo: {
        caption: 'RFQ-Page',
        subCaption: 'Request For Quote'
    },
    nineStarSelector: {
        disabledOptions: [],
        selectedDimension: '9_1'
    },
    splitSelector: {
        checked: true,
        disabled: false
    }
};
const reducer = (state, { type, payload }) => {
    switch (type) {
        case ACTIONS.NINE_STAR_SELECTOR_DIMENSION_CHANGE:
            return {
                ...state, nineStarSelector: { ...state.nineStarSelector, selectedDimension: payload }
            };
        case ACTIONS.SPLIT_SELECTOR_CHANGE:
            return {
                ...state, splitSelector: { ...state.splitSelector, checked: payload }
            };
        case ACTIONS.SPLIT_SELECTOR_DISABLE:
            return {
                ...state, splitSelector: { ...state.splitSelector, disabled: true }
            };
        case ACTIONS.SPLIT_SELECTOR_ENABLE:
            return {
                ...state, splitSelector: { ...state.splitSelector, disabled: false }
            };
        default:
            return {
                ...state,
            };
    }
};

const RFQSample = (props) => {
    const [state, dispatch] = useReducer(reducer, initialState);
    // initiating api
    // initiating rfq page
    // including enable components from the api
    const nineStarHandleChangeDimension = (data) => {
        dispatch({
            type: ACTIONS.NINE_STAR_SELECTOR_DIMENSION_CHANGE,
            payload: data
        });
    }

    const splitSelectorHandleChange = (e) => {
        dispatch({
            type: ACTIONS.SPLIT_SELECTOR_CHANGE,
            payload: e.target.checked
        })
    }
    return (
        <div>
            {/* <Sidebar /> */}
            <section className="sell_body_wrap">
                <GlobalMatrixCard
                    //header information
                    headerCaption={state.headerInfo.caption}
                    headerSubCaption={state.headerInfo.subCaption}

                    enabledComponents={[]}
                    //Nine star selector
                    nineStarSelectedDimension={state.nineStarSelector.selectedDimension}
                    nineStarHandleChangeDimension={nineStarHandleChangeDimension}
                    nineStarDisabledOtpions={state.nineStarSelector.disabledOptions}

                    //split selector
                    splitSelectorChecked={state.splitSelector.checked}
                    splitSelectorHandleChange={splitSelectorHandleChange}
                    splitSelectorDisabled={state.splitSelector.disabled}

                    //matrix totals
                    orderTotalBox ={20}
                    dispalyOrderTotalBox ={true}
                    orderTotalWeight={20}
                    displayOrderTotalWeight={true}
                    orderTotalUSD={25}
                    displayOrderTotalUSD={true}

                    //Week component
                    sourcingWeek={'Week 22'}
                    sourcingDate={'15-09-1234'}
                    shipmentWeek={'Week 22'}
                    shipmentDate={'15-09-1234'}
                    sourcingWeekDisplay={true}
                    shipmentWeekDisplay={true}

                    //matrix Buttons
                    updateButtonDisplay={true}
                    updateButtonDisable={false}
                    updateButtonHandle={()=>{console.log('button clicked update')}}
                    refreshButtonDisplay={true}
                    refreshButtonHandle={()=>{console.log('button clicked refresh')}}
                />
                {/* 
                <Matrix Component goes here />
                 */}
            </section>
        </div>
    )

}

export default RFQSample