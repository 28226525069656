import React from "react";
import ReactDOM from "react-dom";
import { BrowserRouter } from "react-router-dom";
import {
  ToastsContainer,
  ToastsStore,
  ToastsContainerPosition,
} from "react-toasts";
import "./index.css";
import App from "./App";
import * as serviceWorker from "./serviceWorker";
import Axios from "axios";
import { createBrowserHistory } from "history";
import BrowserStorage from "./utils/BrowserStorage";

const history = createBrowserHistory();

Axios.defaults.baseURL = 'https://dev.exocoetidae.no/';
// Axios.defaults.baseURL = 'http://localhost:8000/';
// http://dev-old.exocoetidae.no/

Axios.interceptors.request.use(
  (request) => {
    console.log(request.url.split("?"));

    if (request.params && localStorage.customerLocation) {
      request.params["customer_shipment_location"] =
        localStorage.customerLocation;
    } else if (localStorage.customerLocation) {
      request.url =
        request.url +
        "?customer_shipment_location=" +
        localStorage.customerLocation;
    } else if (localStorage.prospect) {
      request.url = request.url + "?prospect=" + localStorage.prospect;
    } 
    else if (BrowserStorage.getValue('userInfo')?.accessId) {
    }
    document.body.classList.add("loading-indicator");
    return request;
  },
  (error) => {
    document.body.classList.add("loading-indicator");
    return Promise.reject(error);
  }
);

Axios.interceptors.response.use(
  (response) => {
    document.body.classList.remove("loading-indicator");
    return response;
  },
  (error) => {
    if (error.response.status === 403) {
      ToastsStore.error(error.response.data.result);
      document.body.classList.remove("loading-indicator");
      localStorage.clear();
      window.location = "/";
    }
    if (error.response.status === 401) {
      ToastsStore.error(error.response.data.result);
      document.body.classList.remove("loading-indicator");
      localStorage.clear();
      window.location = "/";
    } else {
      document.body.classList.remove("loading-indicator");
    }
    return Promise.reject(error);
  }
);
const app = (
    <BrowserRouter history={history}>
      <App />
      <ToastsContainer
        position={ToastsContainerPosition.BOTTOM_CENTER}
        store={ToastsStore}
      />
    </BrowserRouter>
);

ReactDOM.render(app, document.getElementById("root"));
serviceWorker.unregister();
