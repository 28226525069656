import { decryptValue, encryptValue } from '../util';

class BrowserStorage {
	constructor(key) {
		this.encryptKey = key || '2e35f242a46d67eeb74aabc37d5e5d05';
	}

	//receives: Key as String and a value.
	setValue = (key, value) => {
		return localStorage.setItem(key, JSON.stringify(value));
		return localStorage.setItem(key, encryptValue(value, this.encryptKey));
	};

	//receives : Key of LocalStorage as String
	getValue = (key) => {
		return JSON.parse(localStorage.getItem(key));
		let encryptedValue = localStorage.getItem(key);
		if(encryptedValue !== null){
			try{
				return decryptValue(encryptedValue, this.encryptKey)
			}
			catch(err){
				console.error(err);
				localStorage.clear();
				window.location='/';
			}
		}
	};
	clear = (key=null)=>{
		if(key){
			localStorage.removeItem(key);
		}
		else{
			localStorage.clear();
		}
	}
}

export default new BrowserStorage();
