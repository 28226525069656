import React, { useState } from 'react';
import { ImFilePdf } from 'react-icons/im';
import { Link } from 'react-router-dom';
import Sidebar from '../components/Sidebar/Sidebar';
import StudioServices from '../api/StudioServices';
import '../styles/BitfishDoc.css';
import Moment from 'react-moment';

const BitfishDocPage = () => {
	const [docData, setDocData] = useState([]);

	React.useEffect(() => {
		fetchDocDetails();
	}, []);

	const fetchDocDetails = async () => {
		const RequestDocDetails = await StudioServices.getBitfishDoc();
		if (RequestDocDetails.data) {
			// console.log('Response Data:', RequestDocDetails.data);
			const { result } = RequestDocDetails.data;

			setDocData(result);
		} else {
			console.log('Error on Fetching', RequestDocDetails.error);
		}
	};

	return (
		<section className={'body_wrap'}>
			{/* <Sidebar /> */}
			<div className={'cont_wp'}>
				<Link to={'/home'}>
					<p className={'Back_link'}>Back</p>
				</Link>
				<section className={'cmn_head'}>
					<p>Bitfish Docs</p>
				</section>
				<section>
					{docData.length > 0 ? (
						<ul className='doc-wrap'>
							{docData.map((item) => (
								<li className='doc-card'>
									<div>
										<h4>{item.document_name}</h4>
										<p>{item.caption}</p>
										<h6>
											{<Moment date={item.created} format='DD-MMMM-YYYY (h:mm A)' />}
										</h6>
									</div>
									<div className='svg-container'>
										<a href={item.doc} target='_blank'>
											<ImFilePdf size={20} />
										</a>
									</div>
								</li>
							))}
						</ul>
					) : (
						<p>No data available.</p>
					)}
				</section>
			</div>
		</section>
	);
};

export default BitfishDocPage;
