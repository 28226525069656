import Api from './Api';

class QuoteServices {
	updateQuoteAdminMatrix = async (matrxId, data) =>
		await Api.put(`/accumulator/api/quotee-admin/${matrxId}/`, data);

	getQuoteMatrix = async (is_pending) =>
		await Api.get(`/accumulator/api/quotee/0/view-order/?is_pending=${is_pending}`);

	getOrder = async (quoteId = 0, from_admin = 1) =>
		await Api.get(`/accumulator/api/quotee/${quoteId}/view-order/?from_admin=${from_admin}`);

	getQuote = async () =>
		await Api.get(`/accumulator/api/quotee/get-full/`);

	confirmOrder = async (data, quoteId,isAdmin=false) =>
		await Api.post(`/accumulator/api/quotee/${quoteId}/place-order/?from_admin=${isAdmin?1:0}`, data);

	getQuoteData = async (data) =>
		await Api.post(`/customer/get_quote/`, data);

	requestQuote = async (data = {}) =>
		await Api.post(`/customer/send_request/`, data);

	getOffer = async (data = {}) =>
		await Api.post(`/customer/get_offer/`, data);

	getPriceIndication = async (data) =>
		await Api.post(`accumulator/api/quotee/`, data);

	convertQuoteToOrder = async (quoteId, source = 'web') =>
		await Api.get(`/accumulator/api/quotee-to-order-placed/${quoteId}/?source=${source}`);

	getQuoteCalculator = async (id, origin) =>
		await Api.get(`accumulator/api/quotee-calculator/?id=${id}&origin=${origin}`);

	getMiniInvoice = async () =>
		await Api.get(`/administrator/api/mini-invoice/mini-invoice/`);

	getPlacedOrders = async (params={}) => 
		await Api.get(`/customer/api/placed-orders/`,{params});
	placeOrder = async (data)=>
		await Api.post(`/price/place_order/`,data);
	
	getLastPriceForOrder = async ()=>
		await Api.get(`/price/get_last_price_for_order/`);
	
	getPriceForOrder = async (data)=>
		await Api.post(`/price/get_price_for_order/`,data);

	getActiveQuotes = async (source = 'web')=>
		await Api.get(`accumulator/api/quotee-admin/active-quotes/?source=${source}`);
}
export default new QuoteServices();
