import Api from './Api';

class BaseOrderServices {
	updateBaseOrderMatrix = async (matrxId, data) =>
		await Api.put(`/accumulator/api/base-order/${matrxId}/`, data);

	getBaseOrderMatrix = async (product_id = 1) =>
		await Api.get(`/accumulator/api/base-order/?product_id=${product_id}`);
	
	createBaseOrder = async (data,product_id=1)=>
		await Api.post(`/accumulator/api/base-order/?product_id=${product_id}`,data);
	
	getLastOrder = async(data) =>
		await Api.post(`/customer/get_last_order/`,data);
}
export default new BaseOrderServices();
