import React, { useEffect, useState } from 'react';
import ToggleSwitch from '../components/Toggle/ToggleSwitch';
import './matrixWithToggleSwitch.css';

const matrixWithToggleSwitch = (WrappedComponent, toggleProps = {
	tableStyles:{}
}) => {
	return (props) => {
		const [toggle, setToggle] = useState(false);
		//toggling matrix
		const handleToggleMatrix = () => {
			setToggle(!toggle);
			props.handleToggleStatus && props.handleToggleStatus(!toggle);
		};
		useEffect(() => {
			if (
				props.toggleSwitchDisabled ||
				props.toggleStatus ||
				toggleProps.title === 'Outlook Matrix' ||
				toggleProps.title === 'Residual Matrix' ||
				toggleProps.title === 'Tender Matrix'
			) {
				setToggle(props.toggleStatus);
			}
		}, [props.toggleSwitchDisabled, props.toggleStatus]);

		return (
			<>
				<div className='table-container' style={toggleProps.tableStyles}>
					<div className='matrix_toggle'>
						<h4>{toggleProps.title && toggleProps.title}</h4>
						<ToggleSwitch
							status={!props.toggleStatus}
							onToggleChange={() => handleToggleMatrix()}
							fromMatrix={true}
							disabled={props.toggleSwitchDisabled ? true : false}
						/>
					</div>
					{props.selected_day !== undefined ? (
						<p style={{ margin: '0px 10px 10px 10px' }}>{props.selected_day}</p>
					) : null}
					{<WrappedComponent {...props} toggle={toggle} />}
				</div>
			</>
		);
	};
};
export default matrixWithToggleSwitch;
