import React, { useState, useEffect } from 'react';
import Button from '../../components/Ui/Button/Button';
import Input from '../../components/Ui/Input/Input';
import '../../components/Toggle/ToggleSwitch.css';
import './Auth.css';
import { Link } from 'react-router-dom';
import AuthServices from '../../api/AuthServices';
import BrowserStorage from '../../utils/BrowserStorage';
import { useHistory } from 'react-router-dom';
import { Role } from '../../helper/role';
const Auth = () => {
	const [toggle, setToggle] = useState(false);
	const [formData, setFormData] = useState({
		email: '',
		password: '',
		appType: 'down_stream',
		errorMsg: '',
		toggle: false,
	});
	const history = useHistory();

	const checkValidity = (value) => {
		return value && value.trim() !== '' ? true : false;
	};
	const inputChangedHandler = (event) => {
		const target = event.target;
		const value = target.type === 'checkbox' ? target.checked : target.value;
		const name = target.name;
		const newFormData = { ...formData, [name]: value };
		setFormData(newFormData);
	};

	const callLoginExtendedApi = async (accessId) => {
		const loginExtended = await AuthServices.loginExtended(accessId);
		if (loginExtended.data) {
			const { data } = loginExtended;
			localStorage.setItem('onBase', data.data.base_order);
			BrowserStorage.setValue('token', data.data.token);
			BrowserStorage.setValue('userInfo', {
				...BrowserStorage.getValue('userInfo'),
				accessId: data.data.active_access_id,
			});
			// localStorage.setItem("accessId", data.data.active_access_id);
			history.push('/home');
		} else {
			const newFormData = {
				...formData,
				errorMsg: 'Invalid Username or Password',
			};
			setFormData(newFormData);
			console.error(loginExtended.error);
		}
	};

	const submitHandler = async (event) => {
		event.preventDefault();
		const newFormData = { ...formData };
		newFormData.errorMsg = '';
		if (!checkValidity(newFormData.email) || newFormData.password === '') {
			newFormData.errorMsg = 'Enter valid username and password';
		} else {
			const loginService = await AuthServices.login({
				email: newFormData.email,
				password: newFormData.password,
				stream_type: newFormData.appType,
			});
			if (loginService.data) {
				const { data } = loginService;
				let userInfo = {
					token: data.data.token,
					userName: data.data.name,
					name: data.data.name,
					currentRole: data.data.current_role,
					email: data.data.email,
					userId: data.data.userId,
					accessId: data.data.access_ids[0],
					stream_type: newFormData.appType,
					access_type: data.data.access_type,
				};
				BrowserStorage.setValue('userInfo', userInfo);
				BrowserStorage.setValue('token', data.data.token);
				// localStorage.setItem('token', data.data.token);
				// BrowserStorage.setValue('userName', data.data.name);
				// localStorage.setItem('customer', data.data.customer);
				// localStorage.setItem('shortName', data.data.name);
				// localStorage.setItem('firstName', data.data.first_name);
				// localStorage.setItem('isAdmin', data.data.is_admin);
				// localStorage.setItem('isAgent', data.data.is_agent);
				// localStorage.setItem('isCustomerAdmin', data.data.is_customer_user_admin);
				// localStorage.setItem('isProspect', data.data.is_prospect_user);
				// localStorage.setItem('accessId', data.data.access_ids[0]);
				// localStorage.setItem('hasQuote', data.data.active_quote);
				localStorage.setItem('isNote', data.data.show_customer_note);
				// localStorage.setItem('customerLocation', data.data.customer_shipment_locations.length ? data.data.customer_shipment_locations[0] : '');

				// if (localStorage.isAdmin === "true" || data.data.customer_shipment_locations.length > 1) {
				if (
					newFormData.appType === 'mid_stream' &&
					[Role.ADMIN].includes(BrowserStorage.getValue('userInfo')?.currentRole)
				) {
					callLoginExtendedApi(data.data.access_ids[0]);
				} else if (
					newFormData.appType === 'down_stream' &&
					[Role.ADMIN, Role.CUSTOMER_ADMIN].includes(
						BrowserStorage.getValue('userInfo')?.currentRole
					)
				) {
					history.push('/select-user');
				} else if (
					newFormData.appType === 'down_stream' &&
					[Role.CUSTOMER, Role.PROSPECT].includes(
						BrowserStorage.getValue('userInfo')?.currentRole
					)
				) {
					history.push('/home');
				} else {
					localStorage.clear();
					newFormData.errorMsg =
						'Access Permission Denied! Please contact administrator';
				}
			} else {
				let errorReturns = loginService.error?.response;
				if (errorReturns.data) {
					console.log('error data', errorReturns.data);
					newFormData.errorMsg =
						errorReturns.data?.message ?? errorReturns.data?.error;
				} else {
					newFormData.errorMsg = 'Invalid Username or Password';
				}
			}
		}
		setFormData(newFormData);
	};

	const handleToggle = (value) => {
		setFormData({
			...formData,
			appType: !value ? 'mid_stream' : 'down_stream',
			toggle: !value,
		});
	};
	return (
		<div className={'loginWrap'}>
			<div className={'b_rt_im'}></div>
			<div className={'loginOuter'}>
				<section>
					<div
						className={'logo_landing'}
						style={{ cursor: 'pointer' }}
						onClick={() => window.open('https://seafood7.no/', '_blank')}
					></div>
					<p>Salmon across the seven seas</p>
					<label>Overseas salmon in 1-2-3</label>
					<div className={'downloadWrap'}>
						<div className={'down_btn_out'}>
							<div className={'btn_app'}>
								<div className={'app_ico'}></div>
								Download from
								<br /> Android App Store
							</div>
							<div className={'btn_app'}>
								<div className={'app_ico'}></div>
								Download from
								<br /> App Store
							</div>
						</div>
						<div className='fc_stream_wrapper'>
							<div
								onClick={() => handleToggle(formData.toggle)}
								className={
									formData.toggle === true
										? 'wrg-toggle--checked  wrg-toggle pos-relative'
										: 'wrg-toggle pos-relative'
								}
							>
								<div
									className='wrg-toggle-container'
									style={{
										backgroundColor: formData.toggle ? 'green' : '#4d4d4d',
									}}
								>
									<div className='wrg-toggle-check'>
										<span></span>
									</div>
									<div className='wrg-toggle-uncheck'>
										<span></span>
									</div>
								</div>
								<div className='wrg-toggle-circle'></div>
								<input
									type='checkbox'
									aria-label='Toggle Button'
									className='wrg-toggle-input'
								/>
							</div>
						</div>
					</div>
				</section>
				<section>
					<form className={'loginFormWrap'} onSubmit={submitHandler}>
						<p className={'loginFormHead'}>Login</p>
						<p className={'loginFormSubHead'}>Please Login To Continue</p>

						<Input
							key='email'
							elementType='input'
							elementConfig={{
								type: 'email',
								name: 'email',
								placeholder: 'Mail Address',
								label: 'Username',
								autoComplete: 'off',
							}}
							value={formData.email}
							onChange={inputChangedHandler}
						/>
						<Input
							key='password'
							elementType='input'
							elementConfig={{
								type: 'password',
								placeholder: 'Password',
								name: 'password',
								label: 'Password',
								autoComplete: 'off',
							}}
							value={formData.password}
							onChange={inputChangedHandler}
						/>

						<p className={'log_err'}>{formData.errorMsg || ''}</p>
						<Button btnType='Success'>Login</Button>
						<Link to={'/forgot-password'}>
							<p className={'fP'}>Forgot Password?</p>
						</Link>
					</form>
				</section>
			</div>
		</div>
	);
};
export default Auth;
