import React, { Component } from 'react'
import { Link } from 'react-router-dom';
import Sidebar from '../../Sidebar/Sidebar';

export default class ExportStatistics extends Component {

    state = {
        export_statistics: [],
        result: null
    }

    async componentDidMount() {
        const url = 'https://dev.exocoetidae.no/market/api/sjomat-export-statistics/country/?statistics=5&country=AE&period_start=2020-01'
        const headers = {
            'Authorization': '558ef807-ad2b-4aba-8629-c8d227745493'
        }
        const exportStatisticsResponse = await fetch(url, {headers:headers}).then(res => res.json())
        const statistics = exportStatisticsResponse.data
        console.log(statistics)
        this.setState({ export_statistics: statistics });
    }

    render() {
        return (

            <div >
                <ul>
                    {this.state.export_statistics.map((statistic) => (
                        <li key={statistic.period}>{statistic.period}: {statistic.volume}</li>
                    ))}
                </ul>
            </div>

        )
    }

}