import Api from './Api';

class OrderPlacedServices {
	updateOrderPlacedMatrix = async (matrxId, data) =>
		await Api.put(`/accumulator/api/order-placed/${matrxId}/`, data);

	getOrderPlacedMatrixOnActive = async (request_type = 'active') =>
		await Api.get(`/accumulator/api/order-placed/?request_type=${request_type}`);
}
export default new OrderPlacedServices();
