import React,{useState,useEffect, Fragment} from 'react';
import { MdArrowBackIos, MdFlight, MdFormatQuote, MdLocalAirport, MdModeEditOutline, MdOutlineTimeline } from 'react-icons/md';
import {GrAdd, GrUpgrade} from 'react-icons/gr'
import {VscInspect} from 'react-icons/vsc'
import {BsClipboardData} from 'react-icons/bs'
import '../styles/UserDetails.css';
import {useHistory,useParams} from 'react-router-dom';
import preProspectIMG from '../assets/images/preprospect.png'
import prospectIMG from '../assets/images/prospect.png'
import customerIMG from '../assets/images/customer-ship.png'
import { ToastsStore } from 'react-toasts';
import UserServices from '../api/UserServices';
import userIMG from '../assets/images/user-bg.png'
import editIMG from '../assets/images/edit.png'
import { FiPhone } from 'react-icons/fi';
import { AiFillStar, AiOutlineMail, AiOutlineStar } from 'react-icons/ai';
import {GoGlobe, GoLocation} from 'react-icons/go'
import {BiCodeCurly} from 'react-icons/bi'
import fishIMG from '../assets/images/fish-red.png'

import {ReactComponent as FishIconPotential} from '../assets/images/icon-fish-salmon-subtle.svg'
import {ReactComponent as FishNoInfo} from '../assets/images/icon-fish-salmon-black.svg'
import {ReactComponent as FishPassive} from '../assets/images/icon-fish-grey.svg'
import {ReactComponent as FishIconActive} from '../assets/images/icon-fish-salmon.svg'
import { FaCalculator } from 'react-icons/fa';
import airportIMG from '../assets/images/icon-airport-tower-grey.svg'
import addressIMG from '../assets/images/icon-address-grey.svg'
import countryIMG from '../assets/images/icon-globe-grey.svg'

function UserDetails() {
  const [customerData, setCustomerData] = useState({})
  const history = useHistory();
  const URLParams = useParams();



  const fetchData = async() =>{
    try {
      let access_type = URLParams.access_type.replace(/_/g, "-")
      const getData = await UserServices.userDetail({customerId:URLParams.id,access_type})
      if(getData.data){
        const {success} = getData.data
        success && setCustomerData(getData.data.result)
      }
    } catch (error) {
      ToastsStore.error('An error Occurred on Retrieving Data, Try Reloading.')
    }
  }

  useEffect(() => {
    fetchData()
    
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])


  const RenderDetail = ({accessType='pre_prospect',data={}}) =>{
    const [entity, setEntity] = React.useState(null)

    React.useEffect(() => {
      switch (accessType) {
        case 'pre_prospect':
          setEntity(1)
          break;
        case 'prospect':
          setEntity(2)
          break;
        case 'customer_shipment_location':
          setEntity(3);
          break;
        default:
          setEntity(null)
          break;
      }  
    }, [])


    const EntityTitle = ({entity=1}) =>{
      return (
        <div className='entity_title_container'>
          <img src={entity===1?preProspectIMG:entity===2?prospectIMG:customerIMG} alt='entity-title' className='entity_title_icon'/>
          <div>
            <h5 className='entity_title_h5'>{data.prospect}{data.is_selected?<AiFillStar color='#f54542'/>:<></>}</h5>
            <p className='entity_title_p'>{entity===1?"Pre Prospect":entity===2?'Prospect':"Customer Shipment Location"}</p>
          </div>
        </div>
      )
    }

    const EntityActions = ({entity}) =>{

      const [actions] = useState([
        {
          icon:<MdModeEditOutline color='#002776'/>,
          text: 'Edit',
          handleClick:function(a){
            history.push(`/business/edit/${URLParams.access_type}/${URLParams.id}`)
          },
          isShown:true,
        },
        {
          icon:<GrUpgrade color='#002776'/>,
          text: 'Upgrade',
          handleClick:function(a){},
          isShown:entity===2?false:true,
        },
        {
          icon:<VscInspect color='#002776'/>,
          text: 'Inspect',
          handleClick:function(a){},
          isShown:true,
        },
        {
          icon:<BsClipboardData color='#002776'/>,
          text: 'Requirement',
          handleClick:function(a){},
          isShown:true,
        },
        {
          icon:<MdOutlineTimeline color='#002776'/>,
          text: 'Customer Journey',
          handleClick:function(a){},
          isShown:true,
        },
      ])

      return (
        <div className='entity_ico_container'>
          {actions.map((item,index)=><div className='entity_ico_action' key={index} onClick={item.handleClick}>
            {item.icon}
            <p className='entity_ico_text'>{item.text}</p>
          </div>)}

        </div>
      )
    }

    const EntityContent = () =>{

    const DescriptionCustomComponent = ({icon=<></>,value='',isUrl=false,componentStyle={placeItems:'center'},paragraphStyle={lineHeight:1.7}}) =>{
      return (
        <span style={{display:'grid',gap:10,gridTemplateColumns:'25px 1fr',...componentStyle}}>
          {icon}{isUrl && value!=='--'?<a href={value} target='_blank'><p>{value}</p></a>:<p style={{whiteSpace: "pre-line",...paragraphStyle}}>{value}</p>}
        </span>
      )
    }

    const FishCustomComponent = ({value="",status = 4}) =>{
      return(
        <div  style={{display:'grid',gap:10,gridTemplateColumns:'25px 1fr'}}><span style={{display:'flex',alignItems:'center',justifyContent:'center'}}>
          {status===-1 && <div style={{display:'flex',alignItems:'center',justifyContent:'center'}}>--</div>}
          {status===0 &&<FishPassive  width={20}/>}
          {status===1 &&<FishIconPotential  width={20}/>}
          {status===2 &&<FishIconActive width={20}/>}        
          </span><p style={{display:'flex',alignItems:'center',lineHeight:1.7}}>{value}</p>
        </div>
      )
    }

      return(
        <div className='entity_content_container'>
          <div className='entity_content_wrap' >
            <p className='entity_content_key'>Destination</p>
            <div className='entity_content_value'>
              {/* <DescriptionCustomComponent icon={<img src={airportIMG} alt="airport" style={{width:18,height:18}} />} value={`${data?.seafood_airport?.airport?.airport??'--'} ${data?.seafood_airport?.airport?.iata_code? '( '+data?.seafood_airport?.airport?.iata_code + ' )':''}`}/> */}
              {/* <DescriptionCustomComponent icon={<img src={countryIMG} alt="airport" style={{width:18,height:18}} />} value={data?.seafood_country?.country?.country}/> */}
              <DescriptionCustomComponent icon={<img src={airportIMG} alt="airport" style={{width:18,height:18}} />} value={`${data?.seafood_airport?._airport??'--'}`}/>
              <DescriptionCustomComponent icon={<img src={countryIMG} alt="airport" style={{width:18,height:18}} />} value={`${data?.seafood_airport?.city? data?.seafood_airport?.city + ',':''} ${data?.seafood_country?.country?.country} `}/>
                            

            </div>
          </div>
          <div className='entity_content_wrap' >
            <p className='entity_content_key'>Address</p>
            <div className='entity_content_value'><DescriptionCustomComponent componentStyle={{placeItems:'flex-start'}} value={data?.invoice_address_===''?'--':data?.invoice_address_} paragraphStyle={{lineHeight:1.2}} icon={<img src={addressIMG} alt="airport" style={{width:20,height:20,margin:'0 auto'}} />}/></div>
          </div>
          <div className='entity_content_wrap' >
            <p className='entity_content_key'>URL</p>
            <div className='entity_content_value'>
              <DescriptionCustomComponent icon={<BiCodeCurly  color='#aaa' size={18}/>} value={data?.url===null || data?.url==='' || !data?.url?"--":data?.url} isUrl={true}/>
            </div>
          </div>
          <div className='entity_content_wrap' >
            <p className='entity_content_key'>Industry</p>
            <div className='entity_content_value'>{[{
              text:"Retail",
              available:data?.if_retail ??false
            },
            {
              text:'Horeca',
              available:data?.if_horeca ?? false
            },
            {
              text:"Processing",
              available:data?.if_processing ??false
            }].map((item,index)=><div  style={{display:'grid',gap:10,gridTemplateColumns:'25px 1fr'}}><span style={{display:'flex',alignItems:'center'}}>{item.available && <AiOutlineStar color='#aaa' size={18}/>}</span><p style={{lineHeight:1.7}}>{item.text}</p></div>)}</div>
          </div>
          <div className='entity_content_wrap' >
            <p className='entity_content_key'>Seafood</p>
            <div className='entity_content_value' style={{gap:10}}>
            <div style={{display:'grid',gridAutoFlow:'row'}}>{[{
              text:"Fresh",
              status:data?.seafood_fresh ?? -1
            },
            {
              text:'Frozen',
              status:data?.seafood_frozen ?? -1
            },].map((item,index)=><Fragment key={index}>
              <FishCustomComponent status={item.status} value={item.text}/>
            </Fragment>)}</div>
            <div style={{display:'grid',gridAutoFlow:'row'}}>{[{
              text:"HOG",
              status:data?.seafood_hog ?? -1
            },
            {
              text:'Processed',
              status:data?.seafood_processed ?? -1
            },].map((item,index)=><Fragment key={index}>
            <FishCustomComponent status={item.status} value={item.text}/>
          </Fragment>)}</div>
            <div style={{display:'grid',gridAutoFlow:'row'}}>{[{
              text:"Salmon",
              status:data?.seafood_salmon ?? -1
            },
            {
              text:'Other species',
              status:data?.seafood_other_species ?? -1
            },].map((item,index)=><Fragment key={index}>
            <FishCustomComponent status={item.status} value={item.text}/>
          </Fragment>)}</div>
            </div>
          </div>
        </div>
      )
    }

    const EntityIndication = () =>{

      const IndicatorComponent = ({value="",component=<></>}) =>{
        return (
          <span style={{display:'flex',alignItems:'center',gap:2}}>{component}<p style={{fontSize:10,fontWeight:600}}>{value}</p></span>
        )
      }

      return(
        <div style={{display:'flex',gap:10}}>
          <IndicatorComponent value="Active"  component={<FishIconActive width={18}/>}/>
          <IndicatorComponent value='Potential' component={<FishIconPotential width={18}/>} />
          <IndicatorComponent value='Passive' component={<FishPassive width={18}/>} />        
          <IndicatorComponent value='No info' component={ <div style={{display:'flex',alignItems:'center',justifyContent:'center'}}>--</div>}     />   
          <IndicatorComponent value='Selected' component={<AiFillStar color='#f54542' size={18}/>}/>
        </div>
      )
    }

    const UserComponent = ({item}) =>{
      return(<div className='ud_users_wrap'>
      <span>
      <p className='ud_indicators'>{item?.role.filter(e=>e.role==='Quotes and Orders').length>0 && <MdFormatQuote color='red' size={18}/>}
        {item?.role.filter(e=>e.role==='Shipments notifications').length>0 &&<MdFlight color='red' size={20}/>}
        {item?.role.filter(e=>e.role==='Accounting').length>0 &&<FaCalculator color='red' size={15}/>}
       
        </p>
      </span>
      <div className='ud_users_discription'>
        <h5 className='ud_user_title brk_word'><img src={userIMG} alt='user' style={{width:24,height:24}}/>{item?.first_name} {item?.last_name}</h5>
        <span className='ud_user_subtitle brk_word'> <FiPhone className='ud_users_description_ico' color='#B0B0B0' size={17}/>{item?.phone_number ? <a className='ud_user_hyperlink' href={`tel:${item?.phone_number}`}>{item?.phone_number}</a>: "--"}</span>
        <span  className='ud_user_subtitle brk_word'><AiOutlineMail className='ud_users_description_ico' color='#B0B0B0' size={17}/>{item?.email?<a className='ud_user_hyperlink' href={`mailto:${item?.email}`}>{item?.email}</a> :"--"}</span>
        
      </div>
      <div className='ud_user_action_wrap'>
      {item?.is_main_contact &&<AiFillStar color='002776' size={20}/>}
        <img src={editIMG} alt='edit' style={{width:20,height:20,cursor:'pointer'}} onClick={(e)=>{
          e.preventDefault()
          history.push(`/business/edit/${URLParams.access_type}/${URLParams.id}/user/${item.id}`)
        }}/>
      </div>
    </div>)
    }

    return (
      <section>
       { Object.keys(data).length>0?<div className='ud_contents'>
          <div>
            <EntityTitle entity={entity} />
            <EntityActions entity={entity}/>
            <EntityContent />
            <EntityIndication />
          </div>
          <div className='ud_basic_container users_list'>
            <div className='ud_users_heading'>
              <h5>{entity===1?"Pre-users":entity===2?"Users":<></>}</h5>
              {entity===1 && <span className='ud_users_add_ico'><GrAdd  onClick={(e)=>{
          e.preventDefault()
          history.push(`/business/add/${URLParams.access_type}/${URLParams.id}/user`)
        }}/></span>}
            </div>
            <div className='ud_users_indication'>
              <p><MdFormatQuote color='red' size={21}/> Quotes and orders</p>
              <p><MdFlight color='red' size={21}/> Shipments notifications</p>
              <p><FaCalculator color='red' size={18}/> Accounting</p>
              <p><AiFillStar color='002776' size={18}/> Main point of contact</p>
            </div>
            {
              entity===1 && data?.preuser_set.length>0 && data?.preuser_set.map(item=>
                <UserComponent item={item}/>)
            }
            {
              (entity===2 || entity===3) && data?.customeruser_set.length>0 && data?.customeruser_set.map(item=>
                <UserComponent item={item}/>)
            }
            
          </div>

        </div>:<div style={{marginTop:'1rem'}}>No data Available</div>}
      </section>
    )
  }


    return (
      <section className="body_wrap user_detail_section">
        <div className={'cont_wp'}>
          <div className={'scrl_out'}>
            <section>
              <p className='back_btn' onClick={(e)=>{
                e.preventDefault();
                history.goBack()
              }}>
                <MdArrowBackIos color='#ACACAC' size={14}/>{"  "}Back
              </p>
              <h4 className='ud_heading'>User Management</h4>
            </section>
            <RenderDetail accessType={URLParams.access_type} data={customerData}/>
          </div>
        </div>
      </section>
    )
}

export default UserDetails
