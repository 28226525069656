import React from 'react';


const HistorySelector = ({ weeks, onHistoryWeekClick = () => {} }) => {

    return (
        <div className="nasdaq_week_wrap history_buttons">
            {weeks.map((week, index) => (
                <div 
                    key={index}
                    className={"bubbles inactive_week"}  
                    onClick={() => {
                        onHistoryWeekClick(week)
                    }}
                >
                    {week}W
                </div>
            ))}
        </div>
    )
}
export default HistorySelector;