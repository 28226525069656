import React from "react";
import { Role } from "../../helper/role";
import BrowserStorage from "../../utils/BrowserStorage";
// import { Link, Route } from 'react-router-dom';
import Sidebar from "../Sidebar/Sidebar";
import DashboardOverview from "./DashboardOverview/Dashboard0verview";
import DownStreamDashboard from "./DashboardOverview/DownStreamDashboard";
// import RequestQuote from './RequestQuote/RequestQuote';

const Home = () => {
  let dashBoard = <div></div>;
  switch (BrowserStorage.getValue("userInfo")?.currentRole) {
    case Role.ADMIN:
      dashBoard =
        BrowserStorage.getValue("userInfo")?.stream_type === "down_stream" ? (
          <DownStreamDashboard />
        ) : (
          <DashboardOverview />
        );
      break;
    case Role.CUSTOMER_ADMIN:
    case Role.CUSTOMER:
    case Role.PROSPECT:
      dashBoard = <DownStreamDashboard />;
      break;
    default:
      break;
  }
  return (
    <>
      <section className="body_wrap">
        {/* <Sidebar /> */}
        {dashBoard}
      </section>
    </>
  );
};

export default Home;
