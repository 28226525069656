import React from 'react';
import { Route, Redirect } from 'react-router-dom';
import NotAuthorized from '../../pages/NotAuthorized';
import BrowserStorage from '../../utils/BrowserStorage';
import Layout from '../Layouts/Layout';

export const PrivateRoute = ({
	component: Component,
	roles,
	withOutLayout,
	...rest
}) => (
	<Route
		{...rest}
		render={(props) => {
			let auth = BrowserStorage.getValue('token');
			let currentRole = BrowserStorage.getValue('userInfo')?.currentRole;
			if (!auth) {
				// not logged in so redirect to login page with the return url
				return (
					<Redirect to={{ pathname: '/login', state: { from: props.location } }} />
				);
			}

			// check if route is restricted by role
			if (roles && roles.length && roles.indexOf(currentRole) === -1) {
				// role not authorised so redirect to home page
				return (
					<Layout>
						<NotAuthorized />
					</Layout>
				);
			}

			//without Layout screen
			if (withOutLayout) {
				return <Component {...props} />;
			}
			// authorised so return component

			return (
				<Layout>
					<Component {...props} />
				</Layout>
			);
		}}
	/>
);
