import Api from "./Api";

class UserServices {
  getUserList = async (access_id) =>
    await Api.get(
      `/customer/api/customer-shipment-location/?access_id=${access_id}`
    );

  getUserListForAgent = async (access_id) =>
    await Api.get(
      `/customer/api/agent-access-location/?access_id=${access_id}`
    );
  getUserListManagement = async (customer_type) =>
  await Api.get(`/customer/api/customer-list/?customer_type=${customer_type}`);
  getUserOnSearch = async (searchValue,customer_type,sortedId,tableSorted,country=null,destination=null) =>
  await Api.get(`/customer/api/customer-list/?search=${searchValue}&customer_type=${customer_type}${sortedId!=='' ? `&sort_key=${sortedId}`:''}${tableSorted!=='' ? `&sort_type=${tableSorted}`:''}${country!==null && country!=='' ? `&country=${country}`:''}${destination!==null && destination!=='' ? `&destination=${destination}`:''}`);
  userListAll = async ({pageNo,param='',type='',customer_type='all',searchQuery='',country=null,destination=null,is_selected=null}) =>
    await Api.get(`/customer/api/customer-list/?page=${pageNo}${param!=='' ? `&sort_key=${param}`:''}${type!=='' ? `&sort_type=${type}`:''}&customer_type=${customer_type}${searchQuery!==''?`&search=${searchQuery}`:''}${country!==null && country!=='' ? `&country=${country}`:''}${destination!==null && destination!=='' ? `&destination=${destination}`:''}${is_selected!==null && is_selected!=='' ? `&is_selected=${is_selected}`:''}`);
  userDetail = async ({customerId,access_type}) => await Api.get(`customer/api/${access_type}/details/?pk=${customerId}`);
}
export default new UserServices();