import React, {useState, useEffect} from 'react'
import { Link } from 'react-router-dom';
import Sidebar from '../components/Sidebar/Sidebar';
import SeafoodCountrySelector from '../components/ModelSelectors/SeafoodCountrySelector';
import ExportStatistics from '../components/Home/Market/ExportStatistics';

const ExportStatisticsPage = () => {
    const [seafood_country, setCountry] = useState('')

    const handleChange = (e) => {
        setCountry(e.target.value)

    }

    return (
        <section className={'body_wrap'}>
            {/* <Sidebar /> */}
            <div className={'cont_wp'}>
                <Link to={'/home'}>
                    <p className={'Back_link'}>Back</p>
                </Link>
                <p style={{color:"red"}}>TODO</p>
                    <ul style={{color:"red"}}>
                        <li><h7>Change to function based and style it.</h7></li>
                        <li><h7>Select Country (dropdown).</h7></li>
                        <li><h7>Toggle week and month.</h7></li>
                        <li><h7>Show last and next publication.</h7></li>
                    </ul>
                <section className={'cmn_head'}>
                    <p>Export Statistics</p>
                </section>
                <SeafoodCountrySelector handleChange={ handleChange }/>
                <ExportStatistics />

            </div>
        </section>

    )
}

export default ExportStatisticsPage
