import React from 'react'
import CostTarget from '../components/CostTarget/CostTarget';
import Forecast from '../components/Forecast/Forecast';
import CreateBaseOrder from '../components/Home/CreateBaseOrder/CreateBaseOrder';
import CreateNote from '../components/Home/CreateNote/CreateNote';
import Home from '../components/Home/Home';
import NasdaqPrice from '../components/Home/Nasdaq/nasdaq';
import OrderSummary from '../components/Home/OrderSummary/OrderSummary';
import PriceIndication from '../components/Home/PriceIndication/PriceIndication';
import SeafoodDestination from '../components/Home/SeafoodDestinations/SeafoodDestination';
import ExpiryDateCalculator from '../components/Home/ToolBox/ExpiryDateCalculator';
import Orders from '../components/Orders/Orders';
import PurchaseDetail from '../components/Purchase/PurchaseDetail';
import ViewPurchase from '../components/Purchase/ViewPurchase';
import RFQPage from '../components/RFQ/RFQPage';
import Friday from '../components/Sell/Friday';
import Sell from '../components/Sell/Sell';
import Users from '../components/Users/Users';
import UsersList from '../components/UsersList/UsersList';
import RFQSample from '../components/WIP/RFQSample';
import { Role } from '../helper/role';
import BitfishDocPage from '../pages/BitfishDocPage';
import BusinessPage from '../pages/BusinessPage';
import BuySelectorPage from '../pages/BuySelectorPage';
import CleanDeskPage from '../pages/CleanDeskPage';
import UserSelector from '../pages/downStream/UserSelector';
import ExportStatisticsPage from '../pages/ExportStatisticsPage';
import FreightRatePage from '../pages/FreightRatePage';
import FxPage from '../pages/FxPage';
import IncidentClassificationPage from '../pages/IncidentClassificationPage';
import InvoiceLogPage from '../pages/InvoiceLogPage';
import MiniInvoicePage from '../pages/MiniInvoicePage';
import MiscellaneousPage from '../pages/MiscellaneousPage';
import NQSalmonPage from '../pages/NQSalmonPage';
import PackingManager from '../pages/PackingManager';
import PurchaseLogPage from '../pages/PurchaseLogPage';
import PurchasePage from '../pages/PurchasePage';
import QuotePage from '../pages/QuotePage';
import QuotePageDownstream from '../pages/QuotePageDownstream';
import RefinerPage from '../pages/RefinerPage';
import RFQManagerPage from '../pages/RFQManagerPage';
import SampleMatrixPage from '../pages/SampleMatrixPage';
import SeafoodCalendarPage from '../pages/SeaFoodCalendarPage';
import SeafoodSpecPage from '../pages/SeafoodSpecPage';
import SpeciesPage from '../pages/SpeciesPage';
import TenderPage from '../pages/TenderPage';
import TimelinePage from '../pages/TimelinePage';
import UniversalEmailPage from '../pages/UniversalEmailPage';
import UniversalMatrixPage from '../pages/UniversalMatrixPage';

//IMAGES
// import businessIMG from '../assets/images/business.png'
import {SiHandshake} from 'react-icons/si'
import { BsCartDash, BsCartPlus, BsThreeDots } from 'react-icons/bs';
import { MdEditNote, MdFlight } from 'react-icons/md';
import { GiCirclingFish, GiProgression } from 'react-icons/gi';
import { AiOutlineHome } from 'react-icons/ai';
import UserDetails from '../pages/UserDetails';
import CreatePreprospect from '../pages/CreatePreprospect';
import EditCustomerPage from '../pages/EditCustomerPage';
import AddUser from '../pages/AddUser';
import EditUser from '../pages/EditUser';
import ShipmentTracker from '../pages/downStream/ShipmentTracker/ShipmentTracker';
import ShipmentList from '../pages/downStream/ShipmentList/ShipmentList';

export default [
	{
		label: 'Home',
		path: `/home`,
		image:<AiOutlineHome size={21} color="#A8ADB9" className='sidebar_icon'/>,
		component: Home,
		roles: [],
	},
	{
		label: 'Buy',
		path: `/buy/cost-target`,
		image:<BsCartPlus size={21} color="#A8ADB9" className='sidebar_icon'/>,
		roles: [Role.ADMIN],
		children: [
			{
				label: 'Cost Target',
				path: `/buy/cost-target`,
				component: CostTarget,
				roles: [Role.ADMIN],
			},
			{
				label: 'Tender',
				path: `/buy/tender`,
				component: TenderPage,
				roles: [Role.ADMIN],
			},
			{
				label: 'Selector',
				path: `/buy/selector`,
				component: BuySelectorPage,
				roles: [Role.ADMIN],
			},
			{
				label: 'RFQ List',
				path: `/buy/rfq-list`,
				component: RFQManagerPage,
				roles: [Role.ADMIN],
			},
			{
				label: 'Purchase',
				path: `/buy/purchase`,
				component: PurchasePage,
				roles: [Role.ADMIN],
			},
			{
				label: 'Packing',
				path: `/buy/packing`,
				component: PackingManager,
				roles: [Role.ADMIN],
			},
		],
	},
	{
		label:'Business',
		path:'/business',
		image: <SiHandshake size={21} color="#A8ADB9" className='sidebar_icon'/>,
		roles:[Role.ADMIN],
		component: BusinessPage,
	},
	{
		label: `Ship`,
		path: `/ship/shipments`,
		image:<MdFlight size={21} color="#A8ADB9" className='sidebar_icon'/>,
		roles: [Role.ADMIN],
		children: [
			{
				label: 'Shipments',
				path: `/ship/shipments`,
				component: TimelinePage,
				roles: [Role.ADMIN],
			},
			{
				label: 'Incident Classification',
				path: `/ship/incident-classification`,
				component: IncidentClassificationPage,
				roles: [Role.ADMIN],
			},
		],
	},
	{
		label: 'Sell',
		path: `/sell/thursday`,
		image:<BsCartDash size={21} color="#A8ADB9" className='sidebar_icon'/>,
		roles: [Role.ADMIN],
		children: [
			{
				label: 'Thursday',
				path: `/sell/thursday`,
				component: Sell,
				roles: [Role.ADMIN],
			},
			{
				label: 'Friday',
				path: `/sell/friday`,
				component: Friday,
				roles: [Role.ADMIN],
			},
			{
				label: 'Outlook',
				path: `/sell/outlook`,
				component: Forecast,
				roles: [Role.ADMIN],
			},
		],
	},
	{
		label: 'Seafood',
		path: `/seafood/species`,
		image:<GiCirclingFish size={21} color="#A8ADB9" className='sidebar_icon'/>,
		roles: [Role.ADMIN],
		children: [
			{
				label: 'Species',
				path: `/seafood/species`,
				component: SpeciesPage,
				roles: [Role.ADMIN],
			},
			{
				label: 'Seafood Specs',
				path: `/seafood/seafood-specs`,
				component: SeafoodSpecPage,
				roles: [Role.ADMIN],
			},
		],
	},
	{
		label: 'Other',
		path: `/other/universal-matrix`,
		image:<BsThreeDots size={21} color="#A8ADB9" className='sidebar_icon'/>,
		roles: [Role.ADMIN],
		children: [
			{
				label: 'Universal Matrix',
				path: `/other/universal-matrix`,
				component: UniversalMatrixPage,
				roles: [Role.ADMIN],
			},
			{
				label: 'NASDAQ Salmon Index',
				path: `/other/nasdaq`,
				component: NQSalmonPage,
				roles: [Role.ADMIN],
			},
			{
				label: 'Seafood Destinations',
				path: `/other/seafood-destinations`,
				component: SeafoodDestination,
				roles: [Role.ADMIN],
			},
			{
				label: 'Seafood Calendar',
				path: `/other/seafood-calendar`,
				component: SeafoodCalendarPage,
				roles: [Role.ADMIN],
			},
			{
				label: 'Mini Invoice',
				path: `/other/mini-invoice`,
				component: MiniInvoicePage,
				roles: [Role.ADMIN],
			},
			{
				label: 'Email Preview',
				path: `/other/email-preview`,
				component: UniversalEmailPage,
				roles: [Role.ADMIN],
			},
			{
				label: 'Clean Desk',
				path: `/other/clean-desk`,
				component: CleanDeskPage,
				roles: [Role.ADMIN],
			},
			{
				label: 'FXs',
				path: `/other/fx`,
				component: FxPage,
				roles: [Role.ADMIN],
			},
			{
				label: 'Freight Rates',
				path: `/other/freight-rates`,
				component: FreightRatePage,
				roles: [Role.ADMIN],
			},
			{
				label: 'Export Statistics',
				path: `/other/export-statistics`,
				component: ExportStatisticsPage,
				roles: [Role.ADMIN],
			},
			{
				label: 'Expiry Date Calculator',
				path: `/other/expiry-date-calculator`,
				component: ExpiryDateCalculator,
				roles: [Role.ADMIN],
			},
			{
				label: 'Miscellaneous',
				path: `/other/miscellaneous`,
				component: MiscellaneousPage,
				roles: [Role.ADMIN],
			},
			{
				label: 'Bitfish Docs',
				path: `/other/bitfish`,
				component: BitfishDocPage,
				roles: [Role.ADMIN],
			},
			{
				label: 'Refiner',
				path: `/other/refiner`,
				component: RefinerPage,
				roles: [Role.ADMIN],
			},
		],
	},
	{
		label: 'Logs',
		path: `/logs/invoice-log`,
		image:<MdEditNote size={21} color="#A8ADB9" className='sidebar_icon'/>,
		roles: [Role.ADMIN],
		children: [
			{
				label: 'Invoice Log',
				path: `/logs/invoice-log`,
				component: InvoiceLogPage,
				roles: [Role.ADMIN],
			},
			{
				label: 'Purchase Log',
				path: `/logs/purchase-log`,
				component: PurchaseLogPage,
				roles: [Role.ADMIN],
			},
			{
				label: 'Quote Sheet Log',
				path: `/logs/quote-sheet-log`,
				component: PurchaseLogPage,
				roles: [Role.ADMIN],
			},
			{
				label: 'Product Sheet Log',
				path: `/logs/product-sheet-log`,
				component: PurchaseLogPage,
				roles: [Role.ADMIN],
			},
			{
				label: 'Flight Ticket Log',
				path: `/logs/flight-ticket-log`,
				component: PurchaseLogPage,
				roles: [Role.ADMIN],
			},
		],
	},
	{
		label: 'WIP',
		path: `/wip/week-selector`,
		image:<GiProgression size={21} color="#A8ADB9" className='sidebar_icon'/>,
		roles: [Role.ADMIN],

		children: [
			{
				label: 'Week Selector',
				path: `/wip/week-selector`,
				component: NasdaqPrice,
				roles: [Role.ADMIN],
			},
			{
				label: 'Price Indication',
				path: `/wip/price-indication`,
				component: PriceIndication,
				roles: [Role.ADMIN],
			},
		],
	},
	{
		label: '',
		path: `/home/price`,
		component: PriceIndication,
		roles: [Role.CUSTOMER_ADMIN, Role.CUSTOMER],
	},
	{
		label: '',
		path: `/home/create-baseorder`,
		component: CreateBaseOrder,
		roles: [],
	},
	{
		label: '',
		path: `/home/create-note`,
		component: CreateNote,
		roles: [Role.CUSTOMER, Role.PROSPECT, Role.CUSTOMER_ADMIN],
	},
	{
		label: '',
		path: `/home/shipment-list`,
		component: ShipmentList,
		roles: [Role.CUSTOMER, Role.PROSPECT, Role.CUSTOMER_ADMIN],
	},
	{
		label: '',
		path: `/home/shipment-track/:id`,
		component: ShipmentTracker,
		roles: [Role.CUSTOMER, Role.PROSPECT, Role.CUSTOMER_ADMIN],
	},
	{
		label: '',
		path: `/buy/request/:rfq_id/:access_id/:type`,
		component: RFQPage,
		roles: [Role.ADMIN],
	},
	{
		label: '',
		path: `/buy/view-purchase/:rfq_id`,
		component: ViewPurchase,
		roles: [Role.ADMIN],
	},
	{
		label: '',
		path: `/buy/confirm/:purchase_id/:access_id/:type`,
		component: PurchaseDetail,
		roles: [Role.ADMIN],
	},
	{
		label: '',
		path: `/sell/friday/request/:id/:customer_id/:type`,
		component: QuotePage,
		roles: [Role.ADMIN],
	},
	{
		label: '',
		path: `/business/user-management/:access_type/:id`,
		component: UserDetails,
		roles: [Role.ADMIN],
	},
	{
		label: '',
		path: `/business/create-preprospect`,
		component: CreatePreprospect,
		roles: [Role.ADMIN],
	},
	{
		label: '',
		path: `/business/edit/:access_type/:id`,
		component: EditCustomerPage,
		roles: [Role.ADMIN],
	},
	{
		label: '',
		path: `/business/add/:access_type/:id/user`,
		component: AddUser,
		roles: [Role.ADMIN],
	},
	{
		label: '',
		path: `/business/edit/:access_type/:id/user/:userId`,
		component: EditUser,
		roles: [Role.ADMIN],
	},
	{
		label: '',
		path: `/home/request`,
		component: QuotePageDownstream,
		roles: [Role.CUSTOMER],
	},
	{
		label: '',
		path: `/sell/friday/checkout`,
		component: OrderSummary,
		roles: [Role.ADMIN, Role.CUSTOMER_ADMIN, Role.CUSTOMER],
	},
	{
		label: '',
		path: `/quote/place-order`,
		component: OrderSummary,
		roles: [Role.CUSTOMER_ADMIN, Role.CUSTOMER],
	},
	{
		label: '',
		path: `/sell/users`,
		component: UsersList,
		roles: [Role.ADMIN],
	},
	{
		label: '',
		path: `/orders`,
		component: Orders,
		roles: [Role.ADMIN],
	},
	// {
	// 	label: '',
	// 	path: `/users`,
	// 	component: Users,
	// 	roles: [Role.ADMIN],
	// },
	{
		label: '',
		path: `/summary`,
		component: OrderSummary,
		roles: [Role.ADMIN],
	},
	{
		label: '',
		path: `/wip/rfq-sample`,
		component: RFQSample,
		roles: [Role.ADMIN],
	},
	{
		label: '',
		path: `/select-user`,
		component: UserSelector,
		roles: [Role.CUSTOMER_ADMIN, Role.ADMIN],
		withOutLayout: true,
	},
];
