import React, { useReducer, useEffect } from 'react';
import { HiChevronDoubleLeft } from 'react-icons/hi';
import { HiChevronDoubleRight } from 'react-icons/hi';
import WeekButton from '../../WeekSelector/WeekButton';
import axios from 'axios';
import { BsCalendarFill } from 'react-icons/bs';
import CalendarServices from '../../../api/CalendarServices';

const reducer = (state, action) => {
	switch (action.type) {
		case 'initiate_week_selector':
			return {
				...state,
				weeks: action.payload.weeks,
				years: action.payload.years,
				next_last_backwards: action.payload.next_last_backwards,
				next_last_forwards: action.payload.next_last_forwards,
				week_dates: action.payload.week_dates,
			};
		case 'active_week_data_status': 
			return {
				...state,
				active_week_data_status: action.payload.active_week_data_status
			}
		case 'toogleSelector':
			return {
				...state,
				selectorOn: action.payload.selectorOn,
			};
		case 'setWeekDates':
			return {
				...state,
				week_dates: action.payload.week_dates,
			};
		default:
			console.log(action);
			throw new Error("Don't understand action.");
	}
};

const WeekSelector = ({
	selector_title,
	last_selector_week,
	first_allowed_week,
	last_allowed_week,
	selected_week,
	default_selected_week,
	default_disabled_weeks,
	week_limit,
	week_data_stats,
	style,
	week_selector_toogle,
	onWeekClick,
	onBFsClick,
	is_disabled = false,
	hideArrow = false,
	access_type,
	access_id,
	modelName = '',
	onToggleChange = () => { },
	updateActiveWeekDataStatus = () => {}
}) => {
	const [state, dispatch] = useReducer(reducer, {
		weeks: [],
		active_week_data_status: [],
		years: [],
		next_last_backwards: '',
		next_last_forwards: '',
		week: '',
		selectorOn: selector_title == 'Week' ? false : true,
		switchLabel: '', // not used at the moment,
		week_dates: '',
	});

	useEffect(() => {
		getWeekList({
			last: last_selector_week,
			limit: week_limit,
		});
		if(modelName) {
			matrixInitiator()
		}
	}, [last_selector_week]);

	const getWeekList = async (params={}) => {
		const getAllWeekList = await CalendarServices.getAllWeekList(params);
		if (getAllWeekList.data) {
			const { data } = getAllWeekList;
			const week_dates = data.result.weeks
				.filter((data) => data.week === selected_week)
				.map((data) => data.week_dates);
			dispatch({
				type: 'initiate_week_selector',
				payload: {
					weeks: data.result.weeks,
					years: data.result.years,
					next_last_backwards: data.result.next_last_backwards,
					next_last_forwards: data.result.next_last_forwards,
					week_dates: week_dates,
				},
			});
		}
	};
	const matrixInitiator = async() => {
		let data = {
			"access_type" : access_type,
			"model_name" : modelName,
			"access_id": access_id,
			"limit": week_limit,
			"last_week": last_selector_week
		}
		const matrixInitiatorData = await CalendarServices.matrixInitiator(data);
		updateActiveWeekDataStatus(matrixInitiatorData.data.data_status)
		dispatch({
			type: 'active_week_data_status',
			payload : {
				active_week_data_status : matrixInitiatorData.data.data_status
			}
		})

	}
	useEffect(() => {
		const week_dates = state.weeks
			.filter((data) => data.week === selected_week)
			.map((data) => data.week_dates);
		dispatch({
			type: 'setWeekDates',
			payload: {
				week_dates: week_dates,
			},
		});
	}, [selected_week]);

	useEffect(() => {
		if (is_disabled) {
			dispatch({
				type: 'toogleSelector',
				payload: {
					selectorOn: false,
				},
			});
		}
	}, [is_disabled]);

	/* Toogling the visibility of the selector */
	const toogleWeekSelector = (e) => {
		if (!is_disabled) {
			dispatch({
				type: 'toogleSelector',
				payload: {
					selectorOn: !state.selectorOn,
				},
			});
			onToggleChange(!state.selectorOn);
		}
	};
	const right_disabled = state.weeks.filter(
		(item) => item.week === last_allowed_week
	)[0]
		? true
		: false;
	const left_disabled = state.weeks.filter(
		(item) => item.week === first_allowed_week
	)[0]
		? true
		: false;

	return (
		<div>
			{state.selectorOn ? (
				<p style={{ marginBottom: selector_title == 'Week' ? 0 : 20 }}>
					{selector_title} ({state.years.join('/')})
				</p>
			) : null}
			{state.selectorOn ? (
				<>
					{/* The backwards button */}
					{!hideArrow && (
						<HiChevronDoubleLeft
							size={20}
							className='double_arrow_left'
							key='1'
							disabled={left_disabled}
							style={{
								color: left_disabled ? 'lightgrey' : '#002776',
								cursor: left_disabled ? 'not-allowed' : 'pointer',
							}}
							onClick={
								left_disabled ? null : () => onBFsClick(state.next_last_backwards)
							}
						/>
					)}
					{/* The individual week buttons */}
					{state.weeks.map((week, index) => (
						<WeekButton
              key={index+week.week_no}
							week={week.week}
							week_no={week.week_no}
							last_selector_week={last_selector_week}
							status={week.status}
							selected_week={selected_week}
							default_selected_week={default_selected_week}
							data_stat={week_data_stats ? week_data_stats[index] : ''}
							active_data_staus ={state.active_week_data_status ? state.active_week_data_status[index] : '' }
							default_disabled_weeks={default_disabled_weeks}
							style={style}
							onWeekClick={onWeekClick}
							weeks={state.weeks}
							showInactiveBorder={modelName === '' ? false : true}
						/>
					))}
					{/* The forwards button */}
					{!hideArrow && (
						<HiChevronDoubleRight
							size={20}
							className='double_arrow_right'
							key='2'
							disabled={right_disabled}
							style={{
								color: right_disabled ? 'lightgrey' : '#002776',
								cursor: right_disabled ? 'not-allowed' : 'pointer',
							}}
							onClick={
								right_disabled ? null : () => onBFsClick(state.next_last_forwards)
							}
						/>
					)}
				</>
			) : (
				<></>
			)}
			{/* The week selector toogle */}
			{week_selector_toogle ? (
				<BsCalendarFill
					onClick={toogleWeekSelector}
					className={'calender_icon'}
					style={{ left: state.selectorOn ? 11 : 0 }}
					color={is_disabled ? 'lightgray' : 'rgba(0, 115, 129, 0.7)'}
				/>
			) : (
				''
			)}
			{state.selectorOn && !week_selector_toogle ? (
				<p style={{ marginTop: 20 }}>{state.week_dates}</p>
			) : null}
		</div>
	);
};

export default WeekSelector;
