import Api from './Api';

class CustomerNoteServices {

	updateCustomerNoteMatrix = async (matrxId, data) =>
		await Api.put(`/accumulator/api/customer-note/${matrxId}/`, data);

	getNotes = async () =>
		await Api.get(`/accumulator/api/customer-note/`);

	createNote = async (data, customer_note_id) =>
		await Api.post(`/accumulator/api/customer-note/?customer_note_id=${customer_note_id}`, data);

	announceNote = async (data, noteId) =>
		await Api.post(`/accumulator/api/customer-note/${noteId}/announce-note/`, data);

	getOrderSummary = async (data) =>
		await Api.post(`/customer/get_quote_for_checkout/`, data);

}
export default new CustomerNoteServices();
