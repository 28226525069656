import React, { useState, useEffect } from 'react';
import axios from 'axios';
import ReactHtmlParser from 'react-html-parser';
import CommonServices from '../../api/CommonServices';

const UniversalEmail = () => {
	const [email, setEmail] = useState([]);

	const getEmailPreview = async () => {
		try {
			const EmailPreview = await CommonServices.PreviewMail();

			if (EmailPreview.data) {
				const { data } = EmailPreview;
				setEmail(data);
			}
		} catch (err) {
			console.log('ERROR Preview Email', err);
		}
	};
	useEffect(() => {
		getEmailPreview();
	}, []);
	console.log(email);
	return <div>{ReactHtmlParser(email)}</div>;
};

export default UniversalEmail;
