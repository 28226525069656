import React from 'react';
import { BiHome } from 'react-icons/bi';
import { Link } from 'react-router-dom';
import ErrorIMG from '../assets/images/403 Error.png';

function NotAuthorized() {
	return (
		<div className='sell_body_wrap'>
			<div
				style={{
					display: 'flex',
					justifyContent: 'center',
					alignItems: 'center',
					height: '100%',
					flexDirection: 'column',
				}}
			>
				<img src={ErrorIMG} alt='403' style={{ width: 400 }} />
				<Link to='/home'>
					<p
						style={{
							display: 'flex',
							alignItems: 'center',
							marginTop: 30,
							justifyContent: 'center',
							padding: 0,
						}}
					>
						Back to Home &nbsp; <BiHome />
					</p>
				</Link>
			</div>
		</div>
	);
}

export default NotAuthorized;
