import React from 'react'
import { AiOutlineSearch } from 'react-icons/ai'
import '../../styles/BusinessPage.css';

const SearchInput = ({ value='', onChangeText=()=>{} }) => {

  React.useEffect(() => {
  
    /* Adds an event listener which fires whenever the value of our 
    input field changes and call the onChangeText function passed
    in as a prop to our component */
    let input = document.querySelector('input');
    input.addEventListener('input', onChangeText);
    
    /* Don't forget to return a cleanup function */  
    return input.removeEventListener('input', onChangeText);
   
   }, []);

    return (
      <span className='search_input_container'>
        <AiOutlineSearch size={21} color='#aeaeae' className='search-icon'/>
        <input type="text" placeholder='Search' value={value} onChange={onChangeText}/>
      </span>
    )
}

export default SearchInput
