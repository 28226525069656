import React, { useEffect, useState } from "react";
import WeightClassPrice from "./WeightClassPrice";
import "./PriceLine.css";
import { GrHistory } from "react-icons/gr";
import axios from "axios";
import HistoryModalComponent from "../CostTarget/HistoryModalComponent";

const PriceLine = (props) => {
  let {
    selectedCurrencyCode,
    notSelectedCurrencyCode,
    priceList,
    onUpdate,
    enableUpdate,
    week,
    year,
    showPricesCol,
  } = props;

  const [modal, setModal] = useState(false);
  const [history, setHistory] = useState(props.history);

  useEffect(() => {
    setHistory(props.history);
  }, [props.history]);

  const closeModal = () => {
    setModal(false);
  };

  const renderTitle = (datas) => {
    return (
      <>
        {datas.map((data, index) => (
          <div
            key={index}
            className={"list_header_wrap"}
            style={{
              alignItems: "center",
              justifyContent: "center",
            }}
          >
            <p>{`Price ${data.toUpperCase()}`}</p>
            {data?.currency_code === selectedCurrencyCode && history ? (
              <GrHistory
                size={15}
                color={"#58637A"}
                onClick={() => {
                  setModal(history.has_history);
                }}
                className={`${
                  history.has_history ? "allow_cursor" : "not_allow_cursor"
                } history_icon`}
              />
            ) : null}
          </div>
        ))}
      </>
    );
  };

  return (
    <div className="priceButtonWrap">
      {priceList.length > 0 && (
        <div className={"priceListBlock" + " " + "boldHead"}>
          <div>Weight Class</div>
          {renderTitle([selectedCurrencyCode])}
          {renderTitle(notSelectedCurrencyCode)}
        </div>
      )}

      {priceList.length > 0 ? (
        priceList.map((prices, index) => (
          <React.Fragment key={index}>
            <WeightClassPrice
              key={prices.weight_class}
              price={prices}
              onUpdate={onUpdate}
              enableUpdate={enableUpdate}
              showPricesCol={showPricesCol}
              selectedCurrencyCode={selectedCurrencyCode}
              notSelectedCurrencyCode={notSelectedCurrencyCode}
            />
          </React.Fragment>
        ))
      ) : (
        <p className={"price_no_data"}>No data available</p>
      )}

      {modal && (
        <HistoryModalComponent
          historyData={history}
          modal={modal}
          closeModal={closeModal}
          week={week}
          year={year}
        />
      )}
    </div>
  );
};

export default PriceLine;
