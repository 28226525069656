import Api from './Api';

class CalendarService {
	getWeekDates = async (sourcing_week = null) =>
		await Api.get(
			`/business/api/calendar/week-dates/${
				sourcing_week ? '?sourcing_week=' + sourcing_week : ''
			}`
		);

	getTenderMatrixHeaderDate = async (sourcing_week) =>
		await Api.get(`/business/api/calendar/week-dates/`);

	getAllWeekList = async (params = {}) => 
		await Api.get(`/business/api/calendar/weeks/`,{params});
	getNasdaqWeeks = async (access_type = 'price') =>
		await Api.get(
			`/price/api/nasdaq-index/last-week-prices/?access_type=${access_type}`
		);

	upcomingCalendar = async () =>
		await Api.get('seafood-calendar/api/seafood-calendar/upcoming/');

	matrixInitiator = async(data) =>
		await Api.post(`/business/api/matrix-model/matrix-initiator/`,data);
}

export default new CalendarService();
