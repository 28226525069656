import React, { useState, useEffect } from 'react'
import {FaFilePdf} from 'react-icons/fa'


const InvoiceLog = () => {
    const [invoices, setInvoices] = useState([])

    useEffect(() => {    
        async function fetchInvoiceLog() {
            const url = 'https://dev.exocoetidae.no/administrator/api/invoice-log/'
            const headers = {
                'Authorization': '558ef807-ad2b-4aba-8629-c8d227745493'
            } 
            const invoiceLogResponse = await fetch(url, {headers:headers}).then(res => res.json())
            console.log(invoiceLogResponse)
            const invoices = invoiceLogResponse
            setInvoices(invoices)
            console.log(invoices)
        }
        fetchInvoiceLog()
    }, [])

    return (
        <div>
            <ul>
            {invoices.map((invoice) => (
                <li id={invoice.id}>{invoice.id}: {invoice.invoice_date}—{invoice.cabrv}—{invoice.amount}
                        {invoice.invoice ? 
                        <a href = {invoice.invoice} target = "_blank">
                             <FaFilePdf
                                size={16}
                                style={{
                                    marginLeft: '10px',
                                }}
                                color={'grey'}
                            />
                        </a> : ""}   

                </li>
            ))}  
            </ul>
        </div>
    )
}

export default InvoiceLog