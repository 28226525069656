import { Fragment } from 'react';
import React from 'react';
import { FcTimeline } from 'react-icons/fc';
import './ShipmentTable.css';

const ShipmentTable = ({ shipment_data = [], handleTimeline = () => {} }) => {
	return (
		<table className='shipment-table-new'>
			<WeekHeader />
			<tbody
				style={{ overflowX: 'auto', width: '100%', position: 'relative' }}
				className='fc_scroll'
			>
				{shipment_data.length > 0 ? (
					shipment_data.map((shipment, i) => {
						return (
							<Fragment key={i}>
								<WeightRow rowData={shipment} handleTimeline={handleTimeline} />
							</Fragment>
						);
					})
				) : (
					<td style={{ textAlign: 'center', padding: 20 }} colSpan={3}>
						No data available
					</td>
				)}
			</tbody>
		</table>
	);
};

const WeekHeader = () => {
	return (
		<thead>
			<tr>
				<th>Customer</th>
				<th>Destination Airport</th>
				<th>Arrival Date</th>
				<th></th>
			</tr>
		</thead>
	);
};

const WeightRow = ({ rowData = {}, handleTimeline = () => {} }) => {
	return (
		<tr>
			<td>{rowData.name}</td>

			<td>{rowData.destination_airport_name}</td>
			<td>{rowData.arrival_target}</td>
			<th>
				<FcTimeline
					size={20}
					style={{ cursor: 'pointer' }}
					onClick={(e) => {
						e.preventDefault();
						handleTimeline(rowData.id, rowData.name);
					}}
				/>
			</th>
		</tr>
	);
};

export default ShipmentTable;
