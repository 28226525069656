import React from 'react';
import { Link } from 'react-router-dom';
import Sidebar from '../components/Sidebar/Sidebar';
import FreightRates from '../components/Ship/FreightRates';

const FreightRatePage = () => {
	return (
		<section className={'body_wrap'}>
			{/* <Sidebar /> */}
			<div className={'cont_wp'}>
				<Link to={'/home'}>
					<p className={'Back_link'}>Back</p>
				</Link>
				<section className={'cmn_head'}>
					<p>Freight Rates</p>
				</section>
				<FreightRates />
			</div>
		</section>
	);
};

export default FreightRatePage;
