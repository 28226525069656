import React, { Component } from 'react';
import axios from 'axios';
import { ToastsStore } from 'react-toasts';
import { Link } from 'react-router-dom';

class ResetPassword extends Component {
	constructor() {
		super();
		this.state = {
			showForm: true,
			email: '',
			password: '',
			confirm_password: '',
			otp: '',
		};
	}
	inputChangedHandler = async function (event) {
		await this.setState({
			[event.target.name]: event.target.value,
		});
	};
	submitHandler = (event) => {
		let reg = /^\w+([\.-]?\w+)*@\w+([\.-]?\w+)*(\.\w{2,3})+$/;
		if (
			this.state.email == '' ||
			this.state.password == '' ||
			this.state.confirm_password == '' ||
			this.state.otp == ''
		) {
			ToastsStore.error('Fill all fields');
			return;
		}
		if (reg.test(this.state.email) === false) {
			ToastsStore.error('Email is not correct');
			return;
		}
		if (this.state.password !== this.state.confirm_password) {
			ToastsStore.error('Password Mismatch');
			return;
		}
		const authData = {
			email: this.state.email,
			password: this.state.password,
			confirm_password: this.state.confirm_password,
			otp: this.state.otp,
			is_admin: true,
		};
		axios
			.post('/customer/api/reset-password/', authData)
			.then((response) => {
				this.setState({
					showForm: false,
				});
				setTimeout(() => {
					window.location.href = '/';
				}, 5000);
			})
			.catch((error) => {
				console.log('aaa', error);
			});
	};

	render() {
		return (
			<div className={'loginFormWrap'}>
				<p className={'loginFormHead'}>Reset Your Password</p>
				<p></p>
				{this.state.showForm ? (
					<>
						<div className={'Input'}>
							<label>E-mail</label>
							<input
								type='text'
								name='email'
								placeholder='Mail Address'
								onChange={(e) => {
									this.inputChangedHandler(e);
								}}
							/>
						</div>
						<div className={'Input'}>
							<label>New Password</label>
							<input
								type='password'
								name='password'
								placeholder='New Password'
								onChange={(e) => {
									this.inputChangedHandler(e);
								}}
							/>
						</div>
						<div className={'Input'}>
							<label>Confirm New Password</label>
							<input
								type='password'
								name='confirm_password'
								placeholder='Confirm New Password'
								onChange={(e) => {
									this.inputChangedHandler(e);
								}}
							/>
						</div>
						<div className={'Input'}>
							<label>OTP</label>
							<input
								type='text'
								name='otp'
								placeholder='Enter OTP'
								onChange={(e) => {
									this.inputChangedHandler(e);
								}}
							/>
						</div>
						<div className={'otp_btn'} onClick={(event) => this.submitHandler()}>
							Change Password
						</div>
					</>
				) : (
					<>
						<p className={'proceed_text'}>
							Your Password has been changed successfully
						</p>
					</>
				)}
			</div>
		);
	}
}

export default ResetPassword;
