import React, { Component } from 'react';
import { Link } from 'react-router-dom';
import axios from 'axios';
import './BaseOrder.css';
import BaseOrderServices from '../../../api/BaseOrderServices';
import CustomerNoteServices from '../../../api/CustomerNoteServices';
import OrderPlacedServices from '../../../api/OrderPlacedServices';
import QuoteServices from '../../../api/QuoteServices';
import BrowserStorage from '../../../utils/BrowserStorage';
import PriceTagIMG from '../../../assets/images/tag.png';

class BaseOrder extends Component {
	constructor() {
		super();
		this.state = {
			elements: [],
			sel: null,
			totalOrder: 0,
			custDetails: null,
			selectedQuote: 0,
			addrDrop: false,
			activeTab: 'base',
			accStat: [],
			lastElem: [],
			lastSel: null,
			lastTotal: 0,
			orderDet: {},
			quoteModel: {},
			activeOrderMatrix: [],
			activeTotal: 0,
			activeTotalPrice: 0,
			activeOrderPrice: {},
			pdfData: [],
			orderType: 'active',
			noteMatrix: [],
			noteTotalOrder: 0,
			showNotes: 'true',
			shipment_week: '',
			matrix_dimension_status: 2,
		};
		this.weekDet = [];
		this.baseDetails = [];
		this.shipment_address = '';
	}

	componentDidMount() {
		const userInfos = BrowserStorage.getValue('userInfo');
		if (userInfos.access_type === 'customer_shipment_location') {
			this.getActiveOrder('active');
		}
		this.getDate();
		this.getBaseOrder();
		this.getNotes();
		localStorage.setItem('baseStatus', 0);
		localStorage.removeItem('requestData');
		localStorage.removeItem('requestQuoteId');
		localStorage.removeItem('locationId');
		this.setState({
			showNotes: localStorage.getItem('isNote'),
		});
	}
	async getBaseOrder() {
		const getBaseOrderMatrix = await BaseOrderServices.getBaseOrderMatrix();
		if (getBaseOrderMatrix.data) {
			const { data } = getBaseOrderMatrix;
			let model = {};
			for (let item in data.full_matrix_combined) {
				let sum = 0;
				data.full_matrix_combined[item].forEach((element) => {
					sum += element.volume;
				});
				model[item] = {
					hasVolume: sum != 0,
				};
			}
			this.setState({
				elements: Object.keys(data.full_matrix_combined || {}),
				sel: data.full_matrix_combined,
				totalOrder: data.total_volume ? data.total_volume : 0,
				quoteModel: model,
				totalWieghtForBaseOrder: data.total_weight ? data.total_weight : 0,
				shipment_week: Object.values(data.full_matrix_combined)[0][7].date,
				matrix_dimension_status: data.matrix_dimension_status,
			});
			this.props.actBtn(this.state.totalOrder);

			this.state.elements.map((key, i) => {
				this.state.sel[key].sort(function (x, y) {
					return x['dayIndex'] - y['dayIndex'];
				});
			});
			this.sumAccord();
			localStorage.setItem('requestData', JSON.stringify(data));
			if (this.state.elements.length != 0) {
				localStorage.setItem('onBase', true);
			} else {
				localStorage.setItem('onBase', false);
			}
			this.forceUpdate();
		}
	}

	async getNotes() {
		const getNotes = await CustomerNoteServices.getNotes();
		if (getNotes.data) {
			const { data } = getNotes;
			let order = [];
			for (let item in data.full_matrix_combined) {
				let temp = {};
				temp['key'] = item;
				temp['list'] = data.full_matrix_combined[item].sort((a, b) =>
					a.weight_class > b.weight_class
						? 1
						: b.weight_class > a.weight_class
						? -1
						: 0
				);
				order.push(temp);
			}
			this.setState({
				noteMatrix: order,
				noteTotalOrder: data.total_weight ? data.total_weight : 0,
				noteData: data.customer_data,
			});
			this.forceUpdate();
		}
	}

	async resetNote() {
		let matrix = {};
		for (let item of this.state.noteMatrix) {
			matrix[item.key] = item.list.map((item) => {
				item.volume = 0;
				return item;
			});
		}
		let data = { data: matrix };
		const resetNote = await CustomerNoteServices.updateCustomerNoteMatrix(
			this.state.noteData.id,
			data
		);
		if (resetNote.data) {
			this.state.noteData.is_announced = true;
			this.updateAnnounce();
			this.getNotes();
		}
	}

	async updateAnnounce() {
		this.state.noteData.is_announced = !this.state.noteData.is_announced;
		let data = {
			is_announce: this.state.noteData.is_announced,
		};
		const announceNote = await CustomerNoteServices.announceNote(
			data,
			this.state.noteData.id
		);
		if (announceNote.data) {
			this.forceUpdate();
		}
	}

	async getActiveOrder() {
		const getOrderPlacedMatrixOnActive =
			await OrderPlacedServices.getOrderPlacedMatrixOnActive('active');
		if (getOrderPlacedMatrixOnActive.data.id !== 0) {
			const { data } = getOrderPlacedMatrixOnActive;
			if (data.limited_order_matrix) {
				if (data.id !== null) {
					this.props.setDisableBuyBtn(true);
          this.props.updateOrderPlaced(true)
				}
				let order = [];
				for (let item in data.limited_order_matrix) {
					let temp = {};
					temp['dayIndex'] = data.limited_order_matrix[item][0].date;
					temp['list'] = data.limited_order_matrix[item].sort((a, b) =>
						a.weight_class > b.weight_class
							? 1
							: b.weight_class > a.weight_class
							? -1
							: 0
					);
					order.push(temp);
				}
				this.setState({
					activeOrderMatrix: order,
					activeTotal: data.total_volume,
					activeTotalPrice: data.order_total,
					activeTab: 'active',
					orderType: 'active',
					pdfData: data.pdf_data,
					activeOrderPrice: data.order_price_offer,
				});
				this.forceUpdate();
			} else {
				this.getPendingOrder();
			}
		} else {
			setTimeout(() => {
				this.getLastOrderByStatus();
			}, 500);
		}
	}

	async getLastOrderByStatus() {
		const getOrderPlacedMatrixOnActive =
			await OrderPlacedServices.getOrderPlacedMatrixOnActive('last');
		if (getOrderPlacedMatrixOnActive.data) {
			const { data } = getOrderPlacedMatrixOnActive;
			if (data.limited_order_matrix) {
				let order = [];
				for (let item in data.limited_order_matrix) {
					let temp = {};
					temp['dayIndex'] = data.limited_order_matrix[item][0].date;
					temp['list'] = data.limited_order_matrix[item].sort((a, b) =>
						a.weight_class > b.weight_class
							? 1
							: b.weight_class > a.weight_class
							? -1
							: 0
					);
					order.push(temp);
				}
				this.setState({
					activeOrderMatrix: order,
					activeTotal: data.total_volume,
					activeTotalPrice: data.order_total,
					activeTab: 'active',
					orderType: 'last',
					pdfData: data.pdf_data,
					activeOrderPrice: data.order_price_offer,
				});
				this.forceUpdate();
			} else {
				this.getPendingOrder();
			}
		}
	}

	async getPendingOrder() {
		const getQuoteMatrix = await QuoteServices.getQuoteMatrix(1);
		if (getQuoteMatrix.data) {
			const { data } = getQuoteMatrix;
			if (data.check_out_shipment_matrix) {
				let order = [];
				for (let item in data.check_out_shipment_matrix) {
					let temp = {};
					//  temp['dayIndex'] = data.check_out_shipment_matrix[item][0].date;
					temp['list'] = data.check_out_shipment_matrix[item].sort((a, b) =>
						a.weight_class > b.weight_class
							? 1
							: b.weight_class > a.weight_class
							? -1
							: 0
					);
					order.push(temp);
				}
				this.setState({
					activeOrderMatrix: order,
					activeTotal: data.total_volume,
					activeTotalPrice: data.quotee_total,
					activeTab: 'active',
					orderType: 'pending',
					activeOrderPrice: data.active_admin_offer_full,
				});
				this.forceUpdate();
			}
		}
	}

	getDate() {
		var nextMonday = new Date();
		nextMonday.setDate(
			nextMonday.getDate() + ((1 + 7 - nextMonday.getDay()) % 7)
		);
		var nextWeekArrays = [nextMonday];
		for (let i = 1; i < 7; i++) {
			nextWeekArrays.push(
				new Date(new Date(nextMonday).setDate(nextMonday.getDate() + i))
			);
		}
		this.weekDet = nextWeekArrays;
	}

	async getLastOrder(i) {
		let data = { customer_shipment_location: i };
		const getLastOrder = await BaseOrderServices.getLastOrder(data);
		if (getLastOrder.data) {
			const { data } = getLastOrder;
			this.setState({
				lastElem: Object.keys(data.order_details),
				lastSel: data.order_details,
				lastTotal: data,
				orderDet: data.order,
			});
			this.state.lastElem.map((key, i) => {
				this.state.lastSel[key].sort(function (x, y) {
					return x['dayIndex'] - y['dayIndex'];
				});
			});
			// this.forceUpdate();
			// console.log("lasr order",a)
		}
	}

	toggDrop() {
		this.setState({
			addrDrop: !this.state.addrDrop,
		});
	}
	changeShipment = (event, a, i) => {
		this.state.selectedQuote = i;
		this.getBaseOrder(a);
		this.forceUpdate();
	};
	toggBase = (tab) => {
		this.setState({
			activeTab: tab,
		});

		if (tab === 'active') {
			this.getActiveOrder();
		}
		if (tab === 'last') {
			this.getLastOrderByStatus();
		}
	};
	sumAccord = () => {
		this.state.elements.map((key, i) => {
			var arrEl = this.state.sel[key].map(function (o, i) {
				return o.value;
			});
			var sum = arrEl.reduce(function (a, b) {
				return a + b;
			}, 0);
			var accrStat = sum === 0 ? true : false;
			this.state.accStat[i] = accrStat;
		});
		// var a =this.state.lastElem.sort((x,y) => {
		//     return x['dayIndex']-y['dayIndex'];
		// })
	};
	render() {
		const userInfos = BrowserStorage.getValue('userInfo');

		var daysOrder = ['', 'Mon', 'Tue', 'Wed', 'Thu', 'Fri', 'Sat', 'Sun'];
		return (
			<>
				<section className={'base_add_wrp'}>
					{/* <div className={"home_top_block card_align"}>
            <label className={"placeholder"}>Placeholder</label>
          </div> */}
					<div className={'base_tog_btn'}>
						{userInfos.access_type === 'customer_shipment_location' && (
							<div
								className={this.state.activeTab == 'active' ? 'actBtnB ' : null}
								onClick={() => this.toggBase('active')}
							>
								{this.state.orderType === 'active'
									? 'Active'
									: this.state.orderType === 'last'
									? 'Last'
									: 'Pending '}{' '}
								Order
							</div>
						)}

						<div
							className={this.state.activeTab == 'base' ? 'actBtnB ' : null}
							onClick={() => this.toggBase('base')}
						>
							Base Order
						</div>

						{this.state.showNotes === 'true' && (
							<div
								className={this.state.activeTab == 'note' ? 'actBtnB ' : null}
								onClick={() => this.toggBase('note')}
							>
								Notes
							</div>
						)}
					</div>

					{this.state.activeTab == 'active' && (
						<section className={'Base_wrap'}>
							{this.state.activeTotal != 0 ? (
								<>
									<div className={'Base_block fc_base4'}>
										<h3>
											{this.state.orderType === 'active'
												? 'Active'
												: this.state.orderType === 'last'
												? 'Last'
												: 'Pending'}{' '}
											Order
										</h3>
										<div className={'Base_total_block fc_box_289'}>
											<span>
												{this.state.activeTotal}{' '}
												{this.state.activeTotal == 1 ? 'Box' : 'Boxes'}
											</span>{' '}
											<br />
											<label>Total Quantity</label>
										</div>
										<div className={'Base_total_block fc_box_289'}>
											<span>
												{this.state.activeTotalPrice &&
													this.state.activeTotalPrice
														.toFixed(2)
														.replace(/(\d)(?=(\d{3})+(?!\d))/g, '$1 ')}
											</span>{' '}
											<br />
											<label>Order Total USD</label>
										</div>
										<div className={'Base_addr_block'}>
											<div className={'ship_addr_wrp'} onClick={() => this.toggDrop()}>
												{this.shipment_address}
											</div>
										</div>
									</div>
									<div className={'Base_block'}>
										<div
											className={'Acd_hd base_header primary_bg'}
											style={{ paddingLeft: 150 }}
										>
											{this.state.activeOrderMatrix[0] &&
												this.state.activeOrderMatrix[0].list.map((key, i) => {
													return (
														<div className='Base_days'>
															<span className={'inData'}>{key.weight_class} kg</span>
														</div>
													);
												})}
										</div>

										{this.state.activeOrderMatrix.map((key, i) => {
											return (
												!this.state.accStat[i] && (
													<div className={'base_ord_table Base_ord_head '}>
														{this.state.orderType === 'active' && (
															<div>
																{this.state.pdfData[i] && this.state.pdfData[i].pdf ? (
																	<a
																		className={'doc_icon active'}
																		href={axios.defaults.baseURL + this.state.pdfData[i].pdf}
																		target='_blank'
																	></a>
																) : (
																	<div className={'doc_icon'}></div>
																)}
															</div>
														)}
														{(this.state.orderType ==='last' || this.state.orderType ==='pending' )&& <div className='doc_icon'></div>}
														<p className={'Base_ord_weight'}>{key.list[0].date}</p>
														<section>
															{key.list.map((days, index) => {
																return (
																	<div key={index} className={'Base_days'}>
																		<span>{days.volume ? days.volume : '-'}</span>
																	</div>
																);
															})}
														</section>
													</div>
												)
											);
										})}
										<div className={'base_ord_table Base_ord_head '}>
											<div className='price_icon'>
											<img src={PriceTagIMG} className='price-tag' />

											</div>
											<p className={'Base_ord_weight'}>Price/kg</p>
											<section>
												{this.state.activeOrderMatrix[0] &&
													this.state.activeOrderMatrix[0].list.map((key, index) => {
														return (
															<div key={index} className={'Base_days'}>
																<span>
																	{this.state.activeOrderPrice[key.weight_class]?.toFixed(2)}
																</span>
															</div>
														);
													})}
											</section>
										</div>
									</div>
								</>
							) : (
								<div style={{ textAlign: 'center', padding: 15 }}>No Active Orders</div>
							)}
						</section>
					)}
					{this.state.activeTab == 'base' && (
						<section className={'Base_wrap fc_base1'} style={{gap:25}}>
							{this.state.totalOrder != 0 && (
								<>
									<div className={'Base_block fc_base2'}>
										<h3>Base Order</h3>
										<div className='Base_total_block'>
											<span>
												{this.state.totalOrder}{' '}
												{this.state.totalOrder == 1 ? 'Box' : 'Boxes'}
											</span>{' '}
											<br />
											<label>Total Quantity</label>
										</div>

										<div className='Base_total_block'>
											<span>
												{this.state.totalWieghtForBaseOrder}
												{' kg'}
											</span>{' '}
											<br />
											<label>Total Weight</label>
										</div>

										<div className={'Base_addr_block'}>
											<div className={'ship_addr_wrp'} onClick={() => this.toggDrop()}>
												{this.shipment_address}
											</div>
										</div>
									</div>
									<table
										className='rfq-manager-table'
										style={{ width: '100%', borderSpacing: 0, marginTop: 20,boxShadow:"2px 2px 4px lightgray" }}
									>
										<thead>
											<tr className='table-header'>
												<th style={{ width: 100 }}></th>
												{this.weekDet.map((val, i) => {
													return <th style={{ color: '#fff' }}>{daysOrder[i + 1]}</th>;
												})}
												<th style={{ color: '#fff' }}>{this.state.shipment_week}</th>
											</tr>
										</thead>
										<tbody>
											{this.state.elements.length > 0 ? (
												this.state.elements.map((key, index) => {
													return (
														!this.state.accStat[index] && (
															<tr key={index} className='base-order-downstream-td'>
																<td
																	className='producer-cell'
																	style={{ textAlign: 'left', width: 100 }}
																>
																	HOG {key} kg
																</td>
																{this.state.sel[key].map((days, i) => {
																	return <td>{days.volume ? days.volume : ''}</td>;
																})}
															</tr>
														)
													);
												})
											) : (
												<tr>
													<td colspan='3'>No data available </td>
												</tr>
											)}
										</tbody>
									</table>
								</>
							)}
							{this.state.totalOrder == 0 && (
								<Link className={'ed_out'} to={'/home/create-baseorder'}>
									<div>Create Base Order</div>
								</Link>
							)}
						</section>
					)}

					{this.state.activeTab == 'note' && (
						<section className={'Base_wrap'}>
							{this.state.noteTotalOrder != 0 && (
								<>
									<div className={'Base_block fc_base4'}>
										<h3>Notes</h3>
										<div className={'Base_total_block fc_box_289'}>
											<span>
												{this.state.noteTotalOrder}{' '}
												{this.state.noteTotalOrder == 1 ? 'Box' : 'Boxes'}
											</span>{' '}
											<br />
											<label>Total Quantity</label>
										</div>
										<div className={'announce_wrap'}>
											<p>Announce</p>
											<div className={'toggle_wrap'}>
												<input
													type='checkbox'
													id='announce'
													checked={this.state.noteData.is_announced}
												/>
												<label for='announce' onClick={() => this.updateAnnounce()}></label>
											</div>
										</div>
										<Link to={'/home/create-note'}>
											<div className='sp_btn home_btn note_btn'>Edit Note</div>
										</Link>
										<div className='home_btn note_btn' onClick={() => this.resetNote()}>
											Reset Note
										</div>
										<div className={'Base_addr_block'}>
											<div className={'ship_addr_wrp'} onClick={() => this.toggDrop()}>
												{this.shipment_address}
											</div>
										</div>
									</div>
									<div className={'Base_block'}>
										<div className={'Acd_hd base_header note_header'}>
											{this.state.noteMatrix[0] &&
												this.state.noteMatrix[0].list.map((key, i,matrix) => 
                          i===matrix.length-1? (<div className={'Base_days' + ' ' + 'dayBf'}>
                          <span className='note-header-text'>{this.state.shipment_week}</span>
                        </div>):
													 (
														<div className={'Base_days' + ' ' + 'dayBf'}>
															<span className='note-header-text'>{daysOrder[key.dayIndex]}</span>
														</div>
													)
												)}
										</div>

										{this.state.noteMatrix.map((key, i) => {
											return (
												!this.state.accStat[i] && (
													<div className={'base_ord_table Base_ord_head '}>
														<p className={'Base_ord_weight'}>{key.key} kg</p>
														<section>
															{key.list.map((days, index) => {
																return (
																	<div key={index} className={'Base_days'}>
																		<span>{days.volume ? days.volume : ' '}</span>
																	</div>
																);
															})}
														</section>
													</div>
												)
											);
										})}
									</div>
								</>
							)}
							{this.state.noteTotalOrder == 0 && (
								<Link className={'ed_out'} to={'/home/create-note'}>
									<div>Create Note</div>
								</Link>
							)}
						</section>
					)}
				</section>
			</>
		);
	}
}

export default BaseOrder;
