import React, { Component, useEffect, useState, useReducer } from 'react';
import axios from 'axios';
import Sidebar from '../Sidebar/Sidebar';
import { ToastsStore } from 'react-toasts';
import ToggleSwitch from '../Toggle/ToggleSwitch';
import { AiOutlineCheckCircle, AiOutlineCloseCircle } from 'react-icons/ai';
import { MdRefresh, MdExpandLess, MdExpandMore } from 'react-icons/md';
import Modal from 'react-modal';
import { GrClose } from 'react-icons/gr';
import Moment from 'react-moment';
import OrderVolumeMatrix from '../Matrix/OrderVolumeMatrix';
import MatrixHeader from '../Matrix/MatrixHeader';
import CommonServices from '../../api/CommonServices';
import CalendarServices from '../../api/CalendarServices';

//modal styles
const customStyles = {
	content: {
		background: 'white',
		// width: '45rem',
		maxWidth: 'calc(100vw - 2rem)',
		maxHeight: 'calc(100vh - 2rem)',
		boxshadow: '0 0 30px 0 rgba(0, 0, 0, 0.25)',
		overflowY: 'auto',
		position: 'relative',
	},
	overlay: {
		position: 'fixed',
		zIndex: 999999,
		top: 0,
		left: 0,
		width: '100vw',
		height: '100vh',
		background: 'rgba(0, 0, 0, 0.5)',
		display: 'flex',
		alignItems: 'center',
		justifyContent: 'center',
	},
};

//constants
export const ACTIONS = {
	SET_FIELDS: 'SetFields',
	SET_MATRIX_VOLUMES: 'SetMatrixVolumes',
	UPDATE_MATRIX_VOLUME: 'UpdateMatrixVolume',
	SET_MATRIX_HEADER: 'SetMatrixHeader',
};

//reducers
const reducer = (state, action) => {
	switch (action.type) {
		case ACTIONS.SET_FIELDS:
			return {
				...state,
				[action.payload.field]: action.payload.value,
			};
		case ACTIONS.SET_MATRIX_VOLUMES:
			return {
				...state,
				matrix_volumes_9_1: action.payload.matrix_volumes_9_1,
				matrix_volumes_9_7: action.payload.matrix_volumes_9_7,
				matrix_volumes_9_8: action.payload.matrix_volumes_9_8,
				inital_matrix_volumes: action.payload.inital_matrix_volumes,
				matrix_dimension_status: action.payload.matrix_dimension_status,
				disable_9_7: action.payload.disable_9_7,
				weight_classes: Object.keys(action.payload.matrix_row_total),
			};
		case ACTIONS.UPDATE_MATRIX_VOLUME:
			if (action.payload.matrix_dimension_status === 1)
				return {
					...state,
					matrix_volumes_9_1: action.payload.matrix,
				};
			if (action.payload.matrix_dimension_status === 2)
				return {
					...state,
					matrix_volumes_9_7: action.payload.matrix,
				};
			return {
				...state,
				matrix_volumes_9_8: action.payload.matrix,
			};
		case ACTIONS.SET_MATRIX_HEADER:
			return {
				...state,
				total_matrix_volume: action.payload.total_volume,
				total_matrix_weight: action.payload.total_weight,
			};
		default:
			return {
				...state,
			};
	}
};

const Forecast = () => {
	//STATES
	const [accumulated, setAccumulated] = useState([]);
	const [accumulatedSel, setAccumulatedSel] = useState(null);
	const [accumulatedModel, setAccumulatedModel] = useState({});
	const [accumulatedStat, setAccumulatedStat] = useState([]);
	const [results, setResults] = useState([]);
	const [selectedRows, setSelectedRows] = useState([]);
	const [firstCol, setFirstCol] = useState(1);
	const [secondCol, setSecondCol] = useState(1);
	const [thirdCol, setThirdCol] = useState(1);
	const [fourthCol, setFourthCol] = useState(1);
	const [fifthCol, setFifthCol] = useState(1);
	const [sixthCol, setSixthCol] = useState(1);
	const [forecastColGroup, setForecastColGroup] = useState(1);
	const [firstColClick, setFirstColClick] = useState(false);
	const [secondColClick, setSecondColClick] = useState(false);
	const [thirdColClick, setThirdColClick] = useState(false);
	const [fourthColClick, setFourthColClick] = useState(false);
	const [onClick, setOnClick] = useState(false);
	const [col1, setCol1] = useState(false);
	const [col2, setCol2] = useState(false);
	const [col3, setCol3] = useState(false);
	const [col4, setCol4] = useState(false);
	const [totalResults, setTotalResults] = useState([]);
	const [resetClick, setResetClick] = useState(false);
	const [shipmentWeek, setShipmentWeek] = useState('');
	const [modal, setModal] = useState(false);
	const [modalMatrix, setModalMatrix] = useState(null);
	const [modalMatrixData, setModalMatrixData] = useState([]);
	const [modalMatrixStat, setModalMatrixStat] = useState([]);
	const [modalMatrixModel, setModalMatrixModel] = useState({});
	const [expand, setExpand] = useState(false);
	const [matrixShow, setMatrixShow] = useState(true);
	const [eighthDay, setEighthDay] = useState(false);
	const [weekDet, setWeekDet] = useState([]);
	const [userResult, setUserResult] = useState({});
	const [viewType, setViewType] = useState('');
	const [type, setType] = useState('');
	const [headerData, setHeaderData] = useState(null);

	//FORCE UPDATE
	const [, updateState] = React.useState();

	const [state, dispatch] = useReducer(reducer, {
		matrix_dimension_status: 2, // 1-> 9+1, 2-> 9*7, 3-> 9*8
		disable_9_1: false,
		disable_9_7: false,
		disable_9_8: false,
		total_matrix_volume: 0,
		total_matrix_weight: 0,
		matrix_volumes_9_1: {},
		matrix_volumes_9_7: {},
		matrix_volumes_9_8: {},
		inital_matrix_volumes: {},
		initial_dimension_status: 2,
		week_data: [],
		weight_classes: [],
		sourcing_week: '', // default -> current week
		shipment_week: '', // default -> next week
	});


	useEffect(() => {
		callForecastApi();
		getDate();
		getWeekDates(state.sourcing_week);
	}, []);

	const callForecastApi = async (val, customerShipmentLocations, prospect) => {
		const dataa = {};
			if (val) {
				dataa['filter_param'] = val;
			}
			if (customerShipmentLocations) {
				dataa['customer_shipment_locations'] = customerShipmentLocations;
			}
			if (prospect) {
				dataa['prospect'] = prospect;
			}
		try {
			const updateOutlook = await CommonServices.updateOutlook(dataa);
			if(updateOutlook.data){
				const {data} = updateOutlook;
				let model = {};

				Object.entries(data.accumulated_matrix).map((item, i) => {
					return (model[item[0]] = {
						hasVolume: item[1].some((val) => val.volume > 0),
					});
				});
	
				setAccumulatedModel(model);
				setAccumulatedSel(data.accumulated_matrix);
				setResults(data.results);
				setShipmentWeek(data.week);
				setTotalResults(data.results);
				setAccumulated(Object.keys(data.accumulated_matrix));
	
				accumulated.map((key, i) => {
					accumulatedSel[key].sort(function (x, y) {
						return x['dayIndex'] - y['dayIndex'];
					});
				});
				// this.getDate("val");
				sumAccord();

			}
		} catch (error) {
			console.log(error);
		}
	};

	// method to get shipment week dates from api
	const getWeekDates = async (sourcing_week) => {
		const getWeekDates = await CalendarServices.getWeekDates(sourcing_week);
		if(getWeekDates.data){
			const {data} = getWeekDates
			dispatch({
				type: ACTIONS.SET_FIELDS,
				payload: {
					field: 'week_data',
					value: data.shipment_dates,
				},
			});
			dispatch({
				type: ACTIONS.SET_FIELDS,
				payload: {
					field: 'sourcing_week',
					value: data.sourcing_week,
				},
			});
			dispatch({
				type: ACTIONS.SET_FIELDS,
				payload: {
					field: 'shipment_week',
					value: data.shipment_week,
				},
			});
		}
	};

	useEffect(() => {
		console.log('------------ORDER VOLUME MATRIX CHANGED------------');
		if (state.matrix_dimension_status === 1) {
			dispatchMatrixHeaders(state.matrix_volumes_9_1);
		}
		if (state.matrix_dimension_status === 2) {
			dispatchMatrixHeaders(state.matrix_volumes_9_7);
		}
		if (state.matrix_dimension_status === 3) {
			dispatchMatrixHeaders(state.matrix_volumes_9_8);
		}
	}, [
		state.matrix_volumes_9_1,
		state.matrix_volumes_9_7,
		state.matrix_volumes_9_8,
		state.matrix_dimension_status
	]);

	const getVolumes = (matrix) => {
		let sum = 0;
		Object.entries(matrix).map((item) =>
			item[1].map((val) => (sum += val.volume))
		);
		return sum;
	};

	const dispatchMatrixHeaders = (matrix) => {
		dispatch({
			type: ACTIONS.SET_MATRIX_HEADER,
			payload: {
				total_volume: getVolumes(matrix),
				total_weight: getVolumes(matrix) * 22,
			},
		});
	};

	const sumAccord = () => {
		accumulated.map((key, i) => {
			var arrEl = accumulatedSel[key].map(function (o, i) {
				return o.value;
			});
			var sum = arrEl.reduce(function (a, b) {
				return a + b;
			}, 0);
			var accrStat = sum === 0 ? true : false;

			// this.state.accmulatedStat[i] = accrStat; <- MUTATION STATE

			let tempAccumulatedStat = accumulatedStat;
			tempAccumulatedStat[i] = accrStat;
			setAccumulatedStat(tempAccumulatedStat);
		});
	};

	const forceUpdate = React.useCallback(() => updateState({}), []);

	const getDate = (val, index = false) => {
		var nextMonday = new Date();
		nextMonday.setDate(
			nextMonday.getDate() + ((1 + 7 - nextMonday.getDay()) % 7)
		);
		var nextWeekArrays = [nextMonday];
		for (let i = 1; i < 7; i++) {
			nextWeekArrays.push(
				new Date(new Date(nextMonday).setDate(nextMonday.getDate() + i))
			);
		}
		if(nextWeekArrays.indexOf("undistributed") !== -1){
			console.log('already exists')
		} else{
			nextWeekArrays.push('undistributed')
		}
		setWeekDet(nextWeekArrays);
		forceUpdate();
	};

	const checkisEightDay = (val, refKey) => {
		let isEightDay = val[refKey][0].dayIndex;
		if (isEightDay === 8) setEighthDay(true);
		else setEighthDay(false);
	};

	const getMatrix = async (user, modelName, type) => {
		setType(type);
		try {
			let data = {
				access_type: user.type,
				model_name: modelName,
				access_id: user.access_id,
				sourcing_week: shipmentWeek,
			};
			const getMatrixData = await CommonServices.getMatrixData(data);
			if(getMatrixData.data){
				const {data} = getMatrixData;
				dispatch({
					type: ACTIONS.SET_MATRIX_VOLUMES,
					payload: {
						// response key should be changed once api is ready.
						matrix_volumes_9_1: data.matrix_row_total,
						matrix_volumes_9_7: data.full_active_matrix,
						matrix_volumes_9_8: data.full_matrix_combined,
						// matrix_volumes_9_8: dummyData.full_9_8_matrix,
						inital_matrix_volumes: data,
						matrix_id: data.matrix_id,
						matrix_dimension_status: data.matrix_dimension_status,
						matrix_row_total: data.matrix_row_total,
						disable_9_7: data.matrix_dimension_status === 3 ? true : false,
						shipment_week: data.shipment_week,
					}
				})
				dispatch({
					type: ACTIONS.SET_FIELDS,
					payload: {
						field: 'initial_dimension_status',
						value: data.matrix_dimension_status,
					},
				});

				openModal()
				const results = data
				results["customerName"] = user.name
				setHeaderData(results)
			}
		} catch (error) {
			console.log(error);
		}
	};
	const renderModelType = (dimensionStatus) => {
		console.log(dimensionStatus, 'status')
		switch (dimensionStatus) {
			case 1:
				return state.matrix_volumes_9_1;
			case 2:
				return state.matrix_volumes_9_7;
			case 3:
				return state.matrix_volumes_9_8;
			default:
				return null;
		}
	};

	const openModal = () => {
		setModal(true);
	};

	const changeTogleData = (matrix, data) => {
		let model = {};
		for (let item in matrix) {
			let sum = 0;
			matrix[item].forEach((element) => {
				sum += element.volume;
			});
			model[item] = {
				hasVolume: sum !== 0,
			};
		}

		setResults(data);
		setAccumulated(Object.keys(matrix));
		setAccumulatedSel(matrix);
		setAccumulatedModel(model);

		accumulated.map((key, i) => {
			accumulatedSel[key].sort(function (x, y) {
				return x['dayIndex'] - y['dayIndex'];
			});
		});
		getDate();
		sumAccord();
	};

	const onRowIndexClick = (i, add, remove) => {
		if (add === true && remove === false) {
			if (selectedRows.indexOf(i) !== -1) {
				console.log('exists');
			} else {
				const rows = [...selectedRows, i];
				setSelectedRows(rows);
			}
		} else if (add == false && remove == true) {
			var array = [...selectedRows];
			var index = array.indexOf(i);
			if (index !== -1) {
				array.splice(index, 1);
				setSelectedRows(array);
			}
		}
	};

	const forecastColClick = () => {
		setFirstCol(1);
		setSecondCol(1);
		setThirdCol(0.45);
		setFourthCol(0.45);
		setFifthCol(0.45);
		setSixthCol(0.45);
		setForecastColGroup(1);
		setFirstColClick(true);
		setSecondColClick(false);
		setThirdColClick(false);
		setFourthColClick(false);
		setOnClick(true);
		setCol1(!col1);
		setCol2(false);
		setCol3(false);
		setCol4(false);

		if (col1) {
			const customerShipmentLocations = results
				.filter(
					(x) => x.is_disabled === true && x.type === 'customer_shipment_location'
				)
				.map((x) => x.customer_shipment_location_id);
			const prospect = results
				.filter((x) => x.is_disabled === true && x.type === 'prospect')
				.map((x) => x.customer_shipment_location_id);

			setFirstCol(1);
			setSecondCol(1);
			setThirdCol(1);
			setFourthCol(1);
			setFifthCol(1);
			setSixthCol(1);
			setForecastColGroup(1);
			setFirstColClick(true);
			setSecondColClick(true);
			setThirdColClick(true);
			setFourthColClick(true);
			setOnClick(false);

			callForecastApi(
				'',
				customerShipmentLocations.length > 0 ? customerShipmentLocations : '',
				prospect.length > 0 ? prospect : ''
			);
		} else {
			const customerShipmentLocations = results
				.filter(
					(x) => x.is_disabled === true && x.type === 'customer_shipment_location'
				)
				.map((x) => x.customer_shipment_location_id);
			const prospect = results
				.filter((x) => x.is_disabled === true && x.type === 'prospect')
				.map((x) => x.customer_shipment_location_id);

			callForecastApi(
				'forecast',
				customerShipmentLocations.length > 0 ? customerShipmentLocations : '',
				prospect.length > 0 ? prospect : ''
			);
		}
	};

	const quoteColClick = () => {
		setFirstCol(1);
		setSecondCol(0.45);
		setThirdCol(1);
		setFourthCol(0.45);
		setFifthCol(0.45);
		setSixthCol(0.45);
		setForecastColGroup(0.45);
		setSecondColClick(true);
		setFirstColClick(false);
		setThirdColClick(false);
		setFourthColClick(false);
		setOnClick(true);
		setCol2(!col2);
		setCol1(false);
		setCol3(false);
		setCol4(false);

		if (col2) {
			setFirstCol(1);
			setSecondCol(1);
			setThirdCol(1);
			setFourthCol(1);
			setFifthCol(1);
			setSixthCol(1);
			setForecastColGroup(1);
			setSecondColClick(true);
			setFirstColClick(true);
			setThirdColClick(true);
			setFourthColClick(true);
			setOnClick(false);

			const customerShipmentLocations = results
				.filter(
					(x) => x.is_disabled === true && x.type === 'customer_shipment_location'
				)
				.map((x) => x.customer_shipment_location_id);
			const prospect = results
				.filter((x) => x.is_disabled === true && x.type === 'prospect')
				.map((x) => x.customer_shipment_location_id);

			callForecastApi(
				'',
				customerShipmentLocations.length > 0 ? customerShipmentLocations : '',
				prospect.length > 0 ? prospect : ''
			);
		} else {
			const customerShipmentLocations = results
				.filter(
					(x) => x.is_disabled === true && x.type === 'customer_shipment_location'
				)
				.map((x) => x.customer_shipment_location_id);
			const prospect = results
				.filter((x) => x.is_disabled === true && x.type === 'prospect')
				.map((x) => x.customer_shipment_location_id);

			callForecastApi(
				'quote',
				customerShipmentLocations.length > 0 ? customerShipmentLocations : '',
				prospect.length > 0 ? prospect : ''
			);
		}
	};

	const OrderColClick = () => {
		setFirstCol(1);
		setSecondCol(0.45);
		setThirdCol(0.45);
		setFourthCol(1);
		setFifthCol(0.45);
		setSixthCol(0.45);
		setForecastColGroup(0.45);
		setThirdColClick(true);
		setSecondColClick(false);
		setFirstColClick(false);
		setFourthColClick(false);
		setOnClick(true);
		setCol3(!col3);
		setCol1(false);
		setCol2(false);
		setCol4(false);

		if (col3) {
			setFirstCol(1);
			setSecondCol(1);
			setThirdCol(1);
			setFourthCol(1);
			setFifthCol(1);
			setSixthCol(1);
			setForecastColGroup(1);
			setSecondColClick(true);
			setFirstColClick(true);
			setThirdColClick(true);
			setFourthColClick(true);
			setOnClick(false);

			const customerShipmentLocations = results
				.filter(
					(x) => x.is_disabled === true && x.type === 'customer_shipment_location'
				)
				.map((x) => x.customer_shipment_location_id);
			const prospect = results
				.filter((x) => x.is_disabled === true && x.type === 'prospect')
				.map((x) => x.customer_shipment_location_id);

			callForecastApi(
				'',
				customerShipmentLocations.length > 0 ? customerShipmentLocations : '',
				prospect.length > 0 ? prospect : ''
			);
		} else {
			const customerShipmentLocations = results
				.filter(
					(x) => x.is_disabled === true && x.type === 'customer_shipment_location'
				)
				.map((x) => x.customer_shipment_location_id);
			const prospect = results
				.filter((x) => x.is_disabled === true && x.type === 'prospect')
				.map((x) => x.customer_shipment_location_id);

			callForecastApi(
				'order',
				customerShipmentLocations.length > 0 ? customerShipmentLocations : '',
				prospect.length > 0 ? prospect : ''
			);
		}
	};

	const percentageColClick = () => {
		setFirstCol(1);
		setSecondCol(0.45);
		setThirdCol(0.45);
		setFourthCol(0.45);
		setFifthCol(0.45);
		setSixthCol(1);
		setForecastColGroup(0.45);
		setFourthColClick(true);
		setSecondColClick(false);
		setThirdColClick(false);
		setFirstColClick(false);
		setOnClick(true);
		setCol4(!col4);
		setCol2(false);
		setCol3(false);
		setCol1(false);
		if (col4) {
			setFirstCol(1);
			setSecondCol(1);
			setThirdCol(1);
			setFourthCol(1);
			setFifthCol(1);
			setFifthCol(1);
			setSixthCol(1);
			setForecastColGroup(1);
			setSecondColClick(true);
			setFirstColClick(true);
			setThirdColClick(true);
			setFourthColClick(true);
			setOnClick(false);

			const customerShipmentLocations = results
				.filter(
					(x) => x.is_disabled === true && x.type === 'customer_shipment_location'
				)
				.map((x) => x.customer_shipment_location_id);
			const prospect = results
				.filter((x) => x.is_disabled === true && x.type === 'prospect')
				.map((x) => x.customer_shipment_location_id);

			callForecastApi(
				'',
				customerShipmentLocations.length > 0 ? customerShipmentLocations : '',
				prospect.length > 0 ? prospect : ''
			);
		} else {
			const customerShipmentLocations = results
				.filter(
					(x) => x.is_disabled === true && x.type === 'customer_shipment_location'
				)
				.map((x) => x.customer_shipment_location_id);
			const prospect = results
				.filter((x) => x.is_disabled === true && x.type === 'prospect')
				.map((x) => x.customer_shipment_location_id);

			callForecastApi(
				'percentage',
				customerShipmentLocations.length > 0 ? customerShipmentLocations : '',
				prospect.length > 0 ? prospect : ''
			);
		}
	};

	const reset = () => {
		setSelectedRows([]);
		setFirstCol(1);
		setSecondCol(1);
		setThirdCol(1);
		setFourthCol(1);
		setFifthCol(1);
		setSixthCol(1);
		setForecastColGroup(1);
		setFirstColClick(true);
		setSecondColClick(true);
		setThirdColClick(true);
		setFourthColClick(true);
		setOnClick(false);
		setResetClick(!resetClick);

		callForecastApi();
	};

	const allRowsSelected = (val) => {
		setSelectedRows(val);
	};

	const closeModal = () => {
		setModal(false);
	};

	const hideMatrix = () => {
		setMatrixShow(!matrixShow);
	};

	var daysOrder = ['', 'Mon', 'Tue', 'Wed', 'Thu', 'Fri', 'Sat', 'Sun', state.shipment_week];

	return (
		<>
			<Modal
				isOpen={modal}
				onRequestClose={() => closeModal()}
				onAfterOpen={() => (document.body.style.overflow = 'hidden')}
				onAfterClose={() => (document.body.style.overflow = 'unset')}
				style={customStyles}
			>
				<div class='scrl_out' style={{ paddingBottom: '30px' }}>
					<div className={'modal_heading'}>
							<p>{viewType}</p>
							<div className={'close-icon'} onClick={() => closeModal()}>
								<GrClose size={25} />
							</div>
					</div>
					<section className={'Modal_Base_wrap'}>
						<MatrixHeader
							headerData={headerData}
							type={type}
							state={state}
							viewType={viewType}
						/>
						<>
							<div className={'Base_block'}>
							<OrderVolumeMatrix
								orderVolumeMatrix={renderModelType(state.matrix_dimension_status)}
								enableUpdate={false}
								state={state}
								daysOrder={daysOrder}
								type={type}
								viewType={viewType}
							/>
							</div>
						</>
					</section>
				</div>
			</Modal>
			<section className={'body_wrap packing_wrapper'}>
				{/* <Sidebar /> */}

				<div style={{ paddingBottom: '30px' }}>
					<div class='scrl_out' style={{ paddingBottom: '30px' }}>
						<section className={'home_top_wrap'}>
							<div className={'home_top_block'}>
								<p>Outlook Matrix</p>
								<label>Shipment Week : {shipmentWeek ? shipmentWeek : ' --- '}</label>
							</div>
						</section>
						<section className={'Base_wrap'}>
							<>
								<div className={'Base_block'}>
									<div className={'matrix_toggle'}>
										<ToggleSwitch
											status={false}
											onToggleChange={() => hideMatrix()}
											fromMatrix={true}
										/>
									</div>

									<div className={'Acd_hd base_header'}>
										{weekDet.map((val, i) => {
											return (
												<div className={'Base_days' + ' ' + 'dayBf'}>
													<span className={'inData'}>
														{daysOrder[i + 1]} {val == 'undistributed' ? null : <Moment format='MMM DD'>{val}</Moment>}
													</span>
												</div>
											);
										})}
									</div>
									<div className={`${matrixShow ? '' : 'hide_matrix'}`}>
										{accumulated.map((key, i) => {
											// console.log('accu', accumulatedModel);
											return (
												!accumulatedStat[i] && (
													<div
														className={
															'base_ord_table Base_ord_head ' +
															(accumulatedModel[key].hasVolume ? 'hasVolume' : '')
														}
													>
														<p className={'Base_ord_weight'}>HOG {key} kg</p>
														<section>
															{accumulatedSel[key].map((days, index) => {
																return (
																	<div key={index} className={'Base_days'}>
																		<span>{days.volume ? days.volume : '-'}</span>
																	</div>
																);
															})}
														</section>
													</div>
												)
											);
										})}
									</div>
								</div>
							</>
						</section>
					</div>

					<section className={'OrderTableWrap' + ' ' + 'scrl_out'}>
						<table className={'outlook_table'}>
							<tr className={'table_head'}>
								<th
									style={{
										opacity: firstCol,
									}}
								>
									<div className='icon-aligner'>
										<MdRefresh
											size={20}
											onClick={() => reset()}
											style={{ cursor: 'pointer' }}
										/>
										<span style={{ paddingLeft: '15px' }}>Customer</span>
									</div>
								</th>
								<th
									colSpan={expand ? '4' : '0'}
									scope='colgroup'
									style={{
										cursor: 'pointer',
										opacity: secondCol,
										textAlign: !expand ? 'left' : 'center'
									}}
									
								>
									<span onClick={() => forecastColClick()}>Estimate</span>
									{expand ? (
										<MdExpandLess
											size={20}
											className={'expand_icon'}
											onClick={() => setExpand(false)}
										/>
									) : (
										<MdExpandMore
											size={20}
											className={'expand_icon'}
											onClick={() => setExpand(true)}
										/>
									)}
								</th>
								<th
									onClick={() => quoteColClick()}
									style={{
										cursor: 'pointer',
										opacity: thirdCol,
										textAlign: 'left'
									}}
								>
									Quote
								</th>
								<th
									onClick={() => OrderColClick()}
									style={{
										cursor: 'pointer',
										opacity: fourthCol,
										textAlign: 'left'
									}}
								>
									Order
								</th>
								<th
									onClick={() => percentageColClick()}
									style={{
										cursor: 'pointer',
										opacity: sixthCol,
									}}
								>
									Percentage
								</th>
								<th></th>
							</tr>
							<tr
								style={{
									color: '#58637A',
									background: '#efffff',
									display: expand ? '' : 'none',
								}}
							>
								<th></th>
								<th scope='col' style={{ opacity: forecastColGroup, textAlign: 'left' }}>
									Previous
								</th>
								<th scope='col' style={{ opacity: forecastColGroup, textAlign: 'left' }}>
									Base
								</th>
								<th scope='col' style={{ opacity: forecastColGroup, textAlign: 'left' }}>
									Forecast
								</th>
								<th scope='col' style={{ opacity: forecastColGroup, textAlign: 'left' }}>
									Notes
								</th>

								<th></th>
								<th></th>
								<th></th>
								<th scope='col' style = {{ 	textAlign: 'left' }}>
									<ToggleSwitch
										status={false}
										fromGlobal={true}
										total={totalResults}
										forecastApi={(data, data2, data3) =>
											callForecastApi(data, data2, data3)
										}
										onTogglePress={(matrix, data) => {
											changeTogleData(matrix, data);
										}}
										allRowSelected={(val) => allRowsSelected(val)}
										col1={col1}
										col2={col2}
										col3={col3}
										col4={col4}
										resetClick={resetClick}
									/>
								</th>
							</tr>

							<tbody>
								{results.length > 0 ? (
									results.map((user, index) => {
										return (
											<tr
												key={index}
												style={{
													opacity: selectedRows.some((e) => e === index) && 0.45,
												}}
											>
												<td>{user.name}</td>
												{expand ?
													<>
														<td
															className={`${
																firstColClick && secondCol == 1
																	? 'col1'
																	: !firstColClick && secondCol !== 1
																	? 'col'
																	: 'col1'
															}`}
														>
															{user.active_matrix == 'prev_order' ? (
																<AiOutlineCheckCircle
																	size={18}
																	color={'green'}
																	onClick={() => {
																		getMatrix(user, 'OrderPlaced', 'view')
																		setViewType('Previous Order')
																	}}
																	style={{ cursor: 'pointer' }}
																/>
															) : expand && user.has_previous_order ? (
																<AiOutlineCheckCircle
																	color={'grey'}
																	size={18}
																	onClick={() => {
																	getMatrix(user, 'OrderPlaced', 'view')
																	setViewType('Previous Order')
																	}}
																	style={{ cursor: 'pointer' }}
																/>
															) : null} 
														</td>
														<td
															className={`${
																firstColClick && secondCol == 1
																	? 'col1'
																	: !firstColClick && secondCol !== 1
																	? 'col'
																	: 'col1'
															}`}
														>
															{user.active_matrix == 'base_order' ? (
																<AiOutlineCheckCircle
																	size={18}
																	color={'green'}
																	onClick={() => {
																		getMatrix(user, 'BaseOrder', 'view')
																		setViewType('Base Order')
																	}}
																	style={{ cursor: 'pointer' }}
																/>
															) : expand && user.has_base_order ? (
																<AiOutlineCheckCircle
																	color={'grey'}
																	size={18}
																	onClick={() => {
																		getMatrix(user, 'BaseOrder', 'view')
																		setViewType('Base Order')
																	}}
																	style={{ cursor: 'pointer' }}
																/>
															) : null}
														</td>
														<td
															className={`${
																firstColClick && secondCol == 1
																	? 'col1'
																	: !firstColClick && secondCol !== 1
																	? 'col'
																	: 'col1'
															}`}
														>
															{user.active_matrix == 'forecast_order' ? (
																<AiOutlineCheckCircle
																	size={18}
																	color={'green'}
																	onClick={() => {
																		getMatrix(user, 'ForecastOrder', 'view');
																		setViewType('Forecast Order')
																	}}
																	style={{ cursor: 'pointer' }}
																/>
															) : expand && user.has_forecast_order ? (
																<AiOutlineCheckCircle
																	color={'grey'}
																	size={18}
																	onClick={() => {
																		getMatrix(user, 'ForecastOrder', 'view');
																		setViewType('Forecast Order')
																	}}
																	style={{ cursor: 'pointer' }}
																/>
															) : null}
														</td>
														<td
															className={`${
																firstColClick && secondCol == 1
																	? 'col1'
																	: !firstColClick && secondCol !== 1
																	? 'col'
																	: 'col1'
															}`}
															style={{ textAlign: 'left' }}
														>
															{user.active_matrix == 'note' ? (
																<AiOutlineCheckCircle
																	size={18}
																	color={'green'}
																	onClick={() => {
																		getMatrix(user, 'CustomerNote', 'view')
																		setViewType('Customer Note')
																	}}
																	style={{ cursor: 'pointer' }}
																/>
															) : expand && user.has_customer_note ? (
																<AiOutlineCheckCircle
																	color={'grey'}
																	size={18}
																	onClick={() => {
																		getMatrix(user, 'CustomerNote', 'view')
																		setViewType('Customer Note')
																	}}
																	style={{ cursor: 'pointer' }}
																/>
															) : null}
														</td>
													</>
												:
													<td>
														{user.active_matrix == 'note' || user.active_matrix == 'forecast_order' || user.active_matrix == 'base_order' || user.active_matrix  == 'prev_order' ?
															<AiOutlineCheckCircle
																	color={'green'}
																	size={18}
																	onClick={() => {
																		getMatrix(user, user.active_matrix == 'note' ? 'CustomerNote':
																		user.active_matrix == 'forecast_order' ? 'ForecastOrder' :
																		user.active_matrix == 'base_order' ? 'BaseOrder' :
																		user.active_matrix  == 'prev_order'? 'PreviousOrder' : null, 'view')
																		setViewType(user.active_matrix == 'forecast_order' ?  'Forecast Order' 
																		: user.active_matrix == 'note' ? 'Customer Note':
																		user.active_matrix == 'base_order' ? 'Base Order' :
																		user.active_matrix  == 'prev_order'? 'Previous Order' :null 
																		);
																	}}
																	style={{ cursor: 'pointer' }}
															/>
														: null}
													</td>
												}

												<td
													className={`${
														secondColClick && thirdCol == 1
															? 'col1'
															: !secondColClick && thirdCol !== 1
															? 'col'
															: 'col1'
													}`}
												>
													{user.active_matrix == 'quote' ? (
														<AiOutlineCheckCircle
															size={18}
															color={'green'}
															onClick={() => {
																getMatrix(user, 'Quotee', 'view')
																setViewType('Quote')
															}}
															style={{ cursor: 'pointer' }}
														/>
													) : user.has_quotee ? (
														<AiOutlineCheckCircle
															color={
																user.manage_quotee ||
																(!user.has_order_placed && user.has_quotee)
																	? 'green'
																	: 'gray'
															}
															size={18}
															onClick={() => {
																getMatrix(user, 'Quotee', 'view')
																setViewType('Quote')
															}}
															style={{ cursor: 'pointer' }}
														/>
													) : null}
												</td>
												<td
													className={`${
														thirdColClick && fourthCol == 1
															? 'col1'
															: !thirdColClick && fourthCol !== 1
															? 'col'
															: 'col1'
													}`}
												>
													{user.active_matrix == 'order' ? (
														<AiOutlineCheckCircle
															size={18}
															color={'green'}
															onClick={() => {
																getMatrix(user, 'OrderPlaced', 'view');
																setViewType('OrderPlaced')
															}}
															style={{ cursor: 'pointer' }}
														/>
													) : user.has_order_placed ? (
														<AiOutlineCheckCircle
															color={'grey'}
															size={18}
															onClick={() => {
																getMatrix(user, 'OrderPlaced', 'view');
																setViewType('OrderPlaced')
															}}
															style={{ cursor: 'pointer' }}
														/>
													) : null}
												</td>
												<td
													className={`${
														fourthColClick && sixthCol == 1
															? 'col1'
															: !fourthColClick && sixthCol !== 1
															? 'col'
															: 'col1'
													}`}
													style={{textAlign: "center"}}
												>
													{user.percentage_sum ? user.percentage_sum : ''}
												</td>
												<td className={`${onClick ? 'col' : 'col1'}`}>
													<ToggleSwitch
														rowIndex={index}
														onROWindexClick={(index, add, remove) => {
															onRowIndexClick(index, add, remove);
														}}
														location={user.customer_shipment_location_id}
														total={results}
														onTogglePress={(matrix, data) => {
															changeTogleData(matrix, data);
														}}
														status={user.is_disabled}
														fromForecast={true}
														col1={col1}
														col2={col2}
														col3={col3}
														col4={col4}
														type={user.type}
													/>
												</td>
											</tr>
										);
									})
								) : (
									<tr className={'no_data'}>
										<td colspan='10'>No data available </td>
									</tr>
								)}
							</tbody>
						</table>
					</section>
				</div>
			</section>
		</>
	);
};
export default Forecast;
