import React, { useState, useEffect } from 'react';
import Sidebar from '../components/Sidebar/Sidebar';
import { Link } from 'react-router-dom';
import { MdNavigateNext } from 'react-icons/md';
import SelectorCard from '../components/Cards/SelectorCard';
import ProducerServices from '../api/ProducerServices';

const BuySelectorPage = () => {
	//PRODUCER
	const [selectedProducers, setSelectedProducers] = useState([]);
	const [unselectedProducers, setUnselectedProducers] = useState([]);
	const [selectedProducersDisable, setSelectedProducersDisable] = useState(true);
	const [unselectedProducersDisable, setUnselectedProducersDisable] =
		useState(true);

	//EXPORTER
	const [selectedExporters, setSelectedExporters] = useState([]);
	const [unselectedExporters, setUnselectedExporters] = useState([]);
	const [selectedExportersDisable, setSelectedExportersDisable] = useState(true);
	const [unselectedExportersDisable, setUnselectedExportersDisable] =
		useState(true);

	useEffect(() => {
		getSelectedProducers();
		getUnselectedProducers();
		getSelectedExporters();
		getUnselectedExporters();
	}, []);

	const getSelectedProducers = async () => {
		try {
			const SelectedProducerList = await ProducerServices.SelectedProducers();

			if (SelectedProducerList.data) {
				const { results } = SelectedProducerList.data;
				setSelectedProducers(results);
				if (results.length === 0) {
					setSelectedProducersDisable(true);
				} else {
					setSelectedProducersDisable(false);
				}
			}
		} catch (err) {
			console.log('GET Selected Producers ERROR', err);
		}
	};

	const getUnselectedProducers = async () => {
		try {
			const UnselectedProducerList = await ProducerServices.UnselectedProducers();

			if (UnselectedProducerList.data) {
				const { results } = UnselectedProducerList.data;
				setUnselectedProducers(results);
				if (results.length === 0) {
					setUnselectedProducersDisable(true);
				} else {
					setUnselectedProducersDisable(false);
				}
			}
		} catch (err) {
			console.log('GET Unselected Producers ERROR', err);
		}
	};

	const getSelectedExporters = async () => {
		try {
			const SelectedExporterList = await ProducerServices.SelectedExporters();

			if (SelectedExporterList.data) {
				const { results } = SelectedExporterList.data;
				setSelectedExporters(results);
				if (results.length === 0) {
					setSelectedExportersDisable(true);
				} else {
					setSelectedExportersDisable(false);
				}
			}
		} catch (err) {
			console.log('GET Selected Exporters ERROR', err);
		}
	};

	const getUnselectedExporters = async () => {
		try {
			const UnselectedExportersList = await ProducerServices.UnselectedExporters();

			if (UnselectedExportersList.data) {
				const { results } = UnselectedExportersList.data;
				setUnselectedExporters(results);
				if (results.length === 0) {
					setUnselectedExportersDisable(true);
				} else {
					setUnselectedExportersDisable(false);
				}
			}
		} catch (error) {
			console.log('GET Unselected Exporters ERROR', error);
		}
	};

	const producerSelection = async (id, val) => {
		let data = {};
		console.log('producer id', id);
		if (val === 'addAll') {
			const producerArray = unselectedProducers
				.filter((x) => x.is_selected === false)
				.map((x) => x.id);
			console.log('filtered producer', producerArray);
			data['selected'] = true;
			data['producer'] = producerArray;
		} else if (val === 'removeAll') {
			const producerArray = selectedProducers
				.filter((x) => x.is_selected === true)
				.map((x) => x.id);
			data['selected'] = false;
			data['producer'] = producerArray;
		} else if (val === 'select') {
			data['selected'] = true;
			data['producer'] = [id];
		} else if (val === 'unselect') {
			data['selected'] = false;
			data['producer'] = [id];
		}

		const InitiateProducer = await ProducerServices.SelectAProducer(data);

		if (InitiateProducer.data) {
			getSelectedProducers();
			getUnselectedProducers();
		}
		if (InitiateProducer.error) {
			console.log('GET Producer Selection ERROR', InitiateProducer.error);
		}
	};

	const exportersSelection = async (id, val) => {
		let data = {};
		console.log('exporter');
		if (val === 'addAll') {
			const exporter = unselectedExporters
				.filter((x) => x.is_selected === false)
				.map((x) => x.id);
			data['selected'] = true;
			data['exporter'] = exporter;
		} else if (val === 'removeAll') {
			const exporter = selectedExporters
				.filter((x) => x.is_selected === true)
				.map((x) => x.id);
			data['selected'] = false;
			data['exporter'] = exporter;
		} else if (val === 'select') {
			data['selected'] = true;
			data['exporter'] = [id];
		} else if (val === 'unselect') {
			data['selected'] = false;
			data['exporter'] = [id];
		}

		const InitiateExporter = await ProducerServices.SelectAExporter(data);

		if (InitiateExporter.data) {
			getSelectedExporters();
			getUnselectedExporters();
		}

		if (InitiateExporter.error) {
			console.log('ERROR Select Producers', InitiateExporter.error);
		}
	};

	return (
		<>
			<section className={'sell_body_wrap packing_wrapper'}>
				{/* <Sidebar /> */}

				<div style={{ paddingBottom: '30px' }}>
					<div className='scrl_out' style={{ paddingBottom: '30px' }}>
						<section className={'cmn_head'}>
							<div className={'cmn_head_align'}>
								<p>Thursday</p>
								<Link to={'/buy/rfq-list'}>
									<div className={'btn next_button'}>
										<p className={'next_link'}>NEXT</p>
										<div className={'next_icon next_icon_align'}>
											<MdNavigateNext
												size={20}
												style={{
													color: 'white',
												}}
											/>
										</div>
									</div>
								</Link>
							</div>

							{/* <label>Create New Orders & Modify base orders here</label> */}
						</section>
						<p>Selected</p>
						<div className={'card_con'}>
							<SelectorCard
								title='Producers'
								state={selectedProducers}
								setState={producerSelection}
								disable={selectedProducersDisable}
							/>
							<SelectorCard
								title='Exporters'
								state={selectedExporters}
								setState={exportersSelection}
								disable={selectedExportersDisable}
							/>
						</div>
						<p>Not Selected</p>
						<div className={'card_con'}>
							<SelectorCard
								title='Producers'
								state={unselectedProducers}
								setState={producerSelection}
								is_selected={false}
								disable={unselectedProducersDisable}
							/>
							<SelectorCard
								title='Exporters'
								state={unselectedExporters}
								setState={exportersSelection}
								is_selected={false}
								disable={unselectedExportersDisable}
							/>
						</div>
					</div>
				</div>
			</section>
		</>
	);
};

export default BuySelectorPage;
