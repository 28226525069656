import React from 'react';
import classes from './Buton.css'

const button =(props) =>(
    <button onClick={props.clicked}
    className='Button login'
    >
        {props.children}
    </button>

    // <button onClick={props.clicked}>
    //     {props.children}
    // </button>
);

export default button;