import React, {useState, useEffect} from 'react'
import axios from 'axios'
import CommonServices from '../../api/CommonServices'

const NQSalmonTable = ({last_selector_week, week_limit, selected_week, setWeekDataStats, setPublishedDate}) => {

    const [nasdaq_price_records, setNasdaqRecords] = useState([])

    useEffect(()=> {
        getNQSPrices({
            last_week: last_selector_week,
            limit: week_limit,
        });

    }, [last_selector_week])

    const getNQSPrices = async (params) => {
        const getNQSPrices = await CommonServices.getNQSPrices(params);
        if(getNQSPrices.data){
            const {data} = getNQSPrices;
            setNasdaqRecords(data.result.prices)
            setWeekDataStats(data.result.stats)
            if(selected_week && Object.keys(nasdaq_price_records).length && nasdaq_price_records[selected_week.replace('W', '')].length > 0){
                const data = nasdaq_price_records[selected_week.replace('W', '')][0]
                setPublishedDate(data.published_date)
             }
        }
    }
    // update published_date
    useEffect(()=> {
        if(selected_week && Object.keys(nasdaq_price_records).length && nasdaq_price_records[selected_week.replace('W', '')].length > 0){
           const data = nasdaq_price_records[selected_week.replace('W', '')][0]
           setPublishedDate(data.published_date)
        }
    }, [selected_week])

    return (
       
        <div>
            <div style = {{ width: 740 }}>
                {selected_week ?
                    <>
                        <div className = {'priceListBlock' +' '+ 'boldHead'}>
                            <div>Weight Class</div>
                            <div className ={'list_header_wrap'}>Price NOK</div>
                            <div className ={'list_price_usd'}>Price USD</div>
                            <div className ={'list_price_eur'}>Price EUR</div>
                        </div>
                        {Object.keys(nasdaq_price_records).length && nasdaq_price_records[selected_week.replace('W', '')].length > 0 ? nasdaq_price_records[selected_week.replace('W', '')].map((record) => (
                            <div className="priceListBlock">
                                <div>HOG {' '} {record.weight_class} {record.weight_class == 'NQSALMON' ? '' : 'kg'}</div>
                                <div><span>{record.price_nok ? record.price_nok : ''} {record.price_change ? "(" + record.price_change + ")" : ''}</span></div>
                                <div className={"priceListusd"}><span>{record.price_usd ? '$' +  record.price_usd : ''}</span></div>
                                <div className={"priceListeur"}><span>{record.price_eur ? '€' +  record.price_eur : ''}</span></div>
                            </div>
                        )): <p className={"price_no_data"}>No data available</p>}
                        </>
                :  <p className={"price_no_data"}>No week is selected</p>}
            </div>
        </div>
    
    )
}

export default NQSalmonTable