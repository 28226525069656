import React, { useReducer, useEffect, useState } from 'react';
import { Link } from 'react-router-dom';
import Sidebar from '../components/Sidebar/Sidebar';
import ShipmentTable from '../components/Timeline/ShipmentTable';
import TimelineServices from '../api/TimelineServices';
import Modal from 'react-modal';
import { GrClose, GrDocumentPdf } from 'react-icons/gr';
import { Timeline, TimelineEvent } from 'react-event-timeline';
import '../components/Timeline/ShipmentTable.css';
import Axios from 'axios';

//modal styles
const customStyles = {
	content: {
		background: 'white',
		width: '45rem',
		maxWidth: 'calc(100vw - 2rem)',
		maxHeight: 'calc(100vh - 2rem)',
		boxshadow: '0 0 30px 0 rgba(0, 0, 0, 0.25)',
		overflowY: 'auto',
		position: 'relative',
	},
	overlay: {
		position: 'fixed',
		zIndex: 999999,
		top: 0,
		left: 0,
		width: '100vw',
		height: '100vh',
		background: 'rgba(0, 0, 0, 0.5)',
		display: 'flex',
		alignItems: 'center',
		justifyContent: 'center',
	},
};

const initialState = {
	shipments: [],
	selected_shipment: '',
	timeline: [],
};

export const ACTIONS = {
	SET_SHIPMENTS: 'GetShipments',
	SET_TIMELINE: 'SetTimeline',
	SET_FIELDS: 'SetFields',
	RESET_TIMELINE: 'ResetTimeline',
};

const reducer = (state, { type, payload }) => {
	switch (type) {
		case ACTIONS.SET_SHIPMENTS:
			return {
				...state,
				shipments: payload.shipments,
			};
		case ACTIONS.SET_TIMELINE:
			return {
				...state,
				timeline: payload.timeline,
				selected_shipment: payload.selected_shipment,
			};
		case ACTIONS.SET_FIELDS:
			return {
				...state,
				[payload.field]: payload.value,
			};
		case ACTIONS.RESET_TIMELINE:
			return {
				...state,
				timeline: [],
				selected_shipment: '',
			};
		default:
			break;
	}
};

const TimelinePage = () => {
	const [isTimelineModalOpen, setIsTimelineModalOpen] = useState(false);
	const [state, dispatch] = useReducer(reducer, initialState);

	useEffect(() => {
		fetchShipments();
	}, []);

	const fetchShipments = async () => {
		const shipmentData = await TimelineServices.getShipments();

		if (shipmentData.data) {
			const { data } = shipmentData;
			dispatch({
				type: ACTIONS.SET_SHIPMENTS,
				payload: {
					shipments: data.result,
				},
			});
		} else {
			console.log('error fetching Data', shipmentData.error);
		}
	};

	const fetchTimeline = async (id) => {
		const TimelineData = await TimelineServices.getTimeline(id);

		let result = [];

		if (TimelineData.data) {
			const { data } = TimelineData;
			result = data.result.timeline;
		} else {
			console.log('Error fetching Timeline Data', TimelineData.error);
			result = [];
		}

		return result;
	};

	const handleTimeline = async (id, name) => {
		dispatch({
			type: ACTIONS.SET_TIMELINE,
			payload: {
				timeline: await fetchTimeline(id),
				selected_shipment: name,
			},
		});
		setIsTimelineModalOpen(true);
	};

	const closeModal = () => {
		setIsTimelineModalOpen(false);
		dispatch({
			type: ACTIONS.RESET_TIMELINE,
		});
	};

	return (
		<section className={'body_wrap'}>
			{/* <Sidebar /> */}
			<div className={'cont_wp'}>
				<Link to={'/home'}>
					<p className={'Back_link'}>Back</p>
				</Link>
				<section className={'cmn_head'}>
					<p>Shipments</p>
				</section>
				<div className='fc_scroll'>
					<ShipmentTable
						shipment_data={state.shipments}
						handleTimeline={handleTimeline}
					/>
					<Modal
						isOpen={isTimelineModalOpen}
						onRequestClose={() => closeModal()}
						onAfterOpen={() => (document.body.style.overflow = 'hidden')}
						onAfterClose={() => (document.body.style.overflow = 'unset')}
						style={customStyles}
					>
						<div class='scrl_out' style={{ paddingBottom: '30px' }}>
							<div className={'modal_heading'}>
								<p>{state.selected_shipment} - Timeline</p>
								<div className={'close-icon'} onClick={() => closeModal()}>
									<GrClose size={25} />
								</div>
							</div>
							<section>
								<TimelineComponent data={state.timeline} />
							</section>
						</div>
					</Modal>
				</div>
			</div>
		</section>
	);
};

const TimelineComponent = ({ data }) => {
	return data.length > 0 ? (
		<Timeline>
			{data.map((item) => (
				<TimelineEvent
					key={item.id}
					title={
						item.has_pdf ? (
							<>
								{item.description}
								&emsp;
								<a href={Axios.defaults.baseURL + item.pdf} target='_blank'>
									<GrDocumentPdf style={{ cursor: 'pointer' }} size={16} />
								</a>
							</>
						) : (
							item.description
						)
					}
					createdAt={`${item.date} ${item.time}`}
				></TimelineEvent>
			))}
		</Timeline>
	) : (
		<p style={{ textAlign: 'center' }}>No data Available</p>
	);
};

export default TimelinePage;
