import React from 'react'
import allIMG from '../assets/images/all.png';
import preProspectIMG from '../assets/images/preprospect.png'
import prospectIMG from '../assets/images/prospect.png'
import customerIMG from '../assets/images/customer-ship.png'
import { useTable, usePagination, useGlobalFilter,useSortBy } from 'react-table'
import '../styles/BusinessPage.css';
import UserServices from '../api/UserServices'
import { ToastsStore } from 'react-toasts'
import {TiArrowSortedDown,TiArrowSortedUp} from 'react-icons/ti'
import {FaUser} from 'react-icons/fa'
import {FiHash} from 'react-icons/fi'
import {IoMdAdd} from 'react-icons/io'
import SearchInput from '../components/Elements/SearchInput';
import debounce from 'lodash.debounce';
import FilterButton from '../components/Elements/FilterButton';
import DropdownButton from '../components/Elements/DropdownButton';
import CommonServices from '../api/CommonServices';
import { Link } from 'react-router-dom';
import { AiFillStar } from 'react-icons/ai';

function BusinessPage() {
  const [data, setData] = React.useState({
    count:0,
    results:[],
    page_size:0,
    next:null,
    previous:null,
    error:false,
    pre_prospect_count:0,
    prospect_count:0,
    customer_shipment_location_count:0,
  })

  const [entityCounts, setEntityCounts] = React.useState({
    total:0,
    pre_prospect_count:0,
    prospect_count:0,
    customer_shipment_location_count:0,
  })

  const [pageIndex, setPageIndex] = React.useState(1)
  const [tableSorted, setTableSorted] = React.useState('all')
  const [sortedId, setSortedId] = React.useState('')

  const [activeCustomerType, setActiveCustomerType] = React.useState('all')

  const [searchQuery, setSearchQuery] = React.useState('')

  
  const [selectedSD, setSelectedSD] = React.useState('')
  const [selectedCountry, setSelectedCountry] = React.useState('')
  const [isSelected, setIsSelected] = React.useState(false)

  const [openFilterModal, setOpenFilterModal] = React.useState(false)
  const [isFilterActive, setIsFilterActive] = React.useState(false)



  const columns = React.useMemo(()=>[
    { Header: "ID", accessor: "id" },
    { Header: "Name", accessor: "name" },
    { Header: "User Count", accessor: "user_count" },
    { Header: "Destination", accessor: "destination" },
    { Header: "Country", accessor: "country_name" },
  ],[]);

  React.useEffect(() => {
    fetchUserList();
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])



  const fetchOnSearch = async (query,callback) => {
    console.log('fetching')
    try {
      const getResult = await UserServices.getUserOnSearch(encodeURIComponent(query),activeCustomerType,sortedId,tableSorted,selectedCountry,selectedSD)
      if(getResult.data){
        const {success} = getResult.data;
        if(success){
          // callback(setData({
          //   ...getResult.data,
          //   error:false,
          // }))
          callback(getResult.data)
        }
      }
    } catch (err) {
      ToastsStore.warning('An error occurred on data retrieve, Try Reloading.')
    }
  }

  const debouncedFetchonSearch = debounce((query, cb) => {
    fetchOnSearch(query, cb);
   }, 1500);

  React.useEffect(() => {
    debouncedFetchonSearch(searchQuery, res => {
      setData({
        ...res,
        error:false,
      })
     });
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [searchQuery]);
  
  const fetchUserList = async(customer_type="all") => {
    try {
      const getUserList = await UserServices.getUserListManagement(customer_type);
      if(getUserList.data){
        const {success} = getUserList.data;
        if(success){
          setData({
            ...getUserList.data,
            error:false,
          })
          setActiveCustomerType(customer_type)
          setEntityCounts({
            ...entityCounts,
            total:getUserList.data.count,
            pre_prospect_count:getUserList.data.pre_prospect_count,
            customer_shipment_location_count:getUserList.data.customer_shipment_location_count,
            prospect_count:getUserList.data.prospect_count
          })
        }
        else{
          ToastsStore.warning('an Error Occurred on data retrieving, Try Reloading.')
        }  
      }
    } catch (err) {
      setData({
        ...data,
        error: true
      })
      ToastsStore.warning('an Error Occurred on data retrieving, Try Reloading.')
    }
  }

  const handleTable = async({pageNo=1,param='',type='',customer_type=activeCustomerType}) =>{
    try {
      let getList;
      // if(type==='all'){
      //     getList = await UserServices.userListAll({pageNo,param,type,customer_type, searchQuery,country:selectedCountry,destination:selectedSD})
      // }
      // else {
      //   getList = await UserServices.userListAll({pageNo,param,type,customer_type, searchQuery,country:selectedCountry,destination:selectedSD})
      // }
      getList = await UserServices.userListAll({pageNo,param,type,customer_type, searchQuery,country:selectedCountry,destination:selectedSD,is_selected:isSelected?1:0})
      if(getList){
        const {success} = getList.data
        success?setData({
          ...getList.data,
          error:false,
        }):setData({
          ...getList.data,
          error:true,
        });
        if(pageNo !== pageIndex){
          setPageIndex(pageNo)
        }
        if(type !==''){
          setTableSorted(type)
        }
        setSortedId(param)
      }
      setActiveCustomerType(customer_type)
    } catch (err) {
      ToastsStore.error('An Error Occurred, Try again.')
    }
  }

  const filterSubmit = async(country='',destination='',isArgSelected=null) => {
    try {
      const getResults = await UserServices.userListAll({pageNo:1,param:sortedId,type:tableSorted,customer_type:activeCustomerType,searchQuery,country,destination,is_selected:isArgSelected===null?isSelected:isArgSelected?1:0})
      if(getResults.data){
        const {success} = getResults.data
        success?setData({
          ...getResults.data,
          error:false,
        }):setData({
          ...getResults.data,
          error:true,
        });
        setPageIndex(1)
        isArgSelected!==null && setIsSelected(isArgSelected)
        console.log('argument selected',isArgSelected)
        setSelectedCountry(country)
        setSelectedSD(destination)
        setOpenFilterModal(false)
        country!=='' || destination!=='' || isArgSelected?setIsFilterActive(true):setIsFilterActive(false)
      }
    } catch (error) {
      ToastsStore.error('An Error Occurred, Try Again.')
    }
  }

  // const onChangeSD = (e) => {
  //   e && e.preventDefault();
  //   setSelectedSD(e!==null?e.target.value:'')
  // }

  // const onChangeCountry = (e) =>{
  //   e && e.preventDefault();
  //   setSelectedCountry(e!==null?e.target.value:'')
  // }

  const handleCancelFilter = async(valueChanged,country,destination,selected) => {
    if(valueChanged && isFilterActive){
      if(country==='' && destination===''){
        filterSubmit()
      }
      setOpenFilterModal(false)
    }
    else if(valueChanged && !isFilterActive){
      filterSubmit()
    }
    else{
      setOpenFilterModal(false)
    } 
    setOpenFilterModal(false)
  }
  
    return (
        <section className="body_wrap">
          <div className={'cont_wp'}>
            <div className={'scrl_out'}>
            <section className={'business_header_container'}>
              <p>User Management</p>
              <div className='user_action_container'>
                <SearchInput value={searchQuery} onChangeText={e=>{
                  setSearchQuery(e.target.value)
                }}/>
                <div style={{position:'relative'}}>
                <FilterButton onClick={()=>setOpenFilterModal(true)} isActive={isFilterActive}/>
                {openFilterModal && <FilterModal handleCancel={handleCancelFilter} handleSubmit={(country,seafoodDestination,isArgSelected)=>filterSubmit(country,seafoodDestination,isArgSelected)} selectedCountry={selectedCountry} selectedSD={selectedSD} isSelected={isSelected} />}
                </div>
                <Link to='/business/create-preprospect/'><span className='add_button_container'>
                  <IoMdAdd color='#fff' size={21}/>
                  Create Pre Prospect
                </span></Link>
              </div>
            </section>
            <section>
              <EntityCardContainer data={entityCounts} handleTable={handleTable} activeCustomerType={activeCustomerType}/>
            </section>
            <section className='fc_scroll business_table'>
              {data.page_size > 0?<EntityTable columns={columns} data={data.results} PropState={data} setData={setData} activeCustomerType={activeCustomerType} searchQuery={searchQuery} pageIndex={pageIndex} tableSorted={tableSorted} sortedId={sortedId} handleTable={handleTable}/>:<></>}
            </section>
            </div>
          </div>
        </section>
    )
}

const FilterModal = ({handleSubmit= () =>{},handleCancel = ()=>{},selectedCountry='',selectedSD='',isSelected=false}) => {

  const [seafoodDestinations, setSeafoodDestinations] = React.useState([]) // Format : {id:Number,value:String}
  const [countries, setCountries] = React.useState([])

  const [selectedDestinationList, setSelectedDestinationList] = React.useState([])

  const [tempSelectedCountry, setTempSelectedCountry] = React.useState(selectedCountry)
  const [tempSelectedSD, setTempSelectedSD] = React.useState(selectedSD)

  const [valueChanged, setValueChanged] = React.useState(false)

  const [tempIsSelected, setTempIsSelected] = React.useState(isSelected)


  React.useEffect(() => {
    fetchCountry()
    fetchDestination()
    selectedCountry!=='' && fetchSDOnCountry(selectedCountry)

    return ()=>{
      setValueChanged(false)
      setTempSelectedCountry('')
      setTempSelectedSD('')
    }
  }, [])

  const fetchCountry = async() =>{
    try {
      const getDestination = await CommonServices.getSeafoodCountries()

      if(getDestination.data){
        const {success,results} = getDestination.data;
        let tempResult = []
        if(success){
          for(var i in results){
            tempResult.push({
              id:results[i]?.id,
              value:results[i]?.country?.country
            })
          }
        }
        success?setCountries(tempResult):ToastsStore.warning('An error Occurred on Retrieving Data, Try reloading.')
      }
    } catch (error) {
      ToastsStore.warning('An error Occurred on Retrieving Data, Try reloading.')
    }

  }
  const fetchDestination = async() =>{
    try {
      const getDestination = await CommonServices.getSeafoodDestination()

      if(getDestination.data){
        const {success,results} = getDestination.data;
        let tempResult = []
        if(success){
          for(var i in results){
            tempResult.push({
              id:results[i]?.id,
              value:results[i]?.airport
            })
          }
        }
        success?setSeafoodDestinations(tempResult):ToastsStore.warning('An error Occurred on Retrieving Data, Try reloading.')
      }
    } catch (error) {
      ToastsStore.warning('An error Occurred on Retrieving Data, Try reloading.')
    }
  }

  const fetchSDOnCountry = async(value) =>{
    try {
      const getDestinationById = await CommonServices.getSeafoodDestinationByCountry(value)

      if(getDestinationById.data){
        const {success,results} = getDestinationById.data;
        let tempResult = []
        if(success){
          for(var i in results){
            tempResult.push({
              id:results[i]?.id,
              value:results[i]?.airport
            })
          }
        }
        success?setSelectedDestinationList(tempResult):ToastsStore.warning('An error Occurred on Retrieving Data, Try reloading.')
      }
    } catch (error) {
      ToastsStore.warning('An Error Occurred on Retrieving Data, Try reloading.')
    }
  }

  const onChangeCountry = (e) => {
    e && e.preventDefault()
    setTempSelectedCountry(e!==null?e.target.value:'')
    setTempSelectedSD('')
  }

  const onChangeSD = (e) =>{
    e && e.preventDefault();
    setTempSelectedSD(e!==null?e.target.value:"")
  }

  console.log('temp is selected',tempIsSelected)

  return(
    <span className='filter_selector_container'>
      <DropdownButton placeholder='Select Country' selectedOption={tempSelectedCountry} optionData={countries} onChangeOption={(e)=>{
            onChangeCountry(e)
             e && fetchSDOnCountry(e.target.value)
             setValueChanged(true)
      }}/>
      <DropdownButton placeholder='Select Destination'selectedOption={tempSelectedSD}  optionData={tempSelectedCountry!==''? selectedDestinationList:seafoodDestinations} onChangeOption={(e)=>{
        onChangeSD(e)
        setValueChanged(true)
        }}/>
      <span className='selected_filter_wrap'>
        <span>Selected <AiFillStar color='#f54542'/></span>
        <input type='checkbox' name='is_selected' defaultChecked={isSelected} value={isSelected} onChange={(e)=>{
        // e.preventDefault()
        setTempIsSelected(!tempIsSelected)
        
      }}/></span>
      <span style={{display:'flex',gap:10,justifyContent:"space-between"}}>
      <button className='btn_primary' onClick={(e)=>{
        e.preventDefault();
        handleSubmit(tempSelectedCountry,tempSelectedSD,tempIsSelected)
        }}>Apply</button>
      <button className='btn_cancel' onClick={()=>handleCancel(valueChanged,tempSelectedCountry,tempSelectedSD,tempIsSelected)}>Cancel</button>
      </span>
    </span>
  )
}

const EntityCardContainer = ({data,handleTable = () =>{},activeCustomerType='all',searchQuery=''}) => {
  return (
    <div className="user_management_entity_card_container">
      {[
        {
        img:allIMG,
        label:'All',
        apiKey:'all',
        count:data.total
      },
        {
        img:preProspectIMG,
        label:'Pre Prospects',
        apiKey:'pre_prospect',
        count:data.pre_prospect_count
      },
        {
        img:prospectIMG,
        label:'Prospects',
        apiKey:'prospect',
        count:data.prospect_count
      },
        {
        img:customerIMG,
        label:'Customers',
        apiKey:'customer_shipment_location',
        count:data.customer_shipment_location_count
      },
      ].map((item,index)=><div key={index} className={`entity_card ${activeCustomerType===item.apiKey?'entity_card_active':''}`} onClick={()=>{handleTable({customer_type:item.apiKey})
      }}>
        <img src={item.img} alt={item.label} style={{height:30,width:30}}/>
        <h6>{item.label}{item.count!==null && <span className='entity_card_badge'>{item.count}</span>}</h6>
      </div>)}
    </div>
  )
}

const EntityTable = ({columns,data,PropState,setData, activeCustomerType='all',searchQuery='',pageIndex=1,tableSorted='all',sortedId='',handleTable=()=>{}}) => {
  let tableInstance = useTable({columns,data,initialState: { pageSize: PropState?.page_size},state:{pageSize:PropState?.page_size}},useGlobalFilter,useSortBy,usePagination)


  const {
    getTableProps,
    getTableBodyProps,
    headerGroups,
    page,
    // nextPage,
    // previousPage,
    // canNextPage,
    // canPreviousPage,
    prepareRow,
    // pageOptions,
    // state,
    // gotoPage,
    // pageCount,
    // setGlobalFilter,
  } = tableInstance;

  // const [pageIndex, setPageIndex] = React.useState(1)
  // const [tableSorted, setTableSorted] = React.useState('all')
  // const [sortedId, setSortedId] = React.useState('')
  
  const renderTableCell = (cellProps, access_type,id,value) => {
    // split(/\s/).reduce((response,word)=> response+=word.slice(0,1),'')
    switch (access_type) {
      case 'customer_shipment_location':
        return (
          <td className='entity_table_body_cell entity_cell_align' {...cellProps?.getCellProps}><Link className='bp_name_hyperlink' to={`/business/user-management/${access_type}/${id}`}> <img src={customerIMG} alt='customer' style={{height:30,width:30}} key={cellProps?.value?.id}/>{cellProps?.render("Cell")}</Link></td>
        )
      case 'pre_prospect':
        return (
          <td className='entity_table_body_cell entity_cell_align' {...cellProps?.getCellProps}><Link className='bp_name_hyperlink' to={`/business/user-management/${access_type}/${id}`}> <img src={preProspectIMG} alt='pre-prospect' style={{height:30,width:30}} key={cellProps?.value?.id}/>{cellProps?.render("Cell")}</Link></td>
        )
      case 'prospect':
        return (
          <td className='entity_table_body_cell entity_cell_align' {...cellProps?.getCellProps}><Link className='bp_name_hyperlink' to={`/business/user-management/${access_type}/${id}`}> <img src={prospectIMG} alt='prospect' style={{height:30,width:30}} key={cellProps?.value?.id}/>{cellProps?.render("Cell")}</Link></td>
        )
      default:
        return (
          <td className='entity_table_body_cell' {...cellProps?.getCellProps} key={cellProps?.value?.id}>{cellProps?.render("Cell")}</td>
        )
    }
  }

  return (
    <>
    <div className="entity_table_wrap ">
      <table className='entity_table' {...getTableProps()}>
        <thead className='entity_table_head'>
          {headerGroups.map((headerGroup,headerGroupIndex) => (
            <tr {...headerGroup.getHeaderGroupProps()} key={headerGroupIndex}>
              {headerGroup.headers.map((column,headerIndex) => {
                if(column?.id ==='user_count'){
                  return <th><FaUser /><FiHash /></th>
                }
                return(
                <th className='entity_table_head_cell' {...column.getHeaderProps()} key={headerIndex} style={column?.id==='id'?{textAlign:'center'}:{}}><span style={{display:"inline-flex",alignItems:'center'}}>{column.render("Header")}<span style={{display:'flex',flexDirection:'column'}}>
                  <TiArrowSortedUp onClick={()=>handleTable({pageNo:pageIndex,param:column?.id,type:'asc'})} style={{width:15,height:15,cursor:'pointer',transform:'translateY(2px)',color:tableSorted==='asc' && sortedId===column?.id?'salmon':'white'}}/>
                  <TiArrowSortedDown onClick={()=>handleTable({pageNo:pageIndex,param:column?.id,type:'desc'})} style={{width:15,height:15,cursor:'pointer',transform:'translateY(-5px)',color:tableSorted==='desc' && sortedId===column?.id?'salmon':'white'}}/>
                </span>
              </span></th>
              )})}
            </tr>
          ))}
        </thead>
        <tbody {...getTableBodyProps()}>
          {data.length>0 && !PropState?.error ? page.map((row,pageItemIndex) => {
            prepareRow(row);
            return (
              <tr {...row.getRowProps} key={pageItemIndex}>
                {row.cells.map((cell) => {
                  // cell?.row?.original?.access_type
                  switch (cell?.column?.id) {
                    case 'id':
                      return <td style={{color:"#999"}}><span className='table_id_wrap'>{cell?.row?.original?.is_selected?<AiFillStar color='#f54542'/>:<span></span>} {cell?.row?.original?.id}</span></td>
                    case 'name':
                     return renderTableCell(cell, cell?.row?.original?.access_type,cell?.row?.original?.id,cell?.value)
                    case 'user_count':
                      return <td className='entity_table_body_cell' {...cell.getCellProps} style={{color:"#999",textAlign:'center'}}>{cell.render("Cell")}</td>
                    default:
                      return(
                        <td className='entity_table_body_cell' {...cell.getCellProps}>{cell.render("Cell")}</td>
                      )
                  }
                  })}
              </tr>
            );
          }):<tr><td style={{padding:'10px', textAlign:'center'}} colSpan={5}>No data Available</td></tr>}
        </tbody>
      </table>
    </div>
    {data.length>0 && !PropState?.error && <div className="pagination">
        <button className={PropState?.previous ===null?'disabled_pagination_button':'pagination_button_toggles'} onClick={() => handleTable({pageNo:pageIndex-1,param:sortedId,type:tableSorted})} disabled={PropState?.previous ===null?true:false}>
          {'<'}
        </button>{' '}
        <PaginationButtons page={pageIndex} pages={Math.ceil(PropState?.count/PropState?.page_size)} handleTable={(pageNo)=>handleTable({pageNo,param:sortedId,type:tableSorted})}/>
        <button className={PropState?.next ===null?'disabled_pagination_button':'pagination_button_toggles'} onClick={() => handleTable({pageNo:pageIndex+1,param: sortedId,type: tableSorted})} disabled={PropState?.next ===null?true:false}>
          {'>'}
        </button>{' '}
      </div>}
    </>
  )
}


const PaginationButtons = ({page,pages,handleTable}) => {

  const filterPages = (visiblePages, totalPages) => {
    return visiblePages.filter(page => page <= totalPages);
  };

  const getVisiblePages = (page,total) => {
    let result = []
    if (total < 7) {
      result = filterPages([1, 2, 3, 4, 5, 6], total);
    } else {
      if (page % 5 >= 0 && page > 4 && page + 2 < total) {
        result = [1, page - 1, page, page + 1, total];
      } else if (page % 5 >= 0 && page > 4 && page + 2 >= total) {
        result = [1, total - 3, total - 2, total - 1, total];
      } else {
        result = [1, 2, 3, 4, 5, total];
      }
    }
    setVisiblePages(result)
  }


  const [visiblePages, setVisiblePages] = React.useState([])

  const activePage = page;

  React.useEffect(() => {
    
    getVisiblePages(page,pages)
    
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [page,pages])

  const changePage = (nextPage) => {
    const activePage = page;

    if (nextPage === activePage) {
      return;
    }

    handleTable(nextPage);
  }
  
  return (
    <div className='visible_button_container'>
          {visiblePages.map((page, index, array) => {
            return (
              <span
                key={page}
                className={
                  activePage === page
                    ? "pagination_button active"
                    : "pagination_button_inactive"
                }
                onClick={()=>changePage(page)}
              >
                {array[index - 1] + 1 < page ? <>...&emsp;{page}</> : page}
              </span>
            );
          })}
    </div>
  )
}

export default BusinessPage
