import React, { useEffect, useReducer } from "react";
import Modal from "react-modal";
import axios from 'axios';
import { GrClose } from "react-icons/gr";
import "./Nasdaq.css";
import CommonServices from "../../../api/CommonServices";

//CONSTANTS
export const ACTIONS = {
    SET_HISTORYS: 'set_historys',
    SET_MODAL: 'set_modal'
}

const reducer = (state, action) => {
    switch (action.type) {
        case ACTIONS.SET_HISTORYS: 
            return {
                ...state,
                historys: action.payload.historys
            }
        case ACTIONS.SET_MODAL: 
            return {
                ...state,
                modal: action.payload.value
            }
        default:
            return {
                ...state
            }
    }
}

const NasdaqHistory = ({ limit, closeParentModal = () => {} }) => {
    //STATES
    const [state, dispatch] = useReducer(reducer, {
        historys: [],
        modal: false
    })

    useEffect(()=>{
        getHistory(limit)
    }, [])

    const getHistory = async (limit) => {
        const getNasdaqPriceHistory = await CommonServices.getNasdaqPriceHistory(limit);
        if(getNasdaqPriceHistory.data){
            const {data} = getNasdaqPriceHistory;
            dispatch({
                type: ACTIONS.SET_HISTORYS,
                payload: {
                    historys: data
                }
            })
            dispatch({
                type: ACTIONS.SET_MODAL,
                payload: {
                    value: true
                }
            })
        }
    }

    const closeModal = () => {
        dispatch({
            type: ACTIONS.SET_MODAL,
            payload: {
                value: false
            }
        })
        closeParentModal()
    }
    //MODAL STYLES
    const customStyles = {
        content: {
          background: "white",
          width: "60rem",
          maxWidth: "calc(100vw - 2rem)",
          maxHeight: "calc(100vh - 2rem)",
          boxshadow: "0 0 30px 0 rgba(0, 0, 0, 0.25)",
          overflowY: "auto",
          position: "relative",
        },
        overlay: {
          position: "fixed",
          zIndex: 999999,
          top: 0,
          left: 0,
          width: "100vw",
          height: "100vh",
          background: "rgba(0, 0, 0, 0.5)",
          display: "flex",
          alignItems: "center",
          justifyContent: "center",
        }
    };
      
    return (       
        <>
            <Modal
                isOpen={state.modal}
                onRequestClose={() => closeModal()}
                onAfterOpen={() => (document.body.style.overflow = "hidden")}
                onAfterClose={() => (document.body.style.overflow = "unset")}
                style={customStyles}
            >
                <div class="scrl_out" style={{ paddingBottom: "30px" }}>
                    <div className={"modal_heading"}>
                        <p>Nasdaq price report</p>
                        <div
                            className={"close-icon"}
                            onClick={() => closeModal()}
                        >
                            <GrClose size={25} />
                        </div>
                    </div>
                    <section className={"OrderTableWrap" + " " + "scrl_out historyTable"}>
                        {state.historys.length == 0 ? null : (
                            <table>
                                <tr className={"table_head"}>
                                    {state.historys.heading.map((week, i) => {
                                        return <th>{week}{state.historys.current_week == week ? <sup>*</sup> : null}</th>;
                                    })}
                                </tr>

                                <tbody>
                                    {state.historys.matrix.map((key, index) => {
                                        return (
                                            <tr key={index}>
                                                <td>{key[0]}</td>
                                                {key[1].map((data, i) => {
                                                    return <td>{data == 0 ? '-' : data}</td>;
                                                })}
                                            </tr>
                                        );
                                    })}
                                </tbody>
                            </table>
                        )}
                    </section>
                </div>
            </Modal>
        </>
    );
}

export default NasdaqHistory;