import React from 'react';
import classes from './Input.css'

const Input =(props) =>{

    let inputElement = null;

    switch(props.elementType){
        case('input'):
            inputElement = <input 
                {...props.elementConfig} 
                value = {props.value}
                onChange={props.onChange}
            />;
            break;
        case('textarea'):
            inputElement = <textarea 
                {...props.elementConfig} 
                value = {props.value}
            />;
            break;
        default:
            inputElement = <input 
                {...props.elementConfig}
                value = {props.value}
            />;
    }
    return(
        <div className='Input'>
            <label>{props.elementConfig.label}</label>
            {inputElement}
        </div>
    )
}

export default Input;