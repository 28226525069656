import React from 'react';

function TotalCard({ data, title, isCurrentWeek,hasTitleData=null }) {
	return (
		<div className='mod_lab_out'>
			{hasTitleData?hasTitleData:<></>}
			<div
				className={
					'header_box' + ' ' + (isCurrentWeek ? 'highlht' : 'no_highlight')
				}
			>
				{data ? data : '---'}
			</div>
			<p className={isCurrentWeek ? 'text_highlht' : 'header_box_text'}>{title}</p>
		</div>
	);
}

export default TotalCard;
