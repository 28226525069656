import React, { useContext, useEffect, useState } from "react";
import Button from "../../Elements/Button";
import CustomInput from "../../Elements/CustomInput";
import ModelDropdownSelector from "../../ModelSelectors/ModelDropdownSelector";
import CommonServices from "../../../api/CommonServices";
import WeekSelector from './WeekSelector';
import { ToastsStore } from "react-toasts";

/**
 * @author
 * @function MatrixSelector
 **/
const secondDropDownData = {
  BaseOrder: {
    prospect: "Prospect",
    customer_shipment_location: "Customer Shipment Location",
  },
  ForecastOrder: {
    seafood_destination: "Sea Food Destination",
    pre_prospect: "Pre Prospect",
    prospect: "Prospect",
    customer_shipment_location: "Customer Shipment Location",
  },
  CustomerNote: {
    prospect: "Prospect",
    customer_shipment_location: "Customer Shipment Location",
  },
  ProformaOrder: {
    prospect: "Prospect",
    customer_shipment_location: "Customer Shipment Location",
  },
  LightQuote: {
    seafood_destination: "Sea Food Destination",
    pre_prospect: "Pre Prospect",
  },
  Quotee: {
    prospect: "Prospect",
    customer_shipment_location: "Customer Shipment Location",
  },
  OrderPlaced: { customer_shipment_location: "Customer Shipment Location" },
  Tender: {},
  RFQ: { producer: "Producer", exporter: "Exporter" },
  Purchase: { producer: "Producer", exporter: "Exporter" },
};

const MatrixSelector = ({
  onMatrixModelChange=()=>{},
  onAccessTypeChange=()=>{},
  onAccessIdChange=()=>{},
  onSourcingWeekChange=()=>{},
  onGetMatrixButtonClick=()=>{},
  updateActiveWeekDataStatus=()=>{},

  disableMatrixModel,
  disableAccessType,
  disableAccessId,
  disableSourcingWeek,
  disableGetMatrixButton,

  selectedMatrixModel,
  selectedAccessType,
  selectedAccessId,
  selectedSourcingWeek,
  access_type,
  access_id,
  modelName
}) => {
  const [accesIdData, setAccessIdData] = useState({});
  const [matrixModelData, setMatrixModelData] = useState({});
  const [last_selector_week, setLastSelectorWeek] = useState('')
  const [first_allowed_week, setFirstAlowedWeek] = useState('')
  const [last_allowed_week, setLastAllowedWeek] = useState('')
  const [fromInput, setFromInput] = useState(false)
  const week_data_stats = [true, true, true, true, true, true]
  
  const onChangeAccessType = async (e) => {
    setAccessIdData({});
    onAccessTypeChange(e);
    const accessIdList = await CommonServices.getAccessIdList(
      e.target.value
    );
    if (accessIdList.data) {
      const { data } = accessIdList;
      if(data.results.length > 0) {
        let dropDownData = {};
        data.results &&
          data.results.forEach((d) => {
            dropDownData = Object.assign({}, dropDownData, { [d.id]: d.name });
          });
        setAccessIdData(dropDownData);
      }else {
        ToastsStore.error("No results found");
      }
    }
  };
  const onChangeMatrixModel = (e) => {
    setAccessIdData({});
    onMatrixModelChange(e);
  };
  const fetchMatrixModels = async () => {
    const matrixModels = await CommonServices.getMatrixModels();
    if (matrixModels.data) {
      const { data } = matrixModels;
      if(data.results.length == 0){
        ToastsStore.error("No results found");
      }else{
        let dropDownData = {};
        data.results &&
          data.results.forEach((d) => {
            dropDownData = Object.assign({}, dropDownData, {
              [d.model]: d.model,
            });
          });
          setMatrixModelData(dropDownData);
      }
      setLastSelectorWeek(data.sourcing_week)
      setFirstAlowedWeek(data.first_allowed_week)
      setLastAllowedWeek(data.sourcing_week)
      onSourcingWeekChange(data.sourcing_week)
    }
  };
  useEffect(() => {
    fetchMatrixModels();
  }, []);

  useEffect(() => {
    if(selectedSourcingWeek && selectedSourcingWeek !== '' && selectedAccessId) {
      onGetMatrixButtonClick()
    }
  }, [selectedSourcingWeek, selectedAccessId])

  useEffect(() => {
    if(selectedAccessId){
      onWeekClick(last_allowed_week)
      setLastSelectorWeek(last_allowed_week)
    }
  },[selectedAccessId])

  const onWeekClick = (week) => {
    onSourcingWeekChange(week.target ? week.target.value : week)
  }
  const onBFsClick = (last_selector_week) => {
    onSourcingWeekChange('')
    setLastSelectorWeek(last_selector_week)
  }
  const onToggleChange = () => {
    setLastSelectorWeek(last_allowed_week)
    onSourcingWeekChange(last_allowed_week)
  }

  return (
    <>
      <div className="matrix_selector_wrapper">
        {
          <ModelDropdownSelector
            disabled={disableMatrixModel}
            name="matrix_model"
            value={selectedMatrixModel}
            data={matrixModelData}
            onChange={onChangeMatrixModel}
          />
        }
        {selectedMatrixModel &&
          secondDropDownData[selectedMatrixModel] &&
          Object.keys(secondDropDownData[selectedMatrixModel]).length != 0 && (
            <ModelDropdownSelector
              disabled={disableAccessType}
              name="access_type"
              value={selectedAccessType}
              data={secondDropDownData[selectedMatrixModel]}
              onChange={onChangeAccessType}
            />
          )}
        {selectedAccessType && Object.keys(accesIdData).length != 0 && (
          <>
            <ModelDropdownSelector
              name="access_id"
              disabled={disableAccessId}
              value={selectedAccessId}
              data={accesIdData}
              onChange={(e) => {
                onAccessIdChange(e)
                onSourcingWeekChange(last_allowed_week)
              }}
            />
          
          </>
        )}
      </div>
      {selectedAccessId &&
        !["BaseOrder", "CustomerNote"].includes(selectedMatrixModel) && (
          <>
            <div className={'nasdaq_week'} style={{ display: 'flex',marginTop: 25 }}>
              <div className={'nasdaq_week_wrapper'} style = {{ marginBottom :0}}>
                  <WeekSelector
                    selector_title={'Week'}
                    last_selector_week={last_selector_week}
                    first_allowed_week={first_allowed_week}
                    last_allowed_week={last_allowed_week}
                    selected_week={selectedSourcingWeek}
                    default_selected_week={last_allowed_week}
                    week_limit={6}
                    week_data_stats={week_data_stats}
                    style={'bubbles'}
                    week_selector_toogle={true}
                    onWeekClick={onWeekClick}
                    onBFsClick={onBFsClick}
                    onToggleChange={onToggleChange}
                    access_type={access_type}
                    access_id={access_id}
                    modelName={modelName}
                    updateActiveWeekDataStatus={updateActiveWeekDataStatus}
                  />
              </div>
            </div>
          </>
      )}
    </>
  );
};

export default MatrixSelector;
