import Api from './Api';

class RefinerService {
	getProducerList = async (exclude_xproducer = 1) =>
		await Api.get(`/producer/api/producer/?exclude_xproducer=${exclude_xproducer}`);
	xProducerConvert = async (data) =>
		await Api.post(`producer/api/producer/producer-xproducer/`, data);

	getExporterList = async (exclude_xexporter = 1) =>
		await Api.get(`/chain/api/exporter/?exclude_xexporter=${exclude_xexporter}`);
	xExporterConvert = async (data) =>
		await Api.post(`/chain/api/exporter/exporter-xexporter/`, data);

}

export default new RefinerService();
