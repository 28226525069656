import Api from './Api';

class PurchaseServices {
	updatePurchaseMatrix = async (matrxId, data) =>
		await Api.put(`/purchaser/api/purchase/${matrxId}/`, data);

	getPurchaseMatrixData = async (data) =>
		await Api.post(`purchaser/api/purchase/purchase-lists/`, data);

	getPuchaseList = async () =>
		await Api.get('/purchaser/api/purchase/current_week-purchases/');

	getPurchaseMatirx = async (data) =>
		await Api.get(`/purchaser/api/purchase/?purchase_id=${data}`);

	getPurchasePriceLine = async (data) =>
		await Api.post(`/purchaser/api/purchase-priceline/`, data);

	createBatch = async (data)=>
		await Api.post(`/purchaser/api/purchase-batch/batch-place/`,data);
}
export default new PurchaseServices();
