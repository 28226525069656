import React, { useState } from "react";
import eventBinder from "../../hoc/eventBinder";

const ButtonComponent = ({
  children,
  type,
  ...props
}) => {
  return (
    <button {...props} type="button"  >
      {children || "button"}
    </button>
  );
};
const Button = eventBinder(ButtonComponent);
export default Button;
