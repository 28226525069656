import React, {useState} from 'react'
import { Link } from 'react-router-dom';
import Sidebar from '../components/Sidebar/Sidebar';
import Species from '../components/seafood/Species';
import LanguageSelector from '../components/ModelSelectors/LanguageSelector';

const SpeciesPage = () => {

    const [language, setLanguage] = useState('')

    const handleLanguageChange = (e) => {
        setLanguage(e.target.value)
    };

    return (
        <section className={'body_wrap'}>
        {/* <Sidebar /> */}
        <div className={'cont_wp'}>
            <Link to={'/home'}>
                <p className={'Back_link'}>Back</p>
             </Link>
             <section className={'cmn_head'}>
                 <p>Species</p>
            </section>
            <LanguageSelector handleChange={ handleLanguageChange }/>
            <Species language={language}/>
        </div>
    </section>
    )
}

export default SpeciesPage

